import React from 'react'
import { Typography } from '@material-ui/core'
import { Col } from 'reactstrap'
import { isUndefined } from '../../../shared/utils/common'

interface IFourColumnRowElement {
  labelmd?: number
  labelxs?: number
  valuexs?: number
  valuemd?: number
}

export const fourColumnRowElement = (label: string, value: string, options?: IFourColumnRowElement, key?: string) => {
  const labelxs = options?.labelxs || 6
  const labelmd = options?.labelmd || 3
  const valuexs = options?.valuexs || 6
  const valuemd = options?.valuemd || 3
  return (
    <React.Fragment key={!isUndefined(key) ? key : null}>
      <Col xs={labelxs} md={labelmd}>
        <Typography>{label}</Typography>
      </Col>
      <Col xs={valuexs} md={valuemd}>
        <Typography className="bold text-right">{value}</Typography>
      </Col>
    </React.Fragment>
  )
}
