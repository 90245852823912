import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { DividerWithDiamond } from 'app/shared/components/divider-with-diamond'
import { dateFormat, currencyFormat, percentFormat, isUndefined } from 'app/shared/utils/common'
import { fourColumnRowElement } from '../common/component.util'

export function RequestOverview(props: any) {
  const values = {
    id: props.request.id,
    settlementDate: !isUndefined(props.request.settlementDate)
      ? dateFormat(props.request.settlementDate, props.locale)
      : null,
    firstInstallment: !isUndefined(props.request.firstInstallmentDate)
      ? dateFormat(props.request.firstInstallmentDate, props.locale)
      : null,
    dateOfApplication: dateFormat(props.request.dateOfApplication, props.locale),
    installmentAmount: currencyFormat(props.request.installmentAmount, props.locale),
    installmentLast: currencyFormat(props.request.installmentLast, props.locale),
    installmentLastUnformatted: props.request.installmentLast,
    totalLoan: currencyFormat(props.request.totalLoan, props.locale),
    duration: props.request.duration ? props.request.duration : '-',
    totalAmount: currencyFormat(props.request.totalAmount, props.locale),
    interestRateEffective: percentFormat(props.request.interestRateEffective, props.locale)
  }

  const dateOfApplication = fourColumnRowElement('Antragsdatum', values.dateOfApplication, null, 'dateOfApplication')
  const settlementDate = fourColumnRowElement('Abrechnungsdatum', values.settlementDate, null, 'settlementDate')
  const id = fourColumnRowElement('Vorgangsnummer', values.id, null, 'vorgangsnummerId')
  const installmentAmount = fourColumnRowElement('Ratenhöhe', values.installmentAmount, null, 'installmentAmount')
  const installmentLast =
    values.installmentLastUnformatted === 0
      ? ''
      : fourColumnRowElement('Schlussrate', values.installmentLast, null, 'installmentLast')
  const duration = fourColumnRowElement('Laufzeit', values.duration + ' Monate', null, 'duration')
  const totalLoan = fourColumnRowElement('Gesamtkreditbetrag', values.totalLoan, null, 'totalLoan')
  const interestRateEffective = fourColumnRowElement(
    'Effektiver Jahreszins',
    values.interestRateEffective,
    null,
    'interestRateEffective'
  )
  const totalAmount = fourColumnRowElement('Gesamtbetrag', values.totalAmount, null, 'totalAmount')
  const firstInstallment = fourColumnRowElement('Ratenbeginn', values.firstInstallment, null, 'firstIntallment')

  const requestHeader = [dateOfApplication, id]
  const contractHeader = [settlementDate, id]

  const requesttWithLastInstallment = [
    installmentAmount,
    installmentLast,
    totalLoan,
    duration,
    totalAmount,
    interestRateEffective
  ]

  const requestWithoutLastInstallment = [installmentAmount, duration, totalLoan, interestRateEffective, totalAmount]

  const contract = [
    firstInstallment,
    installmentAmount,
    totalLoan,
    duration,
    totalAmount,
    installmentLast,
    interestRateEffective
  ]

  return (
    <Container className="container-with-border content-container">
      <Row noGutters className="fourColumnRow">
        {(values.settlementDate && contractHeader) || requestHeader}
      </Row>
      <Row noGutters>
        <Col>
          <DividerWithDiamond />
        </Col>
      </Row>
      <Row noGutters className="fourColumnRow">
        {(values.settlementDate && contract) ||
          (values.installmentLastUnformatted > 0 && requesttWithLastInstallment) ||
          requestWithoutLastInstallment}
      </Row>
    </Container>
  )
}
