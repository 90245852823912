import { AUTH } from 'app/config/constants'
import { jwtDecode, JwtPayload } from 'jwt-decode'

interface CustomJwtPayload extends JwtPayload {
  refresh?: number;
  validTime?: number;
}

export const tokenIsValid = () => {
  if (window.sessionStorage.getItem(AUTH.TOKEN_KEY)) {
    const decodedToken = jwtDecode<CustomJwtPayload>(window.sessionStorage.getItem(AUTH.TOKEN_KEY))
    return decodedToken.exp > Math.round(new Date().getTime() / 1000)
  } else {
    return false
  }
}

export const tokenNeedsRefresh = () => {
  if (window.sessionStorage.getItem(AUTH.TOKEN_KEY)) {
    const decodedToken = jwtDecode<CustomJwtPayload>(window.sessionStorage.getItem(AUTH.TOKEN_KEY))
    return Math.round(decodedToken.refresh / 1000) < Math.round(new Date().getTime() / 1000)
  } else {
    return false
  }
}

export const getSecondsTotalValidFromToken = () => {
  if (window.sessionStorage.getItem(AUTH.TOKEN_KEY)) {
    const decodedToken = jwtDecode<CustomJwtPayload>(window.sessionStorage.getItem(AUTH.TOKEN_KEY))
    return decodedToken.validTime
  } else {
    return -1
  }
}
