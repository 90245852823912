import React from 'react'

import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Typography, Divider } from '@material-ui/core'
import { IFooter } from './footer'

export class DesktopFooter extends React.Component<IFooter> {
  render() {
    const faqLink = this.props.isPublic ? '/faq' : '/portal/faq'
    const supportLink = this.props.isPublic ? '/support' : '/portal/support'

    return (
      <>
        <div className="footer-links-no-mobile">
          <Divider className="red-divider" />
          <Container fluid className="p-0">
            <Row noGutters>
              <Col sm="3">
                <ul>
                  {!this.props.isPublic &&
                  <li>
                    <Link to='/portal/contact'>
                      <Typography>
                        <i className="far fa-envelope"></i> Kontakt
                      </Typography>
                    </Link>
                  </li>}
                  <li>
                    <Link to={supportLink}>
                      <Typography>
                        <i className="far fa-envelope"></i> Technischer Support
                      </Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to={faqLink}>
                      <Typography>
                        <i className="far fa-question-circle"></i> FAQ
                      </Typography>
                    </Link>
                  </li>
                  <li>
                    <a href="https://www.honda.de/cars/dealer-search.html" rel="noopener noreferrer" target="_blank">
                      <Typography>
                        <i className="fas fa-map-marker-alt"></i> Händlersuche
                      </Typography>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.honda.de/cars/konfigurator.html" rel="noopener noreferrer" target="_blank">
                      <Typography>
                        <i className="fas fa-wrench"></i> Konfigurator
                      </Typography>
                    </a>
                  </li>
                </ul>
              </Col>
              <Col sm="3">
                <ul>
                  <Typography>
                    <li className="footer-head">HONDA BANK</li>
                  </Typography>
                  <a href="https://www.honda-bank.de/werwirsind.php" rel="noopener noreferrer" target="_blank">
                    <Typography>
                      <li>Über uns</li>
                    </Typography>
                  </a>
                  <a href="https://www.honda-bank.de/kontakt_index.php" rel="noopener noreferrer" target="_blank">
                    <Typography>
                      <li>Kontakt</li>
                    </Typography>
                  </a>
                </ul>
              </Col>
              <Col sm="3">
                <ul>
                  <Typography>
                    <li className="footer-head">MEHR VON HONDA</li>
                  </Typography>
                  <a href="https://www.honda.de/cars.html" rel="noopener noreferrer" target="_blank">
                    <Typography>
                      <li>Automobile</li>
                    </Typography>
                  </a>
                  <a href="https://www.honda.de/motorcycles.html" rel="noopener noreferrer" target="_blank">
                    <Typography>
                      <li>Motorräder</li>
                    </Typography>
                  </a>
                </ul>
              </Col>
              <Col sm="3">
                <ul>
                  <Typography>
                    <li className="footer-head">NÜTZLICHE LINKS</li>
                  </Typography>
                  <Link to="/impressum">
                    <Typography>
                      <li>Impressum</li>
                    </Typography>
                  </Link>
                  <Link to="/dataSecurity">
                    <Typography>
                      <li>Datenschutz&shy;hinweise</li>
                    </Typography>
                  </Link>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}

export default DesktopFooter
