import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormGroup, Row, Col, Form } from 'reactstrap'
import { withRouter, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { IRootState } from 'app/shared/reducers'
import {
  checkConfirmationCodeForUser,
  clearErrorMessage,
  sendConfirmationCode,
  completeRegistration,
  clearRegistration
} from 'app/shared/reducers/registration.reducer'
import { getSession } from 'app/shared/reducers/authentication.reducer'
import { stringIsEmpty } from 'app/shared/utils/common'
import IKeyPassword from 'app/model/keyandpassword.model'

export interface IRegistrationConfirmationCode extends StateProps, DispatchProps {}

export interface IRegistrationConfirmationCodeStateProps {
  errorDescription: string
  codeSendingEnabled: boolean
  codeRequested: boolean
}

export class RegistrationConfirmationCode extends React.Component<
  IRegistrationConfirmationCode & RouteComponentProps,
  IRegistrationConfirmationCodeStateProps
> {
  inputField1: React.RefObject<any>
  inputField2: React.RefObject<any>
  inputField3: React.RefObject<any>
  inputField4: React.RefObject<any>

  constructor(props) {
    super(props)
    this.inputField1 = React.createRef()
    this.inputField2 = React.createRef()
    this.inputField3 = React.createRef()
    this.inputField4 = React.createRef()
    this.state = {
      errorDescription: '',
      codeSendingEnabled: true,
      codeRequested: false
    }
  }

  previousRegistrationStep = () => {
    this.props.history.push('/registration/verify')
  }

  nextRegistrationStep = async () => {
    await this.props.getSession()
    this.props.clearRegistration()
    this.props.history.push('/portal')
  }

  completeRegistration = () => {
    if (!this.allEntriesAreValid()) {
      this.props.history.push('/registration/error')
    } else {
      var keyAndPassword = {
        key: this.props.registrationKey,
        password: this.props.password
      } as IKeyPassword
      this.props.completeRegistration(keyAndPassword, this.nextRegistrationStep)
    }
  }

  allEntriesAreValid = () => {
    if (
      stringIsEmpty(this.props.user.email) ||
      stringIsEmpty(this.props.password) ||
      stringIsEmpty(this.props.pwRepeat)
    ) {
      return false
    }
    if (this.props.password !== this.props.pwRepeat) {
      return false
    }
    if (!this.props.phoneVerified || !this.props.legalTermsAccepted) {
      return false
    }
    return true
  }

  newCodeWasRequested = () => {
    this.setState({ ...this.state, codeRequested: true })
  }

  disableCodeSending = () => {
    this.setState({ ...this.state, codeSendingEnabled: false })
  }

  goToErrorPage = () => {
    this.props.history.push('/registration/error')
  }

  validate = () => {
    for (let i = 1; i < 5; i++) {
      if (
        stringIsEmpty(this['inputField' + i].current.value) ||
        this['inputField' + i].current.validity.valid === false
      ) {
        this.props.clearErrorMessage()
        this.setState({
          ...this.state,
          errorDescription: 'Es müssen alle Felder eingegeben werden.'
        })
        return false
      }
    }
    this.setState({ ...this.state, errorDescription: '' })
    return true
  }

  getValuesAsString = () => {
    return (
      '' +
      this.inputField1.current.value +
      this.inputField2.current.value +
      this.inputField3.current.value +
      this.inputField4.current.value
    )
  }

  submit = () => {
    if (this.validate()) {
      const enteredCode = this.getValuesAsString()
      this.props.checkConfirmationCodeForUser(
        this.props.registrationKey,
        enteredCode,
        this.completeRegistration,
        this.goToErrorPage
      )
    }
  }

  getIdFromTarget = (target: string): number => {
    return parseInt(target.slice(-1))
  }

  handleFieldInput = (target: number, input) => {
    this['inputField' + target].current.value = input
    if (input !== '') {
      let focusTarget = target + 1
      if (focusTarget > 4) {
        focusTarget = 1
      }
      this['inputField' + focusTarget].current.focus()
    }
  }

  handleKeyDown = (event) => {
    event.preventDefault()
    if (event.key === 'Enter') {
      this.submit()
    } else if (event.key === 'Backspace' || event.key === ' ') {
      this.handleFieldInput(this.getIdFromTarget(event.target.id), '')
    } else if (event.key.length === 1) {
      this.handleFieldInput(this.getIdFromTarget(event.target.id), event.key)
    }
  }

  getNewConfirmationCode = () => {
    this.setState({ ...this.state, errorDescription: '' })
    this.props.clearErrorMessage()
    this.props.sendConfirmationCode(this.props.registrationKey, this.newCodeWasRequested, this.disableCodeSending)
  }

  render() {
    const codeInputProps = {
      required: true,
      maxLength: 1
    }
    if (this.props.isLoading) {
      return (
        <div className="paper inner-form">
          <Typography variant="h2" color="primary">
            Bestätigungscode
          </Typography>
          <Typography variant="body2" className="no-line-height" display="inline">
            Bestätigungscode eingeben
          </Typography>
          <div className="margin2" />
          <CircularProgress />
        </div>
      )
    }
    if (stringIsEmpty(this.props.user.email)) {
      return <Redirect to="/registration/error" />
    } else {
      return (
        <div className="paper inner-form">
          <Typography variant="h3" color="primary">
            Bestätigungscode
          </Typography>
          <Typography variant="body2" className="no-line-height" display="inline">
            Bestätigungscode eingeben
          </Typography>
          <div className="margin" />
          <Form>
            <FormGroup>
              {this.props.errorMessage && (
                <>
                  <div className="margin" />
                  <Typography className="validationFailed" id="pwValidationLabel" variant="body1">
                    {this.props.errorMessage}
                  </Typography>
                  <div className="margin1" />
                </>
              )}
              {!this.props.errorMessage && !this.state.codeRequested && (
                <>
                  <Typography>
                    Vielen Dank für die Verifizierung Ihrer Mobilnummer. Sie erhalten in wenigen Minuten Ihren
                    Bestätigungscode per SMS.
                  </Typography>
                  <div className="margin" />
                  <Typography>Eingabe Bestätigungscode:</Typography>
                </>
              )}
              {!this.props.errorMessage && this.state.codeRequested && (
                <>
                  <Typography>
                    Sie haben einen neuen Bestätigungscode angefordert. Der vorherige Code ist nicht mehr gültig. Sie
                    erhalten den neuen Code in wenigen Minuten per SMS.{' '}
                  </Typography>
                  <div className="margin" />
                  <Typography>Eingabe Bestätigungscode:</Typography>
                </>
              )}
              <Row className="phoneDigitsFields">
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField1}
                    id="confirmationCodeChar1"
                    variant="outlined"
                    inputProps={{ ...codeInputProps }}
                    onKeyDown={this.handleKeyDown}
                    autoFocus
                  />
                </Col>
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField2}
                    id="confirmationCodeChar2"
                    variant="outlined"
                    inputProps={{ ...codeInputProps }}
                    onKeyDown={this.handleKeyDown}
                  />
                </Col>
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField3}
                    id="confirmationCodeChar3"
                    variant="outlined"
                    inputProps={{ ...codeInputProps }}
                    onKeyDown={this.handleKeyDown}
                  />
                </Col>
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField4}
                    id="confirmationCodeChar4"
                    variant="outlined"
                    inputProps={{ ...codeInputProps }}
                    onKeyDown={this.handleKeyDown}
                  />
                </Col>
              </Row>
            </FormGroup>
            <div className="margin" />
            {this.state.errorDescription && (
              <Typography className="validationFailed" id="pwValidationLabel" variant="body2">
                {this.state.errorDescription}
              </Typography>
            )}
            <div className="margin" />
            <FormGroup className="inline">
              <Button
                className="white-square-button bordered-button"
                color="primary"
                onClick={this.previousRegistrationStep}>
                Zurück
              </Button>
              <Button className="square-button red-border" onClick={this.submit} variant="contained" color="secondary">
                Weiter
              </Button>
            </FormGroup>
          </Form>
          {this.props.errorMessage && this.state.codeSendingEnabled && (
            <>
              <Button className="lower-case" color="primary" onClick={this.getNewConfirmationCode}>
                Code erneut zusenden
              </Button>
            </>
          )}
        </div>
      )
    }
  }
}

const mapStateToProps = ({ registration }: IRootState) => ({
  errorMessage: registration.errorMessage,
  registrationKey: registration.registrationKey,
  user: registration.user,
  isLoading: registration.loading,
  password: registration.password,
  pwRepeat: registration.pwRepeat,
  phoneVerified: registration.phoneVerified,
  codeVerified: registration.codeVerified,
  legalTermsAccepted: registration.legalTermsAccepted
})

const mapDispatchToProps = {
  checkConfirmationCodeForUser,
  sendConfirmationCode,
  clearErrorMessage,
  completeRegistration,
  clearRegistration,
  getSession
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(RegistrationConfirmationCode)
