import React from 'react'
import { Container, Col, Row } from 'reactstrap'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  CircularProgress
} from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { connect } from 'react-redux'
import {
  sendVerificationCode,
  checkVerificationCode,
  resetErrorMessage,
  completeDeletion,
  setErrorMessage,
  resetState,
  previousStep
} from './delete.account.reducer'
import ValidationCodebox from 'app/components/codebox/validation.codebox'
import { IRootState } from 'app/shared/reducers'
import { compose } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { messages } from 'app/config/constants'

export interface IDeleteAccountProps extends StateProps, DispatchProps {
  anchor: string
}

export interface IDeleteAccountState {
  expanded: boolean
  startedDelete: boolean
}

export class DeleteAccount extends React.Component<IDeleteAccountProps & RouteComponentProps, IDeleteAccountState> {
  showExpanded = this.props.anchor !== null && this.props.anchor === 'delete'
  constructor(props) {
    super(props)
    this.state = {
      expanded: this.showExpanded,
      startedDelete: false
    }
  }

  componentDidMount() {
    this.props.resetState()
  }

  getSteps() {
    return ['Konto löschen', 'Bestätigungscode eingeben']
  }

  handleChange = () => {
    this.setState({ ...this.state, expanded: !this.state.expanded })
  }

  startDelete = () => {
    this.setState({ ...this.state, startedDelete: true })
  }

  completeDelete = () => {
    this.props.completeDeletion(this.logout)
  }

  sendCode = () => {
    this.props.sendVerificationCode()
  }

  checkSmsCode = (enteredCode: string) => {
    this.props.checkVerificationCode(enteredCode, this.completeDelete, this.handleUserLocked)
  }

  reset = () => {
    this.setState({ ...this.state, startedDelete: false })
    this.props.resetState()
  }

  handleUserLocked = () => {
    this.props.history.push('/locked')
  }

  logout = () => {
    this.props.history.push('/deleted')
  }

  getStepContent = (index: number) => {
    const codeboxProps = {
      onValidSubmit: this.checkSmsCode,
      onBack: this.reset,
      resetError: this.props.resetErrorMessage,
      setError: this.props.setErrorMessage,
      hasBack: false,
      errorDescription: this.props.errorMessage,
      loading: this.props.loading,
      nextButtonText: 'Konto endgültig löschen',
      previousFunc: this.props.previousStep
    }

    switch (index) {
      case 0:
        return (
          <Container fluid className="p-0">
            <Row noGutters>
              <Col>
                <Typography variant="body2" className="regular-line-height text-margin">
                  Wenn Sie Ihr Honda Bank Konto löschen, werden alle damit verbundenen Daten gelöscht.
                </Typography>
                <Typography variant="body2" className="regular-line-height text-margin">
                  Sind Sie sich sicher das Konto zu löschen?
                </Typography>
              </Col>
            </Row>
            {this.props.errorMessage && this.props.errorMessage === messages.CODE_SENDING_ERROR && (
              <Typography className="validationFailed regular-line-height text-margin" variant="body2">
                Wir haben versucht Ihnen einen Bestätigungscode per SMS zu senden, allerdings haben Sie das tägliche
                Limit an Nachrichten bereits überschritten. Bitte versuchen Sie es morgen erneut oder wenden Sie sich an
                unseren Kundenservice.
              </Typography>
            )}
            {this.props.errorMessage && this.props.errorMessage !== messages.CODE_SENDING_ERROR && (
              <Typography className="validationFailed regular-line-height text-margin" variant="body2">
                Beim Versuch Ihnen einen Bestätigungscode per SMS zu senden, ist ein Fehler aufgetreten. Bitte versuchen
                Sie es erneut oder wenden Sie sich an unseren Kundenservice.
              </Typography>
            )}
            <Row noGutters>
              <Col>
                {(this.props.loading && (
                  <div className="text-center">
                    <CircularProgress></CircularProgress>
                  </div>
                )) || (
                  <div className="d-flex">
                    <Button
                      className="square-button no-uppercase fullWidth mr-2"
                      onClick={this.reset}
                      variant="contained"
                      color="primary">
                      Abbrechen
                    </Button>
                    <Button
                      className="square-button red-border no-uppercase account-button"
                      variant="contained"
                      color="secondary"
                      onClick={() => this.sendCode()}>
                      Konto jetzt löschen
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        )
      case 1:
        return (
          <Container fluid className="p-0">
            <Row noGutters>
              <Col>
                <Typography variant="body2" className="regular-line-height text-margin">
                  Sie erhalten in wenigen Minuten Ihren Bestätigungscode per SMS. Bitte geben Sie diesen unten ein.
                </Typography>
                <Typography variant="body2" className="regular-line-height text-margin">
                  Eingabe Bestätigungscode:
                </Typography>
              </Col>
            </Row>
            <ValidationCodebox {...codeboxProps} />
          </Container>
        )
      default:
        return 'ERROR'
    }
  }

  render() {
    return (
      <Accordion
        id="delete"
        className="container-with-border content-container"
        expanded={this.state.expanded}
        onChange={() => this.handleChange()}>
        <AccordionSummary expandIcon={<RedTriangle medium />}>
          <Typography>Konto löschen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {(!this.state.startedDelete && (
            <Container fluid className="p-0">
              <Row noGutters>
                <Col xs="12" md="6" className="mobile-margin-bottom">
                  <Typography variant="body2" className="regular-line-height bold">
                    Konto löschen:
                  </Typography>
                  <Typography variant="body2" className="regular-line-height">
                    Hier können Sie Ihr Honda Bank Portal Konto löschen. Alle mit Ihrem Konto verbundenen Daten werden
                    entfernt.
                  </Typography>
                </Col>
                <Col xs="12" md="6" className="text-center margin-auto">
                  <Button
                    className="square-button red-border no-uppercase account-button"
                    variant="contained"
                    color="secondary"
                    onClick={() => this.startDelete()}>
                    Konto löschen
                  </Button>
                </Col>
              </Row>
            </Container>
          )) || (
            <Stepper activeStep={this.props.currentStep} orientation="vertical" className="email-reset">
              {this.getSteps().map((label, index) => {
                const stepProps = {}
                const labelProps = {}
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    <StepContent>{this.getStepContent(index)}</StepContent>
                  </Step>
                )
              })}
            </Stepper>
          )}
          {this.props.currentStep === 2 && (
            <Container fluid className="p-0">
              <Row noGutters>
                <Col>
                  <Typography className="email-reset-header mw-100">Ihr Konto wurde erfolgreich gelöscht.</Typography>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <Button
                    className="square-button red-border no-uppercase"
                    variant="contained"
                    color="secondary"
                    onClick={() => this.reset()}>
                    OK
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        </AccordionDetails>
      </Accordion>
    )
  }
}

const mapStateToProps = ({ deleteaccount }: IRootState) => ({
  loading: deleteaccount.loading,
  errorMessage: deleteaccount.errorMessage,
  currentStep: deleteaccount.currentStep
})

const mapDispatchToProps = {
  sendVerificationCode,
  checkVerificationCode,
  resetErrorMessage,
  completeDeletion,
  setErrorMessage,
  resetState,
  previousStep
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(DeleteAccount)
