import './inbox.css'

import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import { IRootState } from 'app/shared/reducers'

import { getMessages } from './inbox.reducer'
import { Typography } from '@material-ui/core'
import InboxFolders from './inbox.folders'
import InboxMessages from './inbox.messages'
import { Container, Row, Col } from 'reactstrap'

export interface IInboxProps extends StateProps, DispatchProps {}

export class Inbox extends React.Component<IInboxProps> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.getMessages()
  }

  render() {
    return (
      <div className="contentContainer mb-5">
        <Typography variant="h3" className="section-heading">
          Mein Postfach
        </Typography>
        <Container fluid className="p-0 mt-1 mb-3">
          <Row noGutters>
            <Col className="subheading-message">
              <Typography>
                Unsere Nachrichten stehen Ihnen zeitlich befristet zur Verfügung und werden nach einer bestimmten Zeit
                automatisch gelöscht. Verschieben Sie Mitteilungen ins Archiv, um sie unbefristet zu speichern.
              </Typography>
            </Col>
          </Row>
        </Container>
        <Switch>
          <Route path="/portal/inbox/:section" component={InboxMessages} />
          <Route path="/portal/inbox" component={InboxFolders} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = ({ inbox }: IRootState) => ({
  messages: inbox.messages
})

const mapDispatchToProps = {
  getMessages
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(Inbox)
