import './registration.css'

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import RegistrationKeyForm from './registration.key'
import RegistrationPasswordForm from './registration.password'
import RegistrationLegalForm from './registration.legal'
import RegistrationVerifyPhone from './registration.verifyphone'
import RegistrationCodeForm from './registration.confirmationcode'
import RegistrationNewUserForm from './registration.new.enter'
import RegistrationNewUserConfirmed from './registration.new.confirmed'
import RegistrationErrorForm from './registration.error'
import logo from 'resources/images/honda_financial_services_logo.png'
import RegistrationFooter from 'app/components/legal/registrationFooter'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'
import { getBikeOrCarBackgroundClassName } from 'app/shared/utils/common'
import RegistrationNewUserError from './registration.new.error'

export interface RegistrationRouterProps extends StateProps, DispatchProps {}

export class RegistrationRouter extends React.Component<RegistrationRouterProps> {
  render() {
    return (
      <>
        <Grid container className="root">
          <div {...getBikeOrCarBackgroundClassName(this.props.user)}>
            <Grid style={{ minHeight: '850px' }} className="registrationBox" component={Paper} square>
              <div className="paper inner-form">
                <img className="form-logo" src={logo} alt="Honda Bank Logo" />
                <Switch>
                  <Route path="/registration/key/:userKey" component={RegistrationKeyForm} />
                  <Route path="/registration/key" component={RegistrationKeyForm} />
                  <Route path="/registration/password" component={RegistrationPasswordForm} />
                  <Route path="/registration/legal" component={RegistrationLegalForm} />
                  <Route path="/registration/verify" component={RegistrationVerifyPhone} />
                  <Route path="/registration/confirmationcode" component={RegistrationCodeForm} />
                  <Route path="/registration/new/enter" component={RegistrationNewUserForm} />
                  <Route path="/registration/new/confirmed" component={RegistrationNewUserConfirmed} />
                  <Route path="/registration/new/error" component={RegistrationNewUserError} />
                  <Route path="/registration/error" component={RegistrationErrorForm} />
                  <Route path="/registration/" component={RegistrationErrorForm} />
                </Switch>
                <RegistrationFooter />
              </div>
            </Grid>
          </div>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = ({ registration }: IRootState) => ({
  user: registration.user
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationRouter)
