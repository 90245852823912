import React from 'react'
import { Typography, Divider } from '@material-ui/core'
import FAQRow from './faq.row'
import IFAQContent from 'app/model/faqcontent.model'

export interface IFAQ {
  content: IFAQContent[]
}

export class FAQ extends React.Component<IFAQ> {
  render() {
    return (
      <div className="contentContainer mt-4 mb-5">
        <Typography variant="h3" className="section-heading">
          Häufig gestellte Fragen (FAQ)
        </Typography>
        <div className="content-container faq-container">
          {this.props.content.map((row, key) => (
            <div key={key}>
              <FAQRow title={row.title} text={row.text} />
              {key < this.props.content.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default FAQ
