import './contract.css'

import React from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from 'react-router-dom'

import { isUndefined } from 'app/shared/utils/common'
import { IRootState } from 'app/shared/reducers'

import { ContractEntity } from './contract.entity'

export interface IContractSelectProps extends StateProps, DispatchProps {}

export class ContractSelect extends React.Component<IContractSelectProps & RouteComponentProps> {
  render() {
    const contracts = this.props.contracts
    const showLoading = this.props.loading || isUndefined(contracts)

    const contractDisplayData = []

    if (showLoading) {
      contractDisplayData.push(
        <div className="text-center" key="1">
          <CircularProgress></CircularProgress>
        </div>
      )
    } else {
      contracts.forEach((contract, key) => {
        const props = {
          contract: { ...contract }
        }
        contractDisplayData.push(
          <div key={key}>
            <Link to={`/portal/contract/${contract.id}`}>
              <ContractEntity {...props} />
            </Link>
          </div>
        )
      })
    }

    return (
      <div className="contentContainer">
        <Typography variant="h3" className="section-heading" id="select">
          Vertrag auswählen
        </Typography>
        {contractDisplayData}
      </div>
    )
  }
}

const mapStateToProps = ({ data }: IRootState) => ({
  contracts: data.contracts,
  loading: data.loading
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(ContractSelect)
