import React from 'react'
import { Divider, Button, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { Container, Row, Col, NavLink } from 'reactstrap'

import logo from 'resources/images/honda_financial_services_logo.png'
import Footer from 'app/components/navigation/footer'

export class Impressum extends React.Component<RouteComponentProps> {
  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const footerProps = {
      isPublic: true
    }
    return (
      <>
        <Container fluid>
          <Row className="portal-header-bg">
            <Col className="portal-header">
              <NavLink to="/login">
                <div>
                  <img className="logo portal" src={logo} alt="Honda Logo" />
                </div>
              </NavLink>
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col>
              <Divider className="red-divider" />
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col xs="12">
              <div className="content-container contentContainer-wide mt-4 mb-5">
                <Typography variant="h3" className="section-heading mt-5">
                  Impressum
                </Typography>
                <div className="container-with-border ml-2 mr-2 mt-3">
                  <Container>
                    <Row>
                      <Col xs="12">
                        <Typography>
                          Alle Inhalte dieser Website wurden mit größtmöglicher Sorgfalt erfasst. Wir bitten jedoch um
                          Verständnis, dass wir keine Gewähr für Fehlerfreiheit und Richtigkeit der Informationen und
                          Konditionen übernehmen können.
                        </Typography>
                        <Typography>
                          Wir schließen jede Haftung für eventuell fehlerhafte Inhalte und Daten dieser Website aus.
                          Bitte setzen Sie sich für die aktuellsten Informationen mit Ihrem Honda-Händler in Verbindung.
                        </Typography>
                        <Typography variant="h4" className="mt-4 bold">
                          Copyright:
                        </Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          Honda Bank GmbH
                        </Typography>
                        <Typography className="mt-3">
                          Hanauer Landstraße 222-226
                          <br />
                          D-60314 Frankfurt am Main
                          <br />
                          Tel.: 069 4898-0
                          <br />
                          Fax: 069 4898-9011
                          <br />
                          Honda.Bank@honda-eu.com
                        </Typography>
                        <Typography className="mt-3">
                          Geschäftsführer:
                          <br />
                          Volker Boehme
                          <br />
                          Ingo Husemeyer
                        </Typography>
                        <Typography className="mt-3">
                          Handelsregister des Amtsgerichts Frankfurt am Main:
                          <br />
                          HRB 76327
                        </Typography>
                        <Typography className="mt-3">
                          Umsatzsteuer-Identifikationsnummer:
                          <br />
                          DE 194809305
                        </Typography>
                        <Typography className="mt-3">
                          Zuständige Aufsichtsbehörde:
                          <br />
                          Bundesanstalt für Finanzdienstleistungsaufsicht <br />
                          Sektor Bankenaufsicht
                          <br />
                          Graurheindorfer Straße 108
                          <br />
                          D - 53117 Bonn
                          <br />
                          <a href="mailto:Poststelle@bafin.de">Poststelle@bafin.de</a>
                          <a href="http://www.bafin.de/" target="_blank" rel="noreferrer noopener">
                            http://www.bafin.de/
                          </a>
                        </Typography>
                        <Typography className="mt-3">
                          Im Falle von den Kredit- oder Leasingvertrag betreffenden Streitigkeiten mit der Bank können
                          die Kredit- bzw. Leasingnehmer ein außergerichtliches Schlichtungsverfahren durch den
                          Bundesverband deutscher Banken e.V., Burgstraße 28, 10178 Berlin, herbeiführen. Informationen
                          zu dem Schlichtungsverfahren sowie ein Beschwerdeformular finden sich im Internet unter{' '}
                          <a href="http://www.bankenverband.de/ombudsmann" target="_blank" rel="noreferrer noopener">
                            http://www.bankenverband.de/ombudsmann
                          </a>
                          .
                        </Typography>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
            <Col xs="12">
              <div className="contentContainer mt-4 mb-5 text-center">
                <Button
                  className="square-button red-border"
                  type="submit"
                  onClick={this.goBack}
                  variant="contained"
                  color="secondary">
                  Zurück
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer {...footerProps} />
      </>
    )
  }
}

export default withRouter(Impressum)
