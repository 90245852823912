import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { Container, Row, Col } from 'reactstrap'
import { currencyFormat, percentFormat } from 'app/shared/utils/common'
import { DividerWithDiamond } from 'app/shared/components/divider-with-diamond'
import { fourColumnRowElement } from '../common/component.util'

export function ContractOverview(props: any) {
  const showExpanded = props.anchor !== null && props.anchor === 'overview'
  const [expanded, setExpanded] = React.useState(showExpanded)

  const handleChange = () => () => {
    setExpanded(!expanded)
  }

  const contractId = fourColumnRowElement('Kreditvertrag-Nr.', props.contract.contractId, null, 'contractId')
  const balance = fourColumnRowElement(
    'Aktueller Saldo',
    currencyFormat(props.contract.balance, props.locale),
    null,
    'balance'
  )
  const interestRate = fourColumnRowElement(
    'Sollzinssatz',
    percentFormat(props.contract.interestRate, props.locale),
    null,
    'interestRate'
  )
  const interestRateEffective = fourColumnRowElement(
    'Effektiver Jahreszins',
    percentFormat(props.contract.contractInterestRateEffective, props.locale),
    null,
    'interestRateEffective'
  )
  const paymentMethod = fourColumnRowElement('Zahlungsweise', props.contract.paymentMethod, null, 'paymentMethod')

  return (
    <Accordion
      id="overview"
      className="container-with-border content-container"
      expanded={expanded}
      onChange={handleChange()}>
      <AccordionSummary expandIcon={<RedTriangle medium />}>
        <Typography className="bold">Kreditvertragsübersicht</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container fluid className="p-0">
          <Row noGutters className="fourColumnRow">
            {contractId}
            {balance}
          </Row>
          <Row noGutters>
            <Col>
              <DividerWithDiamond />
            </Col>
          </Row>
          <Row noGutters className="fourColumnRow">
            {interestRate}
            {interestRateEffective}
            {paymentMethod}
          </Row>
          <Row noGutters className="pt-2">
            <Col align="left">
              <a
                href="https://www.honda-bank.de/faq.php"
                target="_blank"
                rel="noopener noreferrer"
                className="regular-line-height">
                <Typography>
                  <i className="far fa-question-circle"></i> Sie haben Fragen zu Ihrem Vertrag? Mehr Informationen
                  finden Sie in den FAQ.
                </Typography>
              </a>
            </Col>
          </Row>
        </Container>
      </AccordionDetails>
    </Accordion>
  )
}
