import React from 'react'
import { Divider, Button, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { Container, Row, Col, NavLink } from 'reactstrap'

import logo from 'resources/images/honda_financial_services_logo.png'
import Footer from 'app/components/navigation/footer'
import config from 'app/config/constants'

export class LegalInformation extends React.Component<RouteComponentProps> {
  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const footerProps = {
      isPublic: true
    }
    return (
      <>
        <Container fluid>
          <Row className="portal-header-bg">
            <Col className="portal-header">
              <NavLink to="/login">
                <div>
                  <img className="logo portal" src={logo} alt="Honda Logo" />
                </div>
              </NavLink>
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col>
              <Divider className="red-divider" />
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col xs="12">
              <div className="content-container contentContainer-wide mt-4 mb-5">
                <Typography variant="h3" className="section-heading mt-5">
                  Nutzungsbedingungen für das Kundenportal der Honda Bank GmbH
                </Typography>
                <div className="container-with-border ml-2 mr-2 mt-3">
                  <Container>
                    <Row>
                      <Col xs="12">
                        <Typography>
                          Das Online-Kundenportal der Honda Bank GmbH (im Folgenden: HBG-Kundenportal) ist ein freiwilliger
                          Service der Honda Bank GmbH (im Folgenden  Honda Bank), Hanauer Landstraße 222-226, 60314 Frankfurt
                          am Main. Die Honda Bank stellt ihren Privatkunden unter {' '}
                          <a href={config.FRONTEND_URL} target="_blank" rel="noreferrer noopener">
                            {config.FRONTEND_URL}
                          </a>{' '}
                          die als HBG-Kundenportal angebotenen kostenlosen Serviceleistungen nach Maßgabe dieser Nutzungsbedingungen
                          zur Verfügung.
                        </Typography>
                        <Typography className="mt-3">
                          Diese Nutzungsbedingungen gelten nur für die Nutzung des HBG-Kundenportals und lassen alle sonstigen
                          Vereinbarungen des Nutzers mit der Honda Bank unberührt.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          1. Zugang und Registrierung
                        </Typography>
                        <Typography>
                          Der Zugang zum HBG-Kundenportal setzt einen Kreditantrag bzw. Kreditvertrag zwischen dem Nutzer und der
                          Honda Bank voraus.
                        </Typography>
                        <Typography className="mt-3">
                          Die Nutzung des HBG-Kundenportal bedarf einer vorherigen Registrierung, die online unter {' '}
                          <a href={config.FRONTEND_URL + 'registration/new/enter'} target="_blank" rel="noreferrer noopener">
                            {config.FRONTEND_URL + 'registration/new/enter'}
                          </a>{' '}
                          vorgenommen werden kann. Bei der Registrierung muss der Nutzer die dafür vorgesehenen Eingabefelder ausfüllen,
                          ein Passwort vergeben und diese Nutzungsbedingungen akzeptieren.
                        </Typography>
                        <Typography className="mt-3">
                          Im Anschluss wird dem Nutzer ein einmalig verwendbarer 4-stelligen Bestätigungscode an die von ihm bei der Honda
                          Bank hinterlegte Mobilnummer versandt. Nach Eingabe des Bestätigungscodes im HBG-Kundenportal erhält der Nutzer
                          eine E-Mail an die von ihm angegebene Adresse. Die E-Mail ist die Bestätigung der erfolgreichen Registrierung.
                        </Typography>
                        <Typography className="mt-3">
                          Der Zugang zum HBG-Kundenportal und den darin angebotenen Serviceleistungen erfolgt durch Anmeldung unter Eingabe
                          seiner gültigen Zugangsdaten (E-Mail und Passwort) unter {' '}
                          <a href={config.FRONTEND_URL + 'login'} target="_blank" rel="noreferrer noopener">
                            {config.FRONTEND_URL + 'login'}
                          </a>
                        </Typography>
                        <Typography className="mt-3">
                          Wenn die Registrierung fehlschlägt oder wenn der Nutzer bislang noch keine Mobilnummer für seinen Kreditvertrag
                          hinterlegt hat, kann er unsere Kundenbetreuung unter Support erreichen.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          2. Serviceumfang des HBG-Kundenportals
                        </Typography>
                        <Typography>
                          Der Nutzer hat auf dem HBG-Kundenportal die Möglichkeit, verschiedene Formulare und Dokumente zur weiteren
                          Verwendung und Information herunterzuladen. Zudem besteht die Möglichkeit, ausgefüllte Formulare und bestimmte
                          Dokumente (z.B. eine Heiratsurkunde) in das HBG-Kundenportal hochzuladen. Hochgeladene Dokumente dienen z.B. als
                          Nachweis für die Bearbeitung von Anträgen des Nutzers. Weiter besteht die Möglichkeit Änderungen der persönlichen
                          Angaben (Name, Anschrift, Telefonnummer, E-Mail-Adresse, Bankverbindung) zu aktualisieren.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          3. Verfügbarkeit des HBG-Kundenportals
                        </Typography>
                        <Typography>
                          Die Honda Bank bemüht sich um einen im Rahmen des technisch möglichen reibungslosen Betriebes des
                          HBG-Kundenportals.
                        </Typography>
                        <Typography className="mt-3">
                          Der Nutzer nimmt zur Kenntnis, dass die Verfügbarkeit des HBG-Kundenportals aufgrund von Störungen von Netzwerk
                          oder Telekommunikationsverbindungen, aufgrund höherer Gewalt, aufgrund von für den reibungslosen Betriebsablauf
                          erforderlichen Wartungsarbeiten oder sonstigen Umständen eingeschränkt oder zeitweise ausgeschlossen sein kann.
                        </Typography>
                        <Typography className="mt-3">
                          Die Honda Bank gibt keine Zusage hinsichtlich des dem Nutzer jeweils zur Verfügung stehenden Funktionsumfangs,
                          ist aber bemüht, die Online Serviceleistungen und Services stetig weiter zu verbessern.
                        </Typography>
                        <Typography className="mt-3">
                          Insoweit wird eine Haftung der Honda Bank ausdrücklich ausgeschlossen.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          4. Unverbindlichkeit der Angaben
                        </Typography>
                        <Typography>
                          Sämtliche in dem Kundenportal enthaltenen Angaben sind unverbindlich und erfolgen ohne Gewähr. Sie dienen nur
                          der Vorabinformation. Die Angaben beruhen auf regelmäßig, aber nicht unbedingt tagesgleich aktualisierte
                          Informationen. Abweichungen, insbesondere wegen Rundungsdifferenzen sind auch möglich. Maßgeblich für die
                          Abrechnung Ihres Vertrages sind nur die verbindlichen Angaben im bewilligten Antragsformular.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          5. Sorgfaltspflichten des Nutzers
                        </Typography>
                        <Typography>
                          Der Nutzer ist verpflichtet, die technische Verbindung zum HBG-Kundenportal nur über {' '}
                          <a href={config.FRONTEND_URL} target="_blank" rel="noreferrer noopener">
                            {config.FRONTEND_URL}
                          </a>{' '}
                          herzustellen.
                        </Typography>
                        <Typography className="mt-3">
                          Der Nutzer ist verpflichtet, seine Zugangsdaten (E-Mail und Passwort) vor dem Zugriff durch unberechtigte
                          Dritte geschützt aufzubewahren und diese Daten nicht an Dritte weiterzugeben. Insbesondere hat der Nutzer die
                          Honda Bank unverzüglich darüber in Kenntnis zu setzen, wenn er vermutet, dass unberechtigte Dritte von seinen
                          Zugangsdaten Kenntnis erlangt haben oder er einen nicht autorisierten oder fehlerhaften Sachverhalt feststellt.
                        </Typography>
                        <Typography className="mt-3">
                          Es dürfen nur Kundeninformationen hinterlegt werden, für die der Nutzer der eingetragene Kreditnehmer ist.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          6. Sperrung des Zugangs
                        </Typography>
                        <Typography>
                          Die Honda Bank GmbH behält sich das Recht vor, auch ohne Angabe von Gründen einzelnen Nutzern sowohl temporär
                          als auch permanent den Zugang zum Kundenportal zu verwehren. Eine solche Sperre wird insbesondere bei einem
                          Verstoß gegen die vorliegenden Nutzungsbedingungen oder Voraussetzungen für eine Kündigung durch die Honda Bank
                          aus wichtigem Grund in Betracht gezogen.
                        </Typography>
                        <Typography className="mt-3">
                          Die Honda Bank wird eine Sperre aufheben, wenn die Gründe für die Sperre nicht mehr gegeben sind und den Nutzer
                          unverzüglich hierüber unterrichten.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          7. Löschung des HBG-Kundenportal-Accounts
                        </Typography>
                        <Typography>
                          Der Nutzer kann sein HBG-Kundenportal-Account vollständig über die Funktion <i>„Konto löschen“</i> löschen. Sobald
                          dieses gelöscht ist, kann man sich mit den Zugangsdaten nicht mehr anmelden. Seitens der Honda Bank wird das
                          HBG-Kundenportal-Account mit einer Frist von 6 Monaten nach Ablauf des letzten Vertrages, sofern keinen neuen
                          Antrag gestellt ist oder der Nutzer hat sich innerhalb dieses Zeitraums nicht mehr im Portal angemeldet, löschen.
                          Danach stehen die Serviceleistungen des HBG-Kundenportal nicht mehr zur Verfügung. Die Honda Bank informiert den
                          Nutzer mindestens 3 Wochen vor Löschung des Accounts via E-Mail.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          8. Änderungen der Nutzungsbedingungen und des Serviceumfanges
                        </Typography>
                        <Typography>
                          Die Honda Bank behält sich das Recht vor, die Nutzungsbedingungen  mit Wirkung für die Zukunft zu ändern. Der
                          Nutzer wird über eine Anpassung im Zusammenhang mit der Anmeldung zum HBG-Kundenportal informiert. Die jeweils
                          aktuellen Nutzungsbedingungen sind über die Anmeldeseite für das HBG-Kundenportal und am Ende der Web-Seite
                          aufrufbar.
                        </Typography>
                        <Typography className="mt-3">
                          Ferner behält sich die Honda Bank das Recht vor, den Leistungsumfang des HBG-Kundenportals jederzeit zu erweitern,
                          zu kürzen oder Funktionalitäten zu ändern ggf. zu erweitern, mit oder ohne Mitteilung an den Nutzer. Die Honda
                          Bank ist dem Nutzer oder Dritten gegenüber nicht haftbar, wenn sie dieses Recht ausübt.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          9. Datenschutz
                        </Typography>
                        <Typography>
                          Die Honda Bank erhebt, verarbeitet und nutzt im Rahmen der Bereitstellung Ihres HBG-Kundenportal-Accounts Ihre
                          personenbezogenen Daten. Nähere Informationen zum Umgang mit Ihren Daten entnehmen Sie bitte den
                          Datenschutzhinweisen des HBG-Kundenportals.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          10. Haftung
                        </Typography>
                        <Typography>
                          Die im HBG-Kundenportal abrufbaren Informationen sind für den Nutzer lediglich rein informatorisch. Für die
                          Richtig- und Vollständigkeit übernimmt die Honda Bank keine Haftung.
                        </Typography>
                        <Typography className="mt-3">
                          Die Honda Bank haftet im Falle eines Verschuldens ihrer gesetzlichen Vertreter und/oder Erfüllungsgehilfen nur
                          für Vorsatz, grobe Fahrlässigkeit und die leicht fahrlässige Verletzung wesentlicher Vertragspflichten. Eine
                          Haftung der Honda Bank für Schäden, die durch den Missbrauch der Zugangsdaten und/oder fehlerhafte Eingabe im
                          HBG-Kundenportal verursacht werden, ist ausgeschlossen. Der Kunde hat alle von ihm eingegebenen Daten auf
                          Vollständigkeit und Richtigkeit zu prüfen und trägt daher die Verantwortung für die von ihm eingegebenen Daten.
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          11. Schlussbestimmung
                        </Typography>
                        <Typography>
                          Es gilt das Recht der Bundesrepublik Deutschland.
                        </Typography>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
            <Col xs="12">
              <div className="contentContainer mt-4 mb-5 text-center">
                <Button
                  className="square-button red-border"
                  type="submit"
                  onClick={this.goBack}
                  variant="contained"
                  color="secondary">
                  Zurück
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer {...footerProps} />
      </>
    )
  }
}

export default withRouter(LegalInformation)
