import React from 'react'
import { connect } from 'react-redux'

import { Row, Col, Container } from 'reactstrap'
import { IRootState } from 'app/shared/reducers'

import logo from 'resources/images/honda_financial_services_logo.png'
import { Divider, Typography } from '@material-ui/core'
import { NavHashLink as NavLink } from 'react-router-hash-link'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'

import { logout, refreshToken } from 'app/shared/reducers/authentication.reducer'
import INavPoint from 'app/model/navigation.model'
import {
  createNavigationTree,
  getSubnavigationPointByPathname,
  getDestinationFromNavPoint
} from 'app/shared/utils/navigation.util'
import { isUndefined, arrayIsEmpty, getMinutesAndSecondsFromInputSeconds } from 'app/shared/utils/common'

export interface IHeader extends StateProps, DispatchProps {
  navVisible
  toggleNav
  sessionRemainingSecond
}
export class Header extends React.Component<IHeader & RouteComponentProps> {
  createNavLinkList = (navigation: any[], navPoint: INavPoint, key: number) => {
    navigation.push(this.createNavLink(navPoint, 'portal-nav-link', key))
  }

  createNavLink = (navPoint: INavPoint, cssType: string, key: number) => {
    const destination = getDestinationFromNavPoint(navPoint)

    return (
      <NavLink key={key} className={cssType} to={destination} smooth={true}>
        <Typography>{navPoint.name}</Typography>
      </NavLink>
    )
  }

  createSubNavLinksForCurrentPage = (subnavigation: any[], navigationTree: INavPoint[]) => {
    const result = getSubnavigationPointByPathname(navigationTree, this.props.location.pathname)
    if (!isUndefined(result) && !arrayIsEmpty(result)) {
      result.forEach((entry, key) => {
        subnavigation.push(this.createNavLink(entry, 'portal-subnav-link', key))
      })
    }
  }

  render() {
    const navigationTree: INavPoint[] = createNavigationTree(this.props.requests, this.props.contracts)

    const navigation = []

    navigationTree.forEach((navPoint, key) => {
      this.createNavLinkList(navigation, navPoint, key)
    })

    const subnavigation = []
    this.createSubNavLinksForCurrentPage(subnavigation, navigationTree)

    const getUserName = () => {
      if (!isUndefined(this.props.profile)) {
        return this.props.profile.firstName + ' ' + this.props.profile.lastName
      } else {
        return ''
      }
    }

    return (
      <>
        <Container fluid>
          <Row className="portal-header-bg">
            <Col className="portal-header">
              <NavLink to="/portal">
                <div>
                  <img className="logo portal" src={logo} alt="Honda Logo" />
                </div>
              </NavLink>
              <div className="portal-nav not-mobile-flex">{navigation}</div>
              <div className="portal-header-end not-mobile-flex">
                <Typography>
                  <i className="fas fa-history session-refresh" onClick={this.props.refreshToken}></i>{' '}
                  <span className="mr-3">
                    {getMinutesAndSecondsFromInputSeconds(this.props.sessionRemainingSecond)}
                  </span>
                  {getUserName()}
                </Typography>
                <div className="logout button-and-text" onClick={this.props.logout}>
                  <i className="fas fa-sign-out-alt logout-logo"></i>
                  <Typography>Logout</Typography>
                </div>
              </div>
              <div className="mobile-nav portal-header-end portal-nav-link" onClick={this.props.toggleNav}>
                {(this.props.navVisible && <i className="fas fa-times nav-button"></i>) || (
                  <i className="fas fa-bars nav-button"></i>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col>
              <Divider className="red-divider" />
            </Col>
          </Row>
          <Row noGutters className="d-md-none mt-1">
            <Col xs="6" align="left">
              <Typography className="ml-3">{getUserName()}</Typography>
            </Col>
            <Col xs="6" align="right">
              <Typography className="mr-3">
                <i className="fas fa-history session-refresh" onClick={this.props.refreshToken}></i>{' '}
                <span>{getMinutesAndSecondsFromInputSeconds(this.props.sessionRemainingSecond)}</span>
              </Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <div className="portal-sub-nav not-mobile-flex">{subnavigation}</div>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = ({ data }: IRootState) => ({
  profile: data.profile,
  requests: data.requests,
  contracts: data.contracts
})

const mapDispatchToProps = {
  logout,
  refreshToken
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(Header)
