import React from 'react'

import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Typography, Divider } from '@material-ui/core'
import { IFooter } from './footer'

export class MobileFooter extends React.Component<IFooter> {
  render() {
    const faqLink = this.props.isPublic ? '/faq' : '/portal/faq'
    const supportLink = this.props.isPublic ? '/support' : '/portal/support'

    return (
      <>
        <Container className="footer-links-mobile text-center">
          <Row noGutters>
            <Col>
              <Divider />
            </Col>
          </Row>
          <Row noGutters className="footer-links-mobile-iconbar">
            {!this.props.isPublic &&
            <Col>
              <Link to='/portal/contact'>
                <div>
                  <i className="far fa-envelope" />
                </div>
                <div>
                  <Typography>Kontakt</Typography>
                </div>
              </Link>
            </Col>}
            <Col>
              <Link to={supportLink}>
                <div>
                  <i className="far fa-envelope" />
                </div>
                <div>
                  <Typography>Technischer Support</Typography>
                </div>
              </Link>
            </Col>
            <Col>
              <Link to={faqLink}>
                <div>
                  <i className="far fa-question-circle"></i>
                </div>
                <div>
                  <Typography>FAQ</Typography>
                </div>
              </Link>
            </Col>
            <Col>
              <a href="https://www.honda.de/cars/dealer-search.html" rel="noopener noreferrer" target="_blank">
                <div>
                  <i className="fas fa-map-marker-alt" />
                </div>
                <div>
                  <Typography>Händler</Typography>
                </div>
              </a>
            </Col>
            <Col>
              <a href="https://www.honda.de/cars/konfigurator.html" rel="noopener noreferrer" target="_blank">
                <div>
                  <i className="fas fa-wrench" />
                </div>
                <div>
                  <Typography>Konfigurator</Typography>
                </div>
              </a>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Divider />
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Typography>MEHR VON HONDA BANK</Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="text-right">
              <a href="https://www.honda-bank.de/werwirsind.php" rel="noopener noreferrer" target="_blank">
                <Typography>Über uns</Typography>
              </a>
            </Col>
            <Col></Col>
            <Col className="text-left">
              <a href="/portal/faq" rel="noopener noreferrer" target="_blank">
                <Typography>FAQ</Typography>
              </a>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Divider />
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Typography>MEHR VON HONDA</Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="text-right">
              <a href="https://www.honda.de/cars.html" rel="noopener noreferrer" target="_blank">
                <Typography>Automobile</Typography>
              </a>
            </Col>
            <Col></Col>
            <Col className="text-left">
              <a href="https://www.honda.de/motorcycles.html" rel="noopener noreferrer" target="_blank">
                <Typography>Motorräder</Typography>
              </a>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Divider />
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Typography>NÜTZLICHE LINKS</Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="text-right">
              <Link to="/impressum">
                <Typography>Impressum</Typography>
              </Link>
            </Col>
            <Col></Col>
            <Col className="text-left">
              <Link to="/dataSecurity">
                <Typography>Datenschutz</Typography>
              </Link>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default MobileFooter
