import React from 'react'
import { Typography } from '@material-ui/core'
import { IUploadFile } from './upload'
import { useMediaQuery } from 'react-responsive'

export function FileListItem(file: IUploadFile) {
  const isVerySmallMobile = useMediaQuery({ maxWidth: 350 })
  const isSmallMobile = useMediaQuery({ maxWidth: 400 })
  const isBigMobile = useMediaQuery({ maxWidth: 599 })

  const formatFileName = (fileName: string) => {
    if (isVerySmallMobile && fileName.length > 11) {
      return shortenFileName(fileName, 9)
    }
    if (isSmallMobile && fileName.length > 14) {
      return shortenFileName(fileName, 12)
    }
    if (isBigMobile && fileName.length > 24) {
      return shortenFileName(fileName, 22)
    }
    if (fileName.length > 40) {
      return shortenFileName(fileName, 40)
    } else return fileName
  }

  const shortenFileName = (fileName: string, maxlength: number) => {
    if (fileName.substring(0, maxlength).endsWith('.')) {
      return fileName.substring(0, maxlength) + '..'
    } else {
      return fileName.substring(0, maxlength) + '...'
    }
  }

  const formatFilesize = (size: number) => {
    if (size < 1000) {
      return size + ' B'
    } else if (size < 1000000) {
      var sizeInKb = (size / 1000).toFixed(2)
      return sizeInKb + ' KB'
    } else {
      var sizeInMb = (size / 1000000).toFixed(2)
      return sizeInMb + ' MB'
    }
  }

  return (
    <>
      <div className="upload-file-name vertical-center">
        <Typography variant="body2" className="smaller-fontsize">
          <i className="far fa-file-alt"></i> {formatFileName(file.name)}
        </Typography>
      </div>
      <div className="upload-file-size vertical-center">
        <Typography variant="body2" className="smaller-fontsize upload-file-size-text">
          {formatFilesize(file.size)}
        </Typography>
      </div>
      {(file.isValid && (
        <div className="upload-file-valid vertical-center">
          <Typography variant="body2" className="smaller-fontsize upload-file-valid-text">
            Erfolgreich
          </Typography>
        </div>
      )) || (
        <div className="upload-file-invalid vertical-center">
          <Typography variant="body2" className="smaller-fontsize upload-file-invalid-text">
            Error
          </Typography>
        </div>
      )}
    </>
  )
}
