import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { IRootState } from 'app/shared/reducers'
import { sendResetMail } from '../password.reducer'

import { Typography, FormControl, Button, FormGroup, InputAdornment, IconButton } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { stringIsEmpty } from 'app/shared/utils/common'
import { Clear } from '@material-ui/icons'
import { Link } from 'react-router-dom'

export interface IPasswordResetMailFormProps extends StateProps, DispatchProps {}

interface IPasswordResetMailFormState {
  email: string
  emailSend: boolean
}

export class PasswordResetMailForm extends React.Component<
  IPasswordResetMailFormProps & RouteComponentProps,
  IPasswordResetMailFormState
> {
  constructor(props) {
    super(props)
    this.state = {
      email: !stringIsEmpty(props.loginname) ? props.loginname : '',
      emailSend: false
    }
  }

  handleValidSubmit = () => {
    this.props.sendResetMail(this.state.email)
    this.setState({ ...this.state, emailSend: true })
  }

  backToLogin = () => {
    this.props.history.push('/login')
  }

  clearEmailAddress = () => {
    this.setState({ ...this.state, email: '' })
  }

  handleChange = (event) => {
    this.setState({ ...this.state, email: event.target.value })
  }

  handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  render() {
    if (this.state.emailSend) {
      return (
        <div className="paper inner-form full-width">
          <Typography variant="h2">Passwort</Typography>
          <Typography variant="body2" className="no-line-height" display="inline">
            E-Mail wurde gesendet
          </Typography>
          <div className="margin1" />
          <Typography className="mw-100">
            Wir haben Ihnen eine E-Mail an <b>{this.state.email}</b> gesendet.
          </Typography>
          <div className="margin" />
          <Typography className="mw-100">
            Klicken Sie auf den Link in der E-Mail, um Ihr Passwort neu zu vergeben.
          </Typography>
          <div className="margin2" />
          <div className="text-center">
            <Link className="grey" to="/login">
              Zurück zum Login
            </Link>
          </div>
        </div>
      )
    } else {
      return (
        <div className="paper inner-form full-width">
          <Typography variant="h2">Passwort</Typography>
          <Typography variant="body2" className="no-line-height" display="inline">
            Passwort vergessen?
          </Typography>
          <div className="margin1" />
          <Typography className="mw-100">
            Wir senden Ihnen eine E-Mail mit einem Link, um ein neues Passwort zu vergeben.
          </Typography>
          <div className="margin1" />
          <ValidatorForm className="full-width" onSubmit={this.handleValidSubmit}>
            <FormControl fullWidth>
              <TextValidator
                className="full-width"
                label="E-Mail"
                name="email"
                onChange={this.handleChange}
                type="text"
                validators={['required', 'isEmail']}
                errorMessages={['', 'Bitte geben Sie eine gültige E-Mail-Adresse ein']}
                value={this.state.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.clearEmailAddress}
                        onMouseDown={this.handleMouseDown}>
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
            <div className="margin1" />
            <FormGroup className="inline">
              <Button className="white-square-button bordered-button" color="primary" onClick={this.backToLogin}>
                Zurück
              </Button>
              <Button className="square-button red-border" type="submit" variant="contained" color="secondary">
                Senden
              </Button>
            </FormGroup>
          </ValidatorForm>
        </div>
      )
    }
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  loginname: authentication.loginname
})

const mapDispatchToProps = {
  sendResetMail
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect<any>(mapStateToProps, mapDispatchToProps)(PasswordResetMailForm)
