import axios from 'axios'

import { FAILURE, REQUEST, SUCCESS } from './action-type.util'
import { messages } from 'app/config/constants'

import { stringIsEmpty } from '../utils/common'
import { setProfile } from './data.reducer'

export const ACTION_TYPES = {
  SEND_VALIDATION_CODE: 'emailreset/SEND_VALIDATION_CODE',
  VALIDATE_EMAIL_CODE: 'emailreset/VALIDATE_EMAIL_CODE',
  VALIDATE_SMS_CODE: 'emailreset/VALIDATE_SMS_CODE',
  CHANGE_EMAIL_ADDRESS: 'emailreset/CHANGE_EMAIL_ADDRESS',
  RESET_ERROR: 'emailreset/RESET_ERROR',
  RESET_CURRENT_STEP: 'emailreset/RESET_CURRENT_STEP',
  PREV_STEP: 'emailreset/PREV_STEP'
}

export const initialState = {
  loading: false,
  errorMessage: null as string,
  currentStep: 0,
  newUsername: ''
}

export type EmailResetState = Readonly<typeof initialState>

export default (state: EmailResetState = initialState, action): EmailResetState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEND_VALIDATION_CODE):
    case REQUEST(ACTION_TYPES.VALIDATE_EMAIL_CODE):
    case REQUEST(ACTION_TYPES.VALIDATE_SMS_CODE):
    case REQUEST(ACTION_TYPES.CHANGE_EMAIL_ADDRESS):
      return {
        ...state,
        loading: true
      }
    case FAILURE(ACTION_TYPES.SEND_VALIDATION_CODE):
    case FAILURE(ACTION_TYPES.VALIDATE_EMAIL_CODE):
    case FAILURE(ACTION_TYPES.VALIDATE_SMS_CODE):
    case FAILURE(ACTION_TYPES.CHANGE_EMAIL_ADDRESS):
      return {
        ...state,
        loading: false,
        errorMessage:
          action.payload !== undefined && !stringIsEmpty(action.payload.response)
            ? action.payload.response.data.message
            : messages.ERROR_SERVER_COMMUNICATION
      }
    case SUCCESS(ACTION_TYPES.SEND_VALIDATION_CODE):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        newUsername: action.meta.newUsername,
        currentStep: state.currentStep + 1
      }
    case SUCCESS(ACTION_TYPES.VALIDATE_SMS_CODE):
      return {
        ...state,
        loading: false,
        errorMessage: null
      }
    case SUCCESS(ACTION_TYPES.VALIDATE_EMAIL_CODE):
    case SUCCESS(ACTION_TYPES.CHANGE_EMAIL_ADDRESS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentStep: state.currentStep + 1
      }
    case ACTION_TYPES.RESET_ERROR:
      return {
        ...state,
        errorMessage: null
      }
    case ACTION_TYPES.RESET_CURRENT_STEP:
      return {
        ...state,
        currentStep: 0
      }
    case ACTION_TYPES.PREV_STEP: {
      return {
        ...state,
        currentStep: state.currentStep > 0 ? state.currentStep - 1 : state.currentStep
      }
    }
    default: {
      return state
    }
  }
}

export const resetErrorMessage = () => {
  return {
    type: ACTION_TYPES.RESET_ERROR
  }
}

export const resetCurrentStep = () => {
  return {
    type: ACTION_TYPES.RESET_CURRENT_STEP
  }
}

export const previousStep = () => ({
  type: ACTION_TYPES.PREV_STEP
})

export const sendEmailValidationCode = (newUsername: string) => ({
  type: ACTION_TYPES.SEND_VALIDATION_CODE,
  meta: {
    newUsername
  },
  payload: axios.post('api/user/sendEmailValidationCode', newUsername, { headers: { 'Content-Type': 'text/plain' } })
})

export const validateEmailCode = (enteredCode: string) => ({
  type: ACTION_TYPES.VALIDATE_EMAIL_CODE,
  payload: axios.post('api/user/validateEmailCode', enteredCode, { headers: { 'Content-Type': 'text/plain' } })
})

export const validateSmsCode = (enteredCode: string) => (dispatch, getState) => {
  return dispatch({
    type: ACTION_TYPES.VALIDATE_SMS_CODE,
    payload: axios.post('api/user/validateSmsCode', enteredCode, { headers: { 'Content-Type': 'text/plain' } })
  }).then(
    () => {
      dispatch(completeEmailChange(getState().emailreset.newUsername))
    },
    () => {}
  )
}

export const completeEmailChange = (newUsername: string) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.CHANGE_EMAIL_ADDRESS,
    payload: axios.post('api/user/completeEmailChange', newUsername, { headers: { 'Content-Type': 'text/plain' } })
  }).then(
    (payload) => {
      dispatch(setProfile(payload.value.data))
    },
    () => {}
  )
}
