import axios from 'axios'

import config, { AUTH } from 'app/config/constants'

const TIMEOUT = 1 * 60 * 1000
axios.defaults.timeout = TIMEOUT
axios.defaults.baseURL = config.SERVER_API_URL

const setupAxiosInterceptors = (onUnauthenticated) => {
  const onRequestSuccess = (requestConfig) => {
    const token = window.sessionStorage.getItem(AUTH.TOKEN_KEY)
    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`
    }
    return requestConfig
  }
  const onResponseSuccess = (response) => {
    if (response && response.headers && response.headers.authorization) {
      const authorization = response.headers.authorization
      if (authorization && authorization.slice(0, 7) === 'Bearer ') {
        const jwt = authorization.slice(7, authorization.length)

        window.sessionStorage.setItem(AUTH.TOKEN_KEY, jwt)
      }
    }
    return response
  }
  const onResponseError = (err) => {
    const status = err.status || (err.response ? err.response.status : 0)
    if (status === 401) {
      onUnauthenticated()
    }
    return Promise.reject(err)
  }
  axios.interceptors.request.use(onRequestSuccess)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

export default setupAxiosInterceptors
