import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { AvGroup, AvForm, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import { getEmailFromUser } from 'app/shared/utils/user.util'
import { IRootState } from 'app/shared/reducers'
import { withRouter, Link, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { FormGroup, Label } from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { stringIsEmpty } from 'app/shared/utils/common'
import { acceptLegalTerms } from 'app/shared/reducers/registration.reducer'

export interface ILegalForm extends StateProps, DispatchProps {}

export class RegistrationLegalForm extends React.Component<ILegalForm & RouteComponentProps> {
  previousRegistrationStep = () => {
    this.props.history.push('/registration/password')
  }

  nextRegistrationStep = () => {
    this.props.acceptLegalTerms()
    this.props.history.push('/registration/verify')
  }

  render() {
    if (stringIsEmpty(this.props.user.email)) {
      return <Redirect to="/registration/error" />
    } else {
      return (
        <div className="paper inner-form">
          <Typography variant="h3" color="primary">
            Nutzungsbedingungen
          </Typography>
          <Typography variant="body2" className="no-line-height" display="inline">
            {getEmailFromUser(this.props.user)}
          </Typography>
          <div className="margin1" />
          <AvForm onValidSubmit={this.nextRegistrationStep}>
            <FormGroup>
              <AvGroup check>
                <AvInput type="checkbox" name="dataSecurity" id="dataSecurity" required />
                <Label check for="dataSecurity">
                  <Typography>
                    Ich akzeptiere die{' '}
                    <Link className="grey underline" to="/termsOfUse">
                      Nutzungsbedingungen
                    </Link>{' '}
                    und habe die{' '}
                    <Link className="grey underline" to="/dataSecurity">
                      Datenschutzhinweise
                    </Link>{' '}
                    zur Kenntnis genommen
                  </Typography>
                </Label>
                <AvFeedback>
                  Bitte bestätigen Sie, dass Sie die rechtlichen Hinweise gelesen haben und diese akzeptieren.
                </AvFeedback>
              </AvGroup>
            </FormGroup>
            <div className="margin2" />
            <FormGroup className="inline">
              <Button
                className="white-square-button bordered-button"
                color="primary"
                onClick={this.previousRegistrationStep}>
                Zurück
              </Button>
              <Button className="square-button red-border" type="submit" variant="contained" color="secondary">
                Weiter
              </Button>
            </FormGroup>
          </AvForm>
        </div>
      )
    }
  }
}

const mapStateToProps = ({ registration }: IRootState) => ({
  user: registration.user
})

const mapDispatchToProps = {
  acceptLegalTerms
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(RegistrationLegalForm)
