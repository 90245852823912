import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom'

import { getEmailFromUser } from 'app/shared/utils/user.util'
import { IRootState } from 'app/shared/reducers'
import { getUserByKey, setRegistrationPassword } from 'app/shared/reducers/registration.reducer'
import { connect } from 'react-redux'
import PasswordChangeForm from 'app/components/password/password-change-form'
import { stringIsEmpty } from 'app/shared/utils/common'
import { compose } from 'redux'

export interface IRegistrationPassword extends StateProps, DispatchProps {}

export class RegistrationPasswordForm extends React.Component<IRegistrationPassword & RouteComponentProps> {
  handleValidSubmit = (password, pwRepeat) => {
    this.props.setRegistrationPassword(password, pwRepeat)
    this.props.history.push('/registration/legal')
  }

  render() {
    const props = {
      nextButtonText: 'Weiter',
      password: this.props.password,
      pwRepeat: this.props.pwRepeat,
      handleValidSubmit: this.handleValidSubmit,
      fullWidthButton: true,
      type: this.props.user.type,
      username: this.props.user.email
    }

    if (stringIsEmpty(this.props.user.email)) {
      return <Redirect to="/registration/error" />
    } else {
      return (
        <div className="paper inner-form full-width">
          <Typography variant="h2">Registrieren</Typography>
          <Typography variant="body2" className="no-line-height" display="inline">
            {getEmailFromUser(this.props.user)}
          </Typography>
          <div className="margin1" />
          <PasswordChangeForm {...props} />
        </div>
      )
    }
  }
}

const mapStateToProps = ({ registration }: IRootState) => ({
  isLoading: registration.loading,
  errorMessage: registration.errorMessage,
  user: registration.user,
  password: registration.password,
  pwRepeat: registration.pwRepeat
})

const mapDispatchToProps = {
  getUserByKey,
  setRegistrationPassword
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect<any>(mapStateToProps, mapDispatchToProps))(RegistrationPasswordForm)
