import React from 'react'
import {
  TextField,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core'
import { Container, Row, Col } from 'reactstrap'
import PersonalDataImage from 'resources/images/profile_personal_data.png'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { valueOrEmptyString } from 'app/shared/utils/common'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

export interface IPersonalDataInputProps {
  title: string
  firstName: string
  lastName: string
  loading: boolean
  dateOfBirth: Date
  handleValidSubmit: any
  errorMessage: string
  setErrorMessage: any
  resetErrorMessage: any
  previousFunc: any
}

export interface IPersonalDataInputState {
  title: string
  firstName: string
  lastName: string
}

export class PersonalDataInput extends React.Component<IPersonalDataInputProps, IPersonalDataInputState> {
  constructor(props) {
    super(props)
    this.state = {
      title: valueOrEmptyString(this.props.title),
      firstName: valueOrEmptyString(this.props.firstName),
      lastName: valueOrEmptyString(this.props.lastName)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleDateChange = () => {
    // empty stub as the input fields are non functional for now but the datepicker needs this method
  }

  handleTitleChange = (event) => {
    this.setState({ ...this.state, title: event.target.value })
    this.props.resetErrorMessage()
  }

  handleFirstNameChange = (event) => {
    this.setState({ ...this.state, firstName: event.target.value })
    this.props.resetErrorMessage()
  }

  handleLastNameChange = (event) => {
    this.setState({ ...this.state, lastName: event.target.value })
    this.props.resetErrorMessage()
  }

  handleValidSubmit = () => {
    if (
      valueOrEmptyString(this.props.title) === this.state.title &&
      valueOrEmptyString(this.props.firstName) === this.state.firstName &&
      valueOrEmptyString(this.props.lastName) === this.state.lastName
    ) {
      this.props.setErrorMessage('Um zum nächsten Schritt zu gelangen, müssen Sie eine Veränderung vornehmen.')
    } else {
      this.props.handleValidSubmit(this.state.title, this.state.firstName, this.state.lastName)
    }
  }

  render() {
    return (
      <Container fluid className="p-0">
        <ValidatorForm className="full-width" onSubmit={this.handleValidSubmit}>
          <Row noGutters>
            <Col>
              <div>
                <InputLabel className="MuiInputLabel-shrink select-input-label" data-shrink="true" id="title-label">
                  Titel
                </InputLabel>
                <Select
                  className="full-width profileInputField"
                  labelId="title-label"
                  id="title-input"
                  value={this.state.title}
                  onChange={this.handleTitleChange}>
                  <MenuItem value={''}>&nbsp;</MenuItem>
                  <MenuItem value={'Dr.'}>Dr.</MenuItem>
                  <MenuItem value={'Prof.'}>Prof.</MenuItem>
                  <MenuItem value={'Prof. Dr.'}>Prof. Dr.</MenuItem>
                </Select>
              </div>
              <div>
                <TextValidator
                  id="firstname"
                  name="firstname"
                  className="profileInputField"
                  label="Vorname(n)"
                  onChange={this.handleFirstNameChange}
                  value={this.state.firstName}
                  validators={['required']}
                  errorMessages={['Bitte geben Sie einen oder mehrere Vornamen ein']}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <i className="fas fa-pen edit-icon" />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div>
                <TextValidator
                  id="lastname"
                  name="lastname"
                  className="profileInputField"
                  label="Nachname"
                  onChange={this.handleLastNameChange}
                  value={this.state.lastName}
                  validators={['required']}
                  errorMessages={['Bitte geben Sie einen Nachnamen ein']}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <i className="fas fa-pen edit-icon" />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              {(this.props.dateOfBirth && (
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      id="dateOfBirth"
                      className="profileInputField"
                      disableFuture
                      format="dd.MM.yyyy"
                      label="Geburtsdatum"
                      views={['year', 'month', 'date']}
                      value={valueOrEmptyString(this.props.dateOfBirth)}
                      onChange={this.handleDateChange}
                      disabled
                    />
                  </MuiPickersUtilsProvider>
                </div>
              )) || (
                // the datepicker needs some value to render. if there is no value show this "empty" inputfield instead
                <TextField id="dateOfBirth" className="profileInputField" label="Geburtsdatum" value=" " disabled />
              )}
            </Col>
            <Col className="text-center profile-images">
              <img src={PersonalDataImage} alt="Persönliche Daten"></img>
            </Col>
          </Row>
          {this.props.errorMessage && (
            <Typography
              className="validationFailed regular-line-height text-margin"
              id="pwValidationLabel"
              variant="body2">
              {this.props.errorMessage}
            </Typography>
          )}
          {(this.props.loading && (
            <div className="loading-center">
              <CircularProgress></CircularProgress>
            </div>
          )) || (
            <div>
              <Button
                className="square-button mr-2"
                onClick={this.props.previousFunc}
                variant="contained"
                color="primary">
                Zurück
              </Button>
              <Button className="square-button red-border ml-2" type="submit" variant="contained" color="secondary">
                Weiter
              </Button>
            </div>
          )}
        </ValidatorForm>
      </Container>
    )
  }
}
