import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

export class RegistrationErrorForm extends React.Component {
  render() {
    return (
      <div className="paper inner-form full-width">
        <Typography variant="h2">Fehler</Typography>
        <Typography variant="body2" className="no-line-height" display="inline">
          Registrierung fehlgeschlagen
        </Typography>
        <div className="margin1" />
        <Typography variant="body2" className="red mw-100">
          Leider hat die Registrierung nicht funktioniert. Bitte wenden Sie sich an den Support.
        </Typography>
        <div className="margin" />
        <div className="inline full-width icon-and-text">
          <Link className="underline" to='/support'>Zum technischen Support</Link>
        </div>
      </div>
    )
  }
}

export default RegistrationErrorForm
