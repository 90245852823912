import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { Typography } from '@material-ui/core'
import { DividerWithDiamond } from 'app/shared/components/divider-with-diamond'
import LicensePlate from 'app/shared/components/licenseplate'
import { dateFormat, currencyFormat } from 'app/shared/utils/common'

export interface IAccountInformationProps {
  locale: any
  licenseNumber: string
  installmentNextAmount: any
  installmentNextDate: any
  installmentRemainingCount: number
  balance: any
}
export class AccountInformation extends React.Component<IAccountInformationProps> {
  render() {
    return (
      <Container>
        <Row>
          <Col sm="12" className="infobox-icon-container">
            <LicensePlate licenseNumber={this.props.licenseNumber} centered={true} />
          </Col>
        </Row>
        <DividerWithDiamond />
        <Row className="infobox-content">
          <Col xs="6">
            <div>
              <Typography variant="h6" className="bold mobile-smaller">
                Nächste Rate
              </Typography>
              <Typography variant="body1" className="mobile-smaller">
                {currencyFormat(this.props.installmentNextAmount, this.props.locale)}
              </Typography>
            </div>
          </Col>
          <Col xs="6">
            <div>
              <Typography variant="h6" className="bold mobile-smaller">
                Fälligkeit
              </Typography>
              <Typography variant="body1" className="mobile-smaller">
                {dateFormat(this.props.installmentNextDate, this.props.locale)}
              </Typography>
            </div>
          </Col>
        </Row>
        <div className="margin" />
        <Row className="infobox-content">
          <Col xs="6">
            <div>
              <Typography variant="h6" className="bold mobile-smaller">
                Restsaldo
              </Typography>
              <Typography variant="body1" className="mobile-smaller">
                {currencyFormat(this.props.balance, this.props.locale)}
              </Typography>
            </div>
          </Col>
          <Col xs="6">
            <div>
              <Typography variant="h6" className="bold mobile-smaller">
                Restlaufzeit
              </Typography>
              <Typography variant="body1" className="mobile-smaller">
                {this.props.installmentRemainingCount}{' '}
                {(this.props.installmentRemainingCount === 1 && 'Monat') || 'Monate'}
              </Typography>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default AccountInformation
