import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { withRouter, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import { getEmailFromUser } from 'app/shared/utils/user.util'
import { IRootState } from 'app/shared/reducers'
import {
  checkVerificationCode,
  completePasswordChange,
  resetErrorMessage,
  sendVerificationCodeForUser,
  setErrorMessage
} from '../password.reducer'
import { stringIsEmpty } from 'app/shared/utils/common'

import Typography from '@material-ui/core/Typography'
import ValidationCodebox from 'app/components/codebox/validation.codebox'
import { messages } from 'app/config/constants'
import { Form, FormGroup } from 'reactstrap'
import { Button } from '@material-ui/core'

export interface IPasswordVerify extends StateProps, DispatchProps {}

export interface IPasswordVerifyStateProps {}

export class PasswordVerify extends React.Component<IPasswordVerify & RouteComponentProps, IPasswordVerifyStateProps> {
  onValidSubmit = (enteredCode: string) => {
    this.props.checkVerificationCode(
      this.props.passwordresetKey,
      enteredCode,
      this.completePasswordChange,
      this.goToError
    )
  }

  getNewConfirmationCode = () => {
    this.props.sendVerificationCodeForUser(this.props.user.email, true, this.doNothing)
  }

  doNothing = () => {}

  completePasswordChange = () => {
    this.props.completePasswordChange(this.props.passwordresetKey, this.props.password, this.next)
  }

  goToError = () => {
    this.props.history.push('/passwordreset/error')
  }

  next = () => {
    this.props.history.push('/passwordreset/complete')
  }

  onBack = () => {
    this.props.history.push('/passwordreset/key')
  }

  render() {
    if (stringIsEmpty(this.props.user.email)) {
      return <Redirect to="/passwordreset/error" />
    } else {
      const props = {
        onValidSubmit: this.onValidSubmit,
        onBack: this.onBack,
        hasBack: true,
        resetError: this.props.resetErrorMessage,
        setError: this.props.setErrorMessage,
        errorDescription: this.props.errorMessage,
        loading: this.props.loading,
        nextButtonText: 'Weiter'
      }

      let dailySMSLimitReached = false
      if (this.props.errorMessage && this.props.errorMessage === messages.CODE_SENDING_ERROR) {
        dailySMSLimitReached = true
      }
      return (
        <div className="paper inner-form">
          <Typography variant="h3">Verifizierung</Typography>
          <Typography variant="body2" className="no-line-height" display="inline">
            {getEmailFromUser(this.props.user)}
          </Typography>
          <div className="margin1" />
          <Form>
            <FormGroup>
              {(dailySMSLimitReached && (
                <div>
                  <Typography variant="body2" className="validationFailed">
                    Wir haben versucht Ihnen eine SMS mit einem Bestätigungscode zu schicken.
                  </Typography>
                  <Typography variant="body2" className="validationFailed">
                    {this.props.errorMessage}
                  </Typography>
                </div>
              )) || (
                <>
                  <Typography variant="body2">
                    Wir haben Ihnen eine SMS mit einem Bestätigungscode zugeschickt. Bitte geben Sie diesen Code zur
                    Verifizierung ein.
                  </Typography>
                  <div className="margin" />
                  <Typography variant="body2">Eingabe Verifizierungscode:</Typography>
                </>
              )}
            </FormGroup>
          </Form>
          {!dailySMSLimitReached && <ValidationCodebox {...props} />}
          {!this.props.loading && !dailySMSLimitReached && (
            <Button className="lower-case" color="primary" onClick={this.getNewConfirmationCode}>
              Code erneut zusenden
            </Button>
          )}
        </div>
      )
    }
  }
}

const mapStateToProps = ({ password }: IRootState) => ({
  user: password.user,
  password: password.password,
  phoneVerified: password.phoneVerified,
  errorMessage: password.errorMessage,
  loading: password.loading,
  passwordresetKey: password.passwordresetKey
})

const mapDispatchToProps = {
  checkVerificationCode,
  completePasswordChange,
  resetErrorMessage,
  setErrorMessage,
  sendVerificationCodeForUser
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(PasswordVerify)
