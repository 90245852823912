import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button
} from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { Container, Row, Col } from 'reactstrap'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'
import {
  reset,
  resetStep,
  nextStep,
  previousStep,
  sendVerificationCode,
  checkVerificationCode,
  startContractBankAccountChange,
  completeContractBankAccountChange
} from 'app/shared/reducers/data-change.reducer'
import { valueOrEmptyString, stringIsEmpty } from 'app/shared/utils/common'
import ValidationCodebox from 'app/components/codebox/validation.codebox'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import BankAccountImage from 'resources/images/bankaccount.png'
import IContract from 'app/model/contract.model'
import { ContractBankAccountDisplay } from './contract.bankaccount.display'
import { ContractBankAccountInput } from './contract.bankaccount.input'

export interface IContractBankAccountProps extends StateProps, DispatchProps {
  contract: IContract
  anchor: string
}

export interface IContractBankAccountState {
  expanded: boolean
  iban: string
  changeType: string
  errorMessage: string
}

export class ContractBankAccount extends React.Component<
  IContractBankAccountProps & RouteComponentProps,
  IContractBankAccountState
> {
  readonly NUMBEROFSTEPS = 2

  constructor(props) {
    super(props)
    this.state = {
      expanded: props.anchor !== null && props.anchor === 'bankaccount',
      changeType: '',
      errorMessage: null,
      iban: props.contract.iban
    }
  }

  componentDidMount() {
    this.props.reset()
  }

  handleChange = () => () => {
    this.setState({ expanded: !this.state.expanded })
  }

  startChange = () => {
    this.props.startContractBankAccountChange()
  }

  getSteps() {
    return ['Bankverbindung ändern', 'Bestätigungscode eingeben']
  }

  handleValidInputSubmit = (iban: string) => {
    this.setState({ iban })
    this.props.sendVerificationCode()
  }

  handleValidCodeSubmit = (enteredCode: string) => {
    this.props.checkVerificationCode(enteredCode, this.completeDataChange, this.handleUserLocked)
  }

  handleUserLocked = () => {
    this.props.history.push('/locked')
  }

  completeDataChange = () => {
    const formData = new FormData()
    formData.append('iban', this.state.iban)
    this.props.completeContractBankAccountChange(formData, this.props.contract.id, this.NUMBEROFSTEPS)
  }

  resetErrorMessage = () => {
    this.setState({ errorMessage: null })
  }

  setErrorMessage = (errorMessage) => {
    this.setState({ errorMessage })
  }

  reset = () => {
    this.setState({
      iban: valueOrEmptyString(this.props.contract.bankAccount.iban)
    })
    this.props.reset()
  }

  getStepContent = (index: number) => {
    const inputProps = {
      loading: this.props.loading,
      handleValidSubmit: this.handleValidInputSubmit,
      errorMessage: !stringIsEmpty(this.props.errorMessage) ? this.props.errorMessage : this.state.errorMessage,
      setErrorMessage: this.setErrorMessage,
      resetErrorMessage: this.resetErrorMessage,
      contract: this.props.contract,
      previousFunc: this.reset
    }
    const codeboxProps = {
      onValidSubmit: this.handleValidCodeSubmit,
      onBack: null,
      resetError: this.resetErrorMessage,
      setError: this.setErrorMessage,
      hasBack: false,
      errorDescription: !stringIsEmpty(this.props.errorMessage) ? this.props.errorMessage : this.state.errorMessage,
      loading: this.props.loading,
      nextButtonText: 'Weiter',
      previousFunc: this.props.previousStep
    }
    switch (index) {
      case 0:
        return <ContractBankAccountInput {...inputProps} />
      case 1:
        return (
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <Typography variant="body2" className="regular-line-height text-margin">
                  Sie erhalten in wenigen Minuten Ihren Bestätigungscode per SMS. Bitte geben Sie diesen unten ein.
                </Typography>
              </Col>
              <Col xs="12">
                <Typography variant="body2" className="regular-line-height">
                  Eingabe Bestätigungscode:
                </Typography>
              </Col>
            </Row>
            <ValidationCodebox {...codeboxProps} />
          </Container>
        )
      default:
        return 'ERROR'
    }
  }

  render() {
    const props = {
      startChange: this.startChange,
      contract: this.props.contract
    }
    return (
      <Accordion
        id="data"
        className="container-with-border content-container"
        expanded={this.state.expanded}
        onChange={this.handleChange()}>
        <AccordionSummary expandIcon={<RedTriangle medium />}>
          <Typography className="bold">
            {(this.props.change && 'Änderung der Bankverbindung') || 'Bankverbindung'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container fluid className="p-0">
            {!this.props.change && <ContractBankAccountDisplay {...props} />}
            {this.props.change && this.props.currentStep < this.NUMBEROFSTEPS && (
              <Stepper activeStep={this.props.currentStep} orientation="vertical" className="email-reset">
                {this.getSteps().map((label, index) => {
                  const stepProps = {}
                  const labelProps = {}
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                      <StepContent>{this.getStepContent(index)}</StepContent>
                    </Step>
                  )
                })}
              </Stepper>
            )}
            {this.props.change && this.props.currentStep === this.NUMBEROFSTEPS && (
              <>
                <Row noGutters>
                  <Col xs="12" sm="6">
                    <Typography className="text-margin bold">Änderung der Bankverbindung war erfolgreich</Typography>
                    <Typography variant="body2" className="text-margin regular-line-height">
                      Vielen Dank für Ihre Änderung der Bankverbindung. Sie erhalten in wenigen Minuten eine Information
                      über die Änderung per E-Mail.
                    </Typography>
                    <Typography variant="body2" className="text-margin regular-line-height">
                      Bitte beachten Sie, dass es aufgrund der SEPA Vorlagefrist bei einer Änderung der Bankverbindung
                      kurz vor einem Fälligkeitstermin, die neue Bankverbindung für die anstehende Lastschrift ggf.
                      nicht mehr berücksichtigt werden kann!
                    </Typography>
                    <Typography variant="body2" className="text-margin regular-line-height">
                      Ihre Änderung wird im Portal nach 1-2 Werktagen sichtbar sein.
                    </Typography>
                  </Col>
                  <Col xs="6" className="text-center profile-images">
                    <img src={BankAccountImage} alt="Bankverbindung" className="smaller-picture-top"></img>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col xs="12">
                    <Button
                      className="square-button red-border"
                      onClick={this.reset}
                      variant="contained"
                      color="secondary">
                      OK
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </AccordionDetails>
      </Accordion>
    )
  }
}

const mapStateToProps = ({ datachange }: IRootState) => ({
  currentStep: datachange.currentStep,
  loading: datachange.loading,
  change: datachange.contractBankaccountChange,
  errorMessage: datachange.errorMessage
})

const mapDispatchToProps = {
  reset,
  nextStep,
  previousStep,
  resetStep,
  sendVerificationCode,
  checkVerificationCode,
  startContractBankAccountChange,
  completeContractBankAccountChange
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(ContractBankAccount)
