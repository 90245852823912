import React from 'react'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { valueOrEmptyString } from 'app/shared/utils/common'

import CommunicationImage from 'resources/images/profile_communication.png'
import { Row, Col } from 'reactstrap'

export default function CommunicationDataDisplay(props: any) {
  const preventDefault = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Row noGutters>
      <Col>
        <div>
          <TextField
            id="email"
            className="profileInputField"
            label="E-Mail-Adresse (Benutzername)"
            value={valueOrEmptyString(props.profile.email)}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="edit name" onClick={props.startEmailChange} onMouseDown={preventDefault}>
                    <i className="fas fa-pen small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div>
          <TextField
            id="mobile"
            className="profileInputField"
            label="Mobiltelefon"
            value={valueOrEmptyString(props.profile.mobilePhone)}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="edit name" onClick={props.startChange} onMouseDown={preventDefault}>
                    <i className="fas fa-pen small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div>
          <TextField
            id="landline"
            className="profileInputField"
            label="Festnetznummer"
            value={valueOrEmptyString(props.profile.landLine)}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="edit name" onClick={props.startChange} onMouseDown={preventDefault}>
                    <i className="fas fa-pen small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      </Col>
      <Col className="text-center profile-images">
        <img src={CommunicationImage} alt="Kommunikationsdaten"></img>
      </Col>
    </Row>
  )
}
