import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress
} from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { Container, Row, Col } from 'reactstrap'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'
import CommunicationDataDisplay from './communicationdata.display'
import {
  reset,
  resetStep,
  nextStep,
  previousStep,
  sendVerificationCode,
  sendMailVerificationCode,
  checkVerificationCode,
  checkEmailVerificationCode,
  startCommunicationDataChange,
  completeCommunicationDataChange,
  setDigitalCommunication
} from 'app/shared/reducers/data-change.reducer'
import { valueOrEmptyString, stringIsEmpty } from 'app/shared/utils/common'
import { messages } from 'app/config/constants'
import { CommunicationDataInput } from './communicationdata.input'
import ValidationCodebox from 'app/components/codebox/validation.codebox'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import CommunicationImage from 'resources/images/profile_communication.png'

export interface ICommunicationDataProps extends StateProps, DispatchProps {}

export interface ICommunicationDataState {
  expanded: boolean
  mobilePhone: string
  landLine: string
  changeType: string
  errorMessage: string
  digitalCommunicationConfirmOpen: boolean
}

export class CommunicationData extends React.Component<
  ICommunicationDataProps & RouteComponentProps,
  ICommunicationDataState
> {
  readonly MOBILECHANGE = 'mobilePhone'
  readonly LANDLINECHANGE = 'landLine'

  readonly NUMBEROFSTEPS = 3

  constructor(props) {
    super(props)
    this.state = {
      expanded: true,
      mobilePhone: valueOrEmptyString(this.props.profile.mobilePhone),
      landLine: valueOrEmptyString(this.props.profile.landLine),
      changeType: '',
      errorMessage: null,
      digitalCommunicationConfirmOpen: false
    }
  }

  componentDidMount() {
    this.props.reset()
  }

  handleChange = () => () => {
    this.setState({ expanded: !this.state.expanded })
  }

  startEmailChange = () => {
    this.props.history.push('/portal/account?emailedit')
  }

  startChange = () => {
    this.props.startCommunicationDataChange()
  }

  getSteps() {
    return ['Nummern aktualisieren', 'Bestätigungscode eingeben', 'Mobilnummer bestätigen']
  }

  handleValidInputSubmit = (mobilePhone: string, landLine: string) => {
    this.setState({ mobilePhone, landLine, errorMessage: null })
    if (mobilePhone !== this.props.profile.mobilePhone) {
      // the mobilephone was changed, thus we need to send out the validationcode via mail instead of sms
      this.setState({ changeType: this.MOBILECHANGE })
      this.props.sendMailVerificationCode()
    } else {
      this.setState({ changeType: this.LANDLINECHANGE })
      this.props.sendVerificationCode()
    }
  }

  handleValidCodeSubmit = (enteredCode: string) => {
    if (this.state.changeType === this.MOBILECHANGE) {
      this.props.checkEmailVerificationCode(
        enteredCode,
        this.state.mobilePhone,
        this.props.sendVerificationCode,
        this.handleUserLocked
      )
    } else {
      this.props.checkVerificationCode(enteredCode, this.completeDataChange, this.handleUserLocked)
    }
  }

  handleValidMobilePhoneConfirmation = (enteredCode: string) => {
    this.props.checkVerificationCode(enteredCode, this.completeDataChange, this.handleUserLocked)
  }

  handleUserLocked = () => {
    this.props.history.push('/locked')
  }

  handleDigitalCommunicationDialogShow = () => {
    this.setState({ digitalCommunicationConfirmOpen: true })
  }

  handleDigitalCommunicationDialogClose = () => {
    this.setState({ digitalCommunicationConfirmOpen: false })
  }

  handleDigitalCommuncationDialogChange = () => {
    this.props.setDigitalCommunication(!this.props.profile.digitalCommunicationEnabled)
    this.handleDigitalCommunicationDialogClose()
  }

  completeDataChange = () => {
    this.props.completeCommunicationDataChange(this.state.landLine, this.state.mobilePhone, this.NUMBEROFSTEPS)
  }

  resetErrorMessage = () => {
    this.setState({ errorMessage: null })
  }

  setErrorMessage = (errorMessage) => {
    this.setState({ errorMessage })
  }

  reset = () => {
    this.setState({
      mobilePhone: valueOrEmptyString(this.props.profile.mobilePhone),
      landLine: valueOrEmptyString(this.props.profile.landLine)
    })
    this.props.reset()
  }

  getStepContent = (index: number) => {
    const inputProps = {
      loading: this.props.loading,
      handleValidSubmit: this.handleValidInputSubmit,
      errorMessage: !stringIsEmpty(this.props.errorMessage) ? this.props.errorMessage : this.state.errorMessage,
      setErrorMessage: this.setErrorMessage,
      resetErrorMessage: this.resetErrorMessage,
      mobilePhone: this.props.profile.mobilePhone,
      landLine: this.props.profile.landLine,
      previousFunc: this.props.reset
    }
    const emailCodeboxProps = {
      onValidSubmit: this.handleValidCodeSubmit,
      onBack: null,
      resetError: this.resetErrorMessage,
      setError: this.setErrorMessage,
      hasBack: false,
      errorDescription: !stringIsEmpty(this.props.errorMessage) ? this.props.errorMessage : this.state.errorMessage,
      loading: this.props.loading,
      nextButtonText: 'Weiter',
      previousFunc: this.props.previousStep
    }
    const smsCodeboxProps = {
      onValidSubmit: this.handleValidMobilePhoneConfirmation,
      onBack: null,
      resetError: this.resetErrorMessage,
      setError: this.setErrorMessage,
      hasBack: false,
      errorDescription: !stringIsEmpty(this.props.errorMessage) ? this.props.errorMessage : this.state.errorMessage,
      loading: this.props.loading,
      nextButtonText: 'Weiter',
      previousFunc: this.props.previousStep
    }
    switch (index) {
      case 0:
        return <CommunicationDataInput {...inputProps} />
      case 1:
        return (
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <Typography variant="body2" className="regular-line-height text-margin">
                  Sie erhalten in wenigen Minuten Ihren Bestätigungscode per{' '}
                  {this.state.changeType === this.MOBILECHANGE ? 'E-Mail' : 'SMS'}. Bitte geben Sie diesen unten ein.
                </Typography>
              </Col>
              <Col xs="12">
                <Typography variant="body2" className="regular-line-height">
                  Eingabe Bestätigungscode:
                </Typography>
              </Col>
            </Row>
            <ValidationCodebox {...emailCodeboxProps} />
          </Container>
        )
      case 2:
        return (
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <Typography variant="body2" className="regular-line-height text-margin">
                  Zur Bestätigung Ihrer Mobilnummer haben wir Ihnen einen Bestätigungscode per SMS geschickt. Bitte
                  geben Sie diesen unten ein.
                </Typography>
              </Col>
              <Col xs="12">
                <Typography variant="body2" className="regular-line-height">
                  Eingabe Bestätigungscode:
                </Typography>
              </Col>
            </Row>
            <ValidationCodebox {...smsCodeboxProps} />
          </Container>
        )
      default:
        return 'ERROR'
    }
  }

  render() {
    const props = {
      startEmailChange: this.startEmailChange,
      startChange: this.startChange,
      profile: this.props.profile
    }
    return (
      <>
        <Accordion
          id="data"
          className="container-with-border content-container"
          expanded={this.state.expanded}
          onChange={this.handleChange()}>
          <AccordionSummary expandIcon={<RedTriangle medium />}>
            <Typography>
              {(this.props.change && 'Änderung der Kommunikationsdaten') || 'Kommunikationsdaten'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container fluid className="p-0">
              {!this.props.change && <CommunicationDataDisplay {...props} />}
              {this.props.change && this.props.currentStep < this.NUMBEROFSTEPS && (
                <Stepper activeStep={this.props.currentStep} orientation="vertical" className="email-reset">
                  {this.getSteps().map((label, index) => {
                    const stepProps = {}
                    const labelProps = {}
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                        <StepContent>{this.getStepContent(index)}</StepContent>
                      </Step>
                    )
                  })}
                </Stepper>
              )}
              {this.props.change && this.props.currentStep === this.NUMBEROFSTEPS && (
                <>
                  <Row noGutters>
                    <Col xs="12" sm="6">
                      <Typography className="text-margin bold">
                        Änderung der Kommunikationsdaten war erfolgreich
                      </Typography>
                      <Typography variant="body2" className="text-margin regular-line-height">
                        Vielen Dank für Ihre Änderung der Kommunikationsdaten.
                      </Typography>
                      <Typography variant="body2" className="text-margin regular-line-height">
                        Sie erhalten in wenigen Minuten eine Information über die Änderung per E-Mail.
                      </Typography>
                    </Col>
                    <Col xs="6" className="text-center profile-images">
                      <img src={CommunicationImage} alt="Kommunikationsdaten" className="smaller-picture-top"></img>
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs="12" className="mb-4">
                      <Typography variant="body2" className="regular-line-height">
                        Bitte beachten Sie, dass die Änderung nach 1-2 Werktagen im Portal sichtbar ist.
                      </Typography>
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs="12">
                      <Button
                        className="square-button red-border"
                        onClick={this.reset}
                        variant="contained"
                        color="secondary">
                        OK
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </AccordionDetails>
        </Accordion>
        <Dialog open={this.state.digitalCommunicationConfirmOpen} onClose={this.handleDigitalCommunicationDialogClose}>
          <DialogTitle>Digitaler Schriftverkehr</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.profile.digitalCommunicationEnabled
                ? messages.DIGITAL_COMMUNICATION_DISABLE
                : messages.DIGITAL_COMMUNICATION_ENABLE}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Container fluid className="p-0">
              <Row noGutters>
                {this.props.loading && (
                  <Col align="center">
                    <CircularProgress></CircularProgress>
                  </Col>
                )}
                {!this.props.loading && (
                  <>
                    <Col align="center">
                      <Button
                        className="square-button red-border no-uppercase"
                        variant="contained"
                        color="secondary"
                        onClick={this.handleDigitalCommunicationDialogClose}>
                        Abbrechen
                      </Button>
                    </Col>
                    <Col align="center">
                      <Button
                        className="square-button red-border no-uppercase"
                        variant="contained"
                        color="secondary"
                        onClick={this.handleDigitalCommuncationDialogChange}>
                        {this.props.profile.digitalCommunicationEnabled ? 'Deaktivieren' : 'Aktivieren'}
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </DialogActions>
        </Dialog>
        <Accordion id="digitalCommunication" className="container-with-border content-container" expanded={true}>
          <AccordionSummary expandIcon="">
            <Typography>Zustellungsart für den Schriftverkehr ändern</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container fluid className="p-0">
              <Row noGutters>
                <Col>
                  <FormGroup row>
                    <FormControlLabel
                      className="regular-line-height"
                      control={
                        <Checkbox
                          checked={this.props.profile.digitalCommunicationEnabled}
                          name="digitalCommunication"
                          color="primary"
                        />
                      }
                      onChange={this.handleDigitalCommunicationDialogShow}
                      label={messages.DIGITAL_COMMUNICATION_CHECKBOX_LABEL}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </AccordionDetails>
        </Accordion>
      </>
    )
  }
}

const mapStateToProps = ({ data, datachange }: IRootState) => ({
  profile: data.profile,
  currentStep: datachange.currentStep,
  loading: datachange.loading,
  change: datachange.communicationDataChange,
  errorMessage: datachange.errorMessage
})

const mapDispatchToProps = {
  reset,
  nextStep,
  previousStep,
  resetStep,
  sendVerificationCode,
  sendMailVerificationCode,
  checkVerificationCode,
  checkEmailVerificationCode,
  startCommunicationDataChange,
  completeCommunicationDataChange,
  setDigitalCommunication
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(CommunicationData)
