import './licenseplate.css'

import React from 'react'

import LicensePlateImage from 'resources/images/license_plate.png'
import { Typography } from '@material-ui/core'

export interface ILicensePlate {
  licenseNumber: string,
  centered?: boolean
}

export class LicensePlate extends React.Component<ILicensePlate> {
  type: string

  constructor(props) {
    super(props)

    this.type = 'licenseplate'

    if (this.props.centered) {
      this.type = 'licenseplate-centered'
    }
  }

  render() {
    return (
      <div className={this.type}>
        <div className="licenseplate-text">
          <Typography>{this.props.licenseNumber}</Typography>
        </div>
        <img src={LicensePlateImage} alt={this.props.licenseNumber}></img>
      </div>
    )
  }
}

export default LicensePlate
