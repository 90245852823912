import React from 'react'
import { Button, Typography, TextField, CircularProgress } from '@material-ui/core'
import { Col, Container, Row, Form, FormGroup } from 'reactstrap'
import { stringIsEmpty, isUndefined } from 'app/shared/utils/common'

export interface IEmailResetCodeboxProps {
  checkCode: any
  feedback: string
  errorDescription: string
  clearErrorMessage: any
  loading: boolean
  previousFunc: any
}

export interface IEmailResetCodeboxState {
  errorDescription: string
}

export default class EmailResetCodebox extends React.Component<IEmailResetCodeboxProps, IEmailResetCodeboxState> {
  inputField1: React.RefObject<any>
  inputField2: React.RefObject<any>
  inputField3: React.RefObject<any>
  inputField4: React.RefObject<any>

  constructor(props) {
    super(props)
    this.inputField1 = React.createRef()
    this.inputField2 = React.createRef()
    this.inputField3 = React.createRef()
    this.inputField4 = React.createRef()
    this.state = {
      errorDescription: ''
    }
  }

  getValuesAsString = () => {
    return (
      '' +
      this.inputField1.current.value +
      this.inputField2.current.value +
      this.inputField3.current.value +
      this.inputField4.current.value
    )
  }

  validate = () => {
    for (let i = 1; i < 5; i++) {
      if (
        stringIsEmpty(this['inputField' + i].current.value) ||
        this['inputField' + i].current.validity.valid === false
      ) {
        this.props.clearErrorMessage()
        this.setState({
          ...this.state,
          errorDescription: 'Es müssen alle Felder eingegeben werden.'
        })
        return false
      }
    }
    return true
  }

  submit = () => {
    if (this.validate()) {
      const enteredCode = this.getValuesAsString()
      this.props.checkCode(enteredCode)
    }
  }

  getIdFromTarget = (target: string): number => {
    return parseInt(target.slice(-1))
  }

  handleFieldInput = (target: number, input) => {
    this['inputField' + target].current.value = input
    if (input !== '') {
      let focusTarget = target + 1
      if (focusTarget > 4) {
        focusTarget = 1
      }
      this['inputField' + focusTarget].current.focus()
    }
  }

  handleKeyDown = (event) => {
    event.preventDefault()
    if (event.key === 'Enter') {
      this.submit()
    } else if (event.key === 'Backspace' || event.key === ' ') {
      this.handleFieldInput(this.getIdFromTarget(event.target.id), '')
    } else if (event.key.length === 1) {
      this.handleFieldInput(this.getIdFromTarget(event.target.id), event.key)
    }
  }

  hasError = () => {
    if (!stringIsEmpty(this.props.errorDescription) || !stringIsEmpty(this.state.errorDescription)) {
      return true
    }
    return false
  }

  getErrorDescription = () => {
    if (!stringIsEmpty(this.props.errorDescription)) {
      return this.props.errorDescription
    } else if (!stringIsEmpty(this.state.errorDescription)) {
      return this.state.errorDescription
    }
    return ''
  }

  render() {
    const codeInputProps = {
      required: true,
      maxLength: 1
    }
    return (
      <Container fluid className="p-0 email-reset-inner-box">
        <Row noGutters>
          <Col>
            <Typography className="email-reset-header">{this.props.feedback}</Typography>
          </Col>
        </Row>
        <Row noGutters>
          <Form>
            <FormGroup>
              <div className="margin" />
              <Typography>Eingabe Bestätigungscode:</Typography>
              <Row className="phoneDigitsFields">
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField1}
                    id="confirmationCodeChar1"
                    variant="outlined"
                    inputProps={{ ...codeInputProps }}
                    onKeyDown={this.handleKeyDown}
                    autoFocus
                  />
                </Col>
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField2}
                    id="confirmationCodeChar2"
                    variant="outlined"
                    inputProps={{ ...codeInputProps }}
                    onKeyDown={this.handleKeyDown}
                  />
                </Col>
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField3}
                    id="confirmationCodeChar3"
                    variant="outlined"
                    inputProps={{ ...codeInputProps }}
                    onKeyDown={this.handleKeyDown}
                  />
                </Col>
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField4}
                    id="confirmationCodeChar4"
                    variant="outlined"
                    inputProps={{ ...codeInputProps }}
                    onKeyDown={this.handleKeyDown}
                  />
                </Col>
              </Row>
            </FormGroup>
            <div className="margin" />
            {this.hasError() && (
              <Typography className="validationFailed" id="pwValidationLabel" variant="body2">
                {this.getErrorDescription()}
              </Typography>
            )}
            <div className="margin" />
            <FormGroup className="inline">
              {(this.props.loading && (
                <div className="text-center">
                  <CircularProgress></CircularProgress>
                </div>
              )) || (
                <>
                  {!isUndefined(this.props.previousFunc) && (
                    <Button
                      className="square-button fullWidth mr-2"
                      onClick={this.props.previousFunc}
                      variant="contained"
                      color="primary">
                      Zurück
                    </Button>
                  )}
                  <Button
                    className="square-button red-border"
                    onClick={this.submit}
                    variant="contained"
                    color="secondary">
                    Weiter
                  </Button>
                </>
              )}
            </FormGroup>
          </Form>
        </Row>
      </Container>
    )
  }
}
