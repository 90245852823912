import React from 'react'
import { stringIsEmpty, isUndefined } from 'app/shared/utils/common'

import { FormGroup, Row, Col, Form } from 'reactstrap'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Typography, CircularProgress } from '@material-ui/core'

export interface IValidationCodebox {
  onValidSubmit: any
  onBack: any
  resetError: any
  setError: any
  hasBack: boolean
  errorDescription: string
  loading: boolean
  nextButtonText: string
  previousFunc?: any
}

export interface IValidationCodeboxStateProps {}

export class ValidationCodebox extends React.Component<IValidationCodebox, IValidationCodeboxStateProps> {
  inputField1: React.RefObject<any>
  inputField2: React.RefObject<any>
  inputField3: React.RefObject<any>
  inputField4: React.RefObject<any>

  constructor(props) {
    super(props)
    this.inputField1 = React.createRef()
    this.inputField2 = React.createRef()
    this.inputField3 = React.createRef()
    this.inputField4 = React.createRef()
  }

  validate = () => {
    for (let i = 1; i < 5; i++) {
      if (
        stringIsEmpty(this['inputField' + i].current.value) ||
        this['inputField' + i].current.validity.valid === false
      ) {
        this.props.setError('Es müssen alle Felder eingegeben werden.')
        return false
      }
    }
    this.props.resetError()
    return true
  }

  getCodeAsString = () => {
    return (
      '' +
      this.inputField1.current.value +
      this.inputField2.current.value +
      this.inputField3.current.value +
      this.inputField4.current.value
    )
  }

  submit = () => {
    if (this.validate()) {
      const enteredCode = this.getCodeAsString()
      this.props.onValidSubmit(enteredCode)
    }
  }

  getIdFromTarget = (target: string): number => {
    return parseInt(target.slice(-1))
  }

  handleFieldInput = (target: number, input) => {
    this['inputField' + target].current.value = input
    if (input !== '') {
      let focusTarget = target + 1
      if (focusTarget > 4) {
        focusTarget = 1
      }
      this['inputField' + focusTarget].current.focus()
    }
  }

  handleKeyDown = (event) => {
    event.preventDefault()
    if (event.key === 'Enter') {
      this.submit()
    } else if (event.key === 'Backspace' || event.key === ' ') {
      this.handleFieldInput(this.getIdFromTarget(event.target.id), '')
    } else if (event.key.length === 1) {
      this.handleFieldInput(this.getIdFromTarget(event.target.id), event.key)
    }
  }

  render() {
    const codeInputProps = {
      required: true,
      maxLength: 1
    }
    return (
      <Form>
        <FormGroup>
          <Row className="phoneDigitsFields">
            <Col md="auto">
              <TextField
                className="noborderradius"
                inputRef={this.inputField1}
                id="codeBox1"
                variant="outlined"
                inputProps={{ ...codeInputProps }}
                onKeyDown={this.handleKeyDown}
                autoFocus
              />
            </Col>
            <Col md="auto">
              <TextField
                className="noborderradius"
                inputRef={this.inputField2}
                id="codeBox2"
                variant="outlined"
                inputProps={{ ...codeInputProps }}
                onKeyDown={this.handleKeyDown}
              />
            </Col>
            <Col md="auto">
              <TextField
                className="noborderradius"
                inputRef={this.inputField3}
                id="codeBox3"
                variant="outlined"
                inputProps={{ ...codeInputProps }}
                onKeyDown={this.handleKeyDown}
              />
            </Col>
            <Col md="auto">
              <TextField
                className="noborderradius"
                inputRef={this.inputField4}
                id="codeBox4"
                variant="outlined"
                inputProps={{ ...codeInputProps }}
                onKeyDown={this.handleKeyDown}
              />
            </Col>
          </Row>
        </FormGroup>
        {this.props.errorDescription && (
          <Typography className="validationFailed" id="pwValidationLabel" variant="body2">
            {this.props.errorDescription}
          </Typography>
        )}
        <div className="margin2" />
        {this.props.loading && (
          <div className="text-center">
            <CircularProgress></CircularProgress>
          </div>
        )}
        {this.props.hasBack && !this.props.loading && (
          <FormGroup className="inline">
            <Button className="white-square-button bordered-button" color="primary" onClick={this.props.onBack}>
              Zurück
            </Button>
            <Button className="square-button red-border" onClick={this.submit} variant="contained" color="secondary">
              {this.props.nextButtonText}
            </Button>
          </FormGroup>
        )}
        {!this.props.hasBack && !this.props.loading && (
          <>
            {!isUndefined(this.props.previousFunc) && (
              <Button
                className="square-button fullWidth mr-2"
                onClick={this.props.previousFunc}
                variant="contained"
                color="primary">
                Zurück
              </Button>
            )}
            <Button
              className={'square-button red-border fullWidth ' + (isUndefined(this.props.previousFunc) ? '' : 'ml-2')}
              onClick={this.submit}
              variant="contained"
              color="secondary">
              {this.props.nextButtonText}
            </Button>
          </>
        )}
      </Form>
    )
  }
}

export default ValidationCodebox
