import axios from 'axios'
import { IMessage } from 'app/model/message.model'
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util'
import { stringIsEmpty } from 'app/shared/utils/common'
import { messages } from 'app/config/constants'

export const ACTION_TYPES = {
  FETCH_MESSAGES: 'inbox/FETCH_MESSAGES',
  GET_MESSAGE_COUNT: 'inbox/GET_MESSAGE_COUNT',
  MESSAGE_TOGGLE_SELECTED: 'inbox/MESSAGE_TOGGLE_SELECTED',
  MESSAGE_SET_CHECKED: 'inbox/MESSAGE_SET_CHECKED',
  DELETE_MESSAGES: 'inbox/DELETE_MESSAGES',
  DELETE_MESSAGE: 'inbox/DELETE_MESSAGE',
  RESTORE_MESSAGES: 'inbox/RESTORE_MESSAGES',
  ARCHIVE_MESSAGES: 'inbox/ARCHIVE_MESSAGES',
  DOWNLOAD_MESSAGE_DATA: 'inbox/DOWNLOAD_MESSAGE_DATA',
  RESET: 'inbox/RESET'
}

export const initialState = {
  loading: false,
  errorMessage: null as string,
  messages: [] as IMessage[],
  messageCountTotal: 0,
  messageCountUnread: 0,
  messageCountDeleted: 0,
  messageCountArchived: 0,
  messagesSelectedCount: 0,
  messageData: null as Blob
}

export type InboxState = Readonly<typeof initialState>

export default (state: InboxState = initialState, action): InboxState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_MESSAGES):
    case REQUEST(ACTION_TYPES.GET_MESSAGE_COUNT):
    case REQUEST(ACTION_TYPES.DELETE_MESSAGE):
    case REQUEST(ACTION_TYPES.RESTORE_MESSAGES):
    case REQUEST(ACTION_TYPES.ARCHIVE_MESSAGES):
    case REQUEST(ACTION_TYPES.DOWNLOAD_MESSAGE_DATA):
      return {
        ...state,
        loading: true
      }
    case SUCCESS(ACTION_TYPES.FETCH_MESSAGES):
      return {
        ...state,
        errorMessage: null,
        loading: false,
        messages: action.payload.data
      }
    case SUCCESS(ACTION_TYPES.GET_MESSAGE_COUNT):
      return {
        ...state,
        errorMessage: null,
        loading: false,
        messageCountTotal: action.payload.data.total,
        messageCountUnread: action.payload.data.unread,
        messageCountDeleted: action.payload.data.deleted,
        messageCountArchived: action.payload.data.archived
      }
    case SUCCESS(ACTION_TYPES.DELETE_MESSAGE):
    case SUCCESS(ACTION_TYPES.ARCHIVE_MESSAGES):
    case SUCCESS(ACTION_TYPES.RESTORE_MESSAGES):
      return {
        ...state,
        errorMessage: null,
        loading: false
      }
    case SUCCESS(ACTION_TYPES.DOWNLOAD_MESSAGE_DATA):
      // eslint-disable-next-line no-case-declarations
      const downloadDataMessages = state.messages
      downloadDataMessages.splice(downloadDataMessages.indexOf(action.meta.message), 1)
      downloadDataMessages.push({ ...action.meta.message, read: true })
      return {
        ...state,
        errorMessage: null,
        loading: false,
        messages: downloadDataMessages,
        messageData: new Blob([action.payload.data], { type: 'application/pdf' })
      }
    case FAILURE(ACTION_TYPES.GET_MESSAGE_COUNT):
    case FAILURE(ACTION_TYPES.DELETE_MESSAGE):
    case FAILURE(ACTION_TYPES.FETCH_MESSAGES):
    case FAILURE(ACTION_TYPES.RESTORE_MESSAGES):
    case FAILURE(ACTION_TYPES.ARCHIVE_MESSAGES):
    case FAILURE(ACTION_TYPES.DOWNLOAD_MESSAGE_DATA):
      return {
        ...state,
        errorMessage:
          action.payload !== undefined && !stringIsEmpty(action.payload.response)
            ? action.payload.response.data.message
            : messages.ERROR_SERVER_COMMUNICATION,
        loading: false
      }
    case ACTION_TYPES.MESSAGE_TOGGLE_SELECTED: {
      const togleSelectedMessages = state.messages
      togleSelectedMessages.splice(togleSelectedMessages.indexOf(action.payload.message), 1)
      togleSelectedMessages.push({ ...action.payload.message, selected: !action.payload.message.selected })
      return {
        ...state,
        messages: togleSelectedMessages,
        messagesSelectedCount: togleSelectedMessages.filter((m) => m.selected).length
      }
    }
    case ACTION_TYPES.MESSAGE_SET_CHECKED: {
      const messagesChecked = state.messages
      messagesChecked.splice(messagesChecked.indexOf(action.payload.message), 1)
      messagesChecked.push({ ...action.payload.message, selected: action.payload.state })
      return {
        ...state,
        messages: messagesChecked,
        messagesSelectedCount: messagesChecked.filter((m) => m.selected).length
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}

export const getMessages = () => ({
  type: ACTION_TYPES.FETCH_MESSAGES,
  payload: axios.get('/api/messages/')
})

export const getMessageCount = () => ({
  type: ACTION_TYPES.GET_MESSAGE_COUNT,
  payload: axios.get('/api/messages/count')
})

export const toggleSelected = (message) => ({
  type: ACTION_TYPES.MESSAGE_TOGGLE_SELECTED,
  payload: { message }
})

export const setChecked = (message, state) => ({
  type: ACTION_TYPES.MESSAGE_SET_CHECKED,
  payload: { message, state }
})

export const deleteMessageList = (ids: number[]) => ({
  type: ACTION_TYPES.DELETE_MESSAGE,
  payload: axios.delete('/api/messages/', { data: ids })
})

export const deleteMessages = (messagesToDelete: IMessage[]) => async (dispatch) => {
  const idList = messagesToDelete
    .filter((message) => message.selected)
    .map((message) => {
      return message.id
    })
  await dispatch(deleteMessageList(idList))
}

export const restoreMessageList = (ids: number[]) => ({
  type: ACTION_TYPES.RESTORE_MESSAGES,
  payload: axios.post('api/messages/restore', ids)
})

export const restoreMessages = (messagesToRestore: IMessage[]) => async (dispatch) => {
  const idList = messagesToRestore
    .filter((message) => message.selected)
    .map((message) => {
      return message.id
    })
  await dispatch(restoreMessageList(idList))
}

export const archiveMessageList = (ids: number[]) => ({
  type: ACTION_TYPES.ARCHIVE_MESSAGES,
  payload: axios.post('api/messages/archive', ids)
})

export const archiveMessages = (messagesToArchive: IMessage[]) => async (dispatch) => {
  const idList = messagesToArchive
    .filter((message) => message.selected)
    .map((message) => {
      return message.id
    })
  await dispatch(archiveMessageList(idList))
}

export const downloadMessageData = (message: IMessage) => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.DOWNLOAD_MESSAGE_DATA,
    payload: axios.get(`/api/messages/content/${message.id}`, { responseType: 'blob' }),
    meta: { message }
  })

  await dispatch(getMessageCount())
}

export const reset = () => ({
  type: ACTION_TYPES.RESET
})
