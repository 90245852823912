import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button
} from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { Container, Row, Col } from 'reactstrap'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'
import PersonalDataDisplay from './personaldata.display'
import {
  reset,
  resetStep,
  nextStep,
  previousStep,
  setErrorMessage,
  resetErrorMessage,
  sendVerificationCode,
  checkVerificationCode,
  startPersonalDataChange,
  completePersonalDataChange
} from 'app/shared/reducers/data-change.reducer'
import { UploadContainer } from 'app/components/upload/upload.container'
import { IUploadFile } from 'app/components/upload/upload'
import { valueOrEmptyString } from 'app/shared/utils/common'
import { PersonalDataInput } from './personaldata.input'
import ValidationCodebox from 'app/components/codebox/validation.codebox'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import PersonalDataImage from 'resources/images/profile_personal_data.png'
import IDImage from 'resources/images/identity_card.png'
import { DEFAULT_MAX_UPLOAD_FILE_COUNT, DEFAULT_MIN_UPLOAD_FILE_COUNT, DEFAULT_VALID_UPLOAD_FILES, DEFAULT_VALID_UPLOAD_FILE_DESCRIPTION } from 'app/config/constants'

export interface IPersonalDataProps extends StateProps, DispatchProps {}

export interface IPersonalDataState {
  expanded: boolean
  files: IUploadFile[]
  title: string
  firstName: string
  lastName: string
}

export class PersonalData extends React.Component<IPersonalDataProps & RouteComponentProps, IPersonalDataState> {
  readonly NUMBEROFSTEPS = 3

  constructor(props) {
    super(props)
    this.state = {
      expanded: true,
      files: [],
      title: valueOrEmptyString(this.props.profile.title),
      firstName: valueOrEmptyString(this.props.profile.firstName),
      lastName: valueOrEmptyString(this.props.profile.lastName)
    }
  }

  componentDidMount() {
    this.props.reset()
  }

  handleChange = () => () => {
    this.setState({ ...this.state, expanded: !this.state.expanded })
  }

  startChange = () => {
    this.props.startPersonalDataChange()
  }

  getSteps() {
    return ['Dokument hochladen', 'Namen aktualisieren', 'Bestätigungscode eingeben']
  }

  confirmUpload = (files: IUploadFile[]) => {
    this.setState({ ...this.state, files: files })
    this.props.nextStep()
  }

  handleValidInputSubmit = (title: string, firstName: string, lastName: string) => {
    this.setState({ ...this.state, title: title, firstName: firstName, lastName: lastName })
    this.props.sendVerificationCode()
  }

  handleValidCodeSubmit = (enteredCode: string) => {
    this.props.checkVerificationCode(enteredCode, this.completeDataChange, this.handleUserLocked)
  }

  handleUserLocked = () => {
    this.props.history.push('/locked')
  }

  completeDataChange = () => {
    const formData = new FormData()
    this.state.files.forEach((file) => {
      formData.append('file', file.content)
    })
    formData.append('title', this.state.title)
    formData.append('firstName', this.state.firstName)
    formData.append('lastName', this.state.lastName)
    this.props.completePersonalDataChange(formData, this.NUMBEROFSTEPS)
  }

  reset = () => {
    this.setState({
      ...this.state,
      files: [],
      title: valueOrEmptyString(this.props.profile.title),
      firstName: valueOrEmptyString(this.props.profile.firstName),
      lastName: valueOrEmptyString(this.props.profile.lastName)
    })
    this.props.reset()
  }

  getStepContent = (index: number) => {
    const inputProps = {
      title: this.props.profile.title,
      firstName: this.props.profile.firstName,
      lastName: this.props.profile.lastName,
      loading: this.props.loading,
      dateOfBirth: this.props.profile.dateOfBirth,
      handleValidSubmit: this.handleValidInputSubmit,
      errorMessage: this.props.errorMessage,
      setErrorMessage: this.props.setErrorMessage,
      resetErrorMessage: this.props.resetErrorMessage,
      previousFunc: this.props.previousStep
    }
    const codeboxProps = {
      onValidSubmit: this.handleValidCodeSubmit,
      onBack: null,
      resetError: this.props.resetErrorMessage,
      setError: this.props.setErrorMessage,
      hasBack: false,
      errorDescription: this.props.errorMessage,
      loading: this.props.loading,
      nextButtonText: 'Weiter',
      previousFunc: this.props.previousStep
    }
    switch (index) {
      case 0:
        return (
          <>
            <Container fluid className="p-0">
              <Row noGutters>
                <Col xs="12">
                  <Typography className="text-margin regular-line-height">
                    Für die Namensänderung benötigen wir bitte eine amtliche Bescheinigung.
                  </Typography>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs="12" sm="6">
                  <Typography className="bold text-margin regular-line-height">
                    Bitte laden Sie Folgendes hoch:
                  </Typography>
                  <Typography className="regular-line-height">
                    Kopie des Personalausweises oder der Heiratsurkunde
                  </Typography>
                </Col>
                <Col sm="6" className="text-center profile-images">
                  <img src={IDImage} alt="Personalausweis" className="smaller-picture-top"></img>
                </Col>
              </Row>
            </Container>
            <UploadContainer
              sendUploadedFiles={this.confirmUpload}
              customButton={true}
              customButtonText='Weiter'
              cancel={this.reset}
              validFiles={DEFAULT_VALID_UPLOAD_FILES}
              validFileDescription={DEFAULT_VALID_UPLOAD_FILE_DESCRIPTION}
              maxFileCount={DEFAULT_MAX_UPLOAD_FILE_COUNT}
              minFileCount={DEFAULT_MIN_UPLOAD_FILE_COUNT}
              initialUploadDisabled={true}/>
          </>
        )
      case 1:
        return <PersonalDataInput {...inputProps} />
      case 2:
        return (
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <Typography variant="body2" className="regular-line-height text-margin">
                  Sie erhalten in wenigen Minuten Ihren Bestätigungscode per SMS. Bitte geben Sie diesen unten ein.
                </Typography>
              </Col>
              <Col xs="12">
                <Typography variant="body2" className="regular-line-height">
                  Eingabe Bestätigungscode:
                </Typography>
              </Col>
            </Row>
            <ValidationCodebox {...codeboxProps} />
          </Container>
        )
      default:
        return 'ERROR'
    }
  }

  render() {
    const props = {
      startChange: this.startChange,
      profile: this.props.profile
    }
    return (
      <Accordion
        id="data"
        className="container-with-border content-container"
        expanded={this.state.expanded}
        onChange={this.handleChange()}>
        <AccordionSummary expandIcon={<RedTriangle medium />}>
          <Typography>{(this.props.change && 'Behördliche Namensänderung') || 'Persönliche Daten'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container fluid className="p-0">
            {!this.props.change && <PersonalDataDisplay {...props} />}
            {this.props.change && this.props.currentStep < this.NUMBEROFSTEPS && (
              <Stepper activeStep={this.props.currentStep} orientation="vertical" className="email-reset">
                {this.getSteps().map((label, index) => {
                  const stepProps = {}
                  const labelProps = {}
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                      <StepContent>{this.getStepContent(index)}</StepContent>
                    </Step>
                  )
                })}
              </Stepper>
            )}
            {this.props.change && this.props.currentStep === this.NUMBEROFSTEPS && (
              <>
                <Row noGutters>
                  <Col xs="12" sm="6">
                    <Typography className="text-margin bold">Namensänderung war erfolgreich</Typography>
                    <Typography variant="body2" className="text-margin regular-line-height">
                      Vielen Dank für Ihre Namensänderung.
                    </Typography>
                    <Typography variant="body2" className="text-margin regular-line-height">
                      Sie erhalten in wenigen Minuten eine Information über die Änderung per E-Mail.
                    </Typography>
                  </Col>
                  <Col xs="6" className="text-center profile-images">
                    <img src={PersonalDataImage} alt="Persönliche Daten" className="smaller-picture-top"></img>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col xs="12" className="mb-4">
                    <Typography variant="body2" className="regular-line-height">
                      Bitte beachten Sie, dass die Änderung nach 1-2 Werktagen im Portal sichtbar ist.
                    </Typography>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col xs="12">
                    <Button
                      className="square-button red-border"
                      onClick={this.reset}
                      variant="contained"
                      color="secondary">
                      OK
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </AccordionDetails>
      </Accordion>
    )
  }
}

const mapStateToProps = ({ data, datachange }: IRootState) => ({
  profile: data.profile,
  currentStep: datachange.currentStep,
  loading: datachange.loading,
  errorMessage: datachange.errorMessage,
  change: datachange.personalDataChange
})

const mapDispatchToProps = {
  reset,
  nextStep,
  previousStep,
  resetStep,
  setErrorMessage,
  resetErrorMessage,
  sendVerificationCode,
  checkVerificationCode,
  startPersonalDataChange,
  completePersonalDataChange
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(PersonalData)
