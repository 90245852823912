import axios from 'axios'

import { FAILURE, REQUEST, SUCCESS } from './action-type.util'
import { messages } from 'app/config/constants'

import { stringIsEmpty, handleBackendError } from '../utils/common'
import { setMobilePhone, setDigitalCommunicationEnabled } from './data.reducer'

export const ACTION_TYPES = {
  RESET: 'datachange/RESET',
  RESET_STEP: 'datachange/RESET_STEP',
  PREV_STEP: 'datachange/PREV_STEP',
  NEXT_STEP: 'datachange/NEXT_STEP',
  SEND_CODE: 'datachange/SEND_CODE',
  VERIFY_CODE: 'datachange/VERIFY_CODE',
  SET_ERROR: 'datachange/SET_ERROR',
  RESET_ERROR: 'datachange/RESET_ERROR',
  START_PERSONALDATA_CHANGE: 'datachange/START_PERSONALDATA_CHANGE',
  START_ADDRESS_CHANGE: 'datachange/START_ADDRESS_CHANGE',
  START_COMMUNICATIONDATA_CHANGE: 'datachange/START_COMMUNICATIONDATA_CHANGE',
  START_CONTRACT_BANKACCOUNT_CHANGE: 'datachange/START_CONTRACT_BANKACCOUNT_CHANGE',
  COMPLETE_CHANGE: 'datachange/COMPLETE_CHANGE',
  DIGITAL_COMMUNICATION_CHANGE: 'datachange/DIGITAL_COMMUNICATION_CHANGE'
}

export const initialState = {
  loading: false,
  errorMessage: null as string,
  currentStep: 0,
  personalDataChange: false,
  addressDataChange: false,
  communicationDataChange: false,
  contractBankaccountChange: false
}

export type DataChangeState = Readonly<typeof initialState>

export default (state: DataChangeState = initialState, action): DataChangeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEND_CODE):
    case REQUEST(ACTION_TYPES.VERIFY_CODE):
    case REQUEST(ACTION_TYPES.COMPLETE_CHANGE):
      return {
        ...state,
        loading: true
      }
    case FAILURE(ACTION_TYPES.SEND_CODE):
      return {
        ...state,
        loading: false,
        errorMessage:
          action.payload !== undefined && !stringIsEmpty(action.payload.response)
            ? messages.CODE_SENDING_ERROR
            : messages.ERROR_SERVER_COMMUNICATION
      }
    case FAILURE(ACTION_TYPES.COMPLETE_CHANGE):
      return {
        ...state,
        loading: false,
        errorMessage:
          action.payload !== undefined && !stringIsEmpty(action.payload.response)
            ? action.payload.response.data.message
            : messages.ERROR_SERVER_COMMUNICATION
      }
    case FAILURE(ACTION_TYPES.VERIFY_CODE): {
      return {
        ...state,
        loading: false,
        errorMessage:
          action.payload !== undefined ? messages.CODE_VERIFICATION_ERROR : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    case SUCCESS(ACTION_TYPES.SEND_CODE):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentStep: state.currentStep + 1
      }
    case SUCCESS(ACTION_TYPES.COMPLETE_CHANGE):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentStep: action.meta.lastStep
      }
    case SUCCESS(ACTION_TYPES.VERIFY_CODE): {
      return {
        ...state,
        loading: false,
        errorMessage: null
      }
    }
    case ACTION_TYPES.START_PERSONALDATA_CHANGE: {
      return {
        ...initialState,
        personalDataChange: true
      }
    }
    case ACTION_TYPES.START_ADDRESS_CHANGE: {
      return {
        ...initialState,
        addressDataChange: true
      }
    }
    case ACTION_TYPES.START_COMMUNICATIONDATA_CHANGE: {
      return {
        ...initialState,
        communicationDataChange: true
      }
    }
    case ACTION_TYPES.START_CONTRACT_BANKACCOUNT_CHANGE: {
      return {
        ...initialState,
        contractBankaccountChange: true
      }
    }
    case ACTION_TYPES.RESET_STEP: {
      return {
        ...state,
        errorMessage: null,
        currentStep: 0
      }
    }
    case ACTION_TYPES.NEXT_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 1
      }
    }
    case ACTION_TYPES.PREV_STEP: {
      return {
        ...state,
        currentStep: state.currentStep > 0 ? state.currentStep - 1 : state.currentStep
      }
    }
    case ACTION_TYPES.SET_ERROR: {
      return {
        ...state,
        errorMessage: action.payload.error
      }
    }
    case ACTION_TYPES.RESET_ERROR: {
      return {
        ...state,
        errorMessage: null as string
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...initialState
      }
    }
    default: {
      return state
    }
  }
}

export const reset = () => ({
  type: ACTION_TYPES.RESET
})

export const setErrorMessage = (error: string) => ({
  type: ACTION_TYPES.SET_ERROR,
  payload: {
    error: error
  }
})

export const resetErrorMessage = () => ({
  type: ACTION_TYPES.RESET_ERROR
})

export const nextStep = () => ({
  type: ACTION_TYPES.NEXT_STEP
})

export const previousStep = () => ({
  type: ACTION_TYPES.PREV_STEP
})

export const resetStep = () => ({
  type: ACTION_TYPES.RESET_STEP
})

export const sendVerificationCode = () => ({
  type: ACTION_TYPES.SEND_CODE,
  payload: axios.post('api/user/sendSmsCode')
})

export const sendMailVerificationCode = () => ({
  type: ACTION_TYPES.SEND_CODE,
  payload: axios.post('api/user/sendEmailCode')
})

export const checkVerificationCode = (codeEntered: string, onSuccess: Function, onError: Function) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.VERIFY_CODE,
    payload: axios.get(`api/user/verifycode/${codeEntered}`)
  }).then(
    () => {
      onSuccess()
    },
    (error) => {
      handleBackendError(error.response, error.response.status, 403, onError)
    }
  )
}

export const checkEmailVerificationCode = (
  codeEntered: string,
  newPhoneNumber: string,
  onSuccess: Function,
  onError: Function
) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.VERIFY_CODE,
    payload: axios.get(`api/user/verifyEmailCode/${newPhoneNumber}/${codeEntered}`)
  }).then(
    () => {
      onSuccess()
    },
    (error) => {
      handleBackendError(error.response, error.response.status, 403, onError)
    }
  )
}

export const startPersonalDataChange = () => ({
  type: ACTION_TYPES.START_PERSONALDATA_CHANGE
})

export const startAddressDataChange = () => ({
  type: ACTION_TYPES.START_ADDRESS_CHANGE
})

export const startCommunicationDataChange = () => ({
  type: ACTION_TYPES.START_COMMUNICATIONDATA_CHANGE
})

export const startContractBankAccountChange = () => ({
  type: ACTION_TYPES.START_CONTRACT_BANKACCOUNT_CHANGE
})

export const completePersonalDataChange = (upload: FormData, lastStep: number) => ({
  type: ACTION_TYPES.COMPLETE_CHANGE,
  payload: axios.post('api/user/personalData', upload),
  meta: { lastStep }
})

export const completeAddressDataChange = (upload: FormData, lastStep: number) => ({
  type: ACTION_TYPES.COMPLETE_CHANGE,
  payload: axios.post('api/user/addressData', upload),
  meta: { lastStep }
})

export const completeCommunicationDataChange = (landline: string, mobilePhone: string, lastStep: number) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.COMPLETE_CHANGE,
    payload: axios.post('api/user/communicationData', landline, { headers: { 'Content-Type': 'text/plain' } }),
    meta: { lastStep }
  }).then(() => {
    dispatch(setMobilePhone(mobilePhone))
  })
}

export const completeContractBankAccountChange = (upload: FormData, contractId, lastStep: number) => ({
  type: ACTION_TYPES.COMPLETE_CHANGE,
  payload: axios.post(`api/contract/updateIban/${contractId}`, upload),
  meta: { lastStep }
})

export const setDigitalCommunication = (enabled: boolean) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.DIGITAL_COMMUNICATION_CHANGE,
    payload: axios.put(`/api/user/digitalCommunicationEnabled/${enabled}`)
  }).then(() => {
    dispatch(setDigitalCommunicationEnabled(enabled))
  })
}
