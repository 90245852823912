import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { withRouter, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import { getEmailFromUser } from 'app/shared/utils/user.util'
import { IRootState } from 'app/shared/reducers'
import { stringIsEmpty } from 'app/shared/utils/common'

import {
  clearErrorMessage,
  checkPhoneNumberForUser,
  sendConfirmationCode
} from 'app/shared/reducers/registration.reducer'

import { FormGroup, Row, Col, Form } from 'reactstrap'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

export interface IRegistrationVerifyPhone extends StateProps, DispatchProps {}

export interface IRegistrationVerifyPhoneStateProps {
  errorDescription: string
}

export class RegistrationVerifyPhone extends React.Component<
  IRegistrationVerifyPhone & RouteComponentProps,
  IRegistrationVerifyPhoneStateProps
> {
  inputField1: React.RefObject<any>
  inputField2: React.RefObject<any>
  inputField3: React.RefObject<any>
  inputField4: React.RefObject<any>

  constructor(props) {
    super(props)
    this.inputField1 = React.createRef()
    this.inputField2 = React.createRef()
    this.inputField3 = React.createRef()
    this.inputField4 = React.createRef()
    this.state = {
      errorDescription: ''
    }
  }

  previousRegistrationStep = () => {
    this.props.clearErrorMessage()
    this.props.history.push('/registration/legal')
  }

  nextRegistrationStep = () => {
    this.props.history.push('/registration/confirmationcode')
  }

  showErrorPage = () => {
    this.props.history.push('/registration/error')
  }

  sendConfirmationCode = () => {
    this.props.sendConfirmationCode(this.props.registrationKey, this.nextRegistrationStep, this.nextRegistrationStep)
  }

  validate = () => {
    for (let i = 1; i < 5; i++) {
      if (
        stringIsEmpty(this['inputField' + i].current.value) ||
        this['inputField' + i].current.validity.valid === false
      ) {
        this.props.clearErrorMessage()
        this.setState({
          errorDescription: 'Es müssen alle Felder eingegeben werden.'
        })
        return false
      }
    }
    this.setState({ errorDescription: '' })
    return true
  }

  getDigitsAsString = () => {
    return (
      '' +
      this.inputField1.current.value +
      this.inputField2.current.value +
      this.inputField3.current.value +
      this.inputField4.current.value
    )
  }

  submit = () => {
    if (this.validate()) {
      const enteredDigits = this.getDigitsAsString()
      this.props.checkPhoneNumberForUser(
        this.props.registrationKey,
        enteredDigits,
        this.sendConfirmationCode,
        this.showErrorPage
      )
    }
  }

  getIdFromTarget = (target: string): number => {
    return parseInt(target.slice(-1))
  }

  handleFieldInput = (target: number, input) => {
    this['inputField' + target].current.value = input
    if (input !== '') {
      let focusTarget = target + 1
      if (focusTarget > 4) {
        focusTarget = 1
      }
      this['inputField' + focusTarget].current.focus()
    }
  }

  handleKeyDown = (event) => {
    event.preventDefault()
    if (event.key === 'Enter') {
      this.submit()
    } else if (event.key === 'Backspace' || event.key === ' ') {
      this.handleFieldInput(this.getIdFromTarget(event.target.id), '')
    } else if (event.key.length === 1) {
      this.handleFieldInput(this.getIdFromTarget(event.target.id), event.key)
    }
  }

  render() {
    const smsInputProps = {
      required: true,
      maxLength: 1
    }

    if (stringIsEmpty(this.props.user.email)) {
      return <Redirect to="/registration/error" />
    } else {
      return (
        <div className="paper inner-form">
          <Typography variant="h3">Verifizierung</Typography>
          <Typography variant="body2" className="no-line-height" display="inline">
            {getEmailFromUser(this.props.user)}
          </Typography>
          <div className="margin1" />
          <Form>
            <FormGroup>
              {!this.state.errorDescription && !this.props.errorMessage && (
                <Typography>
                  Bitte geben Sie die letzten vier Zahlen Ihrer Mobilnummer ein damit wir Ihnen einen Bestätigungscode
                  per SMS zusenden können.
                </Typography>
              )}
              {this.state.errorDescription && (
                <Typography className="validationFailed" id="pwValidationLabel" variant="body2">
                  {this.state.errorDescription}
                </Typography>
              )}
              {this.props.errorMessage && (
                <Typography className="validationFailed" id="pwValidationLabel" variant="body2">
                  {this.props.errorMessage}
                </Typography>
              )}
              <Row className="phoneDigitsFields">
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField1}
                    id="phoneNumberDigit1"
                    variant="outlined"
                    inputProps={{ ...smsInputProps }}
                    onKeyDown={this.handleKeyDown}
                    autoFocus
                  />
                </Col>
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField2}
                    id="phoneNumberDigit2"
                    variant="outlined"
                    inputProps={{ ...smsInputProps }}
                    onKeyDown={this.handleKeyDown}
                  />
                </Col>
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField3}
                    id="phoneNumberDigit3"
                    variant="outlined"
                    inputProps={{ ...smsInputProps }}
                    onKeyDown={this.handleKeyDown}
                  />
                </Col>
                <Col md="auto">
                  <TextField
                    className="noborderradius"
                    inputRef={this.inputField4}
                    id="phoneNumberDigit4"
                    variant="outlined"
                    inputProps={{ ...smsInputProps }}
                    onKeyDown={this.handleKeyDown}
                  />
                </Col>
              </Row>
            </FormGroup>
            <div className="margin2" />
            <FormGroup className="inline">
              <Button
                className="white-square-button bordered-button"
                color="primary"
                onClick={this.previousRegistrationStep}>
                Zurück
              </Button>
              <Button className="square-button red-border" onClick={this.submit} variant="contained" color="secondary">
                Weiter
              </Button>
            </FormGroup>
          </Form>
        </div>
      )
    }
  }
}

const mapStateToProps = ({ registration }: IRootState) => ({
  user: registration.user,
  phoneVerified: registration.phoneVerified,
  errorMessage: registration.errorMessage,
  registrationKey: registration.registrationKey
})

const mapDispatchToProps = {
  checkPhoneNumberForUser,
  clearErrorMessage,
  sendConfirmationCode
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(RegistrationVerifyPhone)
