import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { RequestOverview } from '../request/request.overview'
import { RequestDetails } from '../request/request.details'
import { RequestBenefits } from '../request/request.benefits'

export function ContractRequestOverview(props: any) {
  const showExpanded = props.anchor !== null && props.anchor === 'request'
  const [expanded, setExpanded] = React.useState(showExpanded)

  const handleChange = () => () => {
    setExpanded(!expanded)
  }

  const requestProps = {
    request: {
      id: props.contract.id,
      dateOfApplication: props.contract.dateOfApplication,
      installmentAmount: props.contract.installmentAmount,
      installmentLast: props.contract.installmentLast,
      totalLoan: props.contract.totalLoan,
      duration: props.contract.duration,
      totalAmount: props.contract.totalAmount,
      interestRateEffective: props.contract.interestRateEffective,
      interestRatePercent: props.contract.interestRatePercent,
      interestAmount: props.contract.interestAmount,
      installmentCount: props.contract.installmentCount,
      residualDebtInsurace: props.contract.residualDebtInsurace,
      mobilityPackage: props.contract.mobilityPackage,
      extendedWarranty: props.contract.extendedWarranty,
      gapWarranty: props.contract.gapWarranty,
      settlementDate: props.contract.settlementDate,
      firstInstallmentDate: props.contract.firstInstallmentDate
    },
    locale: props.locale
  }

  return (
    <Accordion
      id="request"
      className="container-with-border content-container"
      expanded={expanded}
      onChange={handleChange()}>
      <AccordionSummary expandIcon={<RedTriangle medium />}>
        <Typography className="bold">Antragsdaten</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RequestOverview {...requestProps} />
        <RequestDetails {...requestProps} />
        <RequestBenefits {...requestProps} />
      </AccordionDetails>
    </Accordion>
  )
}
