import React from 'react'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { stringIsEmpty, valueOrEmptyString } from 'app/shared/utils/common'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import PersonalDataImage from 'resources/images/profile_personal_data.png'
import { Row, Col } from 'reactstrap'

export default function PersonalDataDisplay(props: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDateChange = () => {
    // empty stub as the input fields are non functional for now but the datepicker needs this method
  }

  const preventDefault = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Row noGutters>
      <Col>
        <div>
          <TextField
            id="name"
            className="profileInputField"
            label={(!stringIsEmpty(props.profile.title) ? 'Titel, ' : '') + 'Vorname(n) und Nachname'}
            value={
              valueOrEmptyString(props.profile.title + ' ') + props.profile.firstName + ' ' + props.profile.lastName
            }
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="edit name" onClick={props.startChange} onMouseDown={preventDefault}>
                    <i className="fas fa-pen small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        {(props.profile.dateOfBirth && (
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                id="dateOfBirth"
                className="profileInputField"
                disableFuture
                format="dd.MM.yyyy"
                label="Geburtsdatum"
                views={['year', 'month', 'date']}
                value={valueOrEmptyString(props.profile.dateOfBirth)}
                onChange={handleDateChange}
                disabled
              />
            </MuiPickersUtilsProvider>
          </div>
        )) || (
          // the datepicker needs some vlaue to render. if there is no value show this "empty" inputfield instead
          <TextField id="dateOfBirth" className="profileInputField" label="Geburtsdatum" value=" " disabled />
        )}
      </Col>
      <Col className="text-center profile-images">
        <img src={PersonalDataImage} alt="Persönliche Daten"></img>
      </Col>
    </Row>
  )
}
