import React from 'react'

import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core'
import { Container, Row, Col } from 'reactstrap'

import RedTriangle from 'app/shared/components/red-triangle'

interface IFAQRow {
  title: string
  text: any
}

export default function FAQRow(props: IFAQRow) {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = () => () => {
    setExpanded(!expanded)
  }

  return (
    <Accordion expanded={expanded} onChange={handleChange()}>
      <AccordionSummary expandIcon={<RedTriangle medium />}>
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col>{props.text}</Col>
          </Row>
        </Container>
      </AccordionDetails>
    </Accordion>
  )
}
