import React from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

import { IRootState } from 'app/shared/reducers'
import { getSession } from 'app/shared/reducers/authentication.reducer'

import InboxInfoContainer from '../infobox/inbox.infocontainer'
import FrequentlyAskedQuestions from '../infobox/faq'
import Advertisement from '../infobox/advertisement'
import AccountInformationCarousel from '../infobox/accountinfo.carousel'
import { getBikeOrCarStartpageClassName } from 'app/shared/utils/common'

export interface IStartPage extends StateProps, DispatchProps {}

export class StartPage extends React.Component<IStartPage> {
  render() {
    return (
      <>
        <div {...getBikeOrCarStartpageClassName(this.props.user)}>
          <div className="full-width portal">
            <Row noGutters className="startpage-container-row">
              <Col md="6">
                <Link to="/portal/inbox" className="box-link">
                  <div className="container-with-border startpage-info-container">
                    <InboxInfoContainer user={this.props.user} messageCountUnread={this.props.messageCountUnread} />
                  </div>
                </Link>
              </Col>
              <Col md="6">
                <div className="container-with-border startpage-info-container carousel-container">
                  <AccountInformationCarousel />
                </div>
              </Col>
            </Row>
            <Row noGutters className="startpage-container-row">
              <Col md="6">
                <Link to="/portal/faq" className="box-link">
                  <div className="container-with-border startpage-info-container">
                    <FrequentlyAskedQuestions />
                  </div>
                </Link>
              </Col>
              <Col md="6">
                <a href="https://www.honda.de/cars/world-of-honda/news-events.html" className="box-link" rel="noopener noreferrer" target="_blank">
                  <div className="container-with-border startpage-info-container">
                    <Advertisement />
                  </div>
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ authentication, inbox }: IRootState) => ({
  user: authentication.user,
  messageCountUnread: inbox.messageCountUnread
})

const mapDispatchToProps = {
  getSession
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect<any>(mapStateToProps, mapDispatchToProps)(StartPage)
