import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps } from 'react-router'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'
import { getUserByKey } from 'app/shared/reducers/registration.reducer'
import { clearAuthentication } from 'app/shared/reducers/authentication.reducer'
import { withRouter, Redirect } from 'react-router-dom'
import { compose } from 'redux'
import { getEmailFromUser } from 'app/shared/utils/user.util'
import { stringIsEmpty } from 'app/shared/utils/common'

export interface IRegistrationKey extends StateProps, DispatchProps {}

type RegistrationKeyParams = {
  userKey: string
}

export class RegistrationKeyForm extends React.Component<
  IRegistrationKey & RouteComponentProps<RegistrationKeyParams>
> {
  userKey: string

  componentDidMount() {
    this.userKey = this.props.match.params.userKey
    this.props.clearAuthentication('new.registration')
    if (stringIsEmpty(this.props.user.email)) {
      this.getUser()
    }
  }

  getUser = () => {
    this.props.getUserByKey(this.userKey)
  }

  onNext = () => {
    this.props.history.push('/registration/password')
  }

  render() {
    if (this.props.isLoading) {
      return (
        <div className="paper inner-form">
          <Typography variant="h2" color="primary">
            Willkommen
          </Typography>
          <Typography variant="body1" id="no-line-height">
            im Honda Bank Kundenportal
          </Typography>
          <div className="margin2" />
          <CircularProgress />
        </div>
      )
    }
    if (stringIsEmpty(this.props.errorMessage)) {
      return (
        <div className="paper inner-form">
          <Typography variant="h2" color="primary">
            Willkommen
          </Typography>
          <Typography variant="body1" className="no-line-height">
            im Honda Bank Kundenportal
          </Typography>
          <div className="margin2" />
          <Typography variant="body2" className="align-left">
            E-Mail (Benutzername)
          </Typography>
          <Typography variant="body1" display="inline" className="align-left underline">
            {getEmailFromUser(this.props.user)}
          </Typography>
          <div className="margin1" />
          <Button onClick={this.onNext} className="square-button" fullWidth variant="contained" color="secondary">
            Weiter
          </Button>
        </div>
      )
    } else {
      return <Redirect to="/registration/error" />
    }
  }
}

const mapStateToProps = ({ registration }: IRootState) => ({
  isLoading: registration.loading,
  errorMessage: registration.errorMessage,
  user: registration.user
})

const mapDispatchToProps = {
  getUserByKey,
  clearAuthentication
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(RegistrationKeyForm)
