import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { Container, Row, Col } from 'reactstrap'
import { currencyFormat, percentFormat } from 'app/shared/utils/common'
import { DividerWithDiamond } from 'app/shared/components/divider-with-diamond'

export function RequestDetails(props: any) {
  const showExpanded = props.anchor !== null && props.anchor === 'details'
  const [expanded, setExpanded] = React.useState(showExpanded)

  const handleChange = () => () => {
    setExpanded(!expanded)
  }

  return (
    <Accordion
      id="details"
      className="container-with-border content-container"
      expanded={expanded}
      onChange={handleChange()}>
      <AccordionSummary expandIcon={<RedTriangle medium />}>
        <Typography className="bold">Antragsdetails</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col xs="7" md="9">
              <Typography>Gesamtkreditbetrag</Typography>
            </Col>
            <Col xs="5" md="3">
              <Typography className="bold text-right">
                {currencyFormat(props.request.totalLoan, props.locale)}
              </Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs="7" md="9">
              <Typography className="small">
                + Zinsen ({percentFormat(props.request.interestRatePercent, props.locale)} für die Vertragslaufzeit
                gebundener Sollzins p.a.)
              </Typography>
            </Col>
            <Col xs="5" md="3">
              <Typography className="bold text-right">
                {currencyFormat(props.request.interestAmount, props.locale)}
              </Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs="7" md="9">
              <Typography>= Gesamtbetrag</Typography>
            </Col>
            <Col xs="5" md="3">
              <Typography className="bold text-right">
                {currencyFormat(props.request.totalAmount, props.locale)}
              </Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs="7" md="9">
              <Typography>Effektiver Jahreszins</Typography>
            </Col>
            <Col xs="5" md="3">
              <Typography className="bold text-right">
                {percentFormat(props.request.interestRateEffective, props.locale)}
              </Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <DividerWithDiamond />
            </Col>
          </Row>
          <Row noGutters>
            <Col xs="7" md="9">
              <Typography>Anzahl der Raten</Typography>
            </Col>
            <Col xs="5" md="3">
              <Typography className="bold text-right">{props.request.installmentCount} á</Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs="7" md="9">
              <Typography>Ratenhöhe</Typography>
            </Col>
            <Col xs="5" md="3">
              <Typography className="bold text-right">
                {currencyFormat(props.request.installmentAmount, props.locale)}
              </Typography>
            </Col>
          </Row>
          {props.request.installmentLast > 0 && (
            <Row noGutters>
              <Col xs="7" md="9">
                <Typography>Schlussrate</Typography>
              </Col>
              <Col xs="5" md="3">
                <Typography className="bold text-right">
                  {currencyFormat(props.request.installmentLast, props.locale)}
                </Typography>
              </Col>
            </Row>
          )}
        </Container>
      </AccordionDetails>
    </Accordion>
  )
}
