import React from 'react'
import { InputAdornment, Button, CircularProgress, Typography } from '@material-ui/core'
import { Container, Row, Col } from 'reactstrap'
import CommunicationImage from 'resources/images/profile_communication.png'
import { valueOrEmptyString } from 'app/shared/utils/common'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

export interface ICommunicationDataInputProps {
  mobilePhone: string
  landLine: string
  loading: boolean
  handleValidSubmit: any
  errorMessage: string
  setErrorMessage: any
  resetErrorMessage: any
  previousFunc: any
}

export interface ICommunicationDataInputState {
  mobilePhone: string
  landLine: string
}

export class CommunicationDataInput extends React.Component<
  ICommunicationDataInputProps,
  ICommunicationDataInputState
> {
  phoneNumberRegExp = /^[0-9 -_\\/+\\(\\)]*$/g

  constructor(props) {
    super(props)
    this.state = {
      mobilePhone: valueOrEmptyString(props.mobilePhone),
      landLine: valueOrEmptyString(props.landLine)
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('checkPhoneNumber', (value) => {
      return this.checkPhoneNumber(value)
    })
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('checkPhoneNumber')
  }

  handleMobilePhoneChange = (event) => {
    this.setState({ mobilePhone: event.target.value })
    this.props.resetErrorMessage()
  }

  handleLandLineChange = (event) => {
    this.setState({ landLine: event.target.value })
    this.props.resetErrorMessage()
  }

  checkPhoneNumber = (number: string) => {
    if (number.match(this.phoneNumberRegExp)) {
      return true
    } else {
      return false
    }
  }

  handleValidSubmit = () => {
    if (
      valueOrEmptyString(this.props.mobilePhone) === this.state.mobilePhone &&
      valueOrEmptyString(this.props.landLine) === this.state.landLine
    ) {
      this.props.setErrorMessage('Um zum nächsten Schritt zu gelangen, müssen Sie eine Veränderung vornehmen.')
    } else {
      this.props.handleValidSubmit(this.state.mobilePhone, this.state.landLine)
      this.props.resetErrorMessage()
    }
  }

  render() {
    return (
      <Container fluid className="p-0">
        <ValidatorForm className="full-width" onSubmit={this.handleValidSubmit} instantValidate={false}>
          <Row noGutters>
            <Col>
              <div>
                <TextValidator
                  id="mobilephone"
                  name="mobilephone"
                  className="profileInputField"
                  label="Mobilnummer"
                  onChange={this.handleMobilePhoneChange}
                  value={this.state.mobilePhone}
                  validators={['required', 'checkPhoneNumber']}
                  errorMessages={[
                    'Die Mobilnummer darf nicht leer sein',
                    'Bitte geben Sie eine valide Mobilnummer ein'
                  ]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <i className="fas fa-pen edit-icon" />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div>
                <TextValidator
                  id="landline"
                  name="landline"
                  className="profileInputField"
                  label="Festnetznummer"
                  onChange={this.handleLandLineChange}
                  value={this.state.landLine}
                  validators={['required', 'checkPhoneNumber']}
                  errorMessages={[
                    'Die Festnetznummer darf nicht leer sein',
                    'Bitte geben Sie eine valide Festnetznummer ein'
                  ]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <i className="fas fa-pen edit-icon" />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </Col>
            <Col className="text-center profile-images">
              <img src={CommunicationImage} alt="Kommunikationsdaten"></img>
            </Col>
          </Row>
          {this.props.errorMessage && (
            <Typography
              className="validationFailed regular-line-height text-margin"
              id="pwValidationLabel"
              variant="body2">
              {this.props.errorMessage}
            </Typography>
          )}
          {(this.props.loading && (
            <div className="loading-center">
              <CircularProgress></CircularProgress>
            </div>
          )) || (
            <div>
              <Button
                className="square-button mr-2"
                onClick={this.props.previousFunc}
                variant="contained"
                color="primary">
                Abbrechen
              </Button>
              <Button className="square-button red-border" type="submit" variant="contained" color="secondary">
                Weiter
              </Button>
            </div>
          )}
        </ValidatorForm>
      </Container>
    )
  }
}
