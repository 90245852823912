import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { Typography } from '@material-ui/core'
import LicensePlate from 'app/shared/components/licenseplate'
import { DividerWithDiamond } from 'app/shared/components/divider-with-diamond'

import CarImage from 'resources/images/car_silhouette.png'
import BikeImage from 'resources/images/bike_silhouette.png'
import ResidualDebtInsuranceImage from 'resources/images/residual_debt_insurance.jpg'
import MobilityPackageImage from 'resources/images/mobility_package.jpg'
import ExtendedWarrantyImage from 'resources/images/extended_warranty.jpg'
import GapWarrantyImage from 'resources/images/gap_warranty.jpg'
import { isUndefined } from 'app/shared/utils/common'

export interface IAccountInformationAdditions {
  licenseNumber: string
  objectType: string
  objectManufacturer: string
  objectModellName: string
  residualDebtInsurace: boolean
  mobilityPackage: boolean
  extendedWarranty: boolean
  gapWarranty: boolean
}

export class AccountInformationAdditions extends React.Component<IAccountInformationAdditions> {
  render() {
    return (
      <Container fluid className="p-0 full-height">
        <Row noGutters>
          <Col sm="12" className="infobox-icon-container">
            {(!isUndefined(this.props.licenseNumber) && <LicensePlate licenseNumber={this.props.licenseNumber} centered={true}/>) || (
              <img
                id="request-item-image"
                className="picture-margin"
                src={this.props.objectType === 'BIKE' ? BikeImage : CarImage}
                alt={this.props.objectManufacturer + ' ' + this.props.objectModellName}
              />
            )}
          </Col>
        </Row>
        <DividerWithDiamond />
        <Row noGutters>
          {this.props.residualDebtInsurace && (
            <Col xs="6" className="horizontal-center vertical-center d-block">
              <div>
                <img className="text-margin" src={ResidualDebtInsuranceImage} alt="Restschuldversicherung" />
              </div>
              <div>
                <Typography variant="body2" className="mobile-smaller">
                  Restschuldversicherung
                </Typography>
              </div>
            </Col>
          )}
          {this.props.mobilityPackage && (
            <Col xs="6" className="horizontal-center vertical-center d-block">
              <img className="text-margin" src={MobilityPackageImage} alt="4 Mobilitätspakete" />
              <Typography variant="body2" className="mobile-smaller">
                4 Mobilitätspakete
              </Typography>
            </Col>
          )}
          {this.props.extendedWarranty && (
            <Col xs="6" className="horizontal-center vertical-center d-block">
              <img className="text-margin" src={ExtendedWarrantyImage} alt="24 Monate Anschlussgarantie" />
              <Typography variant="body2" className="mobile-smaller">
                24 Monate Anschlussgarantie
              </Typography>
            </Col>
          )}
          {this.props.gapWarranty && (
            <Col xs="6" className="horizontal-center vertical-center d-block">
              <img className="text-margin" src={GapWarrantyImage} alt="GAP Versicherung" />
              <Typography variant="body2" className="mobile-smaller">
                GAP Versicherung
              </Typography>
            </Col>
          )}
        </Row>
      </Container>
    )
  }
}

export default AccountInformationAdditions
