import './request.css'

import React from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from 'react-router-dom'

import { isUndefined } from 'app/shared/utils/common'
import { fetchRequests } from 'app/shared/reducers/data.reducer'
import { IRootState } from 'app/shared/reducers'

import { RequestEntity } from './request.entity'

export interface IRequestProps extends StateProps, DispatchProps {}

export class RequestSelect extends React.Component<IRequestProps & RouteComponentProps> {
  componentDidMount() {}

  render() {
    const requests = this.props.requests
    const showLoading = this.props.loading || isUndefined(requests)

    const requestDisplayData = []

    if (showLoading) {
      requestDisplayData.push(
        <div className="text-center" key="1">
          <CircularProgress></CircularProgress>
        </div>
      )
    } else {
      requests.forEach((request, key) => {
        const props = {
          request: { ...request }
        }
        requestDisplayData.push(
          <div key={key}>
            <Link to={`/portal/request/${request.id}`}>
              <RequestEntity {...props} />
            </Link>
          </div>
        )
      })
    }

    return (
      <div className="contentContainer">
        <Typography variant="h3" className="section-heading" id="select">
          Antrag auswählen
        </Typography>
        {requestDisplayData}
      </div>
    )
  }
}

const mapStateToProps = ({ data }: IRootState) => ({
  requests: data.requests,
  loading: data.loading
})

const mapDispatchToProps = {
  fetchRequests
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(RequestSelect)
