import './app.css'
import { hot } from 'react-hot-loader'

import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Routing from './routing'
import { IRootState } from './shared/reducers'
import { getSession } from './shared/reducers/authentication.reducer'

import { ThemeProvider, StylesProvider, Grid } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiCircularProgress: {
      color: 'secondary'
    }
  },
  palette: {
    primary: {
      main: '#898d8d'
    },
    secondary: {
      main: '#ff3333'
    }
  },
  typography: {
    h1: {
      fontFamily: ['ProximaNovaExCnLight', 'Tahoma', 'Arial', 'Helvetica', 'sans-serif'].join(','),
      color: '#ff3333'
    },
    h2: {
      fontFamily: ['ProximaNovaExCnLight', 'Tahoma', 'Arial', 'Helvetica', 'sans-serif'].join(','),
      color: '#898d8d'
    },
    h3: {
      fontFamily: ['ProximaNovaExCnLight', 'Tahoma', 'Arial', 'Helvetica', 'sans-serif'].join(','),
      color: '#898d8d'
    },
    h4: {
      fontFamily: ['ProximaNovaExCnLight', 'Tahoma', 'Arial', 'Helvetica', 'sans-serif'].join(','),
      color: '#898d8d'
    },
    h5: {
      fontFamily: ['ProximaNovaExCnLight', 'Tahoma', 'Arial', 'Helvetica', 'sans-serif'].join(','),
      color: '#898d8d'
    },
    h6: {
      fontFamily: ['ProximaNovaExCnLight', 'Tahoma', 'Arial', 'Helvetica', 'sans-serif'].join(','),
      color: '#898d8d'
    },
    subtitle1: {
      color: '#898d8d'
    },
    subtitle2: {
      color: '#898d8d'
    },
    body1: {
      color: '#898d8d'
    },
    body2: {
      color: '#898d8d'
    },
    caption: {
      color: '#898d8d'
    },
    overline: {
      color: '#898d8d'
    },
    fontFamily: ['Tahoma', 'Arial', 'Helvetica', 'sans-serif'].join(',')
  }
})

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
  useEffect(() => {
    if (!props.sessionHasBeenFetched) {
      props.getSession()
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Grid container className="app">
          <Routing />
        </Grid>
      </StylesProvider>
    </ThemeProvider>
  )
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  sessionHasBeenFetched: authentication.sessionHasBeenFetched
})

const mapDispatchToProps = {
  getSession
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect<any>(mapStateToProps, mapDispatchToProps)(hot(module)(App))
