import React from 'react'
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap'
import { Button, CircularProgress, Divider, Typography } from '@material-ui/core'
import { Link, NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createExternalTechSupportRequestMessage } from 'app/components/portal/contact/contact.reducer'
import { IRootState } from 'app/shared/reducers'
import { EXTERNAL_SUPPORT_REASONS } from 'app/config/constants'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'

import logo from 'resources/images/honda_financial_services_logo.png'
import Footer from 'app/components/navigation/footer'

export interface ITechsupportPublicFormProps extends StateProps, DispatchProps {}

export interface ITechsupportPublicFormState {
  messageText: string
  contactReason: string
  contactReasons: string[]
  errorText: string
  success: boolean
  lastname: string
  firstname: string
  transactionKey: string
  phone: string
  email: string
  zipCodeValidationError: string
  contactReasonError: string
}

export class TechsupportPublicForm extends React.Component<ITechsupportPublicFormProps & RouteComponentProps, ITechsupportPublicFormState> {
  constructor(props) {
    super(props)
    this.state = {
      messageText: '',
      contactReason: 'none',
      contactReasons: EXTERNAL_SUPPORT_REASONS,
      errorText: '',
      success: false,
      lastname: '',
      firstname: '',
      transactionKey: '',
      phone: '',
      email: '',
      zipCodeValidationError: '',
      contactReasonError: ''
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('emailCheck', (value) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(value).toLowerCase())
    })
    ValidatorForm.addValidationRule('checkPhoneNumber', (value) => {
      const re = /^[0-9 -_\\/+\\(\\)]*$/g
      return re.test(String(value).toLowerCase())
    })
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('emailCheck')
    ValidatorForm.removeValidationRule('checkPhoneNumber')
  }

  sendSupportRequest = () => {
    if (this.state.contactReason === 'none') {
      this.setState({ ...this.state, contactReasonError: 'Bitte wählen Sie einen Kontaktgrund aus!' })
    } else if (this.state.messageText === '') {
      this.setState({ ...this.state, contactReasonError: 'Bitte beschreiben Sie Ihr Problem im vorgesehenen Textfeld!' })
    } else {
      this.props.createExternalTechSupportRequestMessage(
        this.state.contactReason,
        this.state.messageText,
        this.state.lastname,
        this.state.firstname,
        this.state.transactionKey,
        this.state.phone,
        this.state.email,
        this.setFeedbackAfterSending,
        this.setErrorAfterSending)
    }
  }

  setFeedbackAfterSending = () => {
    this.setState({ ...this.state, success: true })
  }

  setErrorAfterSending = () => {
    this.setState({ ...this.state, errorText: this.props.error })
  }

  selectContactReason = (event) => {
    this.setState({ ...this.state, contactReason: event.target.value, errorText: '', contactReasonError: '' })
  }

  updateMessageText = (event) => {
    this.setState({ ...this.state, messageText: event.target.value, errorText: '', contactReasonError: '' })
  }

  handleLastNameChange = (event) => {
    this.setState({ ...this.state, lastname: event.target.value, errorText: '' })
  }

  handleFirstNameChange = (event) => {
    this.setState({ ...this.state, firstname: event.target.value, errorText: '' })
  }

  handleTransactionKeyChange = (event) => {
    this.setState({ ...this.state, transactionKey: event.target.value, errorText: '' })
  }

  handlePhoneChange = (event) => {
    this.setState({ ...this.state, phone: event.target.value, errorText: '' })
  }

  handleEmailChange = (event) => {
    this.setState({ ...this.state, email: event.target.value, errorText: '' })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const footerProps = {
      isPublic: true
    }

    if (this.props.isLoading) {
      return (
        <>
          <Container fluid>
            <Row className="portal-header-bg">
              <Col className="portal-header">
                <NavLink to="/login">
                  <div>
                    <img className="logo portal" src={logo} alt="Honda Logo" />
                  </div>
                </NavLink>
              </Col>
            </Row>
          </Container>
          <Container fluid className="p-0">
            <Row noGutters>
              <Col>
                <Divider className="red-divider" />
              </Col>
            </Row>
          </Container>
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <div className="contentContainer mt-4 mb-5">
                  <Typography variant="h3" className="section-heading">
                    Technischer Support
                  </Typography>
                  <div className="content-container faq-container container-with-border">
                    <Container fluid>
                      <Row noGutters>
                        <Col xs="12">
                          <div className="text-center">
                            <CircularProgress></CircularProgress>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Footer {...footerProps} />
        </>
      )
    } else {
      return (
        <>
          <Container fluid>
            <Row className="portal-header-bg">
              <Col className="portal-header">
                <NavLink to="/login">
                  <div>
                    <img className="logo portal" src={logo} alt="Honda Logo" />
                  </div>
                </NavLink>
              </Col>
            </Row>
          </Container>
          <Container fluid className="p-0">
            <Row noGutters>
              <Col>
                <Divider className="red-divider" />
              </Col>
            </Row>
          </Container>
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <div className="contentContainer mt-4 mb-5">
                  <Typography variant="h3" className="section-heading">
                    Technischer Support
                  </Typography>
                  <div className="content-container faq-container container-with-border">
                    <Container fluid>
                      <Form>
                        {this.state.success &&
                        <Row noGutters>
                          <Col xs="12">
                            <Typography>Ihre Supportanfrage wurde erfolgreich versendet. Sie erhalten in Kürze eine E-Mail zur Bestätigung.</Typography>
                          </Col>
                          <Col xs="12">
                            <div className="align-center d-block">
                              <Button
                                className="square-button no-uppercase mr-2"
                                variant="contained"
                                color="primary"
                                onClick={this.goBack}>
                                Zurück
                              </Button>
                            </div>
                          </Col>
                        </Row>}
                      </Form>
                      {!this.state.success &&
                        <ValidatorForm className="full-width" onSubmit={this.sendSupportRequest}>
                          <Row noGutters>
                            <Col xs="12">
                              <Typography><i className="fas fa-info-circle"></i> Häufig gestellte Fragen finden Sie <Link className="underline" to={this.props.isAuthenticated ? '/portal/faq' : '/faq'}>hier</Link></Typography>
                            </Col>
                            {this.state.errorText && <Col xs="12"><Typography className="red" variant="body2">{this.state.errorText}</Typography></Col>}
                            <Col xs="12" md="3">
                              <Typography>Kontaktgrund</Typography>
                            </Col>
                            <Col xs="12" md="9">
                              <FormGroup>
                                <Input defaultValue='none' type="select" name="select" onChange={this.selectContactReason}>
                                  <option value='none' disabled={true}>Auswahl: Kontaktgrund</option>
                                  {this.state.contactReasons.map((option) =>
                                    <option key={option} value={option}>{option}</option>
                                  )}
                                </Input>
                              </FormGroup>
                              {this.state.contactReasonError && <Typography className="red" variant="body2">{this.state.contactReasonError}</Typography>}
                            </Col>
                            <Col xs="12" md="3">
                              <Typography>Fehlerbeschreibung</Typography>
                            </Col>
                            <Col xs="12" md="9" className="text-margin">
                              <FormGroup>
                                <Input maxLength={5000} type="textarea" name="text" onChange={this.updateMessageText} style={{ height: 200 }}/>
                              </FormGroup>
                            </Col>
                            <Col xs="12">
                              <Typography>Mit ein paar Angaben können wir Ihre Nachricht schneller bearbeiten:</Typography>
                            </Col>
                            <Col xs="12" md="3" className="padding-1">
                              <Typography>Persönliche Angaben</Typography>
                            </Col>
                            <Col xs="12" md="9" className="text-margin">
                              <Row>
                                <Col xs="12"><TextValidator
                                  className="full-width"
                                  label="Name"
                                  name="lastname"
                                  onChange={this.handleLastNameChange}
                                  type="text"
                                  validators={['required']}
                                  errorMessages={['']}
                                  value={this.state.lastname}
                                /></Col>
                                <Col xs="12"><TextValidator
                                  className="full-width"
                                  label="Vorname"
                                  name="firstname"
                                  onChange={this.handleFirstNameChange}
                                  type="text"
                                  validators={['required']}
                                  errorMessages={['']}
                                  value={this.state.firstname}
                                /></Col>
                                <Col xs="12"><TextValidator
                                  className="full-width"
                                  label="Vorgangsnummer (diese finden Sie auf Ihrem Antrag)"
                                  name="transactionKey"
                                  onChange={this.handleTransactionKeyChange}
                                  type="text"
                                  validators={['required']}
                                  errorMessages={['']}
                                  value={this.state.transactionKey}
                                /></Col>
                                <Col xs="12"><TextValidator
                                  className="full-width"
                                  label="Bevorzugte Telefonnummer für Kontakt"
                                  name="phone"
                                  onChange={this.handlePhoneChange}
                                  type="text"
                                  validators={['required', 'checkPhoneNumber']}
                                  errorMessages={['', 'Bitte geben Sie eine gültige Telefonnummer ein']}
                                  value={this.state.phone}
                                /></Col>
                                <Col xs="12"><TextValidator
                                  className="full-width"
                                  label="E-Mail"
                                  name="email"
                                  onChange={this.handleEmailChange}
                                  type="text"
                                  validators={['required', 'isEmail', 'emailCheck']}
                                  errorMessages={['', 'Bitte geben Sie eine gültige E-Mail-Adresse ein', 'Bitte geben Sie eine gültige E-Mail-Adresse ein']}
                                  value={this.state.email}
                                /></Col>
                              </Row>
                            </Col>
                            <Col xs="12">
                              <Typography className="bold smaller-fontsize regular-line-height">Datenschutz</Typography>
                            </Col>
                            <Col xs="12">
                              <Typography className="smaller-fontsize regular-line-height text-margin">Verantwortlich für die Verarbeitung Ihrer
                                angegebenen personenbezogenen Daten ist die Honda Bank GmbH, Hanauer Landstraße 222-226, 60314 Frankfurt am Main.
                                Wir verarbeiten Ihre angegebenen Daten zum Zweck der Vertragserfüllung und schnellstmöglichen Bearbeitung Ihrer
                                Anfrage. Alle weiteren Informationen zur Verarbeitung und zum Schutz Ihrer Daten sowie Ihren Rechten erhalten Sie
                                im {' '} <Link className="underline" to={'dataSecurity'}>Honda Bank Datenschutzhinweis</Link>.</Typography>
                            </Col>
                            <Col xs="12" className="text-margin">
                              <div className="align-center d-block">
                                <Button
                                  className="square-button no-uppercase mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={this.goBack}>
                                  Abbrechen
                                </Button>
                                <Button
                                  type="submit"
                                  className="square-button no-uppercase mr-2"
                                  variant="contained"
                                  color="secondary">
                                  Absenden
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </ValidatorForm>}

                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Footer {...footerProps} />
        </>
      )
    }
  }
}

const mapStateToProps = ({ contact, authentication }: IRootState) => ({
  error: contact.errorMessage,
  isLoading: contact.loading,
  isAuthenticated: authentication.isAuthenticated
})

const mapDispatchToProps = {
  createExternalTechSupportRequestMessage
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(TechsupportPublicForm)
