import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { Typography } from '@material-ui/core'
import { DividerWithDiamond } from 'app/shared/components/divider-with-diamond'
import { dateFormat, currencyFormat } from 'app/shared/utils/common'

import CarImage from 'resources/images/car_silhouette.png'
import BikeImage from 'resources/images/bike_silhouette.png'

export interface IRequestInformationProps {
  locale: any
  objectType: string
  objectManufacturer: string
  objectModellName: string
  id: number
  dateOfApplication: any
  totalAmount: any
  stillActive: boolean
}
export class RequestInformation extends React.Component<IRequestInformationProps> {
  render() {
    return (
      <Container>
        <Row>
          <Col xs="12" className="vertical-center horizontal-center">
            <img
              id="request-item-image"
              className="picture-margin"
              src={this.props.objectType === 'BIKE' ? BikeImage : CarImage}
              alt={this.props.objectManufacturer + ' ' + this.props.objectModellName}
            />
          </Col>
        </Row>
        <DividerWithDiamond />
        <Row className="infobox-content">
          <Col xs="6">
            <div>
              <Typography variant="h6" className="bold mobile-smaller">
                Vorgangsnummer
              </Typography>
              <Typography variant="body1" className="mobile-smaller">
                {this.props.id}
              </Typography>
            </div>
          </Col>
          <Col xs="6">
            <div>
              <Typography variant="h6" className="bold mobile-smaller">
                Antragsdatum
              </Typography>
              <Typography variant="body1" className="mobile-smaller">
                {dateFormat(this.props.dateOfApplication, this.props.locale)}
              </Typography>
            </div>
          </Col>
        </Row>
        <div className="margin" />
        <Row className="infobox-content">
          <Col xs="6">
            <div>
              <Typography variant="h6" className="bold mobile-smaller">
                Gesamtbetrag
              </Typography>
              <Typography variant="body1" className="mobile-smaller">
                {currencyFormat(this.props.totalAmount, this.props.locale)}
              </Typography>
            </div>
          </Col>
          <Col xs="6">
            <div>
              <Typography variant="h6" className="bold mobile-smaller">
                Genehmigung
              </Typography>
            </div>
            <div
              className={
                'carousel-request-status request-status-' + (this.props.stillActive ? 'active' : 'not-active')
              }></div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default RequestInformation
