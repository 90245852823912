import React from 'react'
import { InputAdornment, Button, CircularProgress, Typography, TextField } from '@material-ui/core'
import { Container, Row, Col } from 'reactstrap'
import { valueOrEmptyString, stringIsEmpty, isUndefined } from 'app/shared/utils/common'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import AddressImage from 'resources/images/profile_address.png'
import FlagGermany from 'resources/images/flag_germany.png'

export interface IAddressDataInputProps {
  street: string
  streetNumber: string
  zipCode: string
  city: string
  loading: boolean
  handleValidSubmit: any
  errorMessage: string
  previousFunc: any
}

export interface IAddressDataInputState {
  street: string
  streetNumber: string
  zipCode: string
  city: string
  cityValidationError: string
  streetValidationError: string
  errorMessage: string
}

export class AddressDataInput extends React.Component<IAddressDataInputProps, IAddressDataInputState> {
  zipCodeRegExp = /^[0-9]{5}$/g
  cityNegativeRegExp = /^[0-9 ]*$/g
  streetNegativeRegExp = /^[0-9 ]*$/g

  constructor(props) {
    super(props)
    this.state = {
      street: valueOrEmptyString(this.props.street),
      streetNumber: valueOrEmptyString(this.props.streetNumber),
      zipCode: valueOrEmptyString(this.props.zipCode),
      city: valueOrEmptyString(this.props.city),
      cityValidationError: '',
      streetValidationError: '',
      errorMessage: null
    }
    this.checkZipcode = this.checkZipcode.bind(this)
    this.checkCity = this.checkCity.bind(this)
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('checkCity', () => {
      return this.checkCity()
    })
    ValidatorForm.addValidationRule('checkZipcode', () => {
      return this.checkZipcode()
    })
    ValidatorForm.addValidationRule('setErrorMessageForCityAndZipCode', () => {
      return this.setErrorMessageForCityAndZipCode()
    })
    ValidatorForm.addValidationRule('checkStreet', () => {
      return this.checkStreet()
    })
    ValidatorForm.addValidationRule('checkStreetNumber', () => {
      return this.checkStreetNumber()
    })
    ValidatorForm.addValidationRule('setErrorMessageForStreetAndNumber', () => {
      return this.setErrorMessageForStreetAndNumber()
    })
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('checkCity')
    ValidatorForm.removeValidationRule('checkZipcode')
    ValidatorForm.removeValidationRule('setErrorMessageForCityAndZipCode')
    ValidatorForm.removeValidationRule('checkStreet')
    ValidatorForm.removeValidationRule('checkStreetNumber')
    ValidatorForm.removeValidationRule('setErrorMessageForStreetAndNumber')
  }

  setErrorMessageForCityAndZipCode = () => {
    if (!this.checkZipcode() && !this.checkCity()) {
      this.setState({ cityValidationError: 'Bitte geben Sie eine valide Postleitzahl und einen Ort ein' })
    } else if (!this.checkZipcode()) {
      this.setState({ cityValidationError: 'Bitte geben Sie eine valide Postleitzahl ein' })
    } else if (!this.checkCity()) {
      this.setState({ cityValidationError: 'Bitte geben Sie einen Ort ein' })
    } else {
      this.setState({ cityValidationError: '' })
    }
    return true
  }

  checkCity = () => {
    return !stringIsEmpty(this.state.city) && !this.state.city.match(this.cityNegativeRegExp)
  }

  checkZipcode = () => {
    if (this.state.zipCode.match(this.zipCodeRegExp)) {
      return true
    } else {
      return false
    }
  }

  setErrorMessageForStreetAndNumber = () => {
    if (!this.checkStreet() && !this.checkStreetNumber()) {
      this.setState({
        streetValidationError: 'Bitte geben Sie eine Straße und Hausnummer ein'
      })
    } else if (!this.checkStreet()) {
      this.setState({
        streetValidationError: 'Bitte geben Sie eine valide Straße ein'
      })
    } else if (!this.checkStreetNumber()) {
      this.setState({ streetValidationError: 'Bitte geben Sie eine Hausnummer ein' })
    } else {
      this.setState({ streetValidationError: '' })
    }
    return true
  }

  checkStreet = () => {
    return !stringIsEmpty(this.state.street) && !this.state.street.match(this.streetNegativeRegExp)
  }

  checkStreetNumber = () => {
    return !stringIsEmpty(this.state.streetNumber)
  }

  handleStreetChange = (event) => {
    this.setState({ street: event.target.value, errorMessage: null })
  }

  handleStreetNumberChange = (event) => {
    this.setState({ streetNumber: event.target.value, errorMessage: null })
  }

  handleZipCodeChange = (event) => {
    this.setState({ zipCode: event.target.value, errorMessage: null })
  }

  handleCityChange = (event) => {
    this.setState({ city: event.target.value, errorMessage: null })
  }

  handleValidSubmit = () => {
    if (
      valueOrEmptyString(this.props.street) === this.state.street &&
      valueOrEmptyString(this.props.streetNumber) === this.state.streetNumber &&
      valueOrEmptyString(this.props.zipCode) === this.state.zipCode &&
      valueOrEmptyString(this.props.city) === this.state.city
    ) {
      this.setState({
        errorMessage: 'Um zum nächsten Schritt zu gelangen, müssen Sie eine Veränderung vornehmen.'
      })
    } else {
      this.props.handleValidSubmit(this.state.street, this.state.streetNumber, this.state.zipCode, this.state.city)
    }
  }

  render() {
    return (
      <Container fluid className="p-0">
        <ValidatorForm className="full-width" onSubmit={this.handleValidSubmit} instantValidate={false}>
          <Row noGutters>
            <Col>
              <Row noGutters>
                <Col xs="8" className="pr-1">
                  <TextValidator
                    id="street"
                    name="street"
                    className="full-width"
                    label="Straße"
                    onChange={this.handleStreetChange}
                    value={isUndefined(this.state.street) ? '' : this.state.street}
                    validators={['checkStreet', 'setErrorMessageForStreetAndNumber']}
                    errorMessages={['', '']}
                  />
                </Col>
                <Col xs="4">
                  <TextValidator
                    id="streetNumber"
                    name="streetNumber"
                    className="full-width"
                    label="Hausnummer"
                    onChange={this.handleStreetNumberChange}
                    value={isUndefined(this.state.streetNumber) ? '' : this.state.streetNumber}
                    validators={['checkStreetNumber', 'setErrorMessageForStreetAndNumber']}
                    errorMessages={['', '']}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fas fa-pen edit-icon" />
                        </InputAdornment>
                      )
                    }}
                  />
                </Col>
                <p className="cityValidationError" id="cityValidationLabel">
                  {this.state.streetValidationError}
                </p>
              </Row>
              <Row noGutters className="mt-3">
                <Col xs="4" className="pr-1">
                  <TextValidator
                    id="zipCode"
                    name="zipCode"
                    className="full-width"
                    label="Postleitzahl"
                    onChange={this.handleZipCodeChange}
                    value={isUndefined(this.state.zipCode) ? '' : this.state.zipCode}
                    validators={['checkZipcode', 'setErrorMessageForCityAndZipCode']}
                    errorMessages={['', '']}
                  />
                </Col>
                <Col xs="8">
                  <TextValidator
                    id="city"
                    name="city"
                    className="full-width"
                    label="Ort"
                    onChange={this.handleCityChange}
                    value={isUndefined(this.state.city) ? '' : this.state.city}
                    validators={['checkCity', 'setErrorMessageForCityAndZipCode']}
                    errorMessages={['', '']}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fas fa-pen edit-icon" />
                        </InputAdornment>
                      )
                    }}
                  />
                </Col>
                <p className="cityValidationError" id="cityValidationLabel">
                  {this.state.cityValidationError}
                </p>
              </Row>
              <Row noGutters>
                <Col>
                  <TextField
                    id="countryCode"
                    className="profileInputField mt-3"
                    label="Land"
                    value="Deutschland"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={FlagGermany} alt="Flagge Deutschland"></img>
                        </InputAdornment>
                      )
                    }}
                    disabled
                  />
                </Col>
              </Row>
            </Col>
            <Col className="text-center profile-images">
              <img src={AddressImage} alt="Adresse"></img>
            </Col>
          </Row>
          {this.props.errorMessage && (
            <Typography
              className="validationFailed regular-line-height text-margin"
              id="pwValidationLabel"
              variant="body2">
              {this.props.errorMessage}
            </Typography>
          )}
          {this.state.errorMessage && (
            <Typography
              className="validationFailed regular-line-height text-margin"
              id="pwValidationLabel"
              variant="body2">
              {this.state.errorMessage}
            </Typography>
          )}
          {(this.props.loading && (
            <div className="loading-center">
              <CircularProgress></CircularProgress>
            </div>
          )) || (
            <div>
              <Button
                className="square-button mr-2"
                onClick={this.props.previousFunc}
                variant="contained"
                color="primary">
                Zurück
              </Button>
              <Button className="square-button red-border ml-2" type="submit" variant="contained" color="secondary">
                Weiter
              </Button>
            </div>
          )}
        </ValidatorForm>
      </Container>
    )
  }
}
