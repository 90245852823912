import '../registration/registration.css'

import React from 'react'
import { connect } from 'react-redux'
import { Paper, Grid, Typography } from '@material-ui/core'
import logo from 'resources/images/honda_financial_services_logo.png'
import RegistrationFooter from 'app/components/legal/registrationFooter'
import { clearAuthentication } from 'app/shared/reducers/authentication.reducer'
import { Link } from 'react-router-dom'

export interface UserLockedProps extends StateProps, DispatchProps {}

export class UserLocked extends React.Component<UserLockedProps> {
  componentDidMount() {
    this.props.clearAuthentication('error.user.locked')
  }

  render() {
    return (
      <>
        <Grid container className="root">
          <div className="background-container-car">
            <Grid className="registrationBox" component={Paper} square>
              <div className="paper inner-form">
                <img className="form-logo" src={logo} alt="Honda Bank Logo" />
                <div className="paper inner-form full-width">
                  <Typography variant="h2">Fehler</Typography>
                  <Typography variant="body2" className="no-line-height" display="inline">
                    Benutzer ist gesperrt
                  </Typography>
                  <div className="margin1" />
                  <Typography variant="body2" className="red">
                    Ihr Benutzer wurde wegen zu vielen fehlgeschlagenen Authentifizierungsversuchen gesperrt. Bitte
                    wenden Sie sich an den Support.
                  </Typography>
                  <div className="margin" />
                  <div className="inline full-width icon-and-text">
                    <Link className="underline" to='/support'>Zum technischen Support</Link>
                  </div>
                </div>
                <RegistrationFooter />
              </div>
            </Grid>
          </div>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  clearAuthentication
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(UserLocked)
