import React from 'react'
import { InputAdornment, Button, CircularProgress, Typography, TextField } from '@material-ui/core'
import { Container, Row, Col } from 'reactstrap'
import BankAccountImage from 'resources/images/bankaccount.png'
import { valueOrEmptyString, stringIsEmpty } from 'app/shared/utils/common'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import IContract from 'app/model/contract.model'
import IBAN from 'iban'

export interface IContractBankAccountInputProps {
  contract: IContract
  handleValidSubmit: any
  errorMessage: string
  setErrorMessage: any
  resetErrorMessage: any
  loading: boolean
  previousFunc: any
}

export interface IContractBankAccountInputState {
  iban: string
}

export class ContractBankAccountInput extends React.Component<
  IContractBankAccountInputProps,
  IContractBankAccountInputState
> {
  constructor(props) {
    super(props)
    this.state = {
      iban: valueOrEmptyString(props.contract.iban)
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('checkIban', (value) => {
      return this.checkIban(value)
    })
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('checkIban')
  }

  handleIbanChange = (event) => {
    this.setState({ iban: event.target.value })
    this.props.resetErrorMessage()
  }

  checkIban = (iban: string) => {
    return IBAN.isValid(iban)
  }

  handleValidSubmit = () => {
    if (valueOrEmptyString(this.props.contract.bankAccount.iban) === this.state.iban) {
      this.props.setErrorMessage('Um zum nächsten Schritt zu gelangen, müssen Sie eine Veränderung vornehmen.')
    } else {
      this.props.handleValidSubmit(this.state.iban)
      this.props.resetErrorMessage()
    }
  }

  render() {
    return (
      <Container fluid className="p-0">
        <ValidatorForm className="full-width" onSubmit={this.handleValidSubmit} instantValidate={false}>
          <Row noGutters>
            <Col>
              <div>
                <TextField
                  id="bankAccountOwner"
                  className="profileInputField"
                  label="Kontoinhaber"
                  value={
                    stringIsEmpty(this.props.contract.bankAccount.accountHolder)
                      ? ' '
                      : this.props.contract.bankAccount.accountHolder
                  }
                  disabled
                />
              </div>
              <div>
                <TextValidator
                  id="iban"
                  name="iban"
                  className="profileInputField"
                  label="IBAN"
                  onChange={this.handleIbanChange}
                  value={this.state.iban}
                  validators={['required', 'checkIban']}
                  errorMessages={['Die IBAN darf nicht leer sein', 'Bitte geben Sie eine valide IBAN ein']}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <i className="fas fa-pen edit-icon" />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </Col>
            <Col className="text-center profile-images">
              <img src={BankAccountImage} alt="Bankverbindung"></img>
            </Col>
          </Row>
          {this.props.errorMessage && (
            <Typography
              className="validationFailed regular-line-height text-margin"
              id="pwValidationLabel"
              variant="body2">
              {this.props.errorMessage}
            </Typography>
          )}
          {(this.props.loading && (
            <div className="loading-center">
              <CircularProgress></CircularProgress>
            </div>
          )) || (
            <div>
              <Button
                className="square-button fullWidth mr-2"
                onClick={this.props.previousFunc}
                variant="contained"
                color="primary">
                Abbrechen
              </Button>
              <Button className="square-button red-border" type="submit" variant="contained" color="secondary">
                Weiter
              </Button>
            </div>
          )}
        </ValidatorForm>
      </Container>
    )
  }
}
