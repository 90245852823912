import React from 'react'
import { Typography } from '@material-ui/core'

export class News extends React.Component {
  render() {
    return (
      <>
        <Typography variant="h2">News</Typography>
      </>
    )
  }
}

export default News
