import { applyMiddleware, compose, createStore } from 'redux'
import promiseMiddleware from 'redux-promise-middleware'
import thunkMiddleware from 'redux-thunk'
import rootReducer, { IRootState } from 'app/shared/reducers'
import DevTools from 'app/config/devtools'
import { loadingBarMiddleware } from 'react-redux-loading-bar'
import loggerMiddleware from 'app/config/logger-middleware'

const defaultMiddlewares = [thunkMiddleware, promiseMiddleware, loadingBarMiddleware(), loggerMiddleware]

const composedMiddlewares = (middlewares) =>
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...defaultMiddlewares, ...middlewares), DevTools.instrument())
    : compose(applyMiddleware(...defaultMiddlewares, ...middlewares))

const initialize = (initialState?: IRootState, middlewares = []) =>
  createStore(rootReducer, initialState, composedMiddlewares(middlewares))

export default initialize
