import React from 'react'
import { Row, Col } from 'reactstrap'
import { Typography } from '@material-ui/core'
import icon from 'resources/images/faq_icon.png'
import InfoboxHeader from './infobox.header'

export class FrequentlyAskedQuestions extends React.Component {
  render() {
    return (
      <>
        <InfoboxHeader imgSource={icon} />
        <Row>
          <Col xs="12" className="vertical-center horizontal-center">
            <Typography variant="h6" className="text-margin">
              Häufige Fragen
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="vertical-center horizontal-center">
            <Typography variant="body1">
              Wir haben die häufigsten Fragen zum Kundenportal für Sie hier zusammengestellt
            </Typography>
          </Col>
        </Row>
      </>
    )
  }
}

export default FrequentlyAskedQuestions
