import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import logo from 'resources/images/honda_financial_services_logo.png'
import RegistrationFooter from 'app/components/legal/registrationFooter'
import PasswordResetMailForm from './passwordreset.mail'
import PasswordResetKeyForm from './passwordreset.key'
import PasswordVerify from './passwordreset.verify'
import PasswordResetComplete from './passwordreset.complete'
import PasswordresetErrorForm from './passwordreset.error'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'
import { getBikeOrCarBackgroundClassName } from 'app/shared/utils/common'

export interface PasswordresetRouterProps extends StateProps, DispatchProps {}

export class PasswordresetRouter extends React.Component<PasswordresetRouterProps> {
  render() {
    return (
      <>
        <Grid container className="root">
          <div {...getBikeOrCarBackgroundClassName(this.props.user)}>
            <Grid className="registrationBox" component={Paper} square>
              <div className="paper inner-form">
                <img className="form-logo" src={logo} alt="Honda Bank Logo" />
                <Switch>
                  <Route path="/passwordreset/mail" component={PasswordResetMailForm} />
                  <Route path="/passwordreset/key/:userKey" component={PasswordResetKeyForm} />
                  <Route path="/passwordreset/key" component={PasswordResetKeyForm} />
                  <Route path="/passwordreset/verify" component={PasswordVerify} />
                  <Route path="/passwordreset/complete" component={PasswordResetComplete} />
                  <Route path="/passwordreset/error" component={PasswordresetErrorForm} />
                  <Route path="/passwordreset/" component={PasswordresetErrorForm} />
                </Switch>
                <RegistrationFooter />
              </div>
            </Grid>
          </div>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = ({ password }: IRootState) => ({
  user: password.user
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(PasswordresetRouter)
