import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { Typography, Divider } from '@material-ui/core'
import INavPoint from 'app/model/navigation.model'
import { arrayIsEmpty } from 'app/shared/utils/common'

export interface IMobileNavPoint {
  navigation: INavPoint
  toggleNav
  navKey: any
  level: number
}

export interface IMobileNavPointStateProps {
  subnavVisible: boolean
}

export class MobileNavPoint extends React.Component<IMobileNavPoint, IMobileNavPointStateProps> {
  constructor(props) {
    super(props)
    this.state = {
      subnavVisible: false
    }
  }

  toggleSubnav = () => {
    this.setState({ ...this.state, subnavVisible: !this.state.subnavVisible })
  }

  handleNavClicked = () => {
    this.props.toggleNav()
  }

  getAnchorFromDestination = (link) => {
    return link.split('#')[1]
  }

  getLinkFromDestination = (link) => {
    return link.split('#')[0]
  }

  createMobileNavpoint = (entry, key) => {
    return (
      <MobileNavPoint
        key={'multipleEntriesKey_' + key}
        navKey={'multipleEntriesKey_' + key}
        navigation={entry}
        toggleNav={this.handleNavClicked}
        level={this.props.level + 1}
      />
    )
  }

  createSubnavLink = (entry, key) => {
    let destination = entry.link
    if (entry.anchor) {
      destination += entry.anchor
    }

    return (
      <div key={'key_mobile_subnav_' + key}>
        <Link
          onClick={this.handleNavClicked}
          className={`mobile-sub-nav mobile-sub-nav_${this.props.level + 1}`}
          to={destination}>
          <Typography variant="body2">{entry.name}</Typography>
        </Link>
        <div className="full-width">
          <Divider />
        </div>
      </div>
    )
  }

  createSubnav = () => {
    var result = []
    if (this.state.subnavVisible && !arrayIsEmpty(this.props.navigation.subnav)) {
      this.props.navigation.subnav.forEach((entry, key) => {
        if (!arrayIsEmpty(entry.subnav)) {
          result.push(this.createMobileNavpoint(entry, key))
        } else {
          result.push(this.createSubnavLink(entry, key))
        }
      })
    }
    return result
  }

  render() {
    return (
      <>
        <div
          key={'key_mobile_nav_' + this.props.navKey}
          className={`mobile-nav-point portal-nav-link mobile-sub-nav_${this.props.level}`}
          onClick={this.toggleSubnav}>
          <Typography>{this.props.navigation.name}</Typography>
          {!arrayIsEmpty(this.props.navigation.subnav) && (
            <>
              {(this.state.subnavVisible && (
                <i key={'foo' + this.props.navKey} className="fas fa-minus portal-nav-icon"></i>
              )) || <i key={'foo' + this.props.navKey} className="fas fa-plus portal-nav-icon"></i>}
            </>
          )}
        </div>
        <div className="full-width">
          <Divider />
        </div>
        {this.createSubnav()}
      </>
    )
  }
}

export default MobileNavPoint
