import React from 'react'

import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { Row, Col } from 'reactstrap'

import { valueOrEmptyString } from 'app/shared/utils/common'

import FlagGermany from 'resources/images/flag_germany.png'
import AddressImage from 'resources/images/profile_address.png'

export default function AddressDataDisplay(props: any) {
  const preventDefault = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Row noGutters>
      <Col>
        <div>
          <TextField
            id="street"
            className="profileInputField"
            label="Straße und Hausnummer"
            value={valueOrEmptyString(props.profile.street) + ' ' + valueOrEmptyString(props.profile.streetNumber)}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="edit name" onClick={props.startChange} onMouseDown={preventDefault}>
                    <i className="fas fa-pen small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div>
          <TextField
            id="zipcode"
            className="profileInputField"
            label="Postleitzahl und Ort"
            value={valueOrEmptyString(props.profile.zipCode) + ' ' + valueOrEmptyString(props.profile.city)}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="edit name" onClick={props.startChange} onMouseDown={preventDefault}>
                    <i className="fas fa-pen small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div>
          <TextField
            id="countryCode"
            className="profileInputField"
            label="Land"
            value="Deutschland"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={FlagGermany} alt="Flagge Deutschland"></img>
                </InputAdornment>
              )
            }}
            disabled
          />
        </div>
      </Col>
      <Col className="text-center profile-images">
        <img src={AddressImage} alt="Adresse"></img>
      </Col>
    </Row>
  )
}
