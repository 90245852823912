import '@brainhubeu/react-carousel/lib/style.css'
import './infobox.css'

import React from 'react'
import Carousel from '@brainhubeu/react-carousel'
import AccountInformation from './accountinfo'
import IContract from 'app/model/contract.model'
import IRequest from 'app/model/request.model'
import AccountInformationAdditions from './accountinfo.additions'
import { arrayIsEmpty } from 'app/shared/utils/common'
import { Link } from 'react-router-dom'
import RequestInformation from './requestinfo'
import { Container, Row, Col } from 'reactstrap'

import CarImage from 'resources/images/car_silhouette.png'
import BikeImage from 'resources/images/bike_silhouette.png'
import { DividerWithDiamond } from 'app/shared/components/divider-with-diamond'
import { Typography } from '@material-ui/core'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'

export interface IAccountInformationCarouselProps extends StateProps, DispatchProps {}

export class AccountInformationCarousel extends React.Component<IAccountInformationCarouselProps> {
  content = []

  addContractInfo = (contract: IContract) => {
    const contractProps = {
      locale: this.props.user.locale,
      licenseNumber: contract.objectLicenseNumber,
      installmentNextAmount: contract.installmentNextAmount,
      installmentNextDate: contract.installmentNextDate,
      installmentRemainingCount: contract.installmentRemainingCount,
      balance: contract.balance
    }
    this.content.push(
      <Link key={contract.id} to={'/portal/contract/' + contract.id} className="box-link full-width">
        <AccountInformation {...contractProps} />
      </Link>
    )
    if (
      contract.residualDebtInsurace ||
      contract.mobilityPackage ||
      contract.extendedWarranty ||
      contract.gapWarranty
    ) {
      const additionsProps = {
        licenseNumber: contract.objectLicenseNumber,
        objectType: contract.objectType,
        objectManufacturer: contract.objectManufacturer,
        objectModellName: contract.objectModellName,
        residualDebtInsurace: contract.residualDebtInsurace,
        mobilityPackage: contract.mobilityPackage,
        extendedWarranty: contract.extendedWarranty,
        gapWarranty: contract.gapWarranty
      }
      this.content.push(<AccountInformationAdditions key={contract.id} {...additionsProps} />)
    }
  }

  addRequestInfo = (request: IRequest) => {
    const requestProps = {
      locale: this.props.user.locale,
      objectType: request.objectType,
      objectManufacturer: request.objectManufacturer,
      objectModellName: request.objectModellName,
      id: request.id,
      dateOfApplication: request.dateOfApplication,
      totalAmount: request.totalAmount,
      stillActive: request.stillActive
    }
    this.content.push(
      <Link key={request.id} to={'/portal/request/' + request.id} className="box-link full-width">
        <RequestInformation {...requestProps} />
      </Link>
    )
    if (request.residualDebtInsurace || request.mobilityPackage || request.extendedWarranty || request.gapWarranty) {
      const additionsProps = {
        licenseNumber: null,
        objectType: request.objectType,
        objectManufacturer: request.objectManufacturer,
        objectModellName: request.objectModellName,
        residualDebtInsurace: request.residualDebtInsurace,
        mobilityPackage: request.mobilityPackage,
        extendedWarranty: request.extendedWarranty,
        gapWarranty: request.gapWarranty
      }
      this.content.push(<AccountInformationAdditions key={request.id} {...additionsProps} />)
    }
  }

  createCarousel = () => {
    this.content = []
    if (!arrayIsEmpty(this.props.contracts)) {
      this.props.contracts.forEach((contract) => {
        this.addContractInfo(contract)
      })
    } else if (!arrayIsEmpty(this.props.requests)) {
      this.props.requests.forEach((request) => {
        this.addRequestInfo(request)
      })
    }
  }

  render() {
    this.createCarousel()
    if (!arrayIsEmpty(this.props.contracts) || !arrayIsEmpty(this.props.requests)) {
      return (
        <Carousel
          arrowLeft={<i className="fas fa-chevron-left carousel-arrows" />}
          arrowLeftDisabled={<i className="fas fa-chevron-left invisible" />}
          arrowRight={<i className="fas fa-chevron-right carousel-arrows" />}
          arrowRightDisabled={<i className="fas fa-chevron-right invisible" />}
          addArrowClickHandler
          clickToChange
          dots>
          {this.content}
        </Carousel>
      )
    }
    const isBike = this.props.user.type === 'BIKE'
    return (
      <a
        href={isBike ? 'https://www.honda.de/motorcycles.html' : 'https://www.honda.de/cars.html'}
        rel="noopener noreferrer"
        target="_blank">
        <Container className="full-height full-width">
          <Row>
            <Col xs="12" className="vertical-center horizontal-center">
              <img
                id="request-item-image"
                className="picture-margin"
                src={isBike ? BikeImage : CarImage}
                alt={isBike ? 'Motorrad' : 'Auto'}
              />
            </Col>
          </Row>
          <DividerWithDiamond />
          <div className="margin" />
          <Row noGutters className="infobox-content">
            <Col xs="12" className="horizontal-center">
              <div>
                <Typography variant="body1" className="mobile-smaller">
                  Sie haben aktuell keinen Vertrag?
                  <br />
                  Finden Sie hier Ihr neues {isBike ? 'Motorrad' : 'Auto'}.
                </Typography>
                <div className="margin" />
                <div className="horizontal-center"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </a>
    )
  }
}

const mapStateToProps = ({ authentication, data }: IRootState) => ({
  user: authentication.user,
  contracts: data.contracts,
  requests: data.requests
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(AccountInformationCarousel)
