import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Typography } from '@material-ui/core'

import CarImage from 'resources/images/car_silhouette.png'
import BikeImage from 'resources/images/bike_silhouette.png'
import LicensePlate from 'app/shared/components/licenseplate'

export function ContractEntity(props: any) {
  return (
    <Container className="container-with-border content-container">
      <Row noGutters className="mt-3">
        <Col xs="12">
          <Typography variant="h4" className="text-left vertical-center full-height bold">
            {props.contract.objectManufacturer} {props.contract.objectModellName}
          </Typography>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs="6" className="vertical-center horizontal-center">
          <img
            className="contract-item-image"
            src={props.contract.objectType === 'BIKE' ? BikeImage : CarImage}
            alt={props.contract.objectManufacturer + ' ' + props.contract.objectModellName}
          />
        </Col>
        <Col xs="6" className="text-center">
          <Row noGutters>
            <Col>
              <LicensePlate licenseNumber={props.contract.objectLicenseNumber} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
