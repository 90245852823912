import './upload.css'

import React from 'react'
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  CircularProgress
} from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { Container, Row, Col } from 'reactstrap'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'
import { sendUploadedFiles } from 'app/shared/reducers/upload.reducer'
import { DEFAULT_MAX_UPLOAD_FILE_COUNT, DEFAULT_MIN_UPLOAD_FILE_COUNT, DEFAULT_VALID_UPLOAD_FILES, DEFAULT_VALID_UPLOAD_FILE_DESCRIPTION } from 'app/config/constants'
import { UploadContainer } from './upload.container'

export interface IUploadProps extends StateProps, DispatchProps {
  anchor: string
  id: number
  type: string
}

export interface IUploadState {
  expanded: boolean
  filesSend: boolean
  responseMessage: string
}

export interface IUploadFile {
  id: number
  name: string
  size: number
  type: string
  isValid: boolean
  content: File
}

export class Upload extends React.Component<IUploadProps, IUploadState> {
  showExpanded = this.props.anchor !== null && this.props.anchor === 'upload'
  constructor(props) {
    super(props)
    this.state = {
      expanded: this.showExpanded,
      filesSend: false,
      responseMessage: ''
    }
  }

  handleChange = () => {
    if (!this.state.expanded) {
      this.setState({ ...this.state, expanded: !this.state.expanded, filesSend: false })
    } else {
      this.setState({ ...this.state, expanded: !this.state.expanded })
    }
  }

  sendUploadedFiles = (files: IUploadFile[]) => {
    const formData = new FormData()
    files.forEach((file) => {
      formData.append('file', file.content)
    })
    formData.append('id', this.props.id.toString())
    formData.append('userEmail', this.props.profile.email)
    formData.append('type', this.props.type)
    this.props.sendUploadedFiles(formData, files.length, this.setFeedbackAfterSending, this.setErrorAfterSending)
  }

  setErrorAfterSending = (response: string, fileCount: number) => {
    this.resetStatusAfterSending(
      'Leider hat das Absenden ' + (fileCount > 1 ? 'der Dokumente' : 'des Dokuments') + ' nicht funktioniert. Bitte wenden Sie sich an den Support.'
    )
  }

  setFeedbackAfterSending = (fileCount: number) => {
    this.resetStatusAfterSending('Sie haben ' + (fileCount > 1 ? 'die Dokumente' : 'das Dokument') + ' erfolgreich abgesendet.')
  }

  resetStatusAfterSending = (feedback: string) => {
    this.setState({
      ...this.state,
      filesSend: true,
      responseMessage: feedback
    })
  }

  resetSendStatus = () => {
    this.setState({ ...this.state, filesSend: false })
  }

  render() {
    if (this.props.isLoading) {
      return (
        <Accordion
          id="upload"
          className="container-with-border content-container"
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}>
          <AccordionSummary expandIcon={<RedTriangle medium />}>
            <Typography className="upload-text">
              <i className="fas fa-upload"></i> Upload von Dokumenten
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="text-center">
              <CircularProgress></CircularProgress>
            </div>
          </AccordionDetails>
        </Accordion>
      )
    }
    if (this.state.filesSend) {
      return (
        <Accordion
          id="upload"
          className="container-with-border content-container"
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}>
          <AccordionSummary expandIcon={<RedTriangle medium />}>
            <Typography className="upload-text">
              <i className="fas fa-upload"></i> Upload von Dokumenten
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container fluid className="p-0">
              <Row noGutters className="upload-component">
                <Col>
                  <Typography variant="body2" className="regular-line-height">
                    {this.state.responseMessage}
                  </Typography>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <div className="align-center">
                    <Button
                      className="square-button red-border no-uppercase"
                      variant="contained"
                      color="secondary"
                      onClick={() => this.resetSendStatus()}>
                      Mehr Dokumente hochladen
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </AccordionDetails>
        </Accordion>
      )
    }
    return (
      <Accordion
        id="upload"
        className="container-with-border content-container"
        expanded={this.state.expanded}
        onChange={() => this.handleChange()}>
        <AccordionSummary expandIcon={<RedTriangle medium />}>
          <Typography className="upload-text">
            <i className="fas fa-upload"></i> Upload von Dokumenten
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadContainer
            sendUploadedFiles={this.sendUploadedFiles}
            customButton={false}
            customButtonText={undefined}
            cancel={undefined}
            validFiles={DEFAULT_VALID_UPLOAD_FILES}
            validFileDescription={DEFAULT_VALID_UPLOAD_FILE_DESCRIPTION}
            maxFileCount={DEFAULT_MAX_UPLOAD_FILE_COUNT}
            minFileCount={DEFAULT_MIN_UPLOAD_FILE_COUNT}
            initialUploadDisabled={true}/>
        </AccordionDetails>
      </Accordion>
    )
  }
}

const mapStateToProps = ({ data, upload }: IRootState) => ({
  profile: data.profile,
  isLoading: upload.loading
})

const mapDispatchToProps = {
  sendUploadedFiles
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(Upload)
