import './profile.css'

import React from 'react'
import { Typography } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { withRouter } from 'react-router-dom'

import { IRootState } from 'app/shared/reducers'
import { stringIsEmpty } from 'app/shared/utils/common'
import Upload from 'app/components/upload/upload'

import PersonalData from './personaldata/personaldata'
import AddressData from './addressdata/addressdata'
import CommuncationData from './communicationdata/communicationdata'

export interface IProfileState {
  expanded: {}
}

export interface IProfileProps extends StateProps, DispatchProps {}

export class Profile extends React.Component<IProfileProps & RouteComponentProps, IProfileState> {
  constructor(props) {
    super(props)
    this.state = {
      expanded: {
        personalData: true,
        address: true,
        communication: true
      }
    }
  }

  render() {
    const profile = this.props.profile

    if (profile === null) {
      return <></>
    }

    const anchor = !stringIsEmpty(this.props.location.hash) ? this.props.location.hash.split('#')[1] : null

    const props = {
      profile: { ...profile },
      id: this.props.profile.id,
      type: 'profile',
      anchor
    }

    return (
      <div className="contentContainer">
        <Typography variant="h3" className="section-heading">
          Meine Daten
        </Typography>
        <PersonalData {...props} />
        <AddressData {...props} />
        <CommuncationData {...props} />
        <Upload {...props} />
      </div>
    )
  }
}

const mapStateToProps = ({ data }: IRootState) => ({
  profile: data.profile
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(Profile)
