import React from 'react'
import Typography from '@material-ui/core/Typography'
import { RouteComponentProps, Redirect } from 'react-router'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { compose } from 'redux'
import { stringIsEmpty } from 'app/shared/utils/common'

export interface IPasswordResetComplete extends StateProps, DispatchProps {}

type PasswordResetCompleteParams = {}

export class PasswordResetComplete extends React.Component<
  IPasswordResetComplete & RouteComponentProps<PasswordResetCompleteParams>
> {
  render() {
    if (!stringIsEmpty(this.props.errorMessage)) {
      return <Redirect to="/passwordreset/error" />
    } else {
      return (
        <div className="paper inner-form full-width">
          <Typography variant="h2">Passwort</Typography>
          <Typography variant="body2" className="no-line-height" display="inline">
            Passwort erfolgreich geändert
          </Typography>
          <div className="margin1" />
          <Typography>
            Sie haben erfolgreich Ihr Passwort geändert und erhalten in wenigen Minuten eine Bestätigung per E-Mail.
          </Typography>
          <div className="margin1" />
          <div className="text-center">
            <Link className="grey" to="/login">
              Zum Login
            </Link>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = ({ password }: IRootState) => ({
  errorMessage: password.errorMessage
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(PasswordResetComplete)
