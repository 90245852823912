import React from 'react'
import { Container, Col, Row } from 'reactstrap'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button
} from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { connect } from 'react-redux'
import {
  checkPassword,
  resetErrorMessage,
  resetState,
  setPassword,
  sendVerificationCode,
  setErrorMessage,
  checkVerificationCodeForAuthenticatedUser,
  completePasswordChangeForAuthenticatedUser,
  previousStep
} from '../password.reducer'
import { IRootState } from 'app/shared/reducers'
import PasswordchangeAuthenticate from './passwordchange.authenticate'
import PasswordChangeForm from '../password-change-form'
import ValidationCodebox from 'app/components/codebox/validation.codebox'
import { compose } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

export interface IPasswordChangeProps extends StateProps, DispatchProps {
  anchor: string
}

export interface IPasswordChangeState {
  expanded: boolean
  changePassword: boolean
  oldPassword: string
}

export class PasswordChange extends React.Component<IPasswordChangeProps & RouteComponentProps, IPasswordChangeState> {
  showExpanded = this.props.anchor !== null && this.props.anchor === 'password'
  constructor(props) {
    super(props)
    this.state = {
      expanded: this.showExpanded,
      changePassword: false,
      oldPassword: ''
    }
  }

  componentDidMount() {
    this.props.resetState()
  }

  getSteps() {
    return ['Passwort ändern', 'Neues Passwort eingeben', 'Bestätigungscode eingeben']
  }

  handleChange = () => {
    this.setState({ ...this.state, expanded: !this.state.expanded })
  }

  startEdit = () => {
    this.setState({ ...this.state, changePassword: true })
  }

  checkPassword = () => {
    this.props.checkPassword(this.state.oldPassword, this.clearOldPassword)
  }

  clearOldPassword = () => {
    this.setState({ ...this.state, oldPassword: '' })
  }

  updateOldPassword = (event) => {
    this.props.resetErrorMessage()
    this.setState({ ...this.state, oldPassword: event.target.value })
  }

  handleValidPasswordChange = (password: string, pwRepeat: string) => {
    this.props.setPassword(password, pwRepeat)
    this.props.sendVerificationCode()
  }

  handleValidCodeInput = (enteredCode: string) => {
    this.props.checkVerificationCodeForAuthenticatedUser(
      enteredCode,
      this.completePasswordChange,
      this.handleUserLocked
    )
  }

  handleUserLocked = () => {
    this.props.history.push('/locked')
  }

  completePasswordChange = () => {
    this.props.completePasswordChangeForAuthenticatedUser(this.props.password)
  }

  resetError = () => {
    this.props.resetErrorMessage()
  }

  reset = () => {
    this.props.resetState()
    this.setState({ ...this.state, changePassword: false })
  }

  getStepContent = (index: number) => {
    const authenticateProps = {
      updateOldPassword: this.updateOldPassword,
      checkPassword: this.checkPassword,
      resetError: this.props.resetErrorMessage,
      oldPassword: this.state.oldPassword,
      errorDescription: this.props.errorMessage,
      loading: this.props.isLoading,
      previousFunc: this.reset
    }
    const passwordchangeProps = {
      nextButtonText: 'Weiter',
      password: this.props.password,
      pwRepeat: this.props.pwRepeat,
      handleValidSubmit: this.handleValidPasswordChange,
      fullWidthButton: false,
      type: this.props.user.type,
      username: this.props.user.email,
      previousFunc: this.props.previousStep
    }
    const validationCodeboxProps = {
      onValidSubmit: this.handleValidCodeInput,
      onBack: this.props.resetErrorMessage,
      resetError: this.props.resetErrorMessage,
      setError: this.props.setErrorMessage,
      hasBack: false,
      errorDescription: this.props.errorMessage,
      loading: this.props.isLoading,
      nextButtonText: 'Weiter',
      previousFunc: this.props.previousStep
    }

    switch (index) {
      case 0:
        return <PasswordchangeAuthenticate {...authenticateProps} />
      case 1:
        return (
          <div>
            {(this.props.errorMessage && (
              <Typography className="regular-line-height">
                Wir haben versucht Ihnen einen Bestätigungscode per SMS zu senden. Sie haben allerdings die maximale
                Anzahl der SMS-Nachrichten für heute überschritten. Bitte versuchen Sie es morgen noch einmal oder
                wenden Sie sich an den Support.
              </Typography>
            )) || (
              <>
                <Typography className="regular-line-height">Bitte geben Sie Ihr neues Passwort ein</Typography>
                <PasswordChangeForm {...passwordchangeProps} />
              </>
            )}
          </div>
        )
      case 2:
        return (
          <div>
            <Typography className="regular-line-height">
              Sie erhalten in wenigen Minuten Ihren Bestätigungscode per SMS. Bitte geben Sie diesen unten ein.
            </Typography>
            <ValidationCodebox {...validationCodeboxProps} />
          </div>
        )
      default:
        return 'ERROR'
    }
  }

  render() {
    return (
      <Accordion
        id="password"
        className="container-with-border content-container"
        expanded={this.state.expanded}
        onChange={() => this.handleChange()}>
        <AccordionSummary expandIcon={<RedTriangle medium />}>
          <Typography>Mein Passwort</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {(!this.state.changePassword && (
            <Container fluid className="p-0">
              <Row noGutters>
                <Col xs="12" md="6" className="mobile-margin-bottom">
                  <Typography variant="body2" className="regular-line-height bold">
                    Passwort ändern:
                  </Typography>
                  <Typography variant="body2" className="regular-line-height">
                    Hier können Sie Ihr Passwort ändern
                  </Typography>
                </Col>
                <Col xs="12" md="6" className="text-center margin-auto">
                  <Button
                    className="square-button red-border no-uppercase account-button"
                    variant="contained"
                    color="secondary"
                    onClick={() => this.startEdit()}>
                    Passwort ändern
                  </Button>
                </Col>
              </Row>
            </Container>
          )) || (
            <Stepper activeStep={this.props.currentStep} orientation="vertical" className="email-reset">
              {this.getSteps().map((label, index) => {
                const stepProps = {}
                const labelProps = {}
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    <StepContent>{this.getStepContent(index)}</StepContent>
                  </Step>
                )
              })}
            </Stepper>
          )}
          {this.props.currentStep === 3 && (
            <Container fluid className="p-0">
              <Row noGutters>
                <Col>
                  <Typography className="email-reset-header">Ihr Passwort wurde erfolgreich geändert</Typography>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <Button
                    className="square-button red-border no-uppercase"
                    variant="contained"
                    color="secondary"
                    onClick={() => this.reset()}>
                    OK
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        </AccordionDetails>
      </Accordion>
    )
  }
}

const mapStateToProps = ({ password, authentication }: IRootState) => ({
  isLoading: password.loading,
  errorMessage: password.errorMessage,
  currentStep: password.currentStep,
  password: password.password,
  pwRepeat: password.pwRepeat,
  user: authentication.user
})

const mapDispatchToProps = {
  checkPassword,
  resetErrorMessage,
  setErrorMessage,
  resetState,
  setPassword,
  sendVerificationCode,
  checkVerificationCodeForAuthenticatedUser,
  completePasswordChangeForAuthenticatedUser,
  previousStep
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(PasswordChange)
