import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { AUTHORITIES } from 'app/config/constants'

import Portal from 'app/components/portal/portal'
import RegistrationRouter from 'app/components/registration/registration.router'
import DataSecurity from 'app/components/legal/dataSecurity'
import TermsOfUse from 'app/components/legal/termsOfUse'
import LoginRouter from 'app/components/login/login'
import PrivateRoute from 'app/shared/auth/private-route'

import Impressum from 'app/components/legal/impressum'
import LegalInformation from 'app/components/legal/legalInformation'
import PasswordResetRouter from './components/password/passwordreset/passwordreset.router'
import UserLocked from './components/error/userlocked.error'
import AccountdeleteInfo from './components/portal/account/delete/accountdelete.info'
import FAQPublic from './components/portal/faq/faq.public'
import TechsupportPublicForm from './components/portal/contact/techsupport.public'

export default function Routing() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/register">
          <Redirect to="/registration/new/enter" />
        </Route>
        <Route path="/registration/" component={RegistrationRouter} />
        <Route path="/termsOfUse/" component={TermsOfUse} />
        <Route path="/dataSecurity/" component={DataSecurity} />
        <Route path="/login" component={LoginRouter} />
        <Route path="/faq" component={FAQPublic} />
        <Route path="/termsOfUse" component={TermsOfUse} />
        <Route path="/dataSecurity" component={DataSecurity} />
        <Route path="/impressum" component={Impressum} />
        <Route path="/legal" component={LegalInformation} />
        <Route path="/passwordreset/" component={PasswordResetRouter} />
        <Route path="/locked" component={UserLocked} />
        <Route path="/deleted" component={AccountdeleteInfo} />
        <Route path="/support" component={TechsupportPublicForm} />
        <PrivateRoute
          path="/portal"
          component={Portal}
          hasAnyAuthorities={[AUTHORITIES.CUSTOMER, AUTHORITIES.ADMIN]}></PrivateRoute>
        <PrivateRoute
          path="/"
          component={Portal}
          hasAnyAuthorities={[AUTHORITIES.CUSTOMER, AUTHORITIES.ADMIN]}></PrivateRoute>
      </Switch>
    </BrowserRouter>
  )
}
