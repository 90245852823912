import React from 'react'
import Typography from '@material-ui/core/Typography'

import { Button } from '@material-ui/core'
import { Link, Link as RouterLink } from 'react-router-dom'

export class RegistrationNewUserError extends React.Component {
  render() {
    return (
      <div className="paper inner-form full-width">
        <Typography variant="h2">Registrierung</Typography>
        <Typography variant="body2" className="red mt-5 mw-100">
          Ihre Registrierung ist leider nicht möglich. Wenn Sie sich bereits registriert haben, klicken Sie bitte auf
          Einloggen.
        </Typography>
        <Typography variant="body2" className="red mt-4 mw-100">
          Sollten Sie sich noch nicht registriert haben, wenden Sie sich bitte an den {' '}
          <Link className="underline" to='/support'>Support</Link>
          .
        </Typography>

        <Button
          className="buttonLink mt-5"
          component={RouterLink}
          to={{
            pathname: '/login'
          }}
          type="submit"
          fullWidth
          variant="contained"
          color="secondary">
          Jetzt einloggen
        </Button>
      </div>
    )
  }
}

export default RegistrationNewUserError
