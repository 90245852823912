import './footer.css'

import React from 'react'
import DesktopFooter from './footer.desktop'
import MobileFooter from './footer.mobile'

export interface IFooter {
  isPublic?: boolean
}

export class Footer extends React.Component<IFooter> {
  render() {
    return (
      <>
        <MobileFooter {...this.props} />
        <DesktopFooter {...this.props} />
      </>
    )
  }
}

export default Footer
