import './portal.css'

import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import { IRootState } from 'app/shared/reducers'
import { fetchRequests, fetchProfile, fetchContracts } from 'app/shared/reducers/data.reducer'
import { refreshToken, clearAuthentication } from 'app/shared/reducers/authentication.reducer'
import { fetchContactReasons } from 'app/components/portal/contact/contact.reducer'
import { getMessageCount } from 'app/components/portal/inbox/inbox.reducer'

import StartPage from './startpage'
import FAQInternal from './faq/faq.internal'
import Contract from './contract/contract'
import Request from './request/request'
import Profile from './profile/profile'
import Account from './account/account'
import Inbox from './inbox/inbox'
import News from './news'
import Header from '../navigation/header'
import Footer from '../navigation/footer'
import MobileNav from '../navigation/mobile.navigation'
import BackToTop from './back-to-top'
import RequestSelect from './request/request.select'
import ContractSelect from './contract/contract.select'
import { CircularProgress, Dialog, DialogActions, DialogContent, Typography, Button } from '@material-ui/core'
import { Container, Row, Col } from 'reactstrap'
import { getMinutesAndSecondsFromInputSeconds } from 'app/shared/utils/common'
import { getSecondsTotalValidFromToken, tokenNeedsRefresh } from 'app/shared/auth/token'
import config from 'app/config/constants'
import ContactInternalForm from './contact/contact.internal'
import TechsupportInternalForm from './contact/techsupport.internal'

export interface IPortalProps extends StateProps, DispatchProps {}
export interface IPortalState {
  navVisible: boolean
  sessionRemainingSecond: number
}

export class Portal extends React.Component<IPortalProps, IPortalState> {
  constructor(props) {
    super(props)
    this.state = {
      navVisible: false,
      sessionRemainingSecond: getSecondsTotalValidFromToken()
    }
    if (this.props.isAuthenticated) {
      this.props.fetchRequests()
      this.props.fetchProfile()
      this.props.fetchContracts()
      this.props.getMessageCount()
      this.props.fetchContactReasons()
    }
  }

  handleRefreshToken = async () => {
    await this.props.refreshToken()
    this.setState({ sessionRemainingSecond: getSecondsTotalValidFromToken() })
  }

  componentDidMount() {
    if (tokenNeedsRefresh()) {
      this.handleRefreshToken()
    }
    if (this.props.isAuthenticated) {
      this._sessionTick()
    }
  }

  _sessionTick() {
    if (this.state.sessionRemainingSecond > 0) {
      this.setState({
        sessionRemainingSecond: this.state.sessionRemainingSecond - 1
      })
      setTimeout(this._sessionTick.bind(this), 1000)
    } else {
      this.props.clearAuthentication('session.timeout')
    }
  }

  render() {
    const handleClicked = async () => {
      if (tokenNeedsRefresh()) {
        this.handleRefreshToken()
      } else {
        this.setState({ sessionRemainingSecond: getSecondsTotalValidFromToken() })
      }
    }

    const toggleNav = () => {
      this.setState({ navVisible: !this.state.navVisible })
    }

    if (this.props.loading) {
      return (
        <Container>
          <Row>
            <Col xs="12" className="loading-center">
              <CircularProgress></CircularProgress>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <>
        <Dialog
          open={this.state.sessionRemainingSecond <= config.REMAINING_SECONDS_SHOW_POPUP}
          className="session-refresh-dialog">
          <DialogContent>
            <Container>
              <Row>
                <Col align="center">
                  <Typography>Automatische Abmeldung in</Typography>
                </Col>
              </Row>
              <Row>
                <Col align="center">
                  <Typography variant="h2">
                    {getMinutesAndSecondsFromInputSeconds(this.state.sessionRemainingSecond)}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col align="center">
                  <Typography>Möchten Sie im Portal weiter eingeloggt bleiben?</Typography>
                </Col>
              </Row>
            </Container>
          </DialogContent>
          <DialogActions>
            <Container fluid className="p-0">
              <Row noGutters>
                <Col align="center">
                  <Button
                    id="handleRefreshTokenButton"
                    className="square-button red-border no-uppercase"
                    variant="contained"
                    color="secondary"
                    onClick={this.handleRefreshToken}>
                    Jetzt fortsetzen
                  </Button>
                </Col>
              </Row>
            </Container>
          </DialogActions>
        </Dialog>
        <div className="portal-session-container" onClick={handleClicked}>
          <header>
            <div className="portal">
              <Header
                toggleNav={toggleNav}
                navVisible={this.state.navVisible}
                sessionRemainingSecond={this.state.sessionRemainingSecond}
              />
              <MobileNav
                navVisible={this.state.navVisible}
                toggleNav={toggleNav}
                sessionRemainingSecond={this.state.sessionRemainingSecond}
              />
            </div>
          </header>
          <main>
            <Switch>
              <Route path="/portal/contact" component={ContactInternalForm} />
              <Route path="/portal/support" component={TechsupportInternalForm} />
              <Route path="/portal/faq" component={FAQInternal} />
              <Route path="/portal/contractselect" component={ContractSelect} />
              <Route path="/portal/contract" component={Contract} />
              <Route path="/portal/requestselect" component={RequestSelect} />
              <Route path="/portal/request" component={Request} />
              <Route path="/portal/profile" component={Profile} />
              <Route path="/portal/account" component={Account} />
              <Route path="/portal/inbox" component={Inbox} />
              <Route path="/portal/news" component={News} />
              <Route path="/portal" component={StartPage} />
              <Route path="/" component={StartPage} />
            </Switch>
            <BackToTop />
          </main>
          <footer>
            <Footer />
          </footer>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ data, authentication }: IRootState) => ({
  loading: data.loading,
  isAuthenticated: authentication.isAuthenticated
})

const mapDispatchToProps = {
  fetchRequests,
  fetchProfile,
  fetchContracts,
  getMessageCount,
  refreshToken,
  clearAuthentication,
  fetchContactReasons
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(Portal)
