import React from 'react'
import { connect } from 'react-redux'
import { Drawer, Container, Typography, Divider } from '@material-ui/core'

import { logout } from 'app/shared/reducers/authentication.reducer'
import Header from './header'
import MobileNavPoint from './mobile.navigation.point'
import INavPoint from 'app/model/navigation.model'
import { IRootState } from 'app/shared/reducers'
import { createNavigationTree } from 'app/shared/utils/navigation.util'

export interface IMobileNav extends StateProps, DispatchProps {
  navVisible: boolean
  toggleNav
  sessionRemainingSecond
}

export class MobileNav extends React.Component<IMobileNav> {
  buildNavigation = (navTree: INavPoint[]) => {
    var result = []
    navTree.forEach((navPoint, key) => {
      result.push(
        <MobileNavPoint key={key} navKey={key} navigation={navPoint} toggleNav={this.props.toggleNav} level={1} />
      )
    })
    return result
  }

  render() {
    const navigationTree: INavPoint[] = createNavigationTree(this.props.requests, this.props.contracts)

    return (
      <Drawer
        className="mobile-nav-drawer"
        transitionDuration={0}
        anchor="top"
        open={this.props.navVisible}
        onClose={this.props.toggleNav}>
        <Container className="mobile-open-nav">
          <Header
            toggleNav={this.props.toggleNav}
            navVisible={this.props.navVisible}
            sessionRemainingSecond={this.props.sessionRemainingSecond}
          />
          <>{this.buildNavigation(navigationTree)}</>
          <div className="mobile-nav-point mobile-logout" onClick={this.props.logout}>
            <Typography>Logout</Typography>
            <i className="fas fa-sign-out-alt logout-logo"></i>
          </div>
          <div className="full-width">
            <Divider />
          </div>
        </Container>
      </Drawer>
    )
  }
}

const mapStateToProps = ({ data }: IRootState) => ({
  requests: data.requests,
  contracts: data.contracts
})

const mapDispatchToProps = {
  logout
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(MobileNav)
