import React from 'react'
import FAQ from './faq'
import faqContentPublic from './faq.content.public'
import { Divider, Button } from '@material-ui/core'

import { RouteComponentProps } from 'react-router'
import { Container, Row, Col, NavLink } from 'reactstrap'

import logo from 'resources/images/honda_financial_services_logo.png'
import Footer from 'app/components/navigation/footer'

export class FAQPublic extends React.Component<RouteComponentProps> {
  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const props = {
      content: faqContentPublic
    }
    const footerProps = {
      isPublic: true
    }
    return (
      <>
        <Container fluid>
          <Row className="portal-header-bg">
            <Col className="portal-header">
              <NavLink to="/login">
                <div>
                  <img className="logo portal" src={logo} alt="Honda Logo" />
                </div>
              </NavLink>
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col>
              <Divider className="red-divider" />
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col xs="12">
              <FAQ {...props} />
            </Col>
            <Col xs="12">
              <div className="contentContainer mt-4 mb-5 text-center">
                <Button
                  className="square-button red-border"
                  type="submit"
                  onClick={this.goBack}
                  variant="contained"
                  color="secondary">
                  Zurück
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer {...footerProps} />
      </>
    )
  }
}

export default FAQPublic
