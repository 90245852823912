import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import React from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { bindActionCreators } from 'redux'

import AppComponent from 'app/app'
import initStore from 'app/config/store'
import DevTools from 'app/config/devtools'
import { clearAuthentication } from 'app/shared/reducers/authentication.reducer'
import setupAxiosInterceptors from 'app/config/setup-axios-interceptor'

const store = initStore()

const actions = bindActionCreators({ clearAuthentication }, store.dispatch)
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'))

const container = document.getElementById('root')
const root = createRoot(container)

const render = (Component) =>
  // eslint-disable-next-line react/no-render-return-value
  root.render(
    <Provider store={store}>
      <>
        {process.env.NODE_ENV === 'development' && <DevTools />}
        <Component />
      </>
    </Provider>
  )

render(AppComponent)
