import React from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { IRootState } from 'app/shared/reducers'
import { setLoginname, clearAuthentication } from 'app/shared/reducers/authentication.reducer'

import { Typography, FormControl, Button } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { stringIsEmpty } from 'app/shared/utils/common'

export interface ILoginMailFormProps extends StateProps, DispatchProps {}

interface ILoginMailFormState {
  email: string
}

export class LoginMailForm extends React.Component<ILoginMailFormProps & RouteComponentProps, ILoginMailFormState> {
  constructor(props) {
    super(props)
    this.state = {
      email: !stringIsEmpty(props.loginname) ? props.loginname : ''
    }
  }

  componentDidMount() {
    this.props.clearAuthentication('login.started.new')
  }

  handleValidSubmit = () => {
    this.props.setLoginname(this.state.email)
    this.props.history.push('/login/password')
  }

  handleChange = (event) => {
    this.setState({ email: event.target.value })
  }

  render() {
    return (
      <div className="paper inner-form full-width">
        <Typography variant="h2">Willkommen</Typography>
        <Typography variant="body2" className="no-line-height" display="inline">
          im Honda Bank Kundenportal
        </Typography>
        <div className="margin1" />
        <ValidatorForm className="full-width" onSubmit={this.handleValidSubmit}>
          <FormControl fullWidth>
            <TextValidator
              className="full-width"
              label="E-Mail (Benutzername)"
              name="username"
              onChange={this.handleChange}
              type="text"
              validators={['required']}
              errorMessages={['']}
              value={this.state.email}
            />
          </FormControl>
          <input type="password" name="password" hidden />
          <div className="margin1" />
          <Button className="square-button" fullWidth type="submit" variant="contained" color="secondary">
            Weiter
          </Button>
          <div className="margin1" />
          {/* Registration will be added back into the application, when the business process is started */}
          {/* <div className="text-center">
            <Link className="grey" to="/register">
              Registrieren
            </Link>
          </div> */}
        </ValidatorForm>
      </div>
    )
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  loginname: authentication.loginname
})

const mapDispatchToProps = {
  setLoginname,
  clearAuthentication
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect<any>(mapStateToProps, mapDispatchToProps)(LoginMailForm)
