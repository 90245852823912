import React from 'react'
import { Row, Col } from 'reactstrap'
import { Typography } from '@material-ui/core'
import icon from 'resources/images/news.png'
import InfoboxHeader from './infobox.header'

export class Advertisement extends React.Component {
  render() {
    return (
      <>
        <InfoboxHeader imgSource={icon} />
        <Row>
          <Col xs="12" className="vertical-center horizontal-center mt-3">
            <Typography variant="h6" className="text-margin">
              News & Events
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="vertical-center horizontal-center">
            <Typography variant="body1">
              Mehr Informationen zu News & Events von Honda Deutschland finden Sie hier
            </Typography>
          </Col>
        </Row>
      </>
    )
  }
}

export default Advertisement
