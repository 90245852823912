import React from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Button, Typography, CircularProgress } from '@material-ui/core'
import { Col, Container, Row } from 'reactstrap'

export interface IEmailResetTextfield {
  email: string
  username: string
  enterNewUsername: any
  updateUsername: any
  errorDescription: string
  loading: boolean
  previousFunc: any
}

export default class EmailResetTextfield extends React.Component<IEmailResetTextfield> {
  componentDidMount() {
    ValidatorForm.addValidationRule('emailCheck', (value) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(value).toLowerCase())
    })
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('emailCheck')
  }

  render() {
    return (
      <ValidatorForm onSubmit={this.props.enterNewUsername}>
        <Container fluid className="p-0 email-reset-inner-box">
          <Row noGutters>
            <Col>
              <Typography className="email-reset-header">
                Bitte geben Sie Ihre neue E-Mail-Adresse (Benutzername) ein.
              </Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="email-reset-textbox">
              <TextValidator
                className="full-width"
                label="E-Mail-Adresse"
                onChange={this.props.updateUsername}
                name="email"
                validators={['required', 'isEmail', 'emailCheck']}
                errorMessages={[
                  'Bitte geben Sie eine gültige E-Mail-Adresse ein',
                  'Bitte geben Sie eine gültige E-Mail-Adresse ein',
                  'Bitte geben Sie eine gültige E-Mail-Adresse ein'
                ]}
                value={this.props.username}></TextValidator>
            </Col>
          </Row>
          {this.props.errorDescription && (
            <Row noGutters>
              <Col>
                <Typography className="validationFailed" id="pwValidationLabel" variant="body2">
                  {this.props.errorDescription}
                </Typography>
              </Col>
            </Row>
          )}
          <Row noGutters>
            <Col>
              {(this.props.loading && (
                <div className="text-center">
                  <CircularProgress></CircularProgress>
                </div>
              )) || (
                <>
                  <Button
                    className="square-button mr-2"
                    onClick={this.props.previousFunc}
                    variant="contained"
                    color="primary">
                    Abbrechen
                  </Button>
                  <Button
                    type="submit"
                    className="square-button red-border no-uppercase"
                    variant="contained"
                    color="secondary">
                    Weiter
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </ValidatorForm>
    )
  }
}
