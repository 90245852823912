import React from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { IRootState } from 'app/shared/reducers'
import { login, resetError } from 'app/shared/reducers/authentication.reducer'

import { Typography, FormControl, Button, InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { FormGroup } from 'reactstrap'

export interface ILoginPasswordFormState extends StateProps, DispatchProps {}

interface ILoginPasswordFromProps {
  password: string
  showPassword: boolean
}

export class LoginPasswordForm extends React.Component<
  ILoginPasswordFormState & RouteComponentProps,
  ILoginPasswordFromProps
> {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      showPassword: false
    }
  }

  componentDidMount() {
    this.props.resetError()
  }

  previousLoginStep = () => {
    this.props.history.push('/login')
  }

  handleValidSubmit = () => {
    this.props.login(this.props.loginname, this.state.password)
  }

  handleChange = (event) => {
    this.setState({ password: event.target.value })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />
    }
    if (this.props.loginname === null) {
      return <Redirect to="/login" />
    }
    return (
      <div className="paper inner-form full-width">
        <Typography variant="h2">Einloggen</Typography>
        <Typography variant="body2" className="no-line-height" display="inline">
          {this.props.loginname}
        </Typography>
        <div className="margin1" />
        {this.props.loginError && (
          <>
            <Typography className="validationFailed mw-100" id="pwValidationLabel" variant="body2">
              Der Benutzername oder das Passwort waren nicht korrekt.
            </Typography>
          </>
        )}
        <div className="margin1" />
        <div className="innerForm">
          <ValidatorForm className="full-width" onSubmit={this.handleValidSubmit}>
            <FormControl fullWidth>
              <input id="username" name="username" hidden value={this.props.loginname} readOnly={true} />
              <TextValidator
                className="full-width"
                id="password"
                label="Passwort"
                onChange={this.handleChange}
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                validators={['required']}
                errorMessages={['']}
                value={this.state.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}>
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
            <div className="margin1" />
            <FormGroup className="inline">
              <Button className="white-square-button bordered-button" color="primary" onClick={this.previousLoginStep}>
                Zurück
              </Button>
              <Button className="square-button red-border" type="submit" variant="contained" color="secondary">
                Weiter
              </Button>
            </FormGroup>
            <div className="margin1" />
            <div className="text-center">
              <Link className="grey" to="/passwordreset/mail">
                Passwort vergessen
              </Link>
            </div>
          </ValidatorForm>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  loginname: authentication.loginname,
  loginError: authentication.loginError,
  isAuthenticated: authentication.isAuthenticated
})

const mapDispatchToProps = {
  login,
  resetError
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect<any>(mapStateToProps, mapDispatchToProps)(LoginPasswordForm)
