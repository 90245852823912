import './inbox.css'

import React from 'react'
import {
  Typography,
  Divider,
  Checkbox,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import { connect } from 'react-redux'

import {
  toggleSelected,
  deleteMessages,
  restoreMessages,
  archiveMessages,
  getMessageCount,
  getMessages,
  setChecked,
  downloadMessageData
} from './inbox.reducer'
import { IRootState } from 'app/shared/reducers'

import { dateFormat, isUndefined } from 'app/shared/utils/common'
import { Container, Row, Col } from 'reactstrap'
import { Redirect, RouteComponentProps } from 'react-router'
import { inboxFolders } from 'app/config/constants'
import { Link } from 'react-router-dom'
import { IMessage } from 'app/model/message.model'

export interface IInboxRouteProps {
  section: string
}

export interface IInboxMessagesState {
  deleteDialogOpen: boolean
  restoreDialogOpen: boolean
  archiveDialogOpen: boolean
}

export interface IInboxMessagesProps extends StateProps, DispatchProps {}

export class InboxMessages extends React.Component<
  IInboxMessagesProps & RouteComponentProps<IInboxRouteProps>,
  IInboxMessagesState
> {
  inputRefToggleAll: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      deleteDialogOpen: false,
      restoreDialogOpen: false,
      archiveDialogOpen: false
    }
  }

  render() {
    const section = this.props.match.params.section

    const getMessagedSelectedCount = (): number => {
      return this.props[section].filter((row) => row.selected).length
    }

    const runDeleteMessages = async () => {
      if (getMessagedSelectedCount() > 0) {
        await this.props.deleteMessages(this.props[section])
        await this.props.getMessageCount()
        await this.props.getMessages()
      }
    }

    const runRestoreMessages = async () => {
      if (getMessagedSelectedCount() > 0) {
        await this.props.restoreMessages(this.props[section])
        await this.props.getMessageCount()
        await this.props.getMessages()
      }
    }

    const runArchiveMessages = async () => {
      if (getMessagedSelectedCount() > 0) {
        await this.props.archiveMessages(this.props[section])
        await this.props.getMessageCount()
        await this.props.getMessages()
      }
    }

    const toggleAllMessages = () => {
      const state = this.inputRefToggleAll.current.checked
      this.props[section].forEach((message) => {
        this.props.setChecked(message, state)
      })
    }

    const downloadMessage = async (message: IMessage) => {
      await this.props.downloadMessageData(message)
      if (!isUndefined(this.props.messageData)) {
        // Open blob in new window
        // const newWindow = window.open()
        // if (newWindow.document.readyState === 'complete') {
        //   newWindow.location.assign(URL.createObjectURL(blob))
        // } else {
        //   newWindow.onload = () => {
        //     newWindow.location.assign(URL.createObjectURL(blob))
        //   }
        // }
        const fileUrl = URL.createObjectURL(this.props.messageData)
        const link = document.createElement('a')
        document.body.appendChild(link)
        link.setAttribute('style', 'display: none')
        link.href = fileUrl
        link.download = message.fileName
        link.click()

        setTimeout(() => {
          // For firefox it is necessary to delay revoking the objecturl
          window.URL.revokeObjectURL(fileUrl)
          link.remove()
        }, 100)
      }
    }

    const openRestoreDialog = () => {
      if (getMessagedSelectedCount() > 0) {
        this.setState({ restoreDialogOpen: true })
      }
    }

    const handleRestoreDialogOk = async () => {
      await runRestoreMessages()
      this.setState({ restoreDialogOpen: false })
    }

    const handleRestoreDialogAbort = () => {
      this.setState({ restoreDialogOpen: false })
    }

    const openArchiveDialog = () => {
      if (getMessagedSelectedCount() > 0) {
        this.setState({ archiveDialogOpen: true })
      }
    }

    const handleArchiveDialogOk = async () => {
      await runArchiveMessages()
      this.setState({ archiveDialogOpen: false })
    }

    const handleArchiveDialogAbort = () => {
      this.setState({ archiveDialogOpen: false })
    }

    const openDeleteDialog = () => {
      if (getMessagedSelectedCount() > 0) {
        this.setState({ deleteDialogOpen: true })
      }
    }

    const handleDeleteDialogOk = async () => {
      await runDeleteMessages()
      this.setState({ deleteDialogOpen: false })
    }

    const handleDeleteDialogAbort = () => {
      this.setState({ deleteDialogOpen: false })
    }

    if (isUndefined(this.props[section])) {
      return <Redirect to="/portal/inbox" />
    }

    this.inputRefToggleAll = React.createRef()
    let toggleAllChecked = this.props[section].length !== 0
    const messagesSelectedCount = getMessagedSelectedCount()
    this.props[section].forEach((row) => {
      if (!row.selected) {
        toggleAllChecked = false
      }
    })

    return (
      <>
        <Dialog
          open={this.state.deleteDialogOpen}
          onClose={handleDeleteDialogAbort}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle>Wollen Sie die ausgewählten Nachrichten wirklich löschen?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props[section]
                .filter((row) => row.selected)
                .map((row, key) => (
                  <Typography key={key}>
                    {row.subject} vom {dateFormat(row.receivedDate, this.props.locale)}
                  </Typography>
                ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Container fluid className="p-0">
              <Row noGutters>
                {this.props.loading && (
                  <Col align="center">
                    <CircularProgress></CircularProgress>
                  </Col>
                )}
                {!this.props.loading && (
                  <>
                    <Col align="center">
                      <Button
                        className="square-button red-border no-uppercase"
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteDialogAbort}>
                        Abbrechen
                      </Button>
                    </Col>
                    <Col align="center">
                      <Button
                        className="square-button red-border no-uppercase"
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteDialogOk}>
                        Löschen
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.restoreDialogOpen}
          onClose={handleRestoreDialogAbort}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle>Wollen Sie die ausgewählten Nachrichten wiederherstellen?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props[section]
                .filter((row) => row.selected)
                .map((row, key) => (
                  <Typography key={key}>
                    {row.subject} vom {dateFormat(row.receivedDate, this.props.locale)}
                  </Typography>
                ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Container fluid className="p-0">
              <Row noGutters>
                {this.props.loading && (
                  <Col align="center">
                    <CircularProgress></CircularProgress>
                  </Col>
                )}
                {!this.props.loading && (
                  <>
                    <Col align="center">
                      <Button
                        className="square-button red-border no-uppercase"
                        variant="contained"
                        color="secondary"
                        onClick={handleRestoreDialogAbort}>
                        Abbrechen
                      </Button>
                    </Col>
                    <Col align="center">
                      <Button
                        className="square-button red-border no-uppercase"
                        variant="contained"
                        color="secondary"
                        onClick={handleRestoreDialogOk}>
                        Wiederherstellen
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.archiveDialogOpen}
          onClose={handleArchiveDialogAbort}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle>Wollen Sie die ausgewählten Nachrichten archivieren?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props[section]
                .filter((row) => row.selected)
                .map((row, key) => (
                  <Typography key={key}>
                    {row.subject} vom {dateFormat(row.receivedDate, this.props.locale)}
                  </Typography>
                ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Container fluid className="p-0">
              <Row noGutters>
                {this.props.loading && (
                  <Col align="center">
                    <CircularProgress></CircularProgress>
                  </Col>
                )}
                {!this.props.loading && (
                  <>
                    <Col align="center">
                      <Button
                        className="square-button red-border no-uppercase"
                        variant="contained"
                        color="secondary"
                        onClick={handleArchiveDialogAbort}>
                        Abbrechen
                      </Button>
                    </Col>
                    <Col align="center">
                      <Button
                        className="square-button red-border no-uppercase"
                        variant="contained"
                        color="secondary"
                        onClick={handleArchiveDialogOk}>
                        Archivieren
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </DialogActions>
        </Dialog>
        <Container id="inbox" className="container-with-border content-container mb-2">
          <Row>
            <Col xs="9" md="9" align="left" className="pt-3 align-top">
              <Container fluid className="p-0">
                <Row noGutters>
                  <Col xs="12">
                    <Typography variant="h5">
                      Ordner:{' '}
                      <span className="bold">
                        <Link to="/portal/inbox">Postfach</Link> <i className="fas fa-angle-right small"></i>{' '}
                        {inboxFolders[section]}
                      </span>
                    </Typography>
                  </Col>
                  <Col xs="12" align="left">
                    <Typography variant="h6" className="bold">
                      &nbsp;
                      {messagesSelectedCount > 0 && (
                        <span>
                          ({messagesSelectedCount + ' ' + (messagesSelectedCount === 1 ? ' Nachricht' : 'Nachrichten')}{' '}
                          selektiert)
                        </span>
                      )}
                    </Typography>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs="3" md="3" align="right" className="align-top mt-3 ml-auto mr-auto">
              <Container fluid className="p-0">
                <Row noGutters>
                  <Col xs="6" align="center">
                    {section === 'deleted' && (
                      <Typography>
                        <Tooltip title={messagesSelectedCount > 0 ? 'Nachrichten wiederherstellen' : ''}>
                          <i
                            className={
                              'fas fa-undo' +
                              (messagesSelectedCount > 0 ? ' message-action' : ' message-action-disabled')
                            }
                            onClick={openRestoreDialog}></i>
                        </Tooltip>
                      </Typography>
                    )}
                    {section === 'messages' && (
                      <Typography>
                        <Tooltip title={messagesSelectedCount > 0 ? 'Nachrichten archivieren' : ''}>
                          <i
                            className={
                              'fas fa-archive' +
                              (messagesSelectedCount > 0 ? ' message-action' : ' message-action-disabled')
                            }
                            onClick={openArchiveDialog}></i>
                        </Tooltip>
                      </Typography>
                    )}
                  </Col>
                  <Col xs="6" align="left">
                    <Typography>
                      <Tooltip title={messagesSelectedCount > 0 ? 'Nachrichten löschen' : ''}>
                        <i
                          className={
                            'far fa-trash-alt mr-4 ml-2' +
                            (messagesSelectedCount > 0 ? ' message-action' : ' message-action-disabled')
                          }
                          onClick={openDeleteDialog}></i>
                      </Tooltip>
                    </Typography>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col xs="2" md="1" className="align-self-center">
              <Checkbox
                inputRef={this.inputRefToggleAll}
                checked={toggleAllChecked}
                onChange={toggleAllMessages}
                color="primary"
              />
            </Col>
            <Col xs="8" md="10" className="align-self-center">
              <Container fluid className="d-none d-md-block p-0">
                <Row noGutters>
                  <Col md="7" align="left" className="align-self-center">
                    <Typography>Betreff</Typography>
                  </Col>
                  <Col md="3" align="left" className="align-self-center">
                    <Typography>Empfangen</Typography>
                  </Col>
                  <Col md="2" align="left" className="align-self-center">
                    <Typography>Löschung</Typography>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs="2" md="1" align="center" className="align-self-center"></Col>
          </Row>
          <Divider className="divider-3" />
          {this.props[section].length === 0 && (
            <Row>
              <Col align="center">
                <Typography className="mt-4">Es sind keine Nachrichten vorhanden</Typography>
              </Col>
            </Row>
          )}
          {this.props[section].map((message, key) => (
            <div key={message.id}>
              <Row className="pb-3 pt-1">
                <Col xs="2" md="1">
                  <Checkbox
                    key={message.id}
                    color="primary"
                    checked={isUndefined(message.selected) ? false : message.selected}
                    onChange={() => this.props.toggleSelected(message)}
                  />
                </Col>
                <Col xs="8" md="10" className="align-self-center">
                  <Container
                    fluid
                    className="p-0 download-action"
                    onClick={() => {
                      downloadMessage(message)
                    }}>
                    <Row noGutters>
                      <Col xs="12" md="7" align="left" className="align-self-center">
                        <Typography className={!message.read ? 'bold' : ''}>{message.subject}</Typography>
                      </Col>
                      <Col xs="6" className="d-md-none">
                        <Typography>Empfangen</Typography>
                      </Col>
                      <Col xs="6" md="3" align="left" className="align-self-center">
                        <Typography className={!message.read ? 'bold' : ''}>
                          {dateFormat(message.receivedDate, this.props.locale)}
                        </Typography>
                      </Col>
                      <Col xs="6" className="d-md-none">
                        <Typography>Löschung</Typography>
                      </Col>
                      <Col xs="6" md="2" align="left" className="align-self-center">
                        <Typography className={!message.read ? 'bold' : ''}>
                          {isUndefined(message.willBeDeletedAt)
                            ? '-'
                            : dateFormat(message.willBeDeletedAt, this.props.locale)}
                        </Typography>
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col xs="2" md="1" align="center" className="mt-1">
                  <Tooltip title="Nachricht herunterladen">
                    <Typography>
                      <i
                        className="fas fa-download download-action"
                        onClick={() => {
                          downloadMessage(message)
                        }}></i>
                    </Typography>
                  </Tooltip>
                </Col>
              </Row>
              {key < this.props[section].length - 1 && <Divider />}
            </div>
          ))}
          <Row noGutters className="pt-2">
            <Col>
              <Typography>
                <Link to="/portal/inbox">
                  <i className="fas fa-angle-left pr-2 red"></i> Zurück zur Übersicht
                </Link>
              </Typography>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

const messagesComparator = (msgA: IMessage, msgB: IMessage) => {
  if (msgA.id === msgB.id) {
    return 0
  } else {
    return msgA.id > msgB.id ? 1 : -1
  }
}

const mapStateToProps = ({ authentication, inbox }: IRootState) => ({
  messages: inbox.messages.filter((message) => !message.deleted && !message.archived).sort(messagesComparator),
  archived: inbox.messages.filter((message) => !message.deleted && message.archived).sort(messagesComparator),
  deleted: inbox.messages.filter((message) => message.deleted).sort(messagesComparator),
  locale: authentication.user.locale,
  messagesSelectedCount: inbox.messagesSelectedCount,
  loading: inbox.loading,
  messageData: inbox.messageData
})

const mapDispatchToProps = {
  toggleSelected,
  deleteMessages,
  getMessages,
  getMessageCount,
  setChecked,
  restoreMessages,
  archiveMessages,
  downloadMessageData
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect<any>(mapStateToProps, mapDispatchToProps)(InboxMessages)
