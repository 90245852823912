import axios from 'axios'

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util'
import { messages } from 'app/config/constants'

import { stringIsEmpty, handleBackendError } from 'app/shared/utils/common'

export const ACTION_TYPES = {
  RESET_ERROR: 'deleteaccount/RESET_ERROR',
  SET_ERROR: 'deleteaccount/SET_ERROR',
  RESET_STATE: 'deleteaccount/RESET_STATE',
  SEND_CODE: 'deleteaccount/SEND_CODE',
  VERIFY_CODE: 'deleteaccount/VERIFY_CODE',
  COMPLETE_CHANGE: 'deleteaccount/COMPLETE_CHANGE',
  PREV_STEP: 'deleteaccount/PREV_STEP'
}

export const initialState = {
  loading: false,
  errorMessage: null as string,
  currentStep: 0
}

export type DeleteState = Readonly<typeof initialState>

export default (state: DeleteState = initialState, action): DeleteState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEND_CODE):
    case REQUEST(ACTION_TYPES.VERIFY_CODE):
    case REQUEST(ACTION_TYPES.COMPLETE_CHANGE):
      return {
        ...state,
        loading: true
      }
    case SUCCESS(ACTION_TYPES.SEND_CODE): {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentStep: state.currentStep + 1
      }
    }
    case ACTION_TYPES.RESET_STATE: {
      return {
        ...initialState
      }
    }
    case SUCCESS(ACTION_TYPES.COMPLETE_CHANGE): {
      return {
        ...initialState,
        currentStep: state.currentStep + 1
      }
    }
    case SUCCESS(ACTION_TYPES.VERIFY_CODE): {
      return {
        ...state,
        loading: false,
        errorMessage: null
      }
    }
    case FAILURE(ACTION_TYPES.COMPLETE_CHANGE): {
      return {
        ...state,
        loading: false,
        errorMessage:
          action.payload !== undefined && !stringIsEmpty(action.payload.response)
            ? action.payload.response.data.message
            : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    case FAILURE(ACTION_TYPES.SEND_CODE): {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload !== undefined ? messages.CODE_SENDING_ERROR : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    case FAILURE(ACTION_TYPES.VERIFY_CODE): {
      return {
        ...state,
        loading: false,
        errorMessage:
          action.payload !== undefined ? messages.CODE_VERIFICATION_ERROR : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    case ACTION_TYPES.PREV_STEP: {
      return {
        ...state,
        currentStep: state.currentStep > 0 ? state.currentStep - 1 : state.currentStep
      }
    }
    case ACTION_TYPES.RESET_ERROR:
      return {
        ...state,
        errorMessage: null as string
      }
    case ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        errorMessage: action.payload.error
      }
    default: {
      return state
    }
  }
}

export const sendVerificationCode = () => ({
  type: ACTION_TYPES.SEND_CODE,
  payload: axios.post('api/user/sendSmsCode')
})

export const checkVerificationCode = (codeEntered: string, onSuccess: Function, onError: Function) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.VERIFY_CODE,
    payload: axios.get(`api/user/verifycode/${codeEntered}`)
  }).then(
    () => {
      onSuccess()
    },
    (error) => {
      handleBackendError(error.response, error.response.status, 403, onError)
    }
  )
}

export const completeDeletion = (onSuccess: Function) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.COMPLETE_CHANGE,
    payload: axios.delete('api/user/deleteAccount')
  }).then(
    () => {
      onSuccess()
    },
    () => {}
  )
}

export const resetErrorMessage = () => ({
  type: ACTION_TYPES.RESET_ERROR
})

export const setErrorMessage = (error: string) => ({
  type: ACTION_TYPES.SET_ERROR,
  payload: {
    error: error
  }
})

export const resetState = () => ({
  type: ACTION_TYPES.RESET_STATE
})

export const previousStep = () => ({
  type: ACTION_TYPES.PREV_STEP
})
