import React from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Button, Typography, CircularProgress } from '@material-ui/core'
import { Col, Container, Row } from 'reactstrap'

export interface IPasswordchangeAuthenticate {
  updateOldPassword: any
  checkPassword: any
  resetError: any
  oldPassword: string
  errorDescription: string
  loading: boolean
  previousFunc: any
}

export default class PasswordchangeAuthenticate extends React.Component<IPasswordchangeAuthenticate> {
  componentDidMount() {
    this.props.resetError()
  }

  render() {
    return (
      <ValidatorForm onSubmit={this.props.checkPassword}>
        <Container fluid className="p-0 email-reset-inner-box">
          <Row noGutters>
            <Col>
              <Typography className="regular-line-height text-margin">
                Bitte geben Sie Ihr altes Passwort ein
              </Typography>
            </Col>
          </Row>
          <Row noGutters className="text-margin">
            <Col>
              <TextValidator
                className="full-width"
                type="password"
                label="Passwort"
                name="password"
                validators={['required']}
                onChange={this.props.updateOldPassword}
                errorMessages={['Bitte geben Sie Ihr Passwort ein']}
                value={this.props.oldPassword}></TextValidator>
            </Col>
          </Row>
          {this.props.errorDescription && (
            <Row noGutters>
              <Col>
                <Typography className="validationFailed regular-line-height text-margin" variant="body2">
                  {this.props.errorDescription}
                </Typography>
              </Col>
            </Row>
          )}
          <Row noGutters>
            <Col>
              {(this.props.loading && <CircularProgress></CircularProgress>) || (
                <>
                  <Button
                    className="square-button no-uppercase mr-2"
                    onClick={this.props.previousFunc}
                    variant="contained"
                    color="primary">
                    Abbrechen
                  </Button>
                  <Button
                    className="square-button red-border no-uppercase"
                    variant="contained"
                    color="secondary"
                    type="submit">
                    Weiter
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </ValidatorForm>
    )
  }
}
