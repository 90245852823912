import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { Container, Row } from 'reactstrap'
import { currencyFormat, dateFormat } from 'app/shared/utils/common'
import { fourColumnRowElement } from '../common/component.util'

export function ContractObject(props: any) {
  const showExpanded = props.anchor !== null && props.anchor === 'object'
  const [expanded, setExpanded] = React.useState(showExpanded)

  const handleChange = () => () => {
    setExpanded(!expanded)
  }

  const objectLicenseNumber = fourColumnRowElement(
    'Kennzeichen',
    props.contract.objectLicenseNumber,
    null,
    'objectLicenseNumber'
  )
  const objectChassisNumber = fourColumnRowElement(
    'Fahrgestell-Nr.',
    props.contract.objectChassisNumber,
    {
      labelmd: 2,
      labelxs: 5,
      valuemd: 4,
      valuexs: 7
    },
    'objectChassisNumber'
  )
  // this is only here to avoid an error in dateFormat. No idea how this is related but it works
  currencyFormat(0, props.locale)

  const objectFirstRegistrationDate = fourColumnRowElement(
    'Erstzulassungsdatum',
    dateFormat(props.contract.objectFirstRegistrationDate, props.locale),
    null,
    'objectFirstRegistrationDate'
  )
  const objectRegistrationDate = fourColumnRowElement(
    'Zulassungsdatum',
    dateFormat(props.contract.objectRegistrationDate, props.locale),
    null,
    'objectRegistrationDate'
  )

  return (
    <Accordion
      id="object"
      className="container-with-border content-container"
      expanded={expanded}
      onChange={handleChange()}>
      <AccordionSummary expandIcon={<RedTriangle medium />}>
        <Typography className="bold">Objektdaten</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container fluid className="p-0">
          <Row noGutters className="fourColumnRow">
            {objectLicenseNumber}
            {objectFirstRegistrationDate}
            {objectChassisNumber}
            {objectRegistrationDate}
          </Row>
        </Container>
      </AccordionDetails>
    </Accordion>
  )
}
