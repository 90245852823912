import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Typography, Divider } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'
import { Col, Row } from 'reactstrap'

export class RegistrationFooter extends React.Component<RouteComponentProps> {
  render() {
    return (
      <div className="footer">
        <Divider />
        <Row>
          <Col xs="8">
            <Link to="/support">
              <Typography className="grey" variant="body1">
                Technischer Support
              </Typography>
            </Link>
          </Col>
          <Col xs="4">
            <Link to="/faq">
              <Typography className="grey" variant="body1">
                FAQ
              </Typography>
            </Link>
          </Col>
        </Row>
        <div className="inline mt-2 mb-2">
          <Typography variant="body1">
            <Link className="grey" to="/impressum">
              Impressum
            </Link>
          </Typography>
          <Typography variant="body1">
            <Link className="grey" to="/legal">
              Rechtliches
            </Link>
          </Typography>
          <Typography variant="body1">
            <Link className="grey" to="/dataSecurity">
              Datenschutz
            </Link>
          </Typography>
        </div>
      </div>
    )
  }
}

export default withRouter(RegistrationFooter)
