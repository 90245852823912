import React from 'react'
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { UploadContainer } from 'app/components/upload/upload.container'
import { IUploadFile } from 'app/components/upload/upload'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSupportRequestWithFiles, createSupportRequestMessage } from 'app/components/portal/contact/contact.reducer'
import { IRootState } from 'app/shared/reducers'

export interface IContactInternalFormProps extends StateProps, DispatchProps {}

export interface IContactInternalFormState {
  messageText: string
  contactReason: string
  contactReasons: string[]
  hasError: boolean
  errorText: string
  success: boolean
}

export class ContactInternalForm extends React.Component<IContactInternalFormProps & RouteComponentProps, IContactInternalFormState> {
  constructor(props) {
    super(props)
    this.state = {
      messageText: '',
      contactReason: 'none',
      contactReasons: this.props.contactReasons,
      hasError: false,
      errorText: '',
      success: false
    }
  }

  sendContactRequest = (files: IUploadFile[]) => {
    if (this.state.contactReason === 'none') {
      this.setState({ ...this.state, hasError: true, errorText: 'Bitte wählen Sie einen Kontaktgrund aus!' })
    } else {
      const formData = new FormData()
      if (files.length === 0) {
        this.props.createSupportRequestMessage(this.state.contactReason, this.state.messageText, this.setFeedbackAfterSending, this.setErrorAfterSending)
      } else {
        files.forEach((file) => {
          formData.append('file', file.content)
        })
        formData.append('contactReason', this.state.contactReason)
        formData.append('message', this.state.messageText)
        this.props.createSupportRequestWithFiles(formData, this.setFeedbackAfterSending, this.setErrorAfterSending)
      }
    }
  }

  setFeedbackAfterSending = () => {
    this.setState({ ...this.state, success: true })
  }

  setErrorAfterSending = () => {
    this.setState({ ...this.state, hasError: true, errorText: this.props.error })
  }

  selectContactReason = (e) => {
    this.setState({ ...this.state, contactReason: e.target.value, hasError: false, errorText: '' })
  }

  updateMessageText = (e) => {
    this.setState({ ...this.state, messageText: e.target.value, hasError: false, errorText: '' })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const validFiles = [
      'image/jpeg',
      'image/png',
      'application/pdf'
    ]
    if (this.props.isLoading) {
      return (
        <>
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <div className="contentContainer mt-4 mb-5">
                  <Typography variant="h3" className="section-heading">
                    Kontaktformular
                  </Typography>
                  <div className="content-container faq-container container-with-border">
                    <Container fluid>
                      <Row noGutters>
                        <Col xs="12">
                          <div className="text-center">
                            <CircularProgress></CircularProgress>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return (
        <>
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <div className="contentContainer mt-4 mb-5">
                  <Typography variant="h3" className="section-heading">
                    Kontaktformular
                  </Typography>
                  <div className="content-container faq-container container-with-border">
                    <Container fluid>
                      <Form>
                        {this.state.success &&
                        <Row noGutters>
                          <Col xs="12">
                            <Typography>Ihre Kontaktanfrage wurde erfolgreich versendet. Sie erhalten in Kürze eine E-Mail zur Bestätigung.</Typography>
                          </Col>
                          <Col xs="12">
                            <div className="align-center d-block">
                              <Button
                                className="square-button no-uppercase mr-2"
                                variant="contained"
                                color="primary"
                                onClick={this.goBack}>
                                Zurück
                              </Button>
                            </div>
                          </Col>
                        </Row>}
                        {!this.state.success &&
                        <Row noGutters>
                          <Col xs="12">
                            <Typography><i className="fas fa-info-circle"></i> Häufig gestellte Fragen finden Sie <Link className="underline" to="/portal/faq">hier</Link></Typography>
                          </Col>
                          {this.state.hasError && <Col xs="12"><Typography className="red" variant="body2">{this.state.errorText}</Typography></Col>}
                          <Col xs="12" md="3">
                            <Typography>Kontaktgrund</Typography>
                          </Col>
                          <Col xs="12" md="9">
                            <FormGroup>
                              <Input defaultValue='none' type="select" name="select" onChange={this.selectContactReason}>
                                <option value='none' disabled={true} selected={true} defaultValue='none'>Auswahl: Kontaktgrund</option>
                                {this.state.contactReasons.map((option) =>
                                  <option key={option} value={option}>{option}</option>
                                )}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3">
                            <Typography>Nachricht</Typography>
                          </Col>
                          <Col xs="12" md="9">
                            <FormGroup>
                              <Input maxLength={5000} type="textarea" name="text" onChange={this.updateMessageText} style={{ height: 200 }}/>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3">
                            <Typography>Dateianlage(n)</Typography>
                          </Col>
                          <Col xs="12" md="9" className="text-margin">
                            <UploadContainer
                              sendUploadedFiles={this.sendContactRequest}
                              customButton={true}
                              customButtonText='Absenden'
                              cancel={this.goBack}
                              validFiles={validFiles}
                              validFileDescription='JPEG-, PNG- oder PDF-'
                              maxFileCount={3}
                              minFileCount={0}
                              initialUploadDisabled={false}/>
                          </Col>
                        </Row>}
                      </Form>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )
    }
  }
}

const mapStateToProps = ({ contact }: IRootState) => ({
  contactReasons: contact.contactReasons,
  error: contact.errorMessage,
  isLoading: contact.loading
})

const mapDispatchToProps = {
  createSupportRequestWithFiles,
  createSupportRequestMessage
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(ContactInternalForm)
