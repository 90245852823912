import IUser from 'app/model/user.model'

export function stringIsEmpty(val: any) {
  if (isUndefined(val)) {
    return true
  }
  return typeof val === 'string' && (val === '' || val.match(/^ *$/) !== null)
}

export function isUndefined(val: any) {
  return val === undefined || val === null
}

export function setIsEmpty(val: Set<any>) {
  if (isUndefined(val)) {
    return true
  }
  return val.size === 0
}

export function arrayIsEmpty(val: any[]) {
  if (isUndefined(val)) {
    return true
  }
  return val.length === 0
}

export function handleBackendError(response: string, status: number, handledStatus: number, onError: Function) {
  if (response && status === handledStatus) {
    onError()
  }
}

export function handleBackendErrorIfNot(
  response: string,
  status: number,
  unhandledStatus: number[],
  onError: Function
) {
  if (response && !unhandledStatus.includes(status)) {
    onError()
  }
}

export function valueOrEmptyString(val: any) {
  if (stringIsEmpty(val)) {
    return ''
  } else {
    return val
  }
}

export function currencyFormat(val: any, locale: string): string {
  if (typeof val === 'string') {
    val = Number(val)
  }
  if (typeof val === 'number') {
    return val.toLocaleString(locale, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  } else {
    return val
  }
}

export function percentFormat(val: any, locale: string): string {
  if (typeof val === 'string') {
    val = Number(val)
  }
  if (typeof val === 'number') {
    const number = Number(val) / 100
    return number.toLocaleString(locale, {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  } else {
    return val
  }
}

export function dateFormat(val: any, locale: string): string {
  if (isUndefined(val)) {
    return 'nicht verfügbar'
  }
  if (isUndefined(locale)) {
    return 'nicht verfügbar'
  }
  if (!(val instanceof Date)) {
    val = new Date(val)
  }

  if (typeof val === 'object' && val instanceof Date) {
    return val.toLocaleDateString(locale, { day: '2-digit', month: '2-digit', year: 'numeric' })
  } else {
    return val
  }
}

export function getBikeOrCarBackgroundClassName(user: IUser) {
  if (isUndefined(user.type)) {
    return { className: 'background-container-car' }
  }
  if (user.type.toLowerCase() === 'bike') {
    return { className: 'background-container-bike' }
  } else return { className: 'background-container-car' }
}

export function getBikeOrCarStartpageClassName(user: IUser) {
  if (isUndefined(user.type)) {
    return { className: 'startpage-overlay-car' }
  }
  if (user.type.toLowerCase() === 'bike') {
    return { className: 'startpage-overlay-bike' }
  } else return { className: 'startpage-overlay-car' }
}

export function getMinutesAndSecondsFromInputSeconds(input: number) {
  const minutes = Math.floor(input / 60)
  const seconds = String(input - minutes * 60)
  return minutes + ':' + seconds.padStart(2, '0')
}
