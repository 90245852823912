import React from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { RouteComponentProps, Redirect } from 'react-router'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'
import { getUserByKey, setPassword, sendVerificationCodeForUser } from '../password.reducer'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { getEmailFromUser } from 'app/shared/utils/user.util'
import { stringIsEmpty } from 'app/shared/utils/common'
import PasswordChangeForm from '../password-change-form'
import { messages } from 'app/config/constants'

export interface IPasswordResetKey extends StateProps, DispatchProps {}

type PasswordResetKeyParams = {
  userKey: string
}

export class PasswordResetKeyForm extends React.Component<
  IPasswordResetKey & RouteComponentProps<PasswordResetKeyParams>
> {
  userKey: string

  componentDidMount() {
    this.userKey = this.props.match.params.userKey
    if (stringIsEmpty(this.props.user.email)) {
      this.getUser()
    }
  }

  getUser = () => {
    this.props.getUserByKey(this.userKey)
  }

  handleValidSubmit = (password, pwRepeat) => {
    this.props.setPassword(password, pwRepeat)
    this.props.sendVerificationCodeForUser(this.props.user.email, false, this.nextStep)
  }

  nextStep = () => {
    this.props.history.push('/passwordreset/verify')
  }

  render() {
    if (this.props.isLoading) {
      return (
        <div className="paper inner-form full-width">
          <Typography variant="h2" color="primary">
            Passwort
          </Typography>
          <Typography variant="body1" id="no-line-height">
            Passwort neu setzen
          </Typography>
          <div className="margin2" />
          <CircularProgress />
        </div>
      )
    }
    if (!stringIsEmpty(this.props.errorMessage) && this.props.errorMessage !== messages.CODE_SENDING_ERROR) {
      return <Redirect to="/passwordreset/error" />
    } else {
      const props = {
        nextButtonText: 'Passwort speichern',
        password: this.props.password,
        pwRepeat: this.props.pwRepeat,
        handleValidSubmit: this.handleValidSubmit,
        fullWidthButton: true,
        type: this.props.user.type
      }
      return (
        <div className="paper inner-form full-width">
          <Typography variant="h2" color="primary">
            Passwort
          </Typography>
          <Typography variant="body1" className="no-line-height">
            Passwort neu setzen
          </Typography>
          <div className="margin" />
          <Typography variant="body2" className="no-line-height" display="inline">
            {getEmailFromUser(this.props.user)}
          </Typography>
          <div className="margin1" />
          <PasswordChangeForm {...props} />
        </div>
      )
    }
  }
}

const mapStateToProps = ({ password }: IRootState) => ({
  isLoading: password.loading,
  errorMessage: password.errorMessage,
  user: password.user,
  password: password.password,
  pwRepeat: password.pwRepeat
})

const mapDispatchToProps = {
  getUserByKey,
  setPassword,
  sendVerificationCodeForUser
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(PasswordResetKeyForm)
