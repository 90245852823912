import INavPoint from 'app/model/navigation.model'
import {
  navPoints,
  requestNavPoint,
  contractNavPoint,
  messages,
  BENEFITS_ANCHOR_FOR_FILTERING
} from 'app/config/constants'
import IRequest from 'app/model/request.model'
import IContract from 'app/model/contract.model'
import { arrayIsEmpty, isUndefined, stringIsEmpty } from './common'

export const createNavigationTree = (requests: IRequest[], contracts: IContract[]): INavPoint[] => {
  const navigationTree: INavPoint[] = []

  if (!arrayIsEmpty(requests)) {
    navigationTree.push(createRequestsNavigation(requests))
  }
  if (!arrayIsEmpty(contracts)) {
    navigationTree.push(createContractsNavigation(contracts))
  }

  navPoints.forEach((navPoint) => {
    navigationTree.push({ ...navPoint })
  })

  return navigationTree
}

export const extendSubNavLinksWithId = (subNav: INavPoint[], id) => {
  const result: INavPoint[] = []
  subNav.forEach((entry) => {
    result.push({ name: entry.name, link: entry.link + '/' + id, anchor: entry.anchor })
  })
  return result
}

export const createRequestsNavigation = (requests) => {
  const navPoint: INavPoint = {
    name: requestNavPoint.name,
    link: requestNavPoint.link,
    selectLink: null,
    showSelectLink: false
  }

  if (isUndefined(requests)) {
    return undefined
  }

  if (requests.length > 1) {
    // if there is more than one request to show then generate the subnav based on the objects in the requests
    const subnav: INavPoint[] = []
    requests.forEach((request) => {
      subnav.push({
        name: request.objectManufacturer + ' ' + request.objectModellName,
        link: requestNavPoint.link + '/' + request.id,
        subnav: filterIfItemHasNoBenefits(extendSubNavLinksWithId(requestNavPoint.subnav, request.id), request)
      })
    })
    navPoint.subnav = subnav
    navPoint.showSelectLink = true
    navPoint.selectLink = requestNavPoint.selectLink
  } else {
    const request = requests[0]
    // if there is only one request use the regular static subnav
    navPoint.subnav = filterIfItemHasNoBenefits(requestNavPoint.subnav, request)
    navPoint.showSelectLink = false
  }

  return navPoint
}

export const createContractsNavigation = (contracts) => {
  const navPoint: INavPoint = {
    name: contractNavPoint.name,
    link: contractNavPoint.link,
    selectLink: null,
    showSelectLink: false
  }

  if (isUndefined(contracts)) {
    return undefined
  }

  if (contracts.length > 1) {
    // if there is more than one contract to show then generate the subnav based on the objects in the contracts
    const subnav: INavPoint[] = []
    contracts.forEach((contract) => {
      subnav.push({
        name: contract.objectManufacturer + ' ' + contract.objectModellName,
        link: contractNavPoint.link + '/' + contract.id,
        subnav: extendSubNavLinksWithId(contractNavPoint.subnav, contract.id)
      })
    })
    navPoint.subnav = subnav
    navPoint.showSelectLink = true
    navPoint.selectLink = contractNavPoint.selectLink
  } else {
    // if there is only one contract use the regular static subnav
    navPoint.subnav = contractNavPoint.subnav
    navPoint.showSelectLink = false
  }

  return navPoint
}

const filterIfItemHasNoBenefits = (subnav: INavPoint[], request: IRequest) => {
  if (hasAnyBenefit(request)) {
    return subnav
  }
  const resultSubNav = []
  subnav.forEach((row) => {
    if (row.anchor !== BENEFITS_ANCHOR_FOR_FILTERING) {
      resultSubNav.push(row)
    }
  })
  return resultSubNav
}

const hasAnyBenefit = (request: IRequest) => {
  return request.residualDebtInsurace || request.mobilityPackage || request.extendedWarranty || request.gapWarranty
}

export const getSubnavigationPointByPathname = (navigationTree: INavPoint[], pathName: string) => {
  let result
  navigationTree.forEach((navPoint) => {
    if (!arrayIsEmpty(navPoint.subnav)) {
      if (navPoint.linkMatchStartsWith && pathName.startsWith(navPoint.link)) {
        result = navPoint.subnav
      } else {
        const recursion = getSubnavigationPointByPathname(navPoint.subnav, pathName)
        if (!isUndefined(recursion)) {
          result = recursion
        }
      }
    }
    if (navPoint.selectLink === pathName) {
      result = createOverviewSubnavByNavPoint(navPoint)
    }
    if (navPoint.link === pathName && !arrayIsEmpty(navPoint.subnav)) {
      result = navPoint.subnav
    }
  })
  return result
}

const createOverviewSubnavByNavPoint = (navPoint: INavPoint) => {
  return [
    {
      name: messages.NAVIGATION_OVERVIEW,
      link: navPoint.selectLink,
      anchor: '#select'
    }
  ]
}

export const getDestinationFromNavPoint = (navPoint: INavPoint) => {
  let destination = navPoint.showSelectLink ? navPoint.selectLink : navPoint.link
  if (!stringIsEmpty(navPoint.anchor)) {
    destination += navPoint.anchor
  }
  return destination
}
