import React from 'react'
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { Container, Row, Col } from 'reactstrap'

export function ContractDealer(props: any) {
  const showExpanded = props.anchor !== null && props.anchor === 'dealer'
  const [expanded, setExpanded] = React.useState(showExpanded)

  const handleChange = () => () => {
    setExpanded(!expanded)
  }

  return (
    <Accordion
      id="dealer"
      className="container-with-border content-container"
      expanded={expanded}
      onChange={handleChange()}>
      <AccordionSummary expandIcon={<RedTriangle medium />}>
        <Typography className="bold">Ihr einreichender Händler</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col xs="6">
              <Typography>{props.contract.dealer.lastName}</Typography>
            </Col>
            <Col xs="6" className="text-right">
              <Typography className="bold">
                {props.contract.dealer.zipCode} {props.contract.dealer.city}
              </Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs="6">
              <Typography>Telefonnummer</Typography>
            </Col>
            <Col xs="6" className="text-right">
              <a href={'tel:' + props.contract.dealer.phoneParsed} className="link-no-decoration">
                <Typography className="bold">{props.contract.dealer.phone}</Typography>
              </a>
            </Col>
          </Row>
        </Container>
      </AccordionDetails>
    </Accordion>
  )
}
