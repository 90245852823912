import { combineReducers } from 'redux'
import registration, { RegistrationState } from './registration.reducer'
import authentication, { AuthenticationState } from './authentication.reducer'
import data, { DataState } from './data.reducer'
import upload, { UploadState } from './upload.reducer'
import inbox, { InboxState } from 'app/components/portal/inbox/inbox.reducer'
import emailreset, { EmailResetState } from './email-reset.reducer'
import password, { PasswordState } from 'app/components/password/password.reducer'
import deleteaccount, { DeleteState } from 'app/components/portal/account/delete/delete.account.reducer'
import datachange, { DataChangeState } from './data-change.reducer'
import contact, { ContactState } from 'app/components/portal/contact/contact.reducer'

export interface IRootState {
  readonly registration: RegistrationState
  readonly authentication: AuthenticationState
  readonly data: DataState
  readonly upload: UploadState
  readonly inbox: InboxState
  readonly emailreset: EmailResetState
  readonly password: PasswordState
  readonly deleteaccount: DeleteState
  readonly datachange: DataChangeState
  readonly contact: ContactState
}

const rootReducer = combineReducers<IRootState>({
  registration,
  authentication,
  data,
  upload,
  inbox,
  emailreset,
  password,
  deleteaccount,
  datachange,
  contact
})

export default rootReducer
