import '../registration/registration.css'

import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'
import { Paper, Grid } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'
import { IRootState } from 'app/shared/reducers'
import logo from 'resources/images/honda_financial_services_logo.png'
import RegistrationFooter from 'app/components/legal/registrationFooter'
import LoginMailForm from './login.mail'
import LoginPasswordForm from './login.password'

export interface LoginRouterProps extends StateProps, DispatchProps {}

export class LoginRouter extends React.Component<LoginRouterProps & RouteComponentProps> {
  render() {
    return (
      <>
        <Grid container className="root">
          <div className="background-container-car">
            <Grid className="registrationBox" component={Paper} square>
              <div className="paper inner-form">
                <img className="form-logo" src={logo} alt="Honda Bank Logo" />
                <Switch>
                  <Route path="/login/email" component={LoginMailForm} />
                  <Route path="/login/password" component={LoginPasswordForm} />
                  <Route path="/login" component={LoginMailForm} />
                </Switch>
                <RegistrationFooter />
              </div>
            </Grid>
          </div>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  loading: authentication.loading
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(LoginRouter)
