import React from 'react'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { Row, Col } from 'reactstrap'
import { valueOrEmptyString, stringIsEmpty, isUndefined } from 'app/shared/utils/common'
import BankAccountImage from 'resources/images/bankaccount.png'

export function ContractBankAccountDisplay(props: any) {
  const preventDefault = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Row noGutters>
      <Col>
        <div>
          <TextField
            id="bankAccountOwner"
            className="profileInputField"
            label="Kontoinhaber"
            value={
              isUndefined(props.contract.bankAccount) || stringIsEmpty(props.contract.bankAccount.accountHolder) || !props.contract.bankAccount.showAccount ||
              props.contract.paymentMethodId !== 2
                ? ' '
                : props.contract.bankAccount.accountHolder
            }
            disabled
          />
        </div>
        <div>
          <TextField
            id="bankName"
            className="profileInputField"
            label="Name der Bank"
            value={
              isUndefined(props.contract.bankAccount) || stringIsEmpty(props.contract.bankAccount.bankName) || !props.contract.bankAccount.showAccount ||
              props.contract.paymentMethodId !== 2
                ? ' '
                : props.contract.bankAccount.bankName
            }
            disabled
          />
        </div>
        <div>
          <TextField
            id="bic"
            className="profileInputField"
            label="BIC"
            value={
              isUndefined(props.contract.bankAccount) || stringIsEmpty(props.contract.bankAccount.bic) || !props.contract.bankAccount.showAccount ||
              props.contract.paymentMethodId !== 2
                ? ' '
                : props.contract.bankAccount.bic
            }
            disabled
          />
        </div>
        {((!isUndefined(props.contract.bankAccount) && props.contract.bankAccount.showAccount && props.contract.paymentMethodId === 2) ||
          isUndefined(props.contract.bankAccount)) && <div>
          <TextField
            id="name"
            className="profileInputField"
            label="IBAN"
            value={!isUndefined(props.contract.bankAccount) ? valueOrEmptyString(props.contract.bankAccount.iban) : ' '}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="edit name" onClick={props.startChange} onMouseDown={preventDefault}>
                    <i className="fas fa-pen small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>}
        {!isUndefined(props.contract.bankAccount) && (!props.contract.bankAccount.showAccount || props.contract.paymentMethodId !== 2) && <div>
          <TextField
            id="name"
            className="profileInputField"
            label="IBAN"
            value=" "
            disabled
          />
        </div>}
      </Col>
      <Col className="text-center profile-images">
        <img src={BankAccountImage} alt="Persönliche Daten"></img>
      </Col>
    </Row>
  )
}
