import './red-triangle.css'

import React from 'react'

import RedTriangleImage from 'resources/images/red_triangle.png'

export interface IRedTriangle {
  small?: boolean
  medium?: boolean
  large?: boolean
}

export class RedTriangle extends React.Component<IRedTriangle> {
  sizeClass: string

  constructor(props) {
    super(props)
    const sizeClassPrefix = 'red-triangle-'

    // default size is large if not set otherwise
    this.sizeClass = sizeClassPrefix + 'large'

    if (props.small) {
      this.sizeClass = sizeClassPrefix + 'small'
    }
    if (props.medium) {
      this.sizeClass = sizeClassPrefix + 'medium'
    }
    if (props.large) {
      this.sizeClass = sizeClassPrefix + 'large'
    }
  }

  render() {
    return <img className={this.sizeClass} src={RedTriangleImage} alt="red triangle"></img>
  }
}

export default RedTriangle
