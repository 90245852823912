import './divider-with-diamond.css'

import React from 'react'
import { Divider } from '@material-ui/core'
import { Row, Col, Container } from 'reactstrap'

export class DividerWithDiamond extends React.Component {
  render() {
    return (
      <Container fluid className="p-0">
        <Row noGutters className="infobox-divider-row">
          <Col className="infobox-divider">
            <Divider />
          </Col>
          <Col xs="1" className="horizontal-center">
            <div className="red-diamond" />
          </Col>
          <Col className="infobox-divider">
            <Divider />
          </Col>
        </Row>
      </Container>
    )
  }
}
