import './request.css'

import React from 'react'
import { Typography } from '@material-ui/core'
import { RouteComponentProps, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import { stringIsEmpty, isUndefined } from 'app/shared/utils/common'
import { IRootState } from 'app/shared/reducers'

import { RequestEntity } from './request.entity'
import { RequestOverview } from './request.overview'
import { RequestDetails } from './request.details'
import { RequestBenefits } from './request.benefits'
import { RequestDealer } from './request.dealer'
import Upload from 'app/components/upload/upload'

export interface IRequestState {
  expanded: {}
}

export interface IRequestProps extends StateProps, DispatchProps {}

export class Request extends React.Component<IRequestProps & RouteComponentProps, IRequestState> {
  constructor(props) {
    super(props)
    this.state = {
      expanded: {
        details: true,
        benefits: true,
        dealer: true
      }
    }
  }

  render() {
    const requests = this.props.requests

    if (requests === null) {
      return <></>
    }

    const anchor = !stringIsEmpty(this.props.location.hash) ? this.props.location.hash.split('#')[1] : null

    const getDisplayRequest = () => {
      if (requests && requests.length > 1) {
        const pathSplit = this.props.location.pathname.split('/')
        const requestId = Number(pathSplit[pathSplit.length - 1])
        return requests.filter((row) => row.id === requestId)[0]
      } else {
        return requests[0]
      }
    }

    const request = getDisplayRequest()
    if (isUndefined(request)) {
      if (!isUndefined(requests) && requests.length > 1) {
        return <Redirect to="/portal/requestselect" />
      } else if (!isUndefined(requests) && requests.length === 1) {
        return <Redirect to="/portal/request" />
      } else {
        return <Redirect to="/portal/" />
      }
    }
    const props = {
      request: { ...request },
      id: request.id,
      type: 'request',
      anchor,
      locale: this.props.user.locale
    }

    return (
      <div className="contentContainer">
        <Typography variant="h3" className="section-heading">
          Mein Antrag
        </Typography>
        <div key="request_1">
          <RequestEntity {...props} />
          <RequestOverview {...props} />
          <RequestDetails {...props} />
          <RequestBenefits {...props} />
          <RequestDealer {...props} />
          <Upload {...props} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ data, authentication }: IRootState) => ({
  requests: data.requests,
  user: authentication.user
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(Request)
