import axios from 'axios'
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util'
import { stringIsEmpty } from 'app/shared/utils/common'
import { messages } from 'app/config/constants'

export const ACTION_TYPES = {
  SEND_REQUEST: 'contact/SEND_REQUEST',
  FETCH_CONTACT_REASONS: 'contact/FETCH_CONTACT_REASONS',
  RESET: 'contact/RESET'
}

export const initialState = {
  loading: false,
  success: false,
  contactReasons: [] as string[],
  errorMessage: null as string
}

export type ContactState = Readonly<typeof initialState>

export default (state: ContactState = initialState, action): ContactState => {
  switch (action.type) {
    //   Für lokale Aktionen, wenn gegen Backend dann REQUEST/SUCCESS/FAILURE
    case REQUEST(ACTION_TYPES.SEND_REQUEST):
    case REQUEST(ACTION_TYPES.FETCH_CONTACT_REASONS):
      return {
        ...state,
        loading: true
      }
    case SUCCESS(ACTION_TYPES.SEND_REQUEST):
      return {
        ...state,
        loading: false,
        success: true,
        errorMessage: null
      }
    case SUCCESS(ACTION_TYPES.FETCH_CONTACT_REASONS):
      return {
        ...state,
        loading: false,
        success: true,
        contactReasons: action.payload.data,
        errorMessage: null
      }
    case FAILURE(ACTION_TYPES.SEND_REQUEST):
    case FAILURE(ACTION_TYPES.FETCH_CONTACT_REASONS):
      return {
        ...state,
        loading: false,
        success: false,
        errorMessage:
          action.payload !== undefined && !stringIsEmpty(action.payload.response)
            ? action.payload.response.data.message
            : messages.ERROR_SERVER_COMMUNICATION
      }
    case ACTION_TYPES.RESET: {
      return {
        ...initialState
      }
    }
    default: {
      return state
    }
  }
}

export const createSupportRequestWithFiles = (
  upload: FormData,
  onSuccess: Function,
  onError: Function
) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.SEND_REQUEST,
    payload: axios.post('api/contact/files', upload)
  }).then(
    () => onSuccess(),
    (error) => onError(error.response)
  )
}

export const createSupportRequestMessage = (
  contactReason: string,
  messageText: string,
  onSuccess: Function,
  onError: Function
) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.SEND_REQUEST,
    payload: axios.post('api/contact/message', { contactReason, messageText })
  }).then(
    () => onSuccess(),
    (error) => onError(error.response)
  )
}

export const createTechSupportRequestMessage = (
  contactReason: string,
  messageText: string,
  onSuccess: Function,
  onError: Function
) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.SEND_REQUEST,
    payload: axios.post('api/contact/supportrequest', { contactReason, messageText })
  }).then(
    () => onSuccess(),
    (error) => onError(error.response)
  )
}

export const createExternalTechSupportRequestMessage = (
  contactReason: string,
  messageText: string,
  lastName: string,
  firstName: string,
  transactionKey: string,
  phone: string,
  email: string,
  onSuccess: Function,
  onError: Function
) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.SEND_REQUEST,
    payload: axios.post('api/contact/externalsupportrequest', { contactReason, messageText, lastName, firstName, transactionKey, phone, email })
  }).then(
    () => onSuccess(),
    (error) => onError(error.response)
  )
}

export const fetchContactReasons = () => ({
  type: ACTION_TYPES.FETCH_CONTACT_REASONS,
  payload: axios.get('api/contact/reasons')
})

export const reset = () => ({
  type: ACTION_TYPES.RESET
})
