import React from 'react'
import FAQ from './faq'
import faqContentInternal from './faq.content.internal'

export class FAQInternal extends React.Component {
  render() {
    const props = {
      content: faqContentInternal
    }
    return <FAQ {...props} />
  }
}

export default FAQInternal
