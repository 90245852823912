import React from 'react'
import { Row, Col } from 'reactstrap'
import { DividerWithDiamond } from 'app/shared/components/divider-with-diamond'

export interface IInfoboxHeaderProps {
  imgSource: string
}

export class InfoboxHeader extends React.Component<IInfoboxHeaderProps> {
  render() {
    return (
      <>
        <Row>
          <Col sm="12" className="infobox-icon-container mt-3">
            <img className="infobox-icon" src={this.props.imgSource} alt="Infobox-Icon" />
          </Col>
        </Row>
        <DividerWithDiamond />
      </>
    )
  }
}

export default InfoboxHeader
