import './account.css'

import React from 'react'
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { Container, Row, Col } from 'reactstrap'
import EmailReset from './emailreset/email-reset'
import { stringIsEmpty } from 'app/shared/utils/common'
import { compose } from 'redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { IRootState } from 'app/shared/reducers'
import DeleteAccount from './delete/delete.account'
import PasswordChange from 'app/components/password/passwordchange/passwordchange'
import { Link } from 'react-router-dom'

export interface IAccountState {
  expanded: {}
}

export interface IAccountProps extends StateProps, DispatchProps {}

export class Account extends React.Component<IAccountProps & RouteComponentProps, IAccountState> {
  constructor(props) {
    super(props)
    this.state = {
      expanded: {
        username: true,
        password: true,
        account: false,
        faq: false
      }
    }
  }

  render() {
    const profile = this.props.profile
    const anchor = !stringIsEmpty(this.props.location.hash) ? this.props.location.hash.split('#')[1] : null

    const props = {
      profile: { ...profile },
      anchor
    }

    return (
      <div className="contentContainer">
        <Typography variant="h3" className="section-heading">
          Mein Konto
        </Typography>
        <EmailReset {...props} />
        <PasswordChange {...props} />
        <FAQ />
        <DeleteAccount {...props} />
      </div>
    )
  }
}

function FAQ() {
  return (
    <Accordion id="faq" className="container-with-border content-container" expanded={true}>
      <AccordionSummary expandIcon="">
        <Typography>FAQ</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col>
              <Typography>
                Wenn Sie Fragen zu dem Portal haben klicken Sie bitte{' '}
                <Link className="bold" to="/portal/faq">
                  hier.
                </Link>
              </Typography>
            </Col>
          </Row>
        </Container>
      </AccordionDetails>
    </Accordion>
  )
}

const mapStateToProps = ({ data }: IRootState) => ({
  profile: data.profile
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(Account)
