import React from 'react'
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { Container, Row, Col } from 'reactstrap'

import ResidualDebtInsuranceImage from 'resources/images/residual_debt_insurance.jpg'
import MobilityPackageImage from 'resources/images/mobility_package.jpg'
import ExtendedWarrantyImage from 'resources/images/extended_warranty.jpg'
import GapWarrantyImage from 'resources/images/gap_warranty.jpg'

export function RequestBenefits(props: any) {
  const showExpanded = props.anchor !== null && props.anchor === 'benefits'
  const [expanded, setExpanded] = React.useState(showExpanded)

  const handleChange = () => () => {
    setExpanded(!expanded)
  }

  // if the request has no benefits set at all then don't show the component
  if (
    !props.request.residualDebtInsurace &&
    !props.request.mobilityPackage &&
    !props.request.extendedWarranty &&
    !props.request.gapWarranty
  ) {
    return <></>
  }

  return (
    <Accordion
      id="benefits"
      className="container-with-border content-container"
      expanded={expanded}
      onChange={handleChange()}>
      <AccordionSummary expandIcon={<RedTriangle medium />}>
        <Typography className="bold">Zusatzleistungen</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container fluid className="p-0 benefits">
          <Row noGutters>
            <Col>
              <Typography className="bold">Ihr Finanzierungsbedarf enthält:</Typography>
            </Col>
          </Row>
          {props.request.residualDebtInsurace && (
            <Row noGutters>
              <Col xs="6" md="2" className="horizontal-center vertical-center">
                <img src={ResidualDebtInsuranceImage} alt="Restschuldversicherung" />
              </Col>
              <Col md="1" className="container-desktop-visible"></Col>
              <Col xs="6" md="9">
                <Typography>Restschuld&shy;versicherung</Typography>
              </Col>
            </Row>
          )}
          {props.request.mobilityPackage && (
            <Row noGutters>
              <Col xs="6" md="2" className="horizontal-center vertical-center">
                <img src={MobilityPackageImage} alt="4 Mobilitätspakete" />
              </Col>
              <Col md="1" className="container-desktop-visible"></Col>
              <Col xs="6" md="9">
                <Typography>4 Mobilitätspakete</Typography>
              </Col>
            </Row>
          )}
          {props.request.extendedWarranty && (
            <Row noGutters>
              <Col xs="6" md="2" className="horizontal-center vertical-center">
                <img src={ExtendedWarrantyImage} alt="24 Monate Anschlussgarantie" />
              </Col>
              <Col md="1" className="container-desktop-visible"></Col>
              <Col xs="6" md="9">
                <Typography>24 Monate Anschlussgarantie</Typography>
              </Col>
            </Row>
          )}
          {props.request.gapWarranty && (
            <Row noGutters>
              <Col xs="6" md="2" className="horizontal-center vertical-center">
                <img src={GapWarrantyImage} alt="GAP Versicherung" />
              </Col>
              <Col md="1" className="container-desktop-visible"></Col>
              <Col xs="6" md="9">
                <Typography>GAP Versicherung</Typography>
              </Col>
            </Row>
          )}
        </Container>
      </AccordionDetails>
    </Accordion>
  )
}
