import axios from 'axios'
import { FAILURE, REQUEST, SUCCESS } from './action-type.util'
import IUser from 'app/model/user.model'

import { messages } from 'app/config/constants'

import IKeyPassword from 'app/model/keyandpassword.model'
import { handleBackendError } from 'app/shared/utils/common'

export const ACTION_TYPES = {
  REGISTRATION_SET_PW: 'registration/SET_PASSWORD',
  VERIFY_MOBILEPHONE: 'registration/VERIFY_MOBILEPHONE',
  LOAD_USER: 'registration/LOAD_USER',
  VERIFY_CONFIRMATION_CODE: 'registration/VERIFY_CONFIRMATION_CODE',
  SEND_CONFIRMATION_CODE: 'registration/SEND_CONFIRMATION_CODE',
  CLEAR_ERROR_MESSAGE: 'registration/CLEAR_ERROR_MESSAGE',
  ACCEPT_LEGAL_TERMS: 'registration/ACCEPT_LEGAL_TERMS',
  COMPLETE_REGISTRATION: 'registration/COMPLETE_REGISTRATION',
  CLEAR_REGISTRATION: 'registration/CLEAR_REGISTRATION',
  VERIFY_REGISTRATION_DATA: 'registration/VERIFY_REGISTRATION_DATA'
}

export const initialState = {
  registrationKey: '',
  password: '',
  pwRepeat: '',
  user: {} as IUser,
  loading: false,
  errorMessage: null as string,
  phoneVerified: false as boolean,
  codeVerified: false as boolean,
  legalTermsAccepted: false as boolean
}

export type RegistrationState = Readonly<typeof initialState>

export default (state: RegistrationState = initialState, action): RegistrationState => {
  switch (action.type) {
    case ACTION_TYPES.REGISTRATION_SET_PW:
      return {
        ...state,
        password: action.payload.password,
        pwRepeat: action.payload.pwRepeat
      }
    case REQUEST(ACTION_TYPES.LOAD_USER):
    case REQUEST(ACTION_TYPES.VERIFY_MOBILEPHONE):
    case REQUEST(ACTION_TYPES.VERIFY_CONFIRMATION_CODE):
    case REQUEST(ACTION_TYPES.SEND_CONFIRMATION_CODE):
    case REQUEST(ACTION_TYPES.COMPLETE_REGISTRATION):
    case REQUEST(ACTION_TYPES.VERIFY_REGISTRATION_DATA):
      return {
        ...state,
        loading: true,
        errorMessage: null
      }
    case FAILURE(ACTION_TYPES.LOAD_USER):
    case FAILURE(ACTION_TYPES.COMPLETE_REGISTRATION):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message
      }
    case FAILURE(ACTION_TYPES.VERIFY_MOBILEPHONE):
      return {
        ...state,
        loading: false,
        errorMessage: messages.PHONE_VERIFICATION_ERROR,
        phoneVerified: false
      }
    case FAILURE(ACTION_TYPES.VERIFY_CONFIRMATION_CODE):
      return {
        ...state,
        loading: false,
        errorMessage: messages.CODE_VERIFICATION_ERROR
      }
    case FAILURE(ACTION_TYPES.SEND_CONFIRMATION_CODE):
      return {
        ...state,
        loading: false,
        errorMessage: messages.CODE_SENDING_ERROR
      }
    case FAILURE(ACTION_TYPES.VERIFY_REGISTRATION_DATA):
      return {
        ...state,
        loading: false
      }
    case SUCCESS(ACTION_TYPES.LOAD_USER):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        user: action.payload.data,
        registrationKey: action.meta.key
      }
    case SUCCESS(ACTION_TYPES.VERIFY_MOBILEPHONE):
      return {
        ...state,
        loading: false,
        phoneVerified: true
      }
    case SUCCESS(ACTION_TYPES.VERIFY_CONFIRMATION_CODE):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        codeVerified: true
      }
    case SUCCESS(ACTION_TYPES.SEND_CONFIRMATION_CODE):
    case SUCCESS(ACTION_TYPES.VERIFY_REGISTRATION_DATA):
      return {
        ...state,
        loading: false,
        errorMessage: null
      }
    case SUCCESS(ACTION_TYPES.COMPLETE_REGISTRATION):
      return {
        ...state
      }
    case ACTION_TYPES.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: ''
      }
    case ACTION_TYPES.ACCEPT_LEGAL_TERMS:
      return {
        ...state,
        legalTermsAccepted: true
      }
    case ACTION_TYPES.CLEAR_REGISTRATION:
      return {
        ...initialState
      }
    default: {
      return state
    }
  }
}

export const getUserByKey = (key: string) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.LOAD_USER,
    payload: axios.get<IUser>(`api/registration/user?registrationKey=${key}`),
    meta: {
      key
    }
  })
}

export const setRegistrationPassword = (pw: string, pwRep: string) => ({
  type: ACTION_TYPES.REGISTRATION_SET_PW,
  payload: {
    password: pw,
    pwRepeat: pwRep
  }
})

export const checkPhoneNumberForUser = (
  registrationKey: string,
  digitsEntered: string,
  onSuccess: Function,
  onBlocked: Function
) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.VERIFY_MOBILEPHONE,
    payload: axios.get(`api/registration/verifymobile/${registrationKey}/${digitsEntered}`)
  }).then(
    () => onSuccess(),
    (error) => handleBackendError(error.response, error.response.status, 401, onBlocked)
  )
}

export const checkConfirmationCodeForUser = (
  registrationKey: string,
  codeEntered: string,
  onSuccess: Function,
  onBlocked: Function
) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.VERIFY_CONFIRMATION_CODE,
    payload: axios.get(`api/registration/verifycode/${registrationKey}/${codeEntered}`)
  }).then(
    () => onSuccess(),
    (error) => handleBackendError(error.response, error.response.status, 401, onBlocked)
  )
}

export const sendConfirmationCode = (registrationKey: string, onSuccess: Function, onBlocked: Function) => (
  dispatch
) => {
  return dispatch({
    type: ACTION_TYPES.SEND_CONFIRMATION_CODE,
    payload: axios.post(`api/registration/sendcode/${registrationKey}`)
  }).then(
    () => onSuccess(),
    (error) => handleBackendError(error.response, error.response.status, 405, onBlocked)
  )
}

export const clearErrorMessage = () => ({
  type: ACTION_TYPES.CLEAR_ERROR_MESSAGE
})

export const acceptLegalTerms = () => ({
  type: ACTION_TYPES.ACCEPT_LEGAL_TERMS
})

export const completeRegistration = (keyAndPassword: IKeyPassword, onSuccess: Function) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.COMPLETE_REGISTRATION,
    payload: axios.post('api/registration/complete', keyAndPassword)
  }).then(() => onSuccess())
}

export const clearRegistration = () => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.CLEAR_REGISTRATION
  })
}

export const verifyRegistrationData = (registrationData: {}, onSuccess: Function, onError: Function) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.VERIFY_REGISTRATION_DATA,
    payload: axios.post('api/registration/verifyRegistrationData', registrationData)
  }).then(
    () => onSuccess(),
    () => onError()
  )
}
