import React from 'react'
import { Divider, Button, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { Container, Row, Col, NavLink } from 'reactstrap'

import logo from 'resources/images/honda_financial_services_logo.png'
import Footer from 'app/components/navigation/footer'

export class DataSecurity extends React.Component<RouteComponentProps> {
  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const footerProps = {
      isPublic: true
    }

    return (
      <>
        <Container fluid>
          <Row className="portal-header-bg">
            <Col className="portal-header">
              <NavLink to="/login">
                <div>
                  <img className="logo portal" src={logo} alt="Honda Logo" />
                </div>
              </NavLink>
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col>
              <Divider className="red-divider" />
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-0">
          <Row noGutters>
            <Col xs="12">
              <div className="content-container contentContainer-wide mt-4 mb-5">
                <Typography variant="h3" className="section-heading mt-5">
                Datenschutzhinweis
                </Typography>
                <div className="container-with-border ml-2 mr-2 mt-3">
                  <Container>
                    <Row>
                      <Col xs="12">
                        <Typography>
                          Honda verpflichtet sich dem Schutz Ihrer Daten und Ihrer Privatsphäre. Mit diesem Datenschutzhinweis
                          teilen wir Ihnen mit, wie die Honda Bank GmbH Ihre personenbezogenen Daten verarbeitet, wenn Sie das
                          Honda-Bank-Kundenportal besuchen oder die Honda Bank GmbH kontaktieren. Dieser Datenschutzhinweis
                          beschreibt auch Ihre Rechte in Bezug auf den Datenschutz, einschließlich des Rechts, der Verarbeitung
                          durch die Honda Bank GmbH zu widersprechen. Weitere Informationen über Ihre Rechte und wie Sie diese
                          ausüben können, erhalten Sie im Abschnitt 6.
                        </Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          Wer ist für die Datenverarbeitung verantwortlich und an wen kann ich mich wenden?
                        </Typography>
                        <Typography className="mt-1">Verantwortliche Stelle ist:</Typography>
                        <Typography className="ml-5">
                          Honda Bank GmbH
                          <br />
                          Hanauer Landstraße 222-226
                          <br />
                          60314 Frankfurt am Main
                        </Typography>
                        <Typography className="mt-1">
                          Sie erreichen unseren betrieblichen Datenschutzbeauftragten unter:
                        </Typography>
                        <Typography className="ml-5">
                          Honda Bank GmbH
                          <br />
                          Datenschutzbeauftragter
                          <br />
                          Hanauer Landstraße 222-226
                          <br />
                          60314 Frankfurt am Main <br />
                          Telefon-Nr.: 069 - 4898 - 0<br />
                          E-Mail-Adresse: <a href="mailto:HBG.Datenschutz@Honda-eu.com">HBG.Datenschutz@Honda-eu.com</a>
                        </Typography>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          2. Welche personenbezogenen Daten erheben wir?
                        </Typography>
                        <Typography className="text-margin">
                          Wenn Sie unser Honda-Bank-Kundenportal besuchen, verarbeiten wir technische Informationen über
                          Ihren Besuch und Ihren Zugriff auf die dort angebotenen Online-Inhalte: z. B. Ihre IP-Adresse,
                          Ihr Betriebssystem und der von Ihnen verwendete Browser (bei Besuchen unserer Webseite).
                        </Typography>
                        <Typography>
                          Wenn Sie sich für ein passwortgeschützten Benutzerkonto registrieren, verarbeiten wir jeweils die
                          von Ihnen eingegebenen Daten, um Ihnen Zugang zu unseren Portalen sowie zu deren Funktionen und
                          Services zu ermöglichen. Hierzu gehören z.B.
                        </Typography>
                        <ul className="disc">
                          <li>
                            Personalien und Kontaktinformationen: z. B. Ihren Namen, Ihre Adresse, Telefonnummer und E-Mail-Adresse.
                          </li>
                          <li>
                            Sonstige Daten, die Sie uns durch Ihre Mitteilung zur Verfügung stellen: z. B. Unterschriften, Fotos,
                            Meinungen, Vertragsinformationen sowie andere mit den Kategorien vergleichbare Daten, Fahrzeugdaten:
                            z. B. Fahrzeugidentifikationsnummer (FIN), Fahrzeugkennzeichen. Produktdaten: z. B. Ihre Produkt-ID,
                            Vertragsnummer, Datum und Ort des Vertragsschlusses, Finanzdaten: z. B. Ihre Bankverbindung und
                            Zahlungsinformationen.
                          </li>
                        </ul>
                        <Typography className="ml-5"></Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          3. Wofür verarbeiten wir Ihre Daten (Zweck der Verarbeitung) und auf welcher Rechtsgrundlage?
                        </Typography>
                        <Typography>
                          Wir erheben, nutzen und speichern Ihre personenbezogenen Daten für die nachstehend angegebenen Zwecke.
                        </Typography>
                        <div className="d-flex">
                          <div className="ml-3 mr-3">
                            <Typography>(a)</Typography>
                          </div>
                          <div>
                            <Typography>
                              Soweit erforderlich, um einen Vertrag zwischen Ihnen und Honda-Bank GmbH zu erfüllen. Beispiele :
                            </Typography>
                            <ul className="disc">
                              <li>
                                um Adressänderungen, Kommunikationsdatenänderung, Namensänderungen oder Bankverbindungsänderungen durchführen zu können.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="ml-3 mr-3">
                            <Typography>(b)</Typography>
                          </div>
                          <div>
                            <Typography>
                              Soweit erforderlich verarbeiten wir Ihre Daten zur Wahrung berechtigter Interessen von uns oder Dritten. Beispiele:
                            </Typography>
                            <ul className="disc">
                              <li>
                                um Ihnen die Honda-Bank-Online-Inhalte und damit zusammenhängende Dienste zu liefern, z.B. Finanzierungsangebote,
                              </li>
                              <li>
                                um die Honda-Bank-Online-Inhalte zu administrieren und zu verwalten.
                              </li>
                              <li>
                                um es uns zu ermöglichen, die Honda-Bank-Online-Inhalte und Honda-Bank-Produkte und -Dienstleistungen zu verbessern und zu optimieren.
                              </li>
                              <li>
                                um Ihre Anfragen zu beantworten und Ihren Bitten nachzukommen, z. B. Übersendung von Broschüren oder Aufzeigen des nächsten Honda-Händlers.
                              </li>
                              <li>
                                um Sie per E-Mail oder telefonisch (direkt oder über eine Honda-Konzerngesellschaft, einen autorisierten
                                Händler oder einen entsprechenden Partner oder Vertreter) zu informieren.
                              </li>
                              <li>
                                um Sie zu bitten, uns Ihre Meinung mitzuteilen oder an Umfragen über unsere Produkte und Dienstleistungen teilzunehmen
                              </li>
                              <li>
                                um zu sehen, welche Teile der Honda-Bank-Online-Inhalte am beliebtesten sind oder am meisten genutzt werden,
                                oder welche geändert oder verbessert werden müssen.
                              </li>
                              <li>
                                um auf Fragen und Beschwerden von Nutzern zu reagieren.
                              </li>
                              <li>
                                für unsere internen Aufzeichnungen.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="ml-3 mr-3">
                            <Typography>(c)</Typography>
                          </div>
                          <div>
                            <Typography>Soweit erforderlich, um gesetzliche Verpflichtungen zu erfüllen.</Typography>
                          </div>
                        </div>
                        <Typography variant="h5" className="mt-4 bold">
                          4. Wer bekommt meine Daten?
                        </Typography>
                        <div className="d-flex">
                          <div className="ml-3 mr-3">
                            <Typography>(a)</Typography>
                          </div>
                          <div>
                            <Typography>
                              Innerhalb der Bank erhalten diejenigen Stellen Zugriff auf Ihre Daten, die diese zur
                              Bearbeitung Ihres Anliegens, sowie zur Erfüllung unserer vertraglichen und gesetzlichen Pflichten brauchen.
                            </Typography>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="ml-3 mr-3">
                            <Typography>(b)</Typography>
                          </div>
                          <div>
                            <Typography>
                              Wenn Ihr Anliegen eine andere Honda-Konzerngesellschaft betrifft, nicht aber die Honda Bank GmbH, dann
                              leiten wir Ihre Daten an die zuständige Honda-Konzerngesellschaft weiter.
                            </Typography>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="ml-3 mr-3">
                            <Typography>(c)</Typography>
                          </div>
                          <div>
                            <Typography>
                              Ihre personenbezogenen Daten werden Gesellschaften mitgeteilt, die der Honda Bank GmbH vertraglich
                              Dienstleistungen erbringen, wie z.B. Webdienstleister, Provider- Dokumentenmanagement zu Treuhandservices,
                              IT-Hosting-Unternehmen.
                            </Typography>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="ml-3 mr-3">
                            <Typography>(d)</Typography>
                          </div>
                          <div>
                            <Typography className="text-margin">
                              Im Hinblick auf die Datenweitergabe an Empfänger außerhalb unserer Bank ist zunächst zu beachten,
                              dass wir als Bank zur Verschwiegenheit über alle kundenbezogenen Tatsachen und Wertungen verpflichtet sind,
                              von denen wir Kenntnis erlangen (Bankgeheimnis). Informationen über Sie dürfen wir nur weitergeben, wenn
                              gesetzliche Bestimmungen dies gebieten, Sie eingewilligt haben oder wir zur Erteilung einer Bankauskunft
                              befugt sind. Unter diesen Voraussetzungen können Empfänger personenbezogener Daten z.B. sein:
                            </Typography>
                            <Typography className="text-margin">
                              Öffentliche Stellen und Institutionen (z.B. Deutsche Bundesbank, Bundesanstalt für Finanzdienstleistungsaufsicht,
                              Europäische Bankenaufsichtsbehörde, Europäische Zentralbank, Finanzbehörden, Strafverfolgungsbehörden) bei
                              Vorliegen einer gesetzlichen oder behördlichen Verpflichtung.
                            </Typography>
                            <Typography className="text-margin">
                              Andere Kredit- und Finanzdienstleistungsinstitute oder vergleichbare Einrichtungen, an die wir zur Durchführung
                              der Geschäftsbeziehung mit Ihnen personenbezogene Daten übermitteln (je nach Vertrag z.B. Korrespondenzbanken, Auskunfteien, Gutachter)
                              andere Unternehmen innerhalb des Honda Konzerns zur Risikosteuerung aufgrund gesetzlicher und behördlicher Verpflichtung
                            </Typography>
                            <Typography>
                              Weitere Datenempfänger können diejenigen Stellen sein, für die Sie uns Ihre Einwilligung zur Datenübermittlung
                              erteilt haben bzw. für die Sie uns vom Bankgeheimnis gemäß Vereinbarung oder Einwilligung befreit haben.
                            </Typography>
                          </div>
                        </div>
                        <Typography variant="h5" className="mt-4 bold">
                          5. Werden Daten in ein Drittland oder an eine internationale Organisation übermittelt?
                        </Typography>
                        <Typography>
                          Eine Datenübermittlung an Stellen in Staaten außerhalb der Europäischen Union (sogenannte
                          Drittstaaten) findet statt, soweit:
                        </Typography>
                        <ul className="disc">
                          <li>es zur Ausführung Ihrer Aufträge erforderlich ist (z. B. Zahlungsaufträge),</li>
                          <li>
                            dies zur Bearbeitung Ihrer Anfragen erforderlich ist (jegliche Anfragen per E-Mail und
                            Kontaktformulare können auf cloudbasierten Server außerhalb des EEA gespeichert werden)
                          </li>
                          <li>es gesetzlich vorgeschrieben ist (z.B. steuerrechtliche Meldepflichten) oder</li>
                          <li>Sie uns Ihre Einwilligung erteilt haben.</li>
                        </ul>
                        <Typography>
                          Bei Einsatz von Dienstleistern in einem Drittland werden diese zusätzlich zu schriftlichen
                          Weisungen durch die Vereinbarung von der EU-Kommission erlassenen Standarddatenschutzklauseln
                          zur Einhaltung des Datenschutzniveaus in Europa verpflichtet.
                        </Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          6. Wie lange werden meine Daten gespeichert?
                        </Typography>
                        <Typography>
                          Wir verarbeiten und speichern Ihre personenbezogenen Daten, solange es für die Erfüllung unserer
                          vertraglichen und gesetzlichen Pflichten erforderlich ist. Dabei ist zu beachten, dass unsere
                          Geschäftsbeziehung ein Dauerschuldverhältnis ist, welches auf Jahre angelegt ist.
                        </Typography>
                        <Typography className="text-margin">
                          Sind die Daten für die Erfüllung vertraglicher oder gesetzlicher Pflichten nicht mehr erforderlich,
                          werden diese regelmäßig gelöscht, es sei denn, deren - befristete - Weiterverarbeitung ist erforderlich
                          zu folgenden Zwecken:
                        </Typography>
                        <ul className="disc">
                          <li>
                            <Typography>
                              Erfüllung handels- und steuerrechtlicher Aufbewahrungspflichten: Zu nennen sind das Handelsgesetzbuch (HGB),
                              die Abgabenordnung (AO), das Kreditwesengesetz (KWG) und das Geldwäschegesetz (GwG). Die dort vorgegebenen
                              Fristen zur Aufbewahrung bzw. Dokumentation betragen zwei bis zehn Jahre.
                            </Typography>
                          </li>
                          <li>
                            <Typography>
                              Erhaltung von Beweismitteln im Rahmen der gesetzlichen Verjährungsvorschriften. Nach den §§ 195ff. des
                              Bürgerlichen Gesetzbuches (BGB) können diese Verjährungsfristen bis zu 30 Jahre betragen, wobei die regelmäßige
                              Verjährungsfrist 3 Jahre beträgt.
                            </Typography>
                          </li>
                        </ul>
                        <Typography variant="h5" className="mt-4 bold">
                          7. Welche Datenschutzrechte habe ich?
                        </Typography>
                        <Typography className="text-margin">
                          Jede betroffene Person hat das Recht auf Auskunft nach Artikel15 DSGVO, das Recht auf Berichtigung nach Artikel 16 DSGVO,
                          das Recht auf Löschung nach Artikel 17 DSGVO, das Recht auf Einschränkung der Verarbeitung nach Artikel 18 DSGVO, das Recht
                          auf Widerspruch aus Artikel 21 DSGVO sowie das Recht auf Datenübertragbarkeit aus Artikel 20 DSGVO. Beim Auskunftsrecht
                          und beim Löschungsrecht gelten die Einschränkungen nach §§ 34 und 35 BDSG. Darüber hinaus besteht ein Beschwerderecht bei
                          einer zuständigen Datenschutzaufsichtsbehörde (Artikel 77 DSGVO i.V.m. § 19 BDSG).
                        </Typography>
                        <Typography>
                          Eine erteilte Einwilligung in die Verarbeitung personenbezogener Daten können Sie jederzeit uns gegenüber widerrufen.
                          Dies gilt auch für den Widerruf von Einwilligungserklärungen, die vor der Geltung der Datenschutzgrundverordnung, also vor
                          dem 25. Mai 2018, uns gegenüber erteilt worden sind. Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt.
                          Verarbeitungen, die vor dem Widerruf erfolgt sind, sind davon nicht betroffen.
                        </Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          8. Einsatz von Cookies
                        </Typography>
                        <Typography className="text-margin">
                          Wir setzen auf dieser Website Cookies ein. Cookies sind Textdateien, welche über einen Internet-browser auf einem Computersystem
                          abgelegt und gespeichert werden. Viele Cookies enthalten eine sogenannte Cookie-ID, eine eindeutige Kennung. Sie besteht aus einer
                          Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie
                          gespeichert wurde. Dies ermöglicht es den besuchten Websites, den individuellen Browser einer betroffenen Person von anderen
                          Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Dazu wird auch Ihre anonymisierte IP-Adresse, Angaben zu Ihrem
                          verwendeten Betriebssystem, Internetbrowser und weitere Gerätedetails wie verwendete Auflösung erhoben. Zweck dieser Wiedererkennung
                          ist es, den Nutzern dieser Website nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.
                        </Typography>
                        <Typography className="text-margin">
                          Auf den Webseiten des Kundenportals der Honda Bank GmbH kommen nur Cookies, die zur Funktion der Website erforderlich sind,
                          sogenannte „Sessions-Cookies“ zum Einsatz.
                        </Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          9. Links zu anderen Websites
                        </Typography>
                        <Typography>
                          Unsere Onlineauftritte enthalten von Zeit zu Zeit Links auf und von Websites unserer Partnernetzwerke, von Werbetreibenden
                          und verbundenen Unternehmen. Die Honda-Bank GmbH ist für den Inhalt verlinkter Websites nicht verantwortlich. Beim Besuch von
                          Websites Dritter obliegt es Ihrer Verantwortung, die für die jeweilige Website geltenden Datenschutzrichtlinien und
                          Nutzungsbedingungen zu lesen.
                        </Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          10. Sicherheit
                        </Typography>
                        <Typography className="text-margin">
                          Wir bedienen uns technischer und organisatorischer Sicherheitsmaßnahmen, um die vom Kunden zur Verfügung gestellten Daten von
                          zufälligen oder vorsätzlichen Manipulationen, Verlust, Zerstörung oder dem Zugriff unberechtigter Personen zu schützen.
                        </Typography>
                        <Typography>
                          Alle persönlichen Daten, die Sie auf unseren Webseiten angeben, werden verschlüsselt an uns übertragen. Anschließend werden Ihre
                          Daten in einer sicheren Betriebsumgebung gespeichert, die gegen Manipulation, Verlust, Zerstörung oder gegen den Zugriff
                          unberechtigter Personen geschützt ist. Die hierbei eingesetzten Sicherheitsmaßnahmen werden entsprechend der technologischen
                          Entwicklung fortlaufend verbessert.
                        </Typography>
                        <Typography variant="h5" className="mt-4 bold">
                          11. Aktualisierung dieses Datenschutzhinweises
                        </Typography>
                        <Typography>
                          Falls wir uns entscheiden, unsere Datenschutzrichtlinie zu ändern, stellen wir die Änderungen auf unsere Website, so dass Sie stets
                          wissen, welche Informationen wir erheben, wie wir sie nutzen und unter welchen Umständen wir sie gegebenenfalls offenlegen. Falls
                          wir uns zu einem beliebigen Zeitpunkt entscheiden, Ihre personenbezogenen Daten auf eine Weise zu nutzen, die von der zum Zeitpunkt
                          der Erhebung mitgeteilten Weise abweicht, informieren wir Sie per E-Mail (soweit wir durch die geltenden Datenschutzbestimmungen
                          verpflichtet sind, dies zu tun).
                        </Typography>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
            <Col xs="12">
              <div className="contentContainer mt-4 mb-5 text-center">
                <Button
                  className="square-button red-border"
                  type="submit"
                  onClick={this.goBack}
                  variant="contained"
                  color="secondary">
                  Zurück
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer {...footerProps} />
      </>
    )
  }
}

export default withRouter(DataSecurity)
