import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button
} from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { Container, Row, Col } from 'reactstrap'
import { IRootState } from 'app/shared/reducers'
import { connect } from 'react-redux'
import AddressDataDisplay from './addressdata.display'
import {
  reset,
  resetStep,
  nextStep,
  previousStep,
  setErrorMessage,
  resetErrorMessage,
  sendVerificationCode,
  checkVerificationCode,
  startAddressDataChange,
  completeAddressDataChange
} from 'app/shared/reducers/data-change.reducer'
import { UploadContainer } from 'app/components/upload/upload.container'
import { IUploadFile } from 'app/components/upload/upload'
import { valueOrEmptyString } from 'app/shared/utils/common'
import ValidationCodebox from 'app/components/codebox/validation.codebox'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { AddressDataInput } from './addressdata.input'
import AddressImage from 'resources/images/profile_address.png'
import IDImage from 'resources/images/identity_card.png'
import { DEFAULT_MAX_UPLOAD_FILE_COUNT, DEFAULT_MIN_UPLOAD_FILE_COUNT, DEFAULT_VALID_UPLOAD_FILES, DEFAULT_VALID_UPLOAD_FILE_DESCRIPTION } from 'app/config/constants'

export interface IAddressDataProps extends StateProps, DispatchProps {}

export interface IAddressDataState {
  expanded: boolean
  files: IUploadFile[]
  street: string
  streetNumber: string
  zipcode: string
  city: string
}

export class AddressData extends React.Component<IAddressDataProps & RouteComponentProps, IAddressDataState> {
  readonly NUMBEROFSTEPS = 3

  constructor(props) {
    super(props)
    this.state = {
      expanded: true,
      files: [],
      street: valueOrEmptyString(this.props.profile.street),
      streetNumber: valueOrEmptyString(this.props.profile.streetNumber),
      zipcode: valueOrEmptyString(this.props.profile.zipCode),
      city: valueOrEmptyString(this.props.profile.city)
    }
  }

  componentDidMount() {
    this.props.reset()
  }

  handleChange = () => () => {
    this.setState({ expanded: !this.state.expanded })
  }

  startChange = () => {
    this.props.startAddressDataChange()
  }

  getSteps() {
    return ['Dokument hochladen', 'Adresse aktualisieren', 'Bestätigungscode eingeben']
  }

  confirmUpload = (files: IUploadFile[]) => {
    this.setState({ files: files })
    this.props.nextStep()
  }

  handleValidInputSubmit = (street: string, streetNumber: string, zipcode: string, city: string) => {
    this.setState({ street, streetNumber, zipcode, city })
    this.props.sendVerificationCode()
  }

  handleValidCodeSubmit = (enteredCode: string) => {
    this.props.checkVerificationCode(enteredCode, this.completeDataChange, this.handleUserLocked)
  }

  handleUserLocked = () => {
    this.props.history.push('/locked')
  }

  completeDataChange = () => {
    const formData = new FormData()
    this.state.files.forEach((file) => {
      formData.append('file', file.content)
    })
    formData.append('street', this.state.street)
    formData.append('streetNumber', this.state.streetNumber)
    formData.append('zipcode', this.state.zipcode)
    formData.append('city', this.state.city)
    this.props.completeAddressDataChange(formData, this.NUMBEROFSTEPS)
  }

  reset = () => {
    this.setState({
      ...this.state,
      files: [],
      street: valueOrEmptyString(this.props.profile.street),
      streetNumber: valueOrEmptyString(this.props.profile.streetNumber),
      zipcode: valueOrEmptyString(this.props.profile.zipCode),
      city: valueOrEmptyString(this.props.profile.city)
    })
    this.props.reset()
  }

  getStepContent = (index: number) => {
    const inputProps = {
      street: this.props.profile.street,
      streetNumber: this.props.profile.streetNumber,
      zipCode: this.props.profile.zipCode,
      city: this.props.profile.city,
      loading: this.props.loading,
      handleValidSubmit: this.handleValidInputSubmit,
      errorMessage: this.props.errorMessage,
      previousFunc: this.props.previousStep
    }
    const codeboxProps = {
      onValidSubmit: this.handleValidCodeSubmit,
      onBack: null,
      resetError: this.props.resetErrorMessage,
      setError: this.props.setErrorMessage,
      hasBack: false,
      errorDescription: this.props.errorMessage,
      loading: this.props.loading,
      nextButtonText: 'Weiter',
      previousFunc: this.props.previousStep
    }
    switch (index) {
      case 0:
        return (
          <>
            <Container fluid className="p-0">
              <Row noGutters>
                <Col xs="12">
                  <Typography className="text-margin regular-line-height">
                    Für die Anschriftenänderung benötigen wir bitte eine amtliche Bescheinigung.
                  </Typography>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs="12" sm="6">
                  <Typography className="bold text-margin regular-line-height">
                    Bitte laden Sie Folgendes hoch:
                  </Typography>
                  <Typography className="mb-2 regular-line-height">
                    Kopie des Personalausweises (Vorder- und Rückseite) oder eine Kopie des Reisepasses mit
                    Meldebescheinigung
                  </Typography>
                </Col>
                <Col sm="6" className="text-center profile-images">
                  <img src={IDImage} alt="Personalausweis" className="smaller-picture-top"></img>
                </Col>
              </Row>
            </Container>
            <UploadContainer
              sendUploadedFiles={this.confirmUpload}
              customButton={true}
              customButtonText='Weiter'
              cancel={this.reset}
              validFiles={DEFAULT_VALID_UPLOAD_FILES}
              validFileDescription={DEFAULT_VALID_UPLOAD_FILE_DESCRIPTION}
              maxFileCount={DEFAULT_MAX_UPLOAD_FILE_COUNT}
              minFileCount={DEFAULT_MIN_UPLOAD_FILE_COUNT}
              initialUploadDisabled={true}/>
          </>
        )
      case 1:
        return <AddressDataInput {...inputProps} />
      case 2:
        return (
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <Typography variant="body2" className="regular-line-height text-margin">
                  Sie erhalten in wenigen Minuten Ihren Bestätigungscode per SMS. Bitte geben Sie diesen unten ein.
                </Typography>
              </Col>
              <Col xs="12">
                <Typography variant="body2" className="regular-line-height">
                  Eingabe Bestätigungscode:
                </Typography>
              </Col>
            </Row>
            <ValidationCodebox {...codeboxProps} />
          </Container>
        )
      default:
        return 'ERROR'
    }
  }

  render() {
    const props = {
      startChange: this.startChange,
      profile: this.props.profile
    }
    return (
      <Accordion
        id="address"
        className="container-with-border content-container"
        expanded={this.state.expanded}
        onChange={this.handleChange()}>
        <AccordionSummary expandIcon={<RedTriangle medium />}>
          <Typography>{(this.props.change && 'Adressänderung') || 'Adresse'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container fluid className="p-0">
            {!this.props.change && <AddressDataDisplay {...props} />}
            {this.props.change && this.props.currentStep < this.NUMBEROFSTEPS && (
              <Stepper activeStep={this.props.currentStep} orientation="vertical" className="email-reset">
                {this.getSteps().map((label, index) => {
                  const stepProps = {}
                  const labelProps = {}
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                      <StepContent>{this.getStepContent(index)}</StepContent>
                    </Step>
                  )
                })}
              </Stepper>
            )}
            {this.props.change && this.props.currentStep === this.NUMBEROFSTEPS && (
              <>
                <Row noGutters>
                  <Col xs="12" sm="6">
                    <Typography className="text-margin bold">Adressänderung war erfolgreich</Typography>
                    <Typography variant="body2" className="text-margin regular-line-height">
                      Vielen Dank für Ihre Adressänderung.
                    </Typography>
                    <Typography variant="body2" className="text-margin regular-line-height">
                      Sie erhalten in wenigen Minuten eine Information über die Änderung per E-Mail.
                    </Typography>
                  </Col>
                  <Col xs="6" className="text-center profile-images">
                    <img src={AddressImage} alt="Persönliche Daten" className="smaller-picture-top"></img>
                  </Col>
                  <Col xs="12">
                    <Typography variant="body2" className="mb-4 regular-line-height">
                      Bitte beachten Sie, dass die Änderung nach 1-2 Werktagen im Portal sichtbar ist.
                    </Typography>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col xs="12">
                    <Button
                      className="square-button red-border"
                      onClick={this.reset}
                      variant="contained"
                      color="secondary">
                      OK
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </AccordionDetails>
      </Accordion>
    )
  }
}

const mapStateToProps = ({ data, datachange }: IRootState) => ({
  profile: data.profile,
  currentStep: datachange.currentStep,
  loading: datachange.loading,
  errorMessage: datachange.errorMessage,
  change: datachange.addressDataChange
})

const mapDispatchToProps = {
  reset,
  nextStep,
  previousStep,
  resetStep,
  setErrorMessage,
  resetErrorMessage,
  sendVerificationCode,
  checkVerificationCode,
  startAddressDataChange,
  completeAddressDataChange
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddressData)
