import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Typography, Tooltip } from '@material-ui/core'

import CarImage from 'resources/images/car_silhouette.png'
import BikeImage from 'resources/images/bike_silhouette.png'

export function RequestEntity(props: any) {
  return (
    <Container id="request" className="container-with-border content-container">
      <Row noGutters className="mt-3">
        <Col xs="12">
          <Typography variant="h4" className="text-left vertical-center full-height bold">
            {props.request.objectManufacturer} {props.request.objectModellName}
          </Typography>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs="6" className="vertical-center horizontal-center">
          <img
            id="request-item-image"
            src={props.request.objectType === 'BIKE' ? BikeImage : CarImage}
            alt={props.request.objectManufacturer + ' ' + props.request.objectModellName}
          />
        </Col>
        <Col xs="6" className="text-center">
          <Row noGutters>
            <Col>
              <Typography>Genehmigungsstatus</Typography>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Tooltip title={!props.request.stillActive ? 'Bitte setzen Sie sich mit uns in Verbindung' : ''}>
                <div
                  className={
                    'request-status request-status-' + (props.request.stillActive ? 'active' : 'not-active')
                  }></div>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
