import IUser from 'app/model/user.model'
import { isUndefined } from './common'

export const getEmailFromUser = (user: IUser) => {
  if (!isUndefined(user)) {
    return user.email
  } else {
    return null
  }
}
