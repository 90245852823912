import React from 'react'
import { Container, Col, Row } from 'reactstrap'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button
} from '@material-ui/core'
import RedTriangle from 'app/shared/components/red-triangle'
import { connect } from 'react-redux'
import IProfile from 'app/model/profile.model'
import EmailResetTextfield from './email-reset.textfield'
import EmailResetCodebox from './email-reset.codebox'
import {
  sendEmailValidationCode,
  validateEmailCode,
  validateSmsCode,
  resetErrorMessage,
  resetCurrentStep,
  previousStep
} from 'app/shared/reducers/email-reset.reducer'
import { IRootState } from 'app/shared/reducers'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'

export interface IEmailResetProps extends StateProps, DispatchProps {
  profile: IProfile
  anchor: string
}

export interface IEmailResetState {
  username: string
  expanded: boolean
  changeUsername: boolean
}

export class EmailReset extends React.Component<IEmailResetProps & RouteComponentProps, IEmailResetState> {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      expanded: true,
      changeUsername: false
    }
  }

  componentDidMount() {
    this.props.resetErrorMessage()
    this.reset()
    if (this.props.location.search === '?emailedit') {
      this.startEdit()
    }
  }

  getSteps() {
    return ['E-Mail-Adresse (Benutzername) ändern', 'Bestätigungscode eingeben', 'Authentifizierungscode eingeben']
  }

  handleChange = () => {
    this.setState({ ...this.state, expanded: !this.state.expanded })
  }

  startEdit = () => {
    this.props.resetCurrentStep()
    this.setState({ ...this.state, changeUsername: true })
  }

  updateUsername = (event) => {
    this.props.resetErrorMessage()
    this.setState({ ...this.state, username: event.target.value })
  }

  enterNewUsername = () => {
    this.props.sendEmailValidationCode(this.state.username)
  }

  checkEmailCode = (enteredCode: string) => {
    this.props.validateEmailCode(enteredCode)
  }

  checkSmsCode = (enteredCode: string) => {
    this.props.validateSmsCode(enteredCode)
  }

  reset = () => {
    this.setState({ ...this.state, changeUsername: false })
    this.props.resetCurrentStep()
  }

  getStepContent = (index: number) => {
    const textfieldProps = {
      email: this.props.profile.email,
      username: this.state.username,
      enterNewUsername: this.enterNewUsername,
      updateUsername: this.updateUsername,
      errorDescription: this.props.errorMessage,
      loading: this.props.loading,
      previousFunc: this.reset
    }
    const mailCodeProps = {
      checkCode: this.checkEmailCode,
      feedback:
        'Wir haben eine E-Mail mit einem Bestätigungscode an Ihre neue E-Mail-Adresse gesendet. Bitte geben Sie diesen Bestätigungscode unten ein.',
      errorDescription: this.props.errorMessage,
      clearErrorMessage: this.props.resetErrorMessage,
      loading: this.props.loading,
      previousFunc: this.props.previousStep
    }
    const smsCodeProps = {
      checkCode: this.checkSmsCode,
      feedback:
        'Sie erhalten in wenigen Minuten einen Authentifizierungscode per SMS. Bitte geben Sie diesen Authentifizierungscode unten ein.',
      errorDescription: this.props.errorMessage,
      clearErrorMessage: this.props.resetErrorMessage,
      loading: this.props.loading,
      previousFunc: this.props.previousStep
    }

    switch (index) {
      case 0:
        return <EmailResetTextfield {...textfieldProps} />
      case 1:
        return <EmailResetCodebox {...mailCodeProps} />
      case 2:
        return <EmailResetCodebox {...smsCodeProps} />
      default:
        return 'ERROR'
    }
  }

  render() {
    return (
      <Accordion
        id="username"
        className="container-with-border content-container"
        expanded={this.state.expanded}
        onChange={() => this.handleChange()}>
        <AccordionSummary expandIcon={<RedTriangle medium />}>
          <Typography>Mein Benutzername</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {(!this.state.changeUsername && (
            <Container fluid className="p-0">
              <Row noGutters>
                <Col xs="12" md="6" className="mobile-margin-bottom">
                  <Typography variant="body2" className="regular-line-height bold">
                    Benutzername ändern:
                  </Typography>
                  <Typography variant="body2" className="regular-line-height">
                    Ihre E-Mail-Adresse ist auch Ihr Benutzername für die Anmeldung
                  </Typography>
                  <Typography variant="body2" className="regular-line-height bold">
                    {this.props.profile.email}
                  </Typography>
                </Col>
                <Col xs="12" md="6" className="text-center margin-auto">
                  <Button
                    className="square-button red-border no-uppercase account-button"
                    variant="contained"
                    color="secondary"
                    onClick={() => this.startEdit()}>
                    Benutzername ändern
                  </Button>
                </Col>
              </Row>
            </Container>
          )) || (
            <Stepper activeStep={this.props.currentStep} orientation="vertical" className="email-reset">
              {this.getSteps().map((label, index) => {
                const stepProps = {}
                const labelProps = {}
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    <StepContent>{this.getStepContent(index)}</StepContent>
                  </Step>
                )
              })}
            </Stepper>
          )}
          {this.props.currentStep === 3 && (
            <Container fluid className="p-0">
              <Row noGutters>
                <Col>
                  <Typography className="email-reset-header">
                    Sie haben erfolgreich Ihre E-Mail-Adresse (Benutzername) geändert und erhalten in wenigen Minuten
                    eine Bestätigung per E-Mail.
                  </Typography>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <Button
                    className="square-button red-border no-uppercase"
                    variant="contained"
                    color="secondary"
                    onClick={() => this.reset()}>
                    OK
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        </AccordionDetails>
      </Accordion>
    )
  }
}

const mapStateToProps = ({ emailreset }: IRootState) => ({
  errorMessage: emailreset.errorMessage,
  loading: emailreset.loading,
  currentStep: emailreset.currentStep
})

const mapDispatchToProps = {
  sendEmailValidationCode,
  validateEmailCode,
  validateSmsCode,
  resetErrorMessage,
  resetCurrentStep,
  previousStep
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(EmailReset)
