import axios from 'axios'

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util'
import { messages } from 'app/config/constants'

import { stringIsEmpty, handleBackendError, handleBackendErrorIfNot } from 'app/shared/utils/common'
import IUser from 'app/model/user.model'
import IKeyPassword from 'app/model/keyandpassword.model'

export const ACTION_TYPES = {
  RESET_ERROR: 'password/RESET_ERROR',
  SET_ERROR: 'password/SET_ERROR',
  RESET_STATE: 'password/RESET_STATE',
  SEND_RESET_MAIL: 'password/SEND_RESET_MAIL',
  GET_USER: 'password/GET_USER',
  SET_PASSWORD: 'password/SET_PASSWORD',
  SEND_CODE: 'password/SEND_CODE',
  VERIFY_CODE: 'password/VERIFY_CODE',
  COMPLETE_CHANGE: 'password/COMPLETE_CHANGE',
  CHECK_PASSWORD: 'password/CHECK_PASSWORD',
  PREV_STEP: 'password/PREV_STEP'
}

export const initialState = {
  loading: false,
  errorMessage: null as string,
  user: {} as IUser,
  passwordresetKey: null as string,
  password: '',
  pwRepeat: '',
  phoneVerified: false,
  currentStep: 0
}

export type PasswordState = Readonly<typeof initialState>

export default (state: PasswordState = initialState, action): PasswordState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEND_RESET_MAIL):
    case REQUEST(ACTION_TYPES.GET_USER):
    case REQUEST(ACTION_TYPES.SEND_CODE):
    case REQUEST(ACTION_TYPES.VERIFY_CODE):
    case REQUEST(ACTION_TYPES.COMPLETE_CHANGE):
    case REQUEST(ACTION_TYPES.CHECK_PASSWORD):
      return {
        ...state,
        loading: true
      }
    case SUCCESS(ACTION_TYPES.SEND_RESET_MAIL): {
      return {
        ...state,
        loading: false,
        errorMessage: null
      }
    }
    case SUCCESS(ACTION_TYPES.CHECK_PASSWORD):
    case SUCCESS(ACTION_TYPES.SEND_CODE): {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentStep: state.currentStep + 1
      }
    }
    case ACTION_TYPES.RESET_STATE: {
      return {
        ...initialState
      }
    }
    case SUCCESS(ACTION_TYPES.COMPLETE_CHANGE): {
      return {
        ...initialState,
        currentStep: state.currentStep + 1
      }
    }
    case SUCCESS(ACTION_TYPES.GET_USER): {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        user: action.payload.data,
        passwordresetKey: action.meta.key
      }
    }
    case SUCCESS(ACTION_TYPES.VERIFY_CODE): {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        phoneVerified: true
      }
    }
    case FAILURE(ACTION_TYPES.SEND_RESET_MAIL):
    case FAILURE(ACTION_TYPES.GET_USER):
    case FAILURE(ACTION_TYPES.COMPLETE_CHANGE): {
      return {
        ...state,
        loading: false,
        errorMessage:
          action.payload !== undefined && !stringIsEmpty(action.payload.response)
            ? action.payload.response.data.message
            : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    case FAILURE(ACTION_TYPES.CHECK_PASSWORD): {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload !== undefined ? messages.PASSWORD_ERROR : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    case FAILURE(ACTION_TYPES.SEND_CODE): {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload !== undefined ? messages.CODE_SENDING_ERROR : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    case FAILURE(ACTION_TYPES.VERIFY_CODE): {
      return {
        ...state,
        loading: false,
        errorMessage:
          action.payload !== undefined ? messages.CODE_VERIFICATION_ERROR : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    case ACTION_TYPES.PREV_STEP: {
      return {
        ...state,
        currentStep: state.currentStep > 0 ? state.currentStep - 1 : state.currentStep
      }
    }
    case ACTION_TYPES.SET_PASSWORD:
      return {
        ...state,
        password: action.payload.password,
        pwRepeat: action.payload.pwRepeat
      }
    case ACTION_TYPES.RESET_ERROR:
      return {
        ...state,
        errorMessage: null as string
      }
    case ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        errorMessage: action.payload.error
      }
    default: {
      return state
    }
  }
}

export const sendResetMail = (userMail: string) => ({
  type: ACTION_TYPES.SEND_RESET_MAIL,
  payload: axios.post('api/password/sendResetMail', userMail, { headers: { 'Content-Type': 'text/plain' } })
})

export const getUserByKey = (key: string) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.GET_USER,
    payload: axios.get<IUser>(`api/user/getUser?passwordresetKey=${key}`),
    meta: {
      key
    }
  })
}

export const sendVerificationCodeForUser = (userMail: string, force: boolean, onSuccess: Function) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.SEND_CODE,
    payload: axios.post(`api/user/sendVerificationCode/${force}`, userMail, {
      headers: { 'Content-Type': 'text/plain' }
    })
  }).then(
    () => {
      onSuccess()
    },
    (error) => {
      handleBackendError(error.response, error.response.status, 405, onSuccess)
    }
  )
}

export const sendVerificationCode = () => ({
  type: ACTION_TYPES.SEND_CODE,
  payload: axios.post('api/user/sendSmsCode')
})

export const checkVerificationCode = (
  passwordresetKey: string,
  codeEntered: string,
  onSuccess: Function,
  onError: Function
) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.VERIFY_CODE,
    payload: axios.get(`api/user/verifycode/${passwordresetKey}/${codeEntered}`)
  }).then(
    () => {
      onSuccess()
    },
    (error) => {
      // when backend sends NotificationMaximumExceededException, only show error message and don't do anything,
      // otherwise handle Error / go to Error Page
      handleBackendErrorIfNot(error.response, error.response.status, [405, 417], onError)
    }
  )
}

export const checkVerificationCodeForAuthenticatedUser = (
  codeEntered: string,
  onSuccess: Function,
  onError: Function
) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.VERIFY_CODE,
    payload: axios.get(`api/user/verifycode/${codeEntered}`)
  }).then(
    () => {
      onSuccess()
    },
    (error) => {
      handleBackendError(error.response, error.response.status, 403, onError)
    }
  )
}

export const completePasswordChange = (passwordresetKey: string, newPassword: string, onSuccess: Function) => (
  dispatch
) => {
  var keyAndPassword = {
    key: passwordresetKey,
    password: newPassword
  } as IKeyPassword
  return dispatch({
    type: ACTION_TYPES.COMPLETE_CHANGE,
    payload: axios.post('api/password/reset', keyAndPassword)
  }).then(
    () => {
      onSuccess()
    },
    () => {}
  )
}

export const completePasswordChangeForAuthenticatedUser = (newPassword: string) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.COMPLETE_CHANGE,
    payload: axios.post('api/password/change', newPassword, {
      headers: { 'Content-Type': 'text/plain' }
    })
  })
}

export const setPassword = (pw: string, pwRep: string) => ({
  type: ACTION_TYPES.SET_PASSWORD,
  payload: {
    password: pw,
    pwRepeat: pwRep
  }
})

export const resetErrorMessage = () => ({
  type: ACTION_TYPES.RESET_ERROR
})

export const setErrorMessage = (error: string) => ({
  type: ACTION_TYPES.SET_ERROR,
  payload: {
    error: error
  }
})

export const resetState = () => ({
  type: ACTION_TYPES.RESET_STATE
})

export const checkPassword = (password: string, onSuccess: Function) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.CHECK_PASSWORD,
    payload: axios.post('api/password/check', password, {
      headers: { 'Content-Type': 'text/plain' }
    })
  }).then(
    () => {
      onSuccess()
    },
    () => {}
  )
}

export const previousStep = () => ({
  type: ACTION_TYPES.PREV_STEP
})
