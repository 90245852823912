import axios from 'axios'

import { FAILURE, REQUEST, SUCCESS } from './action-type.util'
import { messages } from 'app/config/constants'

import { stringIsEmpty } from '../utils/common'

export const ACTION_TYPES = {
  SEND_UPLOADED_FILES: 'data/SEND_UPLOADED_FILES'
}

export const initialState = {
  loading: false,
  errorMessage: null as string
}

export type UploadState = Readonly<typeof initialState>

export default (state: UploadState = initialState, action): UploadState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEND_UPLOADED_FILES):
      return {
        ...state,
        loading: true
      }
    case SUCCESS(ACTION_TYPES.SEND_UPLOADED_FILES): {
      return {
        ...state,
        loading: false,
        errorMessage: null
      }
    }
    case FAILURE(ACTION_TYPES.SEND_UPLOADED_FILES): {
      return {
        ...state,
        loading: false,
        errorMessage:
          action.payload !== undefined && !stringIsEmpty(action.payload.response)
            ? action.payload.response.data.message
            : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    default: {
      return state
    }
  }
}

export const sendUploadedFiles = (
  uploadedFiles: FormData,
  fileCount: number,
  onSuccess: Function,
  onError: Function
) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.SEND_UPLOADED_FILES,
    payload: axios.post('api/user/upload', uploadedFiles)
  }).then(
    () => onSuccess(fileCount),
    (error) => onError(error.response, fileCount)
  )
}
