import React from 'react'
import { Col, Row, Container } from 'reactstrap'

export default function BackToTop() {
  return (
    <Container>
      <Row>
        <Col>
          <div className="back-to-top" onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
            <i className="fas fa-chevron-up" />
          </div>
        </Col>
      </Row>
    </Container>
  )
}
