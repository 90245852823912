import React from 'react'
import { Row, Col } from 'reactstrap'
import { Typography } from '@material-ui/core'

import icon from 'resources/images/inbox_icon.png'
import IUser from 'app/model/user.model'
import InfoboxHeader from './infobox.header'

export interface IInbox {
  user: IUser
  messageCountUnread: number
}

export class InboxInfoContainer extends React.Component<IInbox> {
  render() {
    let inboxNewMessagesNotification = 'Es '
    if (this.props.messageCountUnread === 1) {
      inboxNewMessagesNotification += 'ist 1 neues Dokument'
    } else if (this.props.messageCountUnread > 1) {
      inboxNewMessagesNotification += 'sind ' + this.props.messageCountUnread + ' neue Dokumente'
    } else {
      inboxNewMessagesNotification += 'sind keine neuen Dokumente'
    }
    inboxNewMessagesNotification += ' in Ihrem Posteingang'

    return (
      <>
        <InfoboxHeader imgSource={icon} />
        <Row>
          <Col xs="12" className="vertical-center horizontal-center">
            <Typography variant="h5" className="text-margin bold red text-margin">
              Hallo {this.props.user.firstName} {this.props.user.lastName}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="vertical-center horizontal-center">
            <Typography variant="body1">{inboxNewMessagesNotification}</Typography>
          </Col>
        </Row>
      </>
    )
  }
}

export default InboxInfoContainer
