import React from 'react'
import { NavHashLink as Link } from 'react-router-hash-link'
import { Typography } from '@material-ui/core'
import IFAQContent from 'app/model/faqcontent.model'

const addressChange = {
  title: 'Adresse ändern',
  text: (
    <Link to="/portal/profile#address">
      <Typography>
        Sie sind umgezogen oder haben eine neue Adresse? In der Navigation unter „Meine Daten“ können Sie Ihre neuen
        Adressdaten bequem online anpassen. Einfach auf <span className="nowrap">„Meine Daten“</span> klicken, um die
        Änderung vorzunehmen. Bitte halten Sie für die Änderung eine Kopie des Personalausweises (Vorder- und Rückseite)
        oder eine Kopie des Reisepasses mit Meldebescheinigung bereit.
      </Typography>
    </Link>
  )
}

const phoneChange = {
  title: 'Telefonnummer ändern',
  text: (
    <Link to="/portal/profile#communication">
      <Typography>
        Ihre Telefonnummer hat sich geändert? In der Navigation unter „Meine Daten“ können Sie Ihre neuen Mobiltelefon- oder
        Festnetznummer bequem online anpassen. Klicken Sie auf <span className="nowrap">„Meine Daten“</span>, um die
        Änderung vorzunehmen.
      </Typography>
    </Link>
  )
}

const nameChange = {
  title: 'Name ändern',
  text: (
    <Link to="/portal/profile#data">
      <Typography>
        Sie möchten uns Ihre Namensänderung mitteilen? In der Navigation unter „Meine Daten“ können Sie Ihre Namensänderung
        bequem online durchführen. Einfach auf <span className="nowrap">„Meine Daten“</span> klicken, um die Änderung
        vorzunehmen. Bitte halten Sie für die Änderung eine amtliche Bescheinigung bereit.
      </Typography>
    </Link>
  )
}

const bankAccountChange = {
  title: 'Bankverbindung ändern',
  text: (
    <Link to="/portal/contract#bankaccount">
      <Typography>
        Ihre Kontoverbindung hat sich geändert? In der Navigation unter „Vertrag“ können Sie Ihre Änderung der
        Bankverbindung bequem online durchführen. Klicken Sie auf <span className="nowrap">„Mein Vertrag“</span>, um die
        Änderung vorzunehmen.
      </Typography>
    </Link>
  )
}
const uploadDocument = {
  title: 'Dokumente hochladen',
  text: (
    <>
      <Link to="/portal/request#upload">
        <Typography>
          Sie möchten Dokumente für Ihren Antrag hochladen? In der Navigation unter „Antrag“ können Sie Ihre Dokumente
          bequem hochladen. Einfach auf <span className="nowrap">„Mein Antrag“</span> klicken, um die Änderung
          vorzunehmen.
        </Typography>
      </Link>
      <Link to="/portal/contract#upload">
        <Typography className="mt-3">
          Sie möchten Dokumente für Ihren Vertrag hochladen? In der Navigation unter „Vertrag“ können Sie Ihre Dokumente
          bequem hochladen. Wählen Sie dafür in der Navigation <span className="nowrap">„Mein Vertrag“</span>, um die
          Änderung vorzunehmen.
        </Typography>
      </Link>
    </>
  )
}

const changePassword = {
  title: 'Passwort ändern',
  text: (
    <Link to="/portal/account#password">
      <Typography>
        Sie möchten Ihr Passwort aus Sicherheitsgründen ändern oder sperren? In der Navigation unter „Mein Konto“ können
        Sie Ihre Zugangsdaten bequem jederzeit verwalten. Einfach <span className="nowrap">„Mein Passwort“</span>{' '}
        auswählen und dieses nach wenigen Schritten ändern.
      </Typography>
    </Link>
  )
}

const changeUsername = {
  title: 'Benutzernamen ändern',
  text: (
    <Link to="/portal/account#username">
      <Typography>
        Sie möchten Ihren Benutzernamen / E-Mail-Adresse ändern? Wählen Sie dafür in der Navigation „Mein Konto“ aus.
        Klicken Sie auf <span className="nowrap">„Benutzername ändern“</span> um diesen in wenigen Schritten abzuändern.
      </Typography>
    </Link>
  )
}

const deleteAccount = {
  title: 'Konto löschen',
  text: (
    <Link to="/portal/account#delete">
      <Typography>
        Sie möchten Ihr Konto löschen? In der Navigation unter „Mein Konto“ können Sie Ihre Zugangsdaten bequem jederzeit
        verwalten. Einfach <span className="nowrap">„Konto löschen“</span> anklicken und das Konto löschen.
      </Typography>
    </Link>
  )
}

const generalQuestions = {
  title: 'Allgemeine Vertragsfragen',
  text: (
    <a href="https://www.honda-bank.de/faq.php" target="_blank" rel="noopener noreferrer">
      <Typography>
        Sie haben noch weitere Fragen zu Ihrem Vertrag? Schauen Sie doch mal auf der Honda Bank FAQ Seite
        honda-bank.de/faq vorbei.
      </Typography>
    </a>
  )
}

const digitalMessaging = {
  title: 'Zustellart Schriftverkehr',
  text: (
    <Link to="/portal/profile">
      <Typography>
        In der Navigation unter „Meine Daten“ können Sie jederzeit die Zustellart für den Schriftverkehr verwalten.
        Einfach auf „Zustellart für den Schriftverkehr ändern“ klicken, um die Änderung vorzunehmen.
      </Typography>
    </Link>
  )
}

const mailbox = {
  title: 'Postfach (nur in Verbindung mit digitalem Schriftverkehr)',
  text: (
    <Link to="/portal/inbox">
      <Typography>
        Sie haben der elektronischen Form der Zustellung von Dokumenten und Schreiben zu gestimmt? Dann erhalten
        Sie künftig ausgewählte Schreiben und Dokumente digital in Ihrem Postfach zur Verfügung gestellt. In der
        Navigation unter „Postfach“ können Sie bequem Ihre Post einsehen und verwalten.
      </Typography>
    </Link>
  )
}

const contactForm = {
  title: 'Kontaktformular (Fragen zum Vertrag)',
  text: (
    <Link to="/portal/contact">
      <Typography>
        Sie haben eine Rückfrage zu Ihrem Antrag/Vertrag oder möchten uns eine Nachricht zukommen lassen? In der
        Navigation unter „Kontakt“ können Sie uns jederzeit eine Nachricht zu Ihrem Antrag/Vertrag zu kommen lassen. Einfach
        auf „Kontakt“ klicken, einen der Kontaktgründe auswählen oder die Nachricht frei formulieren und abschicken.
      </Typography>
    </Link>
  )
}

const technicalSupport = {
  title: 'Technischer Support',
  text: (
    <Link to="/portal/support">
      <Typography>
        Sie erhalten keine SMS oder E-Mail? Haben Probleme beim Hochladen von Dokumenten? In der Navigation unter
        „Technischer Support“ können Sie uns jederzeit eine Nachricht zukommen lassen. Einfach auf „Technischer Support“
        klicken, einen der Kontaktgründe auswählen oder die Nachricht frei formulieren und abschicken.
      </Typography>
    </Link>
  )
}

export const faqContent: IFAQContent[] = [
  addressChange,
  phoneChange,
  nameChange,
  bankAccountChange,
  digitalMessaging,
  uploadDocument,
  changePassword,
  changeUsername,
  mailbox,
  contactForm,
  technicalSupport,
  deleteAccount,
  generalQuestions
]

export default faqContent
