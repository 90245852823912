import './password.css'

import React from 'react'

import { stringIsEmpty, isUndefined } from 'app/shared/utils/common'
import PasswordValidationIndicator from './password-validation-indicator'

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { TextField } from '@material-ui/core'

export interface IPasswordChangeForm {
  nextButtonText: string
  password: string
  pwRepeat: string
  handleValidSubmit: any
  fullWidthButton: boolean
  type: string
  username?: string
  previousFunc?: any
}

export interface IPasswordChangeFormStateProps {
  user: {
    password: string
    pwRepeat: string
  }
  showPassword: boolean
  showPasswordRepeat: boolean
}

export class PasswordChangeForm extends React.Component<IPasswordChangeForm, IPasswordChangeFormStateProps> {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        password: this.props.password,
        pwRepeat: this.props.pwRepeat
      },
      showPassword: false,
      showPasswordRepeat: false
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.user.password) {
        return false
      }
      return true
    })
    ValidatorForm.addValidationRule('isPasswordValid', (value) => {
      return this.checkPasswordComplexity(value)
    })
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isPasswordMatch')
    ValidatorForm.removeValidationRule('isPasswordValid')
  }

  handleChange = (event) => {
    const { user } = this.state
    user[event.target.name] = event.target.value
    this.setState({ user })
  }

  handleValidSubmit = () => {
    this.props.handleValidSubmit(this.state.user.password, this.state.user.pwRepeat)
  }

  checkPasswordComplexity = (value: string) => {
    var numberPattern = new RegExp('[0-9]')
    var lowerLettersPattern = new RegExp('[a-z,ä,ö,ü]')
    var upperLettersPattern = new RegExp('[A-Z,Ä,Ö,Ü]')
    var specialCharsPattern = /[!"§$%&/()=?[\\\]{}+\-_#'*<>@,;^°|`´:.~]/g
    if (
      !numberPattern.test(value) ||
      !lowerLettersPattern.test(value) ||
      !upperLettersPattern.test(value) ||
      !specialCharsPattern.test(value) ||
      value.length < 8
    ) {
      return false
    }
    return true
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleClickShowPasswordRepeat = () => {
    this.setState({ showPasswordRepeat: !this.state.showPasswordRepeat })
  }

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  useTogglePassword() {
    const { user } = this.state
    if (stringIsEmpty(this.props.password)) {
      return (
        <TextValidator
          label="Passwort"
          onChange={this.handleChange}
          name="password"
          type={this.state.showPassword ? 'text' : 'password'}
          validators={['isPasswordValid', 'required']}
          errorMessages={['', '']}
          value={user.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}>
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )
    }
    return (
      <TextValidator
        label="Passwort"
        onChange={this.handleChange}
        name="password"
        type={this.state.showPassword ? 'text' : 'password'}
        validators={['isPasswordValid', 'required']}
        errorMessages={['', '']}
        value={user.password}
      />
    )
  }

  useTogglePasswordRepeat() {
    const { user } = this.state
    if (stringIsEmpty(this.props.password)) {
      return (
        <TextValidator
          label="Passwort wiederholen"
          onChange={this.handleChange}
          name="pwRepeat"
          type={this.state.showPasswordRepeat ? 'text' : 'password'}
          validators={['isPasswordMatch', 'required']}
          errorMessages={['Die Passwörter müssen übereinstimmen', '']}
          value={user.pwRepeat}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPasswordRepeat}
                  onMouseDown={this.handleMouseDownPassword}>
                  {this.state.showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )
    }
    return (
      <TextValidator
        label="Passwort wiederholen"
        onChange={this.handleChange}
        name="pwRepeat"
        type={this.state.showPasswordRepeat ? 'text' : 'password'}
        validators={['isPasswordMatch', 'required']}
        errorMessages={['', '']}
        value={user.pwRepeat}
      />
    )
  }

  render() {
    return (
      <div className="innerForm">
        <ValidatorForm className="full-width" onSubmit={this.handleValidSubmit}>
          <TextField id="username" hidden defaultValue={this.props.username} />
          <FormControl fullWidth>{this.useTogglePassword()}</FormControl>
          <PasswordValidationIndicator password={this.state.user.password} type={this.props.type} />
          <div className="margin" />
          <FormControl fullWidth>{this.useTogglePasswordRepeat()}</FormControl>
          <div className="margin" />
          {!isUndefined(this.props.previousFunc) && (
            <Button
              className="square-button mr-2"
              onClick={this.props.previousFunc}
              variant="contained"
              color="primary">
              Zurück
            </Button>
          )}
          <Button
            className="square-button"
            fullWidth={this.props.fullWidthButton}
            type="submit"
            variant="contained"
            color="secondary">
            {this.props.nextButtonText}
          </Button>
        </ValidatorForm>
      </div>
    )
  }
}

export default PasswordChangeForm
