import React from 'react'
import { NavHashLink as Link } from 'react-router-hash-link'
import { Typography } from '@material-ui/core'
// import config from 'app/config/constants'

const whatIsTheCustomerPortal = {
  title: 'Was ist das Kundenportal?',
  text: (
    <Typography>
      Das Kundenportal ist ein Service-Portal für Finanzierungskunden, in dem Sie alle Informationen zu Ihren
      persönlichen Daten sowie Ihre Anträge oder Verträge abrufen können.
    </Typography>
  )
}

// const accessCustomerPortal = {
//   title: 'Aufruf des Kundenportals',
//   text: (
//     <>
//       <Typography>
//         Für die Registrierung nutzen Sie bitten den Link <Link to="/register">{config.FRONTEND_URL + 'register'}</Link>.
//         Im Anschluss an die Registrierung erhalten Sie eine Bestätigung per E-Mail. Diese kann wenige Minuten in
//         Anspruch nehmen. Sie können Ihre Anmeldedaten später im Bereich „PROFIL“ ändern.
//       </Typography>
//       <Typography>
//         Sollten Sie aus irgendeinem Grund Probleme bei der Passworteinstellung haben, wenden Sie sich bitte an unseren
//         technischen Support.
//       </Typography>
//     </>
//   )
// }

// const howToRegister = {
//   title: 'Wie kann ich mich für das Kundenportal registrieren?',
//   text: (
//     <>
//       <Typography>
//         Das Registrieren ist einfach und dauert nur wenige Minuten. Für die Erstellung eines Benutzerkontos im
//         Kundenportal, benötigen Sie Ihre E-Mailadresse und ein Passwort. Im Anschluss erhalten Sie eine E-Mail an Ihre
//         registrierte E-Mail-Adresse mit einem Aktivierungslink. Bitte verwenden Sie diesen Link, um Ihre E-Mail-Adresse
//         zu bestätigen und Ihr Konto zu aktivieren. Zur Verifizierung Ihrer E-Mail-Adresse ist die Eingabe folgender
//         Informationen notwendig:
//       </Typography>
//       <ul className="list-circle">
//         <li>
//           <Typography>Ihr Name</Typography>
//         </li>
//         <li>
//           <Typography>Ihre E-Mail</Typography>
//         </li>
//         <li>
//           <Typography>Ihre Mobilnummer</Typography>
//         </li>
//         <li>
//           <Typography>Ihr Geburtsdatum</Typography>
//         </li>
//         <li>
//           <Typography>Vertragsnummer</Typography>
//         </li>
//         <li>
//           <Typography>TAN</Typography>
//         </li>
//       </ul>
//       <Typography>
//         Sollten Sie Probleme bei der Registrierung oder weitere Fragen haben, nehmen Sie bitte Kontakt zu unserem
//         technischen Support auf.
//       </Typography>
//     </>
//   )
// }

const activationLinkNotWorking = {
  title: 'Was ist zu tun, wenn der Aktivierungslink nicht funktioniert?',
  text: (
    <Link to="/support">
      <Typography>Bitte nehmen Kontakt zu unserem technischen Support auf.</Typography>
    </Link>
  )
}

const contractOverview = {
  title: 'Was kann ich in der Vertragsübersicht sehen?',
  text: <Typography>Die Vertragsübersicht gibt Ihnen einen Überblick über Ihre Finanzierungsverträge.</Typography>
}

const passwordReset = {
  title: 'Wie kann ich mein Passwort zurücksetzen? ',
  text: (
    <Link to="/passwordreset/mail">
      <Typography>
        Klicken Sie auf &quot;Passwort vergessen&quot; im Anmeldebildschirm und wir senden Ihnen für die Erstellung
        eines neuen Passworts, eine E-Mail zu.
      </Typography>
    </Link>
  )
}

const accountLocked = {
  title: 'Gesperrter Zugang',
  text: (
    <Link to="/support">
      <Typography>
        Sollten Sie Ihr Passwort mehrfach falsch eingegeben haben, wird Ihr Zugang aus Sicherheitsgründen gesperrt. In
        diesem Fall wenden Sie sich bitte an den technischen Support.
      </Typography>
    </Link>
  )
}

export const faqContent = [
  whatIsTheCustomerPortal,
  // accessCustomerPortal,
  // howToRegister,
  activationLinkNotWorking,
  contractOverview,
  passwordReset,
  accountLocked
]

export default faqContent
