import './contract.css'

import React from 'react'
import { Typography } from '@material-ui/core'
import { RouteComponentProps, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import { stringIsEmpty, isUndefined } from 'app/shared/utils/common'
import { IRootState } from 'app/shared/reducers'
import { ContractDetails } from './contract.details'
import { ContractOverview } from './contract.overview'
import ContractBankAccount from './bankaccount/contract.bankaccount'
import { ContractObject } from './contract.object'
import { ContractRequestOverview } from './contract.requestoverview'
import Upload from 'app/components/upload/upload'
import { ContractDealer } from './contract.dealer'
import { ContractEntity } from './contract.entity'

export interface IContractState {
  expanded: {}
}

export interface IContractProps extends StateProps, DispatchProps {}

export class Contract extends React.Component<IContractProps & RouteComponentProps, IContractState> {
  constructor(props) {
    super(props)
    this.state = {
      expanded: {
        details: true,
        benefits: true,
        dealer: true
      }
    }
  }

  render() {
    const contracts = this.props.contracts

    if (contracts === null) {
      return <></>
    }

    const anchor = !stringIsEmpty(this.props.location.hash) ? this.props.location.hash.split('#')[1] : null

    const getDisplayContract = () => {
      if (contracts && contracts.length > 1) {
        const pathSplit = this.props.location.pathname.split('/')
        const contractId = Number(pathSplit[pathSplit.length - 1])
        return contracts.filter((row) => row.id === contractId)[0]
      } else {
        return contracts[0]
      }
    }

    const contract = getDisplayContract()
    if (isUndefined(contract)) {
      if (!isUndefined(contracts) && contracts.length > 1) {
        return <Redirect to="/portal/contractselect" />
      } else if (!isUndefined(contracts) && contracts.length === 1) {
        return <Redirect to="/portal/contract" />
      } else {
        return <Redirect to="/portal/" />
      }
    }
    const props = {
      contract: { ...contract },
      id: contract.id,
      type: 'contract',
      anchor,
      locale: this.props.user.locale
    }

    return (
      <div className="contentContainer">
        <Typography variant="h3" className="section-heading">
          Mein Vertrag
        </Typography>
        <div key="contract_1">
          <ContractEntity {...props} />
          <ContractDetails {...props} />
          <ContractOverview {...props} />
          <ContractBankAccount {...props} />
          <ContractObject {...props} />
          <ContractRequestOverview {...props} />
          <ContractDealer {...props} />
          <Upload {...props} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ data, authentication }: IRootState) => ({
  contracts: data.contracts,
  user: authentication.user
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(Contract)
