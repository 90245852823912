import React from 'react'
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createTechSupportRequestMessage } from 'app/components/portal/contact/contact.reducer'
import { IRootState } from 'app/shared/reducers'
import { INTERNAL_SUPPORT_REASONS } from 'app/config/constants'

export interface ITechsupportInternalFormProps extends StateProps, DispatchProps {}

export interface ITechsupportInternalFormState {
  messageText: string
  contactReason: string
  contactReasons: string[]
  hasError: boolean
  errorText: string
  success: boolean
}

export class TechsupportInternalForm extends React.Component<ITechsupportInternalFormProps & RouteComponentProps, ITechsupportInternalFormState> {
  constructor(props) {
    super(props)
    this.state = {
      messageText: '',
      contactReason: 'none',
      contactReasons: INTERNAL_SUPPORT_REASONS,
      hasError: false,
      errorText: '',
      success: false
    }
  }

  sendSupportRequest = () => {
    if (this.state.contactReason === 'none') {
      this.setState({ ...this.state, hasError: true, errorText: 'Bitte wählen Sie einen Kontaktgrund aus!' })
    } else if (this.state.messageText === '') {
      this.setState({ ...this.state, hasError: true, errorText: 'Bitte beschreiben Sie Ihr Problem im vorgesehenen Textfeld!' })
    } else {
      this.props.createTechSupportRequestMessage(this.state.contactReason, this.state.messageText, this.setFeedbackAfterSending, this.setErrorAfterSending)
    }
  }

  setFeedbackAfterSending = () => {
    this.setState({ ...this.state, success: true })
  }

  setErrorAfterSending = () => {
    this.setState({ ...this.state, hasError: true, errorText: this.props.error })
  }

  selectContactReason = (e) => {
    this.setState({ ...this.state, contactReason: e.target.value, hasError: false, errorText: '' })
  }

  updateMessageText = (e) => {
    this.setState({ ...this.state, messageText: e.target.value, hasError: false, errorText: '' })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    if (this.props.isLoading) {
      return (
        <>
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <div className="contentContainer mt-4 mb-5">
                  <Typography variant="h3" className="section-heading">
                    Technischer Support
                  </Typography>
                  <div className="content-container faq-container container-with-border">
                    <Container fluid>
                      <Row noGutters>
                        <Col xs="12">
                          <div className="text-center">
                            <CircularProgress></CircularProgress>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return (
        <>
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <div className="contentContainer mt-4 mb-5">
                  <Typography variant="h3" className="section-heading">
                    Technischer Support
                  </Typography>
                  <div className="content-container faq-container container-with-border">
                    <Container fluid>
                      <Form>
                        {this.state.success &&
                        <Row noGutters>
                          <Col xs="12">
                            <Typography>Ihre Supportanfrage wurde erfolgreich versendet. Sie erhalten in Kürze eine E-Mail zur Bestätigung.</Typography>
                          </Col>
                          <Col xs="12">
                            <div className="align-center d-block">
                              <Button
                                className="square-button no-uppercase mr-2"
                                variant="contained"
                                color="primary"
                                onClick={this.goBack}>
                                Zurück
                              </Button>
                            </div>
                          </Col>
                        </Row>}
                        {!this.state.success &&
                        <Row noGutters>
                          <Col xs="12">
                            <Typography><i className="fas fa-info-circle"></i> Häufig gestellte Fragen finden Sie <Link className="underline" to="/portal/faq">hier</Link></Typography>
                          </Col>
                          {this.state.hasError && <Col xs="12"><Typography className="red" variant="body2">{this.state.errorText}</Typography></Col>}
                          <Col xs="12" md="3">
                            <Typography>Kontaktgrund</Typography>
                          </Col>
                          <Col xs="12" md="9">
                            <FormGroup>
                              <Input defaultValue='none' type="select" name="select" onChange={this.selectContactReason}>
                                <option value='none' disabled={true} selected={true} defaultValue='none'>Auswahl: Kontaktgrund</option>
                                {this.state.contactReasons.map((option) =>
                                  <option key={option} value={option}>{option}</option>
                                )}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3">
                            <Typography>Fehlerbeschreibung</Typography>
                          </Col>
                          <Col xs="12" md="9" className="text-margin">
                            <FormGroup>
                              <Input maxLength={5000} type="textarea" name="text" onChange={this.updateMessageText} style={{ height: 200 }}/>
                            </FormGroup>
                          </Col>
                          <Col xs="0" md="3">
                          </Col>
                          <Col xs="12" md="9" className="text-margin">
                            <div className="align-center d-block">
                              <Button
                                className="square-button no-uppercase mr-2"
                                variant="contained"
                                color="primary"
                                onClick={this.goBack}>
                                Abbrechen
                              </Button>
                              <Button
                                className="square-button no-uppercase mr-2"
                                variant="contained"
                                color="secondary"
                                onClick={this.sendSupportRequest}>
                                Absenden
                              </Button>
                            </div>
                          </Col>
                        </Row>}
                      </Form>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )
    }
  }
}

const mapStateToProps = ({ contact }: IRootState) => ({
  error: contact.errorMessage,
  isLoading: contact.loading
})

const mapDispatchToProps = {
  createTechSupportRequestMessage
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(TechsupportInternalForm)
