import axios from 'axios'

import { FAILURE, REQUEST, SUCCESS } from './action-type.util'
import IUser from 'app/model/user.model'
import { AUTH, messages } from 'app/config/constants'

import { stringIsEmpty, isUndefined } from '../utils/common'
import { tokenIsValid } from '../auth/token'

import { reset as dataReset } from './data.reducer'
import { resetState as passwordReset } from 'app/components/password/password.reducer'
import { reset as inboxReset } from 'app/components/portal/inbox/inbox.reducer'

export const ACTION_TYPES = {
  LOGINNAME: 'authentication/LOGINNAME',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGIN: 'authentication/LOGIN',
  RESET_ERROR: 'authentication/RESET_ERROR',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  REFRESH_TOKEN: 'authentication/REFRESH_TOKEN'
}

export const initialState = {
  loginname: null as string,
  sessionHasBeenFetched: false,
  user: null as IUser,
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false,
  errorMessage: null as string
}

export type AuthenticationState = Readonly<typeof initialState>

export default (state: AuthenticationState = initialState, action): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
    case REQUEST(ACTION_TYPES.REFRESH_TOKEN): {
      return {
        ...state,
        loading: true
      }
    }
    case SUCCESS(ACTION_TYPES.LOGIN):
    case SUCCESS(ACTION_TYPES.REFRESH_TOKEN): {
      return {
        ...state,
        loading: false,
        loginSuccess: true,
        loginError: false,
        errorMessage: null
      }
    }
    case FAILURE(ACTION_TYPES.LOGIN):
    case FAILURE(ACTION_TYPES.REFRESH_TOKEN): {
      return {
        ...state,
        loading: false,
        loginSuccess: false,
        loginError: true,
        errorMessage: !stringIsEmpty(action.payload.response)
          ? action.payload.response.data.message
          : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    case ACTION_TYPES.GET_SESSION: {
      return {
        ...state,
        isAuthenticated: false,
        sessionHasBeenFetched: true
      }
    }
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      const isAuthenticated = action.payload && !isUndefined(action.payload.data)
      return {
        ...state,
        loading: false,
        isAuthenticated,
        sessionHasBeenFetched: true,
        user: action.payload.data
      }
    }
    case FAILURE(ACTION_TYPES.GET_SESSION): {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        errorMessage: !stringIsEmpty(action.payload.response)
          ? action.payload.response.data.message
          : messages.ERROR_SERVER_COMMUNICATION
      }
    }
    case ACTION_TYPES.LOGINNAME: {
      return {
        ...state,
        loginname: action.payload.loginname
      }
    }
    case ACTION_TYPES.RESET_ERROR: {
      return {
        ...state,
        loginError: false,
        errorMessage: null
      }
    }
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...initialState
      }
    default: {
      return state
    }
  }
}

export const getSession = () => async (dispatch: Function) => {
  if (tokenIsValid()) {
    // eslint-disable-next-line no-return-await
    return await dispatch({
      type: ACTION_TYPES.GET_SESSION,
      payload: axios.get('api/account')
    }).then(
      () => {},
      () => {}
    )
  } else {
    return dispatch({
      type: ACTION_TYPES.GET_SESSION
    })
  }
}

export const login = (username, password) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post('api/authenticate/login', { username, password })
  }).then(
    (result) => {
      const jwt = result && result.value && result.value.data && result.value.data.id_token
      if (jwt) {
        window.sessionStorage.setItem(AUTH.TOKEN_KEY, jwt)
      }
      dispatch(getSession())
    },
    () => {}
  )
}

export const setLoginname = (loginname) => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.LOGINNAME,
    payload: { loginname }
  })
}

export const resetError = () => ({
  type: ACTION_TYPES.RESET_ERROR
})

export const clearAuthToken = () => {
  if (window.sessionStorage.getItem(AUTH.TOKEN_KEY)) {
    window.sessionStorage.removeItem(AUTH.TOKEN_KEY)
  }
  if (window.sessionStorage.getItem(AUTH.USERNAME_KEY)) {
    window.sessionStorage.removeItem(AUTH.USERNAME_KEY)
  }
}

export const logout = () => (dispatch) => {
  dispatch(clearAuthentication('logout.user.initiated'))
  dispatch(dataReset())
  dispatch(passwordReset())
  dispatch(inboxReset())
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const clearAuthentication = (messageKey) => (dispatch) => {
  clearAuthToken()
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH
  })
}

export const refreshToken = () => ({
  type: ACTION_TYPES.REFRESH_TOKEN,
  payload: axios.get('/api/authenticate/refresh')
})
