
const config = {
  VERSION: process.env.VERSION,
  SERVER_API_URL: process.env.REACT_APP_BACKEND_URL,
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  REMAINING_SECONDS_SHOW_POPUP: 60
}

export default config

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  CUSTOMER: 'ROLE_CUSTOMER'
}

export const AUTH = {
  TOKEN_KEY: 'authenticationToken',
  USERNAME_KEY: 'username'
}

export const BENEFITS_ANCHOR_FOR_FILTERING = '#benefits'

export const SUPPORT_PHONE_NUMBER = '069 48 98 - 9030'

export const DEFAULT_VALID_UPLOAD_FILES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'image/gif',
  'application/pdf'
]

export const DEFAULT_VALID_UPLOAD_FILE_DESCRIPTION = 'PDF-, JPEG-, GIF-, PNG- oder DOCX-'
export const DEFAULT_MIN_UPLOAD_FILE_COUNT = 1
export const DEFAULT_MAX_UPLOAD_FILE_COUNT = 5

export const INTERNAL_SUPPORT_REASONS = [
  'Ich erhalte keine SMS/keine Email',
  'Ich erhalte keinen Sicherheitscode',
  'Ich kann kein Dokument hochladen',
  'Sonstige Anliegen (bitte unter "Nachricht" beschreiben)'
]

export const EXTERNAL_SUPPORT_REASONS = [
  'Ich erhalte keine SMS/keine Email',
  'Ich habe ein Problem bei der Registrierung/Anmeldung im Portal',
  'Ich habe eine neue E-Mail Adresse oder eine neue Mobilfunknummer',
  'Sonstige Anliegen (bitte unter "Nachricht" beschreiben)'
]

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
  PHONE_VERIFICATION_ERROR:
    'Die Verifizierung hat leider nicht funktioniert. ' +
    'Bitte prüfen Sie Ihre Antrags- bzw. Vertragsunterlagen bevor Sie es erneut versuchen.',
  ERROR_SERVER_COMMUNICATION: 'Fehler bei der Kommunikation mit dem Server',
  NAVIGATION_OVERVIEW: 'Übersicht',
  CODE_VERIFICATION_ERROR: 'Der Bestätigungscode war leider nicht korrekt. Bitte versuchen Sie es erneut.',
  CODE_SENDING_ERROR:
    'Sie haben das tägliche Limit an Nachrichten überschritten. ' +
    'Falls Sie keinen Code erhalten haben, kontaktieren Sie bitte unseren Kundensupport.',
  PASSWORD_ERROR: 'Ihr eingegebenes Passwort war leider nicht korrekt. Bitte versuchen Sie es erneut.',
  DIGITAL_COMMUNICATION_CHECKBOX_LABEL:
    'Ich bin damit einverstanden, dass ich künftige Dokumente und Schreiben der Honda Bank in elektronischer Form erhalte',
  DIGITAL_COMMUNICATION_ENABLE:
    'Sie möchten zukünftig Ihren Schriftverkehr digital in Ihrem Postfach erhalten? Klicken Sie bitte auf den Button aktivieren. Die Einstellung ist jederzeit änderbar.',
  DIGITAL_COMMUNICATION_DISABLE:
    'Sie möchten zukünftig Ihren Schriftverkehr per Post erhalten? Klicken Sie bitte auf den Button deaktivieren. Die Einstellung ist jederzeit änderbar.'
}

export const inboxFolders = {
  messages: 'Mitteilungen',
  archived: 'Archiv',
  deleted: 'Gelöscht'
}

export const requestNavPoint = {
  name: 'Antrag',
  link: '/portal/request',
  selectLink: '/portal/requestselect',
  subnav: [
    {
      name: 'Antrag',
      link: '/portal/request',
      anchor: '#request'
    },
    {
      name: 'Antragsdetails',
      link: '/portal/request',
      anchor: '#details'
    },
    {
      name: 'Zusatzleistungen',
      link: '/portal/request',
      anchor: '#benefits'
    },
    {
      name: 'Händler',
      link: '/portal/request',
      anchor: '#dealer'
    },
    {
      name: 'Upload',
      link: '/portal/request',
      anchor: '#upload'
    }
  ]
}

export const contractNavPoint = {
  name: 'Vertrag',
  link: '/portal/contract',
  selectLink: '/portal/contractselect',
  subnav: [
    {
      name: 'Vertrag',
      link: '/portal/contract',
      anchor: '#contract'
    },
    {
      name: 'Kreditvertragsübersicht',
      link: '/portal/contract',
      anchor: '#overview'
    },
    {
      name: 'Bankverbindung',
      link: '/portal/contract',
      anchor: '#bankaccount'
    },
    {
      name: 'Objektdaten',
      link: '/portal/contract',
      anchor: '#object'
    },
    {
      name: 'Antragsdaten',
      link: '/portal/contract',
      anchor: '#request'
    },
    {
      name: 'Händler',
      link: '/portal/contract',
      anchor: '#dealer'
    },
    {
      name: 'Upload',
      link: '/portal/contract',
      anchor: '#upload'
    }
  ]
}

export const navPoints = [
  {
    name: 'Meine Daten',
    link: '/portal/profile',
    showAlways: true,
    subnav: [
      {
        name: 'Persönliche Daten',
        link: '/portal/profile',
        anchor: '#data'
      },
      {
        name: 'Adresse',
        link: '/portal/profile',
        anchor: '#address'
      },
      {
        name: 'Kommunikationsdaten',
        link: '/portal/profile',
        anchor: '#communication'
      },
      {
        name: 'Upload',
        link: '/portal/profile',
        anchor: '#upload'
      }
    ]
  },
  {
    name: 'Mein Konto',
    link: '/portal/account',
    showAlways: true,
    subnav: [
      {
        name: 'Benutzername',
        link: '/portal/account',
        anchor: '#username'
      },
      {
        name: 'Passwort',
        link: '/portal/account',
        anchor: '#password'
      },
      {
        name: 'Konto',
        link: '/portal/account',
        anchor: '#account'
      },
      {
        name: 'FAQ',
        link: '/portal/account',
        anchor: '#faq'
      },
      {
        name: 'Konto löschen',
        link: '/portal/account',
        anchor: '#delete'
      }
    ]
  },
  {
    name: 'Postfach',
    link: '/portal/inbox',
    showAlways: true,
    linkMatchStartsWith: true,
    subnav: [
      {
        name: 'Postfach',
        link: '/portal/inbox',
        anchor: '#inbox'
      },
      {
        name: inboxFolders.messages,
        link: '/portal/inbox/messages',
        anchor: ''
      },
      {
        name: inboxFolders.archived,
        link: '/portal/inbox/archived',
        anchor: ''
      },
      {
        name: inboxFolders.deleted,
        link: '/portal/inbox/deleted',
        anchor: ''
      }
    ]
  }
]
