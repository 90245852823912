import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Typography } from '@material-ui/core'
import { dateFormat, currencyFormat } from 'app/shared/utils/common'
import { SUPPORT_PHONE_NUMBER } from 'app/config/constants'
import { fourColumnRowElement } from '../common/component.util'

export function ContractDetails(props: any) {
  const installmentNextAmount = fourColumnRowElement(
    'In Höhe von',
    currencyFormat(props.contract.installmentNextAmount, props.locale),
    null,
    'installmentNextAmount'
  )
  const installmentNextDate = fourColumnRowElement(
    'Nächste Rate am',
    dateFormat(props.contract.installmentNextDate, props.locale),
    null,
    'installmentNextDate'
  )
  const installmentLastDate = fourColumnRowElement(
    'Letzte Rate am',
    dateFormat(props.contract.contractEnd, props.locale),
    null,
    'installmentLastDate'
  )
  const residualAmount =
    props.contract.residualAmount > 0
      ? fourColumnRowElement(
        'Restrate / Schlussrate',
        currencyFormat(props.contract.residualAmount, props.locale),
        null,
        'residualAmount'
      )
      : ''
  const installmentRemainingCount = fourColumnRowElement(
    'Anzahl der Restraten',
    props.contract.installmentRemainingCount,
    null,
    'installmentRemainingCount'
  )
  const contractEnd = fourColumnRowElement(
    'Laufzeitende',
    dateFormat(props.contract.contractEnd, props.locale),
    null,
    'contractEnd'
  )

  return (
    <Container className="container-with-border content-container">
      <Row noGutters className="fourColumnRow">
        {installmentNextDate}
        {installmentNextAmount}
        {installmentLastDate}
        {residualAmount}
        {installmentRemainingCount}
        {contractEnd}
      </Row>
      {props.contract.pleaseGetInContact && (
        <Row noGutters>
          <Col xs="2" md="1" className="vertical-center horizontal-center text-danger">
            <i className="fas fa-exclamation-triangle big"></i>
          </Col>
          <Col xs="10" md="11">
            <Typography className="regular-line-height">
              Bitte setzen Sie sich mit uns unter{' '}
              <a className="bold text-nowrap" href={'tel:' + SUPPORT_PHONE_NUMBER}>
                {SUPPORT_PHONE_NUMBER}
              </a>{' '}
              in Verbindung
            </Typography>
          </Col>
        </Row>
      )}
    </Container>
  )
}
