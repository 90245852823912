import '../registration/registration.css'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { stringIsEmpty } from 'app/shared/utils/common'

export interface IPasswordValidatorProps {
  password: string
  type: string
}

export const PasswordValidationIndicator = ({ password, type }: IPasswordValidatorProps) => {
  const numberPattern = new RegExp('[0-9]')
  const lowerLettersPattern = new RegExp('[a-z,ä,ö,ü]')
  const upperLettersPattern = new RegExp('[A-Z,Ä,Ö,Ü]')
  const specialCharsPattern = /["!§$%&/()=?[\\\]{}+\-_#'*<>@,;^°|`´:.~]/g

  const testCases = (pw: string) => {
    const result = []
    result.push(!numberPattern.test(pw))
    result.push(!lowerLettersPattern.test(pw))
    result.push(!upperLettersPattern.test(pw))
    result.push(!specialCharsPattern.test(pw))
    result.push(pw.length < 8)

    return result
  }

  const testResult = testCases(password)

  const checkPasswordComplexity = (result: boolean[], pw: string): number => {
    if (result.includes(true)) {
      return 1
    } else if (pw.length < 10) {
      return 2
    } else {
      return 3
    }
  }

  const complexity = checkPasswordComplexity(testResult, password)

  const getValidationStatus = (indicator: number): string => {
    switch (indicator) {
      case 1:
        return 'unsafe'
      case 2:
        return 'okay'
      case 3:
        return 'safe'
      default:
        return ''
    }
  }

  const getValidationStatusString = (indicator: number): string => {
    switch (indicator) {
      case 1:
        return 'Unzureichend'
      case 2:
        return 'Gut'
      case 3:
        return 'Sicher'
    }
  }

  const getMessages = (result: boolean[]) => {
    var hasError = false
    const messages = []
    if (result[0]) {
      messages.push('eine Zahl')
      hasError = true
    }
    if (result[1]) {
      messages.push('einen Kleinbuchstaben')
      hasError = true
    }
    if (result[2]) {
      messages.push('einen Großbuchstaben')
      hasError = true
    }
    if (result[3]) {
      messages.push('ein Sonderzeichen')
      hasError = true
    }
    if (hasError) {
      return buildMessageString(messages)
    } else {
      return ''
    }
  }

  const buildMessageString = (messages: string[]): string => {
    var notificationText = 'Das Passwort muss mindestens '
    if (messages.length > 2) {
      notificationText = notificationText + messages[0]
      for (var i = 1; i <= messages.length - 2; i++) {
        notificationText = notificationText + ', ' + messages[i]
      }
      notificationText = notificationText + ' und ' + messages[messages.length - 1]
    } else if (messages.length === 2) {
      notificationText = notificationText + messages[0] + ' und ' + messages[1]
    } else {
      notificationText = notificationText + messages
    }
    notificationText = notificationText + ' beinhalten.'
    return notificationText
  }

  const getValidationStrengthIndicator = (indicator: number) => {
    let iconProps
    if (!stringIsEmpty(type)) {
      if (type.toLowerCase() === 'bike') {
        iconProps = { className: 'fas fa-motorcycle' }
      } else {
        iconProps = { className: 'fas fa-car' }
      }
    } else {
      iconProps = { className: 'fas fa-car' }
    }
    switch (indicator) {
      case 1:
        return <i {...iconProps}></i>
      case 2:
        return (
          <>
            <i {...iconProps}></i>
            <i {...iconProps}></i>
          </>
        )
      case 3:
        return (
          <>
            <i {...iconProps}></i>
            <i {...iconProps}></i>
            <i {...iconProps}></i>
          </>
        )
      default:
        return ''
    }
  }

  const validationStatusString = getValidationStatusString(complexity)
  const validationStatus = getValidationStatus(complexity)

  const validationRequirements = getMessages(testResult)

  return (
    <div className="pwValidator">
      <div className={validationStatus} id="pwStrengthIndicator">
        {!stringIsEmpty(password) && password.length > 0 && getValidationStrengthIndicator(complexity)}
      </div>
      <Typography className={validationStatus} id="pwValidationLabel" variant="body2">
        {(!stringIsEmpty(password) && password.length > 0 && validationStatusString) || <span>&nbsp;</span>}
      </Typography>
      <Typography className="pw-validation-notification">{validationRequirements}</Typography>
    </div>
  )
}

export default PasswordValidationIndicator
