import axios from 'axios'

import { FAILURE, REQUEST, SUCCESS } from './action-type.util'
import IProfile from 'app/model/profile.model'
import { messages } from 'app/config/constants'

import { stringIsEmpty } from '../utils/common'
import IRequest from 'app/model/request.model'
import IContract from 'app/model/contract.model'

export const ACTION_TYPES = {
  SET_PROFILE: 'data/SET_PROFILE',
  FETCH_PROFILE: 'data/FETCH_PROFILE',
  FETCH_REQUESTS: 'data/FETCH_REQUESTS',
  FETCH_CONTRACTS: 'data/FETCH_CONTRACTS',
  SET_MOBILE_PHONE: 'data/SET_MOBILE_PHONE',
  SET_DIGITAL_COMMUNICATION: 'data/SET_DIGITAL_COMMUNICATION',
  RESET: 'data/RESET'
}

export const initialState = {
  profile: null as IProfile,
  requests: null as IRequest[],
  contracts: null as IContract[],
  loading: false,
  errorMessage: null as string
}

export type DataState = Readonly<typeof initialState>

export default (state: DataState = initialState, action): DataState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PROFILE):
    case REQUEST(ACTION_TYPES.FETCH_REQUESTS):
    case REQUEST(ACTION_TYPES.FETCH_CONTRACTS):
      return {
        ...state,
        loading: true
      }
    case FAILURE(ACTION_TYPES.FETCH_PROFILE):
    case FAILURE(ACTION_TYPES.FETCH_REQUESTS):
    case FAILURE(ACTION_TYPES.FETCH_CONTRACTS):
      return {
        ...state,
        loading: false,
        errorMessage:
          action.payload !== undefined && !stringIsEmpty(action.payload.response)
            ? action.payload.response.data.message
            : messages.ERROR_SERVER_COMMUNICATION
      }
    case SUCCESS(ACTION_TYPES.FETCH_PROFILE):
      return {
        ...state,
        loading: false,
        profile: action.payload.data,
        errorMessage: null
      }
    case SUCCESS(ACTION_TYPES.FETCH_REQUESTS):
      return {
        ...state,
        loading: false,
        requests: action.payload.data,
        errorMessage: null
      }
    case SUCCESS(ACTION_TYPES.FETCH_CONTRACTS): {
      return {
        ...state,
        loading: false,
        contracts: action.payload.data,
        errorMessage: null
      }
    }
    case ACTION_TYPES.SET_PROFILE: {
      return {
        ...state,
        profile: action.payload.profile
      }
    }
    case ACTION_TYPES.SET_MOBILE_PHONE: {
      return {
        ...state,
        profile: {
          ...state.profile,
          mobilePhone: action.payload
        }
      }
    }
    case ACTION_TYPES.SET_DIGITAL_COMMUNICATION: {
      return {
        ...state,
        profile: {
          ...state.profile,
          digitalCommunicationEnabled: action.payload
        }
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...initialState
      }
    }
    default: {
      return state
    }
  }
}

export const setProfile = (profile: IProfile) => ({
  type: ACTION_TYPES.SET_PROFILE,
  payload: {
    profile: profile
  }
})

export const fetchProfile = () => ({
  type: ACTION_TYPES.FETCH_PROFILE,
  payload: axios.get('/api/user/profile')
})

export const fetchRequests = () => ({
  type: ACTION_TYPES.FETCH_REQUESTS,
  payload: axios.get('/api/request/')
})

export const fetchContracts = () => ({
  type: ACTION_TYPES.FETCH_CONTRACTS,
  payload: axios.get('/api/contract/')
})

export const reset = () => ({
  type: ACTION_TYPES.RESET
})

export const setMobilePhone = (mobilePhone: string) => ({
  type: ACTION_TYPES.SET_MOBILE_PHONE,
  payload: mobilePhone
})

export const setDigitalCommunicationEnabled = (enabled: boolean) => ({
  type: ACTION_TYPES.SET_DIGITAL_COMMUNICATION,
  payload: enabled
})
