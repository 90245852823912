import React from 'react'
import Typography from '@material-ui/core/Typography'

export class RegistrationNewUserConfirmed extends React.Component {
  render() {
    return (
      <div className="paper inner-form full-width">
        <Typography variant="h2">Registrierung</Typography>
        <Typography className="mt-5 mw-100" variant="body2">
          Wir haben Ihnen auf Ihre E-Mail-Adresse einen Bestätigungslink geschickt. Bitte klicken Sie diesen an, um die
          Registrierung abzuschließen.
        </Typography>
        <div className="margin" />
      </div>
    )
  }
}

export default RegistrationNewUserConfirmed
