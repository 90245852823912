import './registration.css'

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { IRootState } from 'app/shared/reducers'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { Container, Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { valueOrEmptyString } from 'app/shared/utils/common'
import { verifyRegistrationData } from 'app/shared/reducers/registration.reducer'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'

export interface IRegistrationNewUserForm extends StateProps, DispatchProps {}

export interface IRegistrationDataNewUser {
  lastname: string
  email: string
  mobilePhone: string
  birthdate: any
  tan: string
  contractNumber: string
}

export class RegistrationNewUserForm extends React.Component<
  IRegistrationNewUserForm & RouteComponentProps,
  IRegistrationDataNewUser
> {
  constructor(props) {
    super(props)
    this.state = {
      lastname: '',
      email: '',
      mobilePhone: '',
      birthdate: '',
      tan: '',
      contractNumber: ''
    }
  }

  handleValidSubmit = () => {
    this.props.history.push('/registration/new/confirmed')
  }

  handleError = () => {
    this.props.history.push('/registration/new/error')
  }

  verifyData = () => {
    const dateInput = this.state.birthdate.split('.')
    const year = dateInput[2]
    const month = dateInput[1]
    const day = dateInput[0]
    const birthDate = moment()
      .year(year)
      .month(month - 1)
      .date(day)
      .format('YYYY-MM-DD')

    const registrationData = {
      lastname: this.state.lastname,
      email: this.state.email,
      mobilePhone: this.state.mobilePhone,
      birthDate: birthDate,
      contractNumber: this.state.contractNumber,
      tan: this.state.tan
    }
    this.props.verifyRegistrationData(registrationData, this.handleValidSubmit, this.handleError)
  }

  handleNameChange = (event) => {
    this.setState({ lastname: event.target.value })
  }

  handleMailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  handleMobilePhoneChange = (event) => {
    this.setState({ mobilePhone: event.target.value })
  }

  handleBirthdateChange = (event) => {
    this.setState({ birthdate: event.target.value })
  }

  handleTanChange = (event) => {
    this.setState({ tan: event.target.value })
  }

  handleContractNumberChange = (event) => {
    this.setState({ contractNumber: event.target.value })
  }

  render() {
    return (
      <div className="paper inner-form w-100">
        <Typography variant="h3" color="primary">
          Willkommen
        </Typography>
        <Typography variant="body2" className="no-line-height" display="inline">
          im Honda Bank Kundenportal
        </Typography>
        <ValidatorForm
          className="full-width registration-new-form mt-3"
          onSubmit={this.verifyData}
          instantValidate={false}>
          <Container fluid className="p-0">
            <Row noGutters>
              <Col xs="12">
                <TextValidator
                  id="lastname"
                  name="lastname"
                  className="registrationInputField"
                  label="Nachname"
                  onChange={this.handleNameChange}
                  value={this.state.lastname}
                  validators={['required']}
                  errorMessages={['Der Nachname darf nicht leer sein']}
                />
              </Col>
              <Col xs="12">
                <TextValidator
                  id="email"
                  name="email"
                  className="registrationInputField"
                  label="E-Mail-Adresse (Benutzername)"
                  onChange={this.handleMailChange}
                  value={this.state.email}
                  validators={['required', 'isEmail']}
                  errorMessages={['Die E-Mail darf nicht leer sein', 'Bitte geben Sie eine valide E-Mail ein']}
                />
              </Col>
              <Col xs="12">
                <TextValidator
                  id="mobilePhone"
                  name="mobilePhone"
                  className="registrationInputField"
                  label="Mobilnummer"
                  onChange={this.handleMobilePhoneChange}
                  value={this.state.mobilePhone}
                  validators={['required', 'matchRegexp:^[0-9 -_\\/+\\(\\)]*$']}
                  errorMessages={[
                    'Die Mobilnummer darf nicht leer sein',
                    'Bitte geben Sie eine valide Telefonnummer ein'
                  ]}
                />
              </Col>
              <Col xs="12">
                <TextValidator
                  id="dateOfBirth"
                  name="dateOfBirth"
                  className="registrationInputField mt-3"
                  label="Geburtsdatum"
                  onChange={this.handleBirthdateChange}
                  value={valueOrEmptyString(this.state.birthdate)}
                  validators={['required', 'matchRegexp:^[0-9]{1,2}\\.[0-9]{1,2}\\.[0-9]{4}$']}
                  errorMessages={[
                    'Das Geburtsdatum darf nicht leer sein',
                    'Bitte geben Sie ein valides Geburtsdatum ein'
                  ]}
                />
              </Col>
              <Col xs="12">
                <TextValidator
                  id="contractNumber"
                  name="contractNumber"
                  className="registrationInputField"
                  label="Vertragsnummer"
                  onChange={this.handleContractNumberChange}
                  value={this.state.contractNumber}
                  validators={['required']}
                  errorMessages={['Die Vertragsnummer darf nicht leer sein']}
                />
              </Col>
              <Col xs="12">
                <TextValidator
                  id="tan"
                  name="tan"
                  className="registrationInputField"
                  label="TAN"
                  onChange={this.handleTanChange}
                  value={this.state.tan}
                  validators={['required', 'minStringLength:10', 'maxStringLength:10']}
                  errorMessages={[
                    'Die TAN darf nicht leer sein',
                    'Die TAN muss 10 stellig sein',
                    'Die TAN muss 10 stellig sein'
                  ]}
                />
              </Col>
            </Row>
          </Container>
          {(this.props.loading && (
            <Container fluid className="p-0">
              <Row noGutters>
                <Col xs="12" className="text-center mt-4">
                  <CircularProgress></CircularProgress>
                </Col>
              </Row>
            </Container>
          )) || (
            <Button
              className="mt-4 square-button red-border"
              type="submit"
              fullWidth
              variant="contained"
              color="secondary">
              Weiter
            </Button>
          )}
        </ValidatorForm>
      </div>
    )
  }
}

const mapStateToProps = ({ registration }: IRootState) => ({
  user: registration.user,
  loading: registration.loading
})

const mapDispatchToProps = {
  verifyRegistrationData
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default compose<any>(withRouter, connect(mapStateToProps, mapDispatchToProps))(RegistrationNewUserForm)
