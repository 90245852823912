import './inbox.css'

import React from 'react'
import { Typography, Divider, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'

import { IRootState } from 'app/shared/reducers'

import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

export interface IInboxFoldersProps extends StateProps, DispatchProps {}

export class InboxFolders extends React.Component<IInboxFoldersProps> {
  render() {
    return (
      <Container id="inbox" className="container-with-border content-container">
        <Row>
          <Col>
            <Typography variant="h5" className="pb-3 pt-3">
              Ordner:{' '}
              <span className="bold">
                <Link to="/portal/inbox">Postfach</Link>
              </span>
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs="2" align="center">
            <Typography>Typ</Typography>
          </Col>
          <Col xs="7" align="left">
            <Typography>Name</Typography>
          </Col>
          <Col xs="3" align="center"></Col>
        </Row>
        <Divider className="divider-3" />
        {renderFolderRow(
          '/portal/inbox/messages',
          'Mitteilungen',
          this.props.messageCountUnread > 0,
          this.props.messageCountUnread
        )}
        <Divider />
        {renderFolderRow('/portal/inbox/archived', 'Archiv', false, 0)}
        <Divider />
        {renderFolderRow('/portal/inbox/deleted', 'Gelöscht', false, 0)}
      </Container>
    )
  }
}

const renderFolderRow = (linktarget: string, name: string, bold: boolean, messageCount: number) => {
  return (
    <Link to={linktarget}>
      <Row className="pb-3 pt-1">
        <Col xs="2" align="center" className="align-self-center">
          <Typography>
            <i className="fas fa-folder-open"></i>
          </Typography>
        </Col>
        <Col xs="7" align="left" className="align-self-center">
          <Typography className={bold ? 'bold' : ''}>
            {name} {messageCount > 0 ? '(' + messageCount + ')' : ''}
          </Typography>
        </Col>
        <Col xs="3" align="center" className="align-self-center">
          <Tooltip title={'Nachrichten in ' + name + ' anzeigen'}>
            <Typography>
              <i className="fas fa-search"></i>
            </Typography>
          </Tooltip>
        </Col>
      </Row>
    </Link>
  )
}

const mapStateToProps = ({ inbox }: IRootState) => ({
  messageCountUnread: inbox.messageCountUnread
})

const mapDispatchToProps = {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(InboxFolders)
