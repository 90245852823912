import './upload.css'

import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { Container, Row, Col } from 'reactstrap'
import Dropzone from 'react-dropzone'
import { FileListItem } from './file-list-item'
import { IUploadFile } from './upload'
import { isUndefined } from 'app/shared/utils/common'

export interface IUploadContainerProps {
  sendUploadedFiles: any
  cancel: any
  customButton: boolean
  customButtonText: string
  validFiles: string[]
  validFileDescription: string
  maxFileCount: number
  minFileCount: number
  initialUploadDisabled: boolean
}

export interface IUploadContainerState {
  files: IUploadFile[]
  uploadDisabled: boolean
  counter: number
}

export class UploadContainer extends React.Component<IUploadContainerProps, IUploadContainerState> {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      uploadDisabled: this.props.initialUploadDisabled,
      counter: 0
    }
  }

  handleDrop = <T extends File>(acceptedFiles: T[]) => {
    acceptedFiles.forEach((element) => {
      this.createUploadFile(element)
    })
    this.checkUploadFiles()
  }

  createUploadFile = (file: File) => {
    this.setState({ ...this.state, counter: this.state.counter + 1 })
    const files = this.state.files
    files.push({
      id: this.state.counter,
      name: file.name,
      size: file.size,
      type: file.type,
      isValid: this.checkIfValid(file),
      content: file
    })
    this.setState({ ...this.state, files: files })
  }

  checkIfValid = (file: File) => {
    if (file.size > 5000000) {
      return false
    }
    if (file.name.length > 40) {
      return false
    }
    let isValid = false
    for (let i = 0; i < this.props.validFiles.length; i++) {
      if (file.type === this.props.validFiles[i]) {
        isValid = true
      }
    }
    return isValid
  }

  checkUploadFiles = () => {
    this.setState({ ...this.state, uploadDisabled: false })
    if (this.state.files.length < this.props.minFileCount) {
      this.setState({ ...this.state, uploadDisabled: true })
    }
    this.state.files.forEach((file) => {
      if (!file.isValid) {
        this.setState({ ...this.state, uploadDisabled: true })
      }
    })
    if (this.state.files.length > this.props.maxFileCount) {
      this.setState({ ...this.state, uploadDisabled: true })
    }
  }

  deleteFile = (id: number) => {
    var uploadDisabled = false
    const updatedFiles = []
    this.state.files.forEach((file) => {
      if (file.id !== id) {
        updatedFiles.push(file)
      }
    })
    if (updatedFiles.length < this.props.minFileCount) {
      uploadDisabled = true
    }
    if (updatedFiles.length > this.props.maxFileCount) {
      uploadDisabled = true
    }
    updatedFiles.forEach((file) => {
      if (!file.isValid) {
        uploadDisabled = true
      }
    })
    this.setState({ ...this.state, files: updatedFiles, uploadDisabled: uploadDisabled })
  }

  render() {
    return (
      <Container fluid className="p-0">
        <Row noGutters className="upload-component">
          <Col>
            <Dropzone onDrop={(acceptedFiles) => this.handleDrop(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-file-picker">
                      <Typography variant="body2" className="flex-start not-mobile-block">
                        <i className="far fa-file-alt"></i> Dateien mit Drag & Drop hier ablegen...
                      </Typography>
                      <Typography className="upload-header">Dokument hochladen</Typography>
                      <div className="upload-file-picker-button">
                        <Typography>
                          <i className="fas fa-folder-open"></i> Datei auswählen
                        </Typography>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </Col>
        </Row>
        <Row noGutters className="upload-component">
          <Col></Col>
        </Row>
        <Row noGutters className="upload-component">
          <Col>
            <Typography variant="body2">
              Die Datei muss im {this.props.validFileDescription}Format vorliegen. Der Dateiname darf maximal 40
              Zeichen lang sein und die Dateigröße höchstens 5 MB betragen. Es können nicht mehr als {this.props.maxFileCount} Dateien
              gleichzeitig versendet werden.
            </Typography>
          </Col>
        </Row>
        <Row noGutters className="upload-component">
          <Col>
            {this.state.files.map((row) => (
              <div key={row.id}>
                <div className="upload-file-row">
                  <FileListItem {...row} />
                  <div className="upload-file-delete" onClick={() => this.deleteFile(row.id)}>
                    <Typography variant="body2" className="smaller-fontsize">
                      <i className="fas fa-times" />
                    </Typography>
                  </div>
                </div>
                {!this.checkIfValid(row.content) && (
                  <Row noGutters>
                    <Col xs="12">
                      <Typography variant="body2" className="smaller-fontsize">
                        Die hochgeladene Datei enstpricht nicht den oben genannten Datei Richtlinien. Bitte versuchen
                        Sie es erneut.
                      </Typography>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
          </Col>
        </Row>
        {this.state.files.length > this.props.maxFileCount && (
          <Row noGutters className="upload-component">
            <Col>
              <Typography variant="body2">Die maximale Anzahl von Dokumenten wurde überschritten.</Typography>
            </Col>
          </Row>
        )}
        <Row noGutters>
          <Col>
            <div className="align-center d-block">
              {!isUndefined(this.props.cancel) && (
                <Button
                  className="square-button no-uppercase mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.cancel()}>
                  Abbrechen
                </Button>
              )}
              <Button
                className={'square-button red-border no-uppercase ' + (isUndefined(this.props.cancel) ? '' : 'ml-2')}
                variant="contained"
                color="secondary"
                disabled={this.state.uploadDisabled}
                onClick={() => this.props.sendUploadedFiles(this.state.files)}>
                {!this.props.customButton && this.state.files.length > 1 && 'Dokumente absenden'}
                {!this.props.customButton && this.state.files.length < 2 && 'Dokument absenden'}
                {this.props.customButton && this.props.customButtonText}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}
